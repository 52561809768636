import Modal from '../../../../components/modal/modal';
import React, { useState, useEffect } from 'react';
import LoadingShadow from '../../../../components/loadingShadow';
import { NotificationManager } from 'react-notifications';

export default function ApportionmentModal({ 
    openModal, 
    onCloseModal, 
    license, 
    readOnly, 
    handleCurrencyFormat, 
    onChangeApportionment
}) {
    const [loading, setLoading] = useState(true);
    const [subscriptionCspQuotes, setSubscriptionCspQuotes] = useState([]);

    useEffect(() => {
        getApportionment();
    }, [license])

    const getApportionment = async () => {
        try{
            if(license?.axt_subscriptioncspid){
                let newApportionment = license.subscriptionCspQuotes;
                setSubscriptionCspQuotes(newApportionment);
            }
        } catch(error){
            onCloseModal();
            return NotificationManager.error(
                `Não foi possível retornar os dados do rateio. Favor entrar em contato com nossa equipe de atendimento.`,
                "Erro ao buscar dados do rateio.",
                7000
            );
        } finally {
            setLoading(false);
        }
    }

    const verifyApportionment = () => {
        const apportionmentSUM = subscriptionCspQuotes.reduce((acumulador, quote) => {
            return acumulador + +quote.axt_amount;
        }, 0);
      
        if (apportionmentSUM > license.axt_quantity) {
            NotificationManager.error(
                `A quantidade de rateio é maior do que a quantidade de assinaturas: ${license.axt_name}.`,
                "Quantidade de rateio inválida.",
                7000
            );
            return false;
        }
    
        if (apportionmentSUM < license.axt_quantity) {
            NotificationManager.error(
                `A quantidade de rateio é menor do que a quantidade de assinaturas: ${license.axt_name}.`,
                "Quantidade de rateio inválida.",
                7000
            );
            return false;
        }

        return true;
    }

    const handleConfirmButton = () => {
        if(verifyApportionment()){
            onCloseModal();
            onChangeApportionment(subscriptionCspQuotes, license);
        }
    }

    const changeAmountApportionment = (value, moreValues) => {
        var regex = /^[0-9]+$/;
        if(value && (!regex.test(value) || (value.toString().substring(0,2) == '00'))){
            return;
        } 

        let newQuotes = subscriptionCspQuotes.map(quote => ({
            ...quote,
            axt_amount: quote._axt_financialcontractid_value === moreValues._axt_financialcontractid_value? value : quote.axt_amount 
        }));

        setSubscriptionCspQuotes(newQuotes);
    }

    const onBlurAmountApportionment = (value, moreValues) => {
        if(!value){
            let newQuotes = subscriptionCspQuotes.map(quote => ({
                ...quote,
                axt_amount: quote._axt_financialcontractid_value === moreValues._axt_financialcontractid_value? (value ? value : 0) : quote.axt_amount 
            }));
    
            setSubscriptionCspQuotes(newQuotes);
        }
    }

    const disableConfirmButton = () => {
        let disable = true;

        if(subscriptionCspQuotes.length){
            for (let index = 0; index < subscriptionCspQuotes.length; index++) {
                const quote = license.subscriptionCspQuotes?.find(quote => quote._axt_financialcontractid_value === subscriptionCspQuotes[index]._axt_financialcontractid_value);
                if(subscriptionCspQuotes[index]?.axt_amount != quote?.axt_amount){
                    disable = false;
                    break;
                }
            }
        }

        return disable;
    }
    
    const renderModal = () => {
        return (
            <div className="csp-modal-wrapper">
                <h1>Ratear assinatura</h1>

                <div className="licenca-info">
                    <span className="title">{license?.axt_name}</span>
                    <span className="subtitle">
                        <strong>Quantidade: </strong>
                        <span>{license?.axt_quantity}</span>
                    </span>
                    <span className="subtitle"><strong>Valor unitário: </strong>{(license.axt_unitpricesale !== undefined) ? handleCurrencyFormat(license.axt_unitpricesale, license.axt_ProductId.transactioncurrencyid.currencysymbol) : handleCurrencyFormat(0, license.axt_ProductId.transactioncurrencyid.currencysymbol)}</span>
                    <span className="subtitle"><strong>Valor total: </strong>{(license.axt_unitpricesale !== undefined) ? handleCurrencyFormat(license.axt_unitpricesale * license.axt_quantity, license.axt_ProductId.transactioncurrencyid.currencysymbol) : handleCurrencyFormat(0, license.axt_ProductId.transactioncurrencyid.currencysymbol)}</span>
                </div>

                <div className="licencas-quantidade">
                    <div className="empresa-rateio">
                        {
                            subscriptionCspQuotes.map((companie, idx) => (
                                <div className="empresa-rateio-wrapper" key={companie._axt_financialcontractid_value || idx}>
                                    <div className="empresa-rateio-name">
                                        <span>Nome fantasia: {companie.axt_companieName}</span><br />
                                        <span>Contrato financeiro: {companie.axt_displayname}</span><br />
                                        <span>{companie.axt_cnpjview}</span><br />
                                        <span>Subtotal: 
                                            <strong>
                                                {!!license.axt_unitpricesale ? 
                                                handleCurrencyFormat(companie.axt_amount * license.axt_unitpricesale, license.axt_ProductId.transactioncurrencyid.currencysymbol) : 
                                                handleCurrencyFormat(0, license.axt_ProductId.transactioncurrencyid.currencysymbol)}
                                            </strong>
                                        </span>
                                    </div>

                                    <div className="rateio">
                                        <input 
                                            type="text" 
                                            className="filter" 
                                            value={companie.axt_amount}
                                            min="0"
                                            onChange={e => changeAmountApportionment(e.target.value, companie)}
                                            onBlur={e => onBlurAmountApportionment(e.target.value, companie)}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="actions">
                    <button 
                        id="btnEditar" 
                        className="btn btn-default"
                        onClick={handleConfirmButton}
                        disabled={disableConfirmButton()} 
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        )
    }

    if(loading){
        return (
            <LoadingShadow 
                sizeClassName={''}
                open={true} 
                close={() => {}}
            />
        )
    }

    return (
        <Modal specialClass="csp-modal" open={openModal} close={() => onCloseModal()}>
            {renderModal()}
        </Modal>
    )
}
