import React, { Component } from "react";
import Picky from "react-picky";
import "react-picky/dist/picky.css";

class CustomSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Picky
				value={this.props.value}
				options={this.props.data}
				onChange={this.props.onChange}
				open={false}
				className={this.props.className}
				valueKey="value"
				labelKey="label"
				multiple={this.props.multiple}
				disabled={this.props.disabled}
				includeSelectAll={true}
				selectAllText={'Selecionar todos'}
				includeFilter={true}
				filterPlaceholder={"Buscar..."}
				placeholder={"Selecione"}
				allSelectedPlaceholder={"Todas as licenças selecionadas"}
				manySelectedPlaceholder={"Você selecionou %s licenças opcionais"}
				dropdownHeight={400}
				render={({
					index,
					style,
					item,
					isSelected,
					selectValue,
					labelKey,
					valueKey,
					multiple
				}) => {
					return (
						<li
							style={{ ...style }} // required
							className={isSelected ? "selected" : ""} // required to indicate is selected
							key={item[valueKey]} // required
							onClick={() => selectValue(item)}
						>
							<input type="checkbox" checked={isSelected} readOnly />
								<span
									style={{
										color: 'black',
										fontWeight: "normal"//isSelected ? "bold" : "normal"
									}}
								>
								{item[labelKey]}
							</span>
						</li>
					);
				}}
			/>
		);
	}
}

export default CustomSelect;
