import getUserByContactId from "EstruturaAntiga/actions/dados/getUserByContactId"

export const rdxGetUser = () => {
    return dispatch => (getUserByContactId().then(obj => {
        dispatch({ type: 'COTACAO_GET_USER_INFO', payload: obj })
    }))
}

export const rdxClearDados = editable => ({
    type: 'COTACAO_CLEAR',
    payload: editable
})
