import PortalClienteService from "services/bases/PortalClienteService";

class ProdutosService extends PortalClienteService{
    async ListarProdutosPorDireito(direitoId){
        return this.get(`produtos/ListarProdutosPorDireito?direitoId=${direitoId}`)
    }
    async ListarTiposServicoParaOcorrencia(templateId, direitoId){
        return this.get(`produtos/ListarTiposServicoParaOcorrencia?modeloOcorrenciaId=${templateId}&direitoId=${direitoId}`)
    }
}

export default new ProdutosService();