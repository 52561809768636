import React from 'react';
import Modal from '../../../../components/modal/modal';
import Button from 'EstruturaAntiga/components/form/Button';
import { IoIosInformationCircleOutline } from 'react-icons/io';

export default props => {
        return (
            <div>
                <Modal open={true} close={props.onCloseModal}>
                    <div>
                        <div className="text-modal">
                            <p><IoIosInformationCircleOutline size={130} color="#FFF19D" /> </p> 
                            <p> Código de assessor já utilizado por outro usuário. Deseja utilizar mesmo assim? </p> 
                            <b>{props.cod}</b>
                        </div>
                        <div className="btn-modal btn-confirm-cod-assessor">
                            <button type="button" onClick={props.onCloseModal} className="btn">Não</button>
                            <Button type="button" onClick={props.onConfirmModal} className="btn btn-default">Sim</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
}

 