const _defaultState = {
    isEditable: true,
    listaGrupoEmail: [],
    listaGrupoEmailLoading: true,
    listaGrupoEmailEmpty: false,
    listaGrupoEmailSkipToken: true,
    listaUsers: [],
    listaUsersLoading: true,
    listaUsersEmpty: false,
    listaSkipToken: true,
    selectedItems: []
}

export default (state = _defaultState, action) => {
    if (action.type === 'LISTA_DISTRIBUICAO_CLEAR' || state.isEditable) {
        switch (action.type) {
            case 'LISTA_DISTRIBUICAO_LISTAGEM':
                return {
                    ...state,
                    listaGrupoEmail: [...state.listaGrupoEmail, ...action.payload.groups],
                    listaGrupoEmailLoading: false,
                    listaGrupoEmailEmpty: [...state.listaGrupoEmail, ...action.payload.groups].length === 0,
                    listaGrupoEmailSkipToken: action.payload.skipToken
                }
            case 'LISTA_DISTRIBUICAO_LISTAGEM_FILTER':
                return {
                    ...state,
                    listaGrupoEmail: action.payload.groups,
                    listaGrupoEmailLoading: false,
                    listaGrupoEmailEmpty: action.payload.groups.length === 0,
                    listaGrupoEmailSkipToken: action.payload.skipToken
                }
            case 'LISTA_DISTRIBUICAO_USUARIOS_LISTAGEM':
                return {
                    ...state,
                    listaUsers: [...state.listaUsers, ...action.payload.users],
                    listaUsersLoading: false,
                    listaUsersEmpty: [...state.listaUsers, ...action.payload.users].length === 0,
                    listaSkipToken: action.payload.skipToken
                }
            case 'LISTA_DISTRIBUICAO_USUARIOS_LISTAGEM_FILTER':
                return {
                    ...state,
                    listaUsers: action.payload.users,
                    listaUsersLoading: false,
                    listaUsersEmpty: action.payload.users.length === 0,
                    listaSkipToken: action.payload.skipToken
                }
            case 'LISTA_DISTRIBUICAO_USUARIOS_CLEAR':
                return {
                    ...state,
                    listaUsers: _defaultState.listaUsers,
                    listaUsersLoading: _defaultState.listaUsersLoading,
                    listaUsersEmpty: _defaultState.listaUsersEmpty,
                    listaSkipToken: _defaultState.listaSkipToken
                }
            case 'LISTA_DISTRIBUICAO_LISTAGEM_START_REQUEST':
                return { ...state, listaGrupoEmailLoading: true }
            case 'LISTA_DISTRIBUICAO_START_REQUEST':
                return { ...state, listaUsersLoading: true }
            case 'LISTA_DISTRIBUICAO_SELECT_USER':
                return { ...state, selectedItems: action.payload }
            case 'LISTA_DISTRIBUICAO_CLEAR':
                return { ..._defaultState, isEditable: action.payload }
            default:
                return state
        }
    }

    return state;
}