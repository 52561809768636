import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from '../../assets/urlsBase';

export default async function verifyConsultantCode(consultantCode){
  const url = `${urlBase.urlBase}/portaldb/Consultores/ChecarCodigoConsultor?codigoConsultor=${consultantCode}`;  
  
  let _header = await HttpRequest.apiTokenHeader();
  let _params = { method: 'GET', headers: _header };

  return fetch(url, _params)
      .then(response => response.json())
      .then(result => {
          return result;
      });
}