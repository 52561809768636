import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export async function getContact() {
  const url = `${urlBase.urlBase}/portaldb/contatos/ObterContato`;

    const header = await HttpRequest.apiTokenHeader();
    const params = {
        method: 'GET',
        headers: header
    };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
        return result;
        });
}
