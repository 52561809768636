import React, { Component } from 'react'
import RichTextEditor from 'react-rte'

export default class RithTextGroup extends Component {
    
    classError = () => {
        if (this.props.configureInput.showerror === "true" && this.props.configureInput.error) {
            return 'form-error';
        }

        return '';
    }

    error = () => {
        if (this.props.configureInput.showerror === "true" && this.props.configureInput.error) {
            return <span className="error">{this.props.configureInput.error}</span>
        }

        return null;
    }

    render() {
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
            INLINE_STYLE_BUTTONS: [
                {label: 'Bold', style: 'BOLD'},
                {label: 'Italic', style: 'ITALIC'},
                {label: 'Underline', style: 'UNDERLINE'},
                {label: 'Strikethrough', style: 'STRIKETHROUGH'}
            ],
            BLOCK_TYPE_DROPDOWN: [
                {label: 'Normal', style: 'unstyled'},
                {label: 'Título principal', style: 'header-one'},
                {label: 'Título secundário', style: 'header-two'},
                {label: 'Título pequeno', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
                {label: 'UL', style: 'unordered-list-item'},
                {label: 'OL', style: 'ordered-list-item'}
            ]
        };
        return (
            <div className={`form-group ${this.classError()}`}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <RichTextEditor 
                    className="input-group rich-text" 
                    toolbarConfig={toolbarConfig} 
                    id={this.props.id} 
                    {...this.props.configureInput.props} />
                {this.error()}
            </div>
        );
    };
}