import postDadosNavegacao from './postDadosNavegacao';

export default async function EnviarDadosNavegacao (page_path, usarPagePathComoLocation = false) {
    const contact = JSON.parse(sessionStorage.getItem('contact'));
    const agora = new Date;
    const dadosNavegacaoEUsuario = {
        contatoId: contact && contact.idContact,  
        pageLocation: (usarPagePathComoLocation == true) ? window.location.origin + "/" +  page_path : window.location.href ,
        pagePath: page_path,
        dataHorario: agora.getDate() + '/' + (agora.getMonth()+1) + '/' + agora.getFullYear() + ' ' + agora.getHours() + ':' + agora.getMinutes() + ':' + agora.getSeconds()
    };

    return await postDadosNavegacao(dadosNavegacaoEUsuario);
}

