import React, { Component } from 'react'

export default class ContentBox extends Component {

    render() {
        return (
            <section className={ "content-box " + (this.props.className || '') }>
                { this.props.children }
            </section>
        );
    };
}