import React, { useEffect } from 'react';

import { LicensesWrapper, LicenseItem } from './style';

export function LicensesContent({
  licenses,
  serviceLine,
  setLicensesAndPolicesHTMLContent
}) {
  useEffect(() => {
    generateLicenseHTML();
  }, [licenses]);

  function generateLicenseHTML() {
    const licenseHTML = licenses.map(license => serviceLine === 'basico' && (
      `<div key=${license.id} serviceLine=${serviceLine}>
        <div>
          <h4>${license.name}</h4>
          <p>Vigência: <strong>${license.period}</strong> Periodicidade de Cobrança: <strong>${license.frequency}</strong></p>
        </div>

        <div>
          <h4>${license.quantity}</h4>
          <p>Valor Unitário: <strong>${license.price && license.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></p>
        </div>
      </div>`
    ));

    const licenseHTMLWrapper = `<div>${licenseHTML.reduce((acc, license) => acc + license, '')}</div>`

    setLicensesAndPolicesHTMLContent(licenseHTMLWrapper);
  }

  return (
    <LicensesWrapper>
      <header>
        <strong>{serviceLine === 'basico' ? 'Licença' : 'Usuário'}</strong>
        <strong>{serviceLine === 'basico' ? 'Quantidade' : 'Licenças'}</strong>
      </header>

      <div>
        {licenses.map(license => serviceLine === 'basico' ? (
          <LicenseItem key={license.id} serviceLine={serviceLine}>
            <div>
              <h4>{license.name}</h4>
              <p>Vigência: <strong>{license.period}</strong> Periodicidade de cobrança: <strong>{license.frequency}</strong></p>
            </div>

            <div>
              <h4>{license.quantity}</h4>
              <p>Preço Unitário: <strong>{license.price && license.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></p>
            </div>
          </LicenseItem>
        ) : (
          <LicenseItem key={license.id} serviceLine={serviceLine}>
            <div>
              <h4>{license.user.name}</h4>
              <p>E-mail: <strong>{license.user.mail}</strong></p>
              <p>E-mail alternativo: <strong>{license.user.altMail}</strong></p>
              <p>Celular: <strong>{license.user.mobile}</strong></p>
              <p>Telefone: <strong>{license.user.phone}</strong></p>
              <p>Perfil de acesso: <strong>{license.user.profile}</strong></p>
            </div>

            <div>
              <h4>{license.bundle.name}</h4>
                <p>Vigência: <strong>{license.bundle.period}</strong> Periodicidade de cobrança: <strong>{license.bundle.frequency}</strong></p>

              {
                license.additionalLicenses.length > 0 && (
                  <React.Fragment>
                    <h5>Licenças opcionais:</h5>
                    {
                      license.additionalLicenses.map(additionalLicense => (
                        <React.Fragment>
                          <h4>{additionalLicense.name}</h4>
                          <p>Vigência: <strong>{additionalLicense.period}</strong> Periodicidade de cobrança: <strong>{additionalLicense.frequency}</strong></p>
                        </React.Fragment>
                      ))
                    }
                  </React.Fragment>
                )
              }
            </div>
          </LicenseItem>
        ))}
      </div>
    </LicensesWrapper>
  )
}
