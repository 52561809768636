import { useEffect, useState } from "react";
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import queryString from 'query-string'
import { withRouter } from "react-router-dom";
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";
import Formulario from "components/Formulario/Formulario";
import dayjs from "dayjs";
import { Timeline } from "antd";
import { CampoEhAceitoPeloPortal } from "utils/ServicosDataHandler";
import { TarefaServicoRazaoStatus, TiposSolicitacao } from "constants/contantesOcorrenciaDynamics";
import { StyledForm } from "components/Formulario/styles";
import OcorrenciasPortalService from "services/OcorrenciasPortalService";
import { NotificationManager } from "react-notifications";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";



function EdicaoAtendimentos({ history, ...props}){
    const [ids, setIds] = useState({})
    const [loading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [timeLineOptions, setTimelineOptions] = useState({ timeline: [] })
    const [ocorrencia, setOcorrencia] = useState({});
    const [camposFormulario, setCamposFormulario] = useState([])
    const [form] = StyledForm.useForm();

    useEffect(()=>{
        const {id} = queryString.parse(props.location.search);
        if (!id) {
            history.push(`/chamados`);
        }
        setIds({ chamadoId: id })
        BuscarDadosEDefinirValoresIniciais(id);
    },[])

    useEffect(()=>{
        if(Object.keys(camposFormulario).length == 0 || Object.keys(ocorrencia).length == 0) return;
        form.setFieldsValue({...ocorrencia,
            metaAtual: ocorrencia.metaAtual !=  null ? dayjs(new Date(ocorrencia.metaAtual)) : null,
            dataCustomizada1: dayjs(new Date(ocorrencia.dataCustomizada1)),
            dataCustomizada2: dayjs(new Date(ocorrencia.dataCustomizada2)),
            dataCustomizada3: dayjs(new Date(ocorrencia.dataCustomizada3)),
        })
    },[camposFormulario,ocorrencia])

    const BuscarDadosEDefinirValoresIniciais = async (id) =>{
        var ocorrenciaResponse = await BuscarDadosOcorrencia(id);
        if(ocorrenciaResponse == null) {
            setLoading(false);
            return;
        }
        if(ocorrenciaResponse.tipoOcorrenciaCodigo == TiposSolicitacao.SOLICITACAO)
        {
            PreencherDadosTarefas(id);
        }
        GerarCamposFormulario(ocorrenciaResponse)
        setLoading(false)
    }
    const BuscarDadosOcorrencia = async (id) =>{
        let ocorrenciaResponse;
        await OcorrenciasPortalService.BuscarOcorrencia(id)
        .then(response =>{
            setOcorrencia(response)
            ocorrenciaResponse = response;
        }).catch((error)=>{
            setHasError(true)
        });
        return ocorrenciaResponse;
    }
    const PreencherDadosTarefas = async (id) =>{
        await OcorrenciasPortalService.ListarTarefas(id).then(x=>{
            let timelineOptions = x?.map(x => { 
                    return {  children: x.nome, color: x.status == TarefaServicoRazaoStatus.EMANDAMENTO ? "blue" : "gray"}
                }
            );
            setTimelineOptions(timelineOptions);
        }).catch(error=>{
            NotificationManager.error(`Não foi possível buscar as Tarefas. Por favor tente novamente ou entre em contato com o suporte.`, 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
        })
    }
    const GerarCamposFormulario = (dadosOcorrencia) =>{
        const campos = [
            { input:{ name: "titulo", label: "Assunto", type: "text" } },
            { input:{ name: "numeroOcorrencia", label: "Identificador do chamado", type: "text" } },
            { input:{ name: "solicitante", label: "Solicitante", type: "text"} },
            { input:{ name: "direito", label: "Contrato de Serviços", type: "text"} },
            { input:{ name: "produto", label: "Tipo de Serviço", type: "text"} },
            { input:{ name: "descricao", label: "Descrição", type: "textarea"} },
        ];

        if(dadosOcorrencia.tipoOcorrenciaCodigo == TiposSolicitacao.INCIDENTE || dadosOcorrencia.tipoOcorrenciaCodigo == TiposSolicitacao.DUVIDA){
            campos.push(...[
                { input:{ name: "urgencia", label: "Urgência", type: "text"} },
                { input:{ name: "impacto", label: "Impacto", type: "text" } }]
            )
            campos.push({
                input:{ name: "razaoStatus", label: "Status", type: "text", },
                divider: { before:{ show: true, text: "Situação" } }
            })
        }
            
        if( dadosOcorrencia.tipoOcorrenciaCodigo == TiposSolicitacao.MUDANCA ||
            dadosOcorrencia.tipoOcorrenciaCodigo == TiposSolicitacao.SOLICITACAO ||
            dadosOcorrencia.tipoOcorrenciaCodigo == TiposSolicitacao.DEMANDA)
        {
            if(dadosOcorrencia.preRequisitos != null){
                let preRequisitos = [];
                Object.entries(dadosOcorrencia.preRequisitos).forEach(preRequisito => {
                    if(preRequisito[1]?.visivel && CampoEhAceitoPeloPortal(preRequisito[0])){
                        let type = "text";
                        let name = preRequisito[0];
                        if(preRequisito[0].startsWith("data")){
                            type = "date"
                        }
                        if(name.endsWith("Id")){
                            name = name.slice(0,-2);
                        }
                        preRequisitos.push({ input:{ name: name, label: preRequisito[1].rotulo, type: type} })
                    }
                });
                if(preRequisitos.length > 0){
                    preRequisitos[0].divider = { before:{ show: true, text: "Pré-requisitos" } }
                    campos.push(...preRequisitos)
                }
            }
            campos.push(...[
                {
                    input:{ name: "razaoStatus", label: "Status", type: "text", },
                    divider: { before:{ show: true, text: "Situação" } }
                },
                { input:{ name: "metaAtual", label: "Previsto concluir até", type: "date" }, helpMessage: dadosOcorrencia.metaAtual == null ? "Calculando previsão": "" }
            ])
        }
        setCamposFormulario(campos)
    }

    return(
        <MasterPage>
            <LoadingOrErrorPanel hasError={hasError} loading={loading}>
                <Formulario
                    disabled={true}
                    image={ocorrencia.modeloOcorrencia?.imagemServico != null ? ocorrencia.modeloOcorrencia.imagemServico : null}
                    title={ocorrencia.modeloOcorrencia != null ? ocorrencia.modeloOcorrencia.nome: ocorrencia.titulo}
                    inputsData={camposFormulario}
                    hideSubmitButton={true}
                    formReference={form}
                    />
                <Timeline
                    items={timeLineOptions}
                />
            </LoadingOrErrorPanel>
        </MasterPage>
    )
}

export default withRouter(EdicaoAtendimentos);