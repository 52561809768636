import React, { useState, useEffect } from 'react';
import ContentBox from '../../../../../components/ContentBox';

import { Container, Content } from './styles';

export default function ConfirmationAlterPlan() {

    return (
        <ContentBox>
            <Container>
                <Content>
                    <div>
                        <br />
                        <p>
                            Sua solicitação de alteração de plano será registrada e em breve nosso time Microsoft 365 entrará em contato.<br />
                            Para concluir a solicitação clique em confirmar.<br />
                        <br />
                        </p>
                        <p>
                            Qualquer dúvida, nos contate através dos canais abaixo.<br />
                        <a href="mailto:atendimento@bhs.com.br" target="_blank" rel="noopener noreferrer">atendimento@bhs.com.br</a> <br />
                        <a href="tel:4000-1744" target="_blank" rel="noopener noreferrer">4000-1744</a>
                        </p>
                    </div>
                </Content>
            </Container>
        </ContentBox>
    )
}