import PortalClienteService from "services/bases/PortalClienteService";

class RoteirosService extends PortalClienteService{
    async ListarRoteiros(){
        return await this.get(`Roteiros`)
    }
    async CriarAudiencia(data){
        return await this.post(`Roteiros/CriarAudiencia`, JSON.stringify(data))
    }
}

export default new RoteirosService();