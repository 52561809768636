import React, { useEffect, useState }  from 'react';
import { Container, Header, Content, Footer } from './styles';

export default function Policy({ policies, checkedPolicy = false, setCheckedPolicy = () => {}, implementCheck = true }) {
    
    const [policy, setPolicy] = useState({});

    useEffect(() => {
        setPolicy(policies);
    }, [policies]);

    return (
        <Container>
            <Header>
                <strong>{policy.title}</strong>
            </Header>
            <Content>
                <div dangerouslySetInnerHTML={{ __html: policy.content }}></div>
            </Content>
            {
                implementCheck && (
                    <Footer>
                        <label htmlFor="acceptPolices">
                            <input className='checked-Policy' id="acceptPolices" type="checkbox" checked={checkedPolicy} onChange={() => setCheckedPolicy(!checkedPolicy)} />
                            <span>Eu li e concordo com os termos da <strong>{policy.title}</strong></span>
                        </label>
                    </Footer>
                )
            }
        </Container>
    )
}