import PortalClienteDBService from "services/bases/PortalClienteDBService";


class DireitoService extends PortalClienteDBService{
    async ListarDireitosPorContato(){
        return await this.get("portaldb/Direitos")
    }
    async BuscarDireitoAtual(){
        return await this.get("portaldb/direitos/DireitoAtual");
    }
    async BuscarDireitoPorIdUsuario(){
        const userSignature = HttpRequest.userSignature(); 
        return await this.get(`portaldb/Direitos/${userSignature.entitlementId}`);
    }
}

export default new DireitoService();