import { Component } from "react";

export default class Form extends Component {
  state = {
    form: {},
    validations: {},
    showFormErrors: false,
    hasError: {},
    showCurrentError: false
  };

  configureInputCustom = (nameExibition, name, func, props) => {
    return {
      props: {
        id: name,
        name: name,
        value: this.state[nameExibition],
        onChange: e => func(e),
        ...props
      },
      error: this.state.hasError[name][
        Object.keys(this.state.hasError[name])[0]
      ],
      showerror: this.state.showFormErrors.toString(),
      showCurrentError: this.state.showCurrentError.toString()
    };
  };

  configureInput = (name, func, props) => {
    return {
      props: {
        id: name,
        name: name,
        value: this.state.form[name],
        onChange: e => this.onInputChange(e, func),
        ...props
      },
      error: this.state.hasError[name][
        Object.keys(this.state.hasError[name])[0]
      ],
      showerror: this.state.showFormErrors.toString(),
      showCurrentError: this.state.showCurrentError.toString()
    };
  };

  configureRichText = (name, func, props) => {
    return {
      props: {
        name: name,
        id: name,
        value: this.state.form[name],
        onChange: val => this.onRichTextChange(name, val, func),
        ...props
      },
      error: this.state.hasError[name][
        Object.keys(this.state.hasError[name])[0]
      ],
      showerror: this.state.showFormErrors.toString()
    };
  };

  onRichTextChange = (name, value, func) => {
    var el = { target: {} };
    el.target.name = name;
    el.target.value = value;

    this.onInputChange(el, func);
  };

  onInputChange = (e, func) => {
    func && func(e);

    const { maxLength = 0 } = this.state.validations[e.target.name];

    let value = maxLength ? e.target.value.slice(0, maxLength) : e.target.value;

    this.validateInput(e);
    this.setState({ form: { ...this.state.form, [e.target.name]: value } });
  };

  validateInput = element => {
    var _e = element.target || {
      name: element,
      value: this.state.form[element]
    };
    var isValid = true;
    var _stringValue = null;

    if(typeof _e.value === "object"){
      _stringValue = element === '_axt_servicebundle_value' ? JSON.stringify(_e.value).replace('{}','') : _e.value.toString("markdown");
    } else {
      _stringValue = _e.value.toString();
    }
      
    var _value = _stringValue.trim().replace(/\s|​/g, "");

    if (
      this.ehClienteXpEPossuiSomenteUmaLinhaDeServico() &&
      (_e.name === "listaLinhaServico" || _e.name === "listaPerfis")
    ) {
      return isValid;
    }

    if (this.state.validations && this.state.validations[_e.name]) {
      const {
        required = false,
        minLength,
        mask,
        hasError,
        errorMensage,
        showErrorCurrentItem = false
      } = this.state.validations[_e.name];
      var mensagens = {};

      if (hasError && errorMensage) {
        mensagens.required = errorMensage;
        isValid = false;
      }

      if(_e.name == '_axt_servicebundle_value' && !this.state.form?._axt_servicebundle_value?.value){
        mensagens.required = '*Campo obrigatório';
        isValid = false;
      }

      if (required && !_value.length) {
        mensagens.required = "*Campo obrigatório";

        isValid = false;
      }

      if (minLength !== undefined && _value.length < minLength) {
        mensagens.size = `Digite ao menos ${minLength} caracteres`;
        isValid = false;
      }

      if (mask !== undefined && _value) {
        let reg;

        if (mask === "phone") {
          if (_value.replace(/[^0-9]/g, "").length < 10) {
            mensagens.mask = `Telefone inválido`;
            isValid = false;
          }
        } else if (mask === "email") {
          reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

          if (!reg.test(_stringValue)) {
            mensagens.mask = `E-mail inválido`;
            isValid = false;
          }
        } else if (mask === "prefix") {
          reg = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+[a-zA-Z0-9]$/;

          if (!reg.test(_stringValue)) {
            mensagens.mask = `E-mail inválido`;
            isValid = false;
          }
        } else {
          reg = new RegExp(mask);

          if (!reg.test(_stringValue)) {
            mensagens.mask = `Formato inválido`;
            isValid = false;
          }
        }
      }

      this.setState({
        hasError: { ...this.state["hasError"], [_e.name]: mensagens },
        showCurrentError: showErrorCurrentItem
      });
    }
    return isValid;
  };

  ehClienteXpEPossuiSomenteUmaLinhaDeServico() {
    return this.state?.hasAcessor && this.props.listaLinhaServico?.length === 1;
  }

  formValido = async () => {
    var valid = true;

    for (var name in this.state.form) {
      let current = await this.validateInput(name);
      valid = valid && current;
    }

    return valid;
  };

  handleSubmit = async e => {
    this.setState({ showFormErrors: true });
    let isValid = await this.formValido();
    if (isValid) {
      this.onSubmit(e);
    }
  };

  onSubmit = form => {};
}
