import React, { Component } from 'react';
import Modal from '../../components/modal/modal';
import { ContentModal, Action, Confirmation, ImageIcon, ImageContainer } from './styles';
import RefusedIcon from 'assets/icons/refused.svg';

export default class ModalGestaoUsuarioResponse extends Component {
    render() {
        return (
            <Modal open={this.props.openModal} close={this.props.onCloseModal} sizeClassName={'modal-content-lg'}>
                <Confirmation>
                    <ContentModal>
                        <div className="content-modal-excluir">
                            {!this.props.responseError ? 
                            <div className="animate-success-icon">
                                <div className="success-circular-line-left"></div>
                                <span className="success-line-tip"></span>
                                <span className="success-line-long"></span>
                                <div className="success-ring"></div>
                                <div className="success-fix"></div>
                                <div className="success-circular-line-right"></div>
                            </div> 
                            : 
                            <ImageContainer> <ImageIcon src={RefusedIcon} /></ImageContainer>
                            }
                            <div className="text-modal">
                                {this.props.response?.map(x => 
                                {return(
                                    <p>{x}</p>
                                )})}
                                <br/>
                                <p>
                                Qualquer dúvida, nos contate através dos canais abaixo.<br />
                                </p>
                                <p>
                                    <a href="mailto:atendimento@bhs.com.br" target="_blank" rel="noopener noreferrer">atendimento@bhs.com.br</a> <br /> 
                                </p>
                                <p>
                                    <a href="tel:4000-1744" target="_blank" rel="noopener noreferrer">4000-1744 – opção 1</a>
                                </p>
                            </div>
                        </div>
                    </ContentModal>
                    <Action className='action-confirmation'>
                        <button
                            onClick={() => this.props.onCloseModal()}
                            type="button"
                            className='btn btn-default'
                        >
                            Fechar
                        </button>
                    </Action>
                </Confirmation>
            </Modal>
        );
    };
}