import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MasterPage from "../../masterpages/errorPage"

export default class Error404 extends Component {

    render() {
        return (
            <MasterPage classContainer="error-404" error="404" title="Página não encontrada" text="Não conseguimos encontrar a página que você procura">
                <Link to='/' className="btn-error"><i className="icon-arrow-left"></i>Voltar ao início</Link>
            </MasterPage>
        );
    };
}