import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import Lottie from 'react-lottie';

import { sendEmailCode } from 'EstruturaAntiga/actions/authorization/sendEmailCode';
import { useQuery } from 'EstruturaAntiga/hooks/useQuery';

import { AccessWrapper, ButtonsWrapper, InputWrapper } from './style';
import { Button } from '../Acceptance/style';

import LockLottie from '../../../../../assets/images/lottie/lock.json';
import { validateEmailCode } from 'EstruturaAntiga/actions/authorization/validateEmailCode';

export function AccessControl({
  setCanAccess
}) {
  const [pinValue, setPinValue] = useState('');
  const [validating, setValidating] = useState(false);
  const [correctPin, setCorrectPin] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const { queryParams } = useQuery();

  useEffect(() => {
    sendEmailCode(queryParams.cotacao);
  }, []);

  useEffect(() => {
    sendEmailCode(queryParams.cotacao);
  }, [queryParams]);

  function handlePinChange(event){
    setCorrectPin(false);
    setInvalidPin(false);
    const { value } = event.target;
    const numberRegex = /^[0-9\b]{0,6}$/;
    if(numberRegex.test(value)){
      setPinValue(value)
    }
  }

  function handleResubmitPin() {
    setInvalidPin(false);
    setPinValue('')

    sendEmailCode(queryParams.cotacao);

    return NotificationManager.success('O novo código de segurança foi enviado para o seu e-mail.', 'Novo código enviado!', 5000);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    setInvalidPin(false);
    setValidating(true);
    const validation = await validateEmailCode(queryParams.cotacao, pinValue);

    if (!validation.authorized) {

      setInvalidPin(true);
      setValidating(false);

      const response = JSON.parse(validation)

      return NotificationManager.error(response.message, 'Código inválido!', 5000);
    }

    setCorrectPin(true);
    setValidating(false);
    setCanAccess(true);
  }

  const options = {
    loop: false,
    autoplay: false,
    animationData: LockLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <AccessWrapper onSubmit={(event) => handleFormSubmit(event)}>
      <div>
        <Lottie
          options={options}
          width={96}
          height={96}
          isStopped={!correctPin}
        />

        <h4>Código de segurança</h4>
        <p>Para acessar a página, insira o código de segurança de 6 digitos que foi enviado para o seu e-mail.</p>

        <InputWrapper>
          <input type="text"
            name="pinValue"
            maxLength="6"
            minLength="6"
            title="Somente números são permitidos"
            disabled={!!validating}
            required={true}
            value={pinValue}
            onChange={(event) => handlePinChange(event)}
          />
        </InputWrapper>

        { !!invalidPin && <span>Código incorreto!</span> }

        {
          !correctPin && (
            <ButtonsWrapper>
              {
                !!validating ? (
                  <div className="block-loading">
                    <div className="simple-loading"></div>
                  </div>
                ) : (
                  <React.Fragment>
                    <Button type="button" buttonType="back" disabled={!!validating} onClick={() => handleResubmitPin()}>Reenviar código</Button>
                    <Button type="submit" buttonType="accept" disabled={!!validating}>Verificar código</Button>
                  </React.Fragment>
                )
              }
            </ButtonsWrapper>
          )
        }
      </div>
    </AccessWrapper>
  )
}
