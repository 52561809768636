import React, { Component } from 'react';
import { NotificationManager } from "react-notifications";

import getAddonsToBuy from '../../../actions/microsoftCSP/getAddonsToBuy';
import getCompaniesToApportionment from '../../../actions/microsoftCSP/getCompaniesToApportionment';

import Modal from "../../../components/modal/modal";
import CustomSelect from './form/CustomSelect';
import BuyLicenseItem from './BuyLicenseItem';

export default class BuyLicenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addons: [],
      allAddons: [],
      userActualAddons: [],
      companies: [],
      licensesList: [],
      newAddons: [],
      newLicensesValue: 0,
      loading: true,
      buying: false,
      preview: false,
      previewResponse: '',
    }
  }

  async componentDidMount() {
    const { contractMasterId } = this.props;

    const getAddons = await getAddonsToBuy(this.props.license._axt_productid_value, contractMasterId);

    const { rateio, license, disblockButton } = this.props;

    const getCompanies = rateio && await getCompaniesToApportionment();

    let newBuyAddonsArray = [];

    if (getAddons.error) {
      this.props.openCloseBuyLicenses();

      return NotificationManager.error(
        "",
        "Não há nenhum addon para adicionar a essa assinatura.",
        9000
      );
    } else {
      if (getAddons.length > 0) {
        disblockButton();
      }

      newBuyAddonsArray = getAddons.map(addon => {
        let companies = [];

        if (rateio) {
          companies = getCompanies.map(companie => ({
            axt_companieName: companie.axt_accountId.name,
            axt_accountid: companie.axt_accountId.accountid,
            axt_subscriptioncspquoteid: null,
            _axt_financialcontractid_value: companie.axt_financialcontractid,
            _axt_productid_value: addon._productid_value,
            axt_cnpjview: companie.axt_accountId.axt_cnpjview,
            axt_displayname: companie.axt_displayname,
            axt_amount: 0,
          }));
        }

        const newAddon = {
          axt_billingcycle: 282390000,
          axt_name: addon.productid.name,
          axt_quantity: 0,
          axt_subscriptioncspid: '',
          _axt_productid_value: addon._productid_value,
          axt_unitpricesale: addon.amount,
          axt_haspurchasableaddons: false,
          axt_ProductId: {
            axt_hasaddon: false
          },
          _axt_maincspsubscriptionid_value: license.axt_subscriptioncspid,
          subscriptionCspQuotes: companies
        }

        return newAddon;
      });
    }

    this.setState({
      addons: newBuyAddonsArray,
      userActualAddons: license.addonChilds,
      loading: false,
      buying: true,
      // rateio: getCanApportionment.axt_secondarycontractqty > 0 ? true : false,
    });
  }

  onChangeLicenses = (value) => {
    const { userActualAddons } = this.state;
    const { license } = this.props;
    const newUserAddons = [...license.addonChilds, ...value];

    this.setState({
      allAddons: newUserAddons,
      newAddons: value,
    });
  }

  renderCompra() {
    const { allAddons, newAddons, addons, buying, preview, rateio, newLicensesValue } = this.state
    const { renderComprar, openCloseBuyLicenses, addNewAddons } = this.props;

    if (renderComprar && buying) {
      return (
        <Modal specialClass="csp-modal" open={renderComprar} close={() => openCloseBuyLicenses()}>
          <div className="csp-modal-wrapper">
            <h1>Adicionar assinaturas</h1>

            <CustomSelect
              className="input-group filter-select"
              value={newAddons}
              data={addons}
              multiple={true}
              disabled={false}
              onChange={this.onChangeLicenses}
            />

            <div className="licencas-quantidade">
              {
                allAddons.length > 0 && allAddons.map(userLicense => (
                  <BuyLicenseItem
                    key={userLicense.axt_subscriptioncspid ? userLicense.axt_subscriptioncspid : userLicense._axt_productid_value}
                    userLicense={userLicense}
                    onChangeQuantity={this.onChangeQuantity}
                    onChangeApportionment={this.onChangeApportionment}
                    readOnly={userLicense.statuscode === 282390001 || userLicense.axt_unitpricesale === undefined || userLicense.axt_unitpricesale === 0 ? true : false}
                    rateio={rateio}
                  />
                ))
              }
            </div>

            <div className="actions">
              <button className="btn" onClick={() => openCloseBuyLicenses()}>Cancelar</button>
              <button id="btnEditar" onClick={() => addNewAddons(newAddons)} className="btn btn-default">Confirmar</button>
            </div>
          </div>
        </Modal>
      )
    } else if (renderComprar && preview) {
        return (
          <Modal specialClass="csp-modal" open={renderComprar} close={() => openCloseBuyLicenses()}>
            <div className="csp-modal-wrapper">
              <h1>Checkout</h1>

              <div className="licencas-quantidade">
                {
                  allAddons.map(userLicense => (
                    <BuyLicenseItem
                      key={userLicense._axt_productid_value}
                      userLicense={userLicense}
                      onChangeQuantity={this.onChangeQuantity}
                      onChangeApportionment={this.onChangeApportionment}
                      readOnly={true}
                      rateio={rateio}
                    />
                  ))
                }
              </div>

              <div className="value-change">
                <span>Alteração de valor na fatura: <strong>{newLicensesValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></span>
              </div>

              <div className="actions">
                <button className="btn" onClick={() => this.back()}>Voltar</button>
                <button id="btnEditar" onClick={() => this.onSubmitReceive()} className="btn btn-default">Confirmar</button>
              </div>
            </div>
          </Modal>
        )
    } else {
      return (
        <Modal specialClass="csp-modal" open={renderComprar} close={() => openCloseBuyLicenses()}>
          <div className="csp-modal-wrapper">
            <div className="loading-profiles">
              <div className="simple-loading"></div>
            </div>
          </div>
        </Modal>
      )
    }
  }

  render() {
    return (
      this.renderCompra()
    );
  }
}
