import React from 'react';
import MasterPage from '../../../masterpages/authenticated';
import ContentBox from '../../../../components/ContentBox';
import Form from '../../../assets/formValidate';
import Select from '../../../components/form/SelectGroup';
import Input from '../../../components/form/InputGroup';
import { checkBundleLimit } from '../../../actions/validation/checkBundleLimit';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  rdxClearUsers,
  rdxGetUserFields,
  rdxGetUser,
  rdxGetPlansRightsByEntitlementId,
  rdxGetAdditionalProductsRightByEntitlementId
} from '../redux/usuariosActions';
import postUsuarioObterProduto from '../../../actions/usuarios/postUsuarioObterProduto';
import getUsuarioStatusCode from 'EstruturaAntiga/actions/usuarios/getUsuarioStatusCode';
import { NotificationManager } from 'react-notifications';
import CustomSelect from '../../../components/customSelect/customSelect';
import { FormHeader } from '../componentes/FormHeader';
import Timeline from '../../../components/timeline';
import Concluded from '../componentes/concluded';
import NavigationButtons from '../componentes/navigationButtons';
import ConfirmationAlterPlan from '../componentes/confirmationAlterPlan';
import TemAcessoAosProfiles from 'EstruturaAntiga/permissions/checkPermissionV2';
import { profilesV2 } from 'EstruturaAntiga/permissions/permissionsV2';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import {
	ContentWrapperStyle,
  } from './styles';
import GetDireitoPorId from 'EstruturaAntiga/actions/direitos/getDireitoPorId';
import ErroGestaoUsuario from '../ErroGestaoUsuario';
import ModalGestaoUsuarioResponse from 'EstruturaAntiga/pages/ModalGestaoUsuarioResponse';
import LoadingShadow from 'EstruturaAntiga/components/loadingShadow';
import _ from 'underscore';
import UsuariosService from 'services/UsuariosService';

const estruturaProduto = {
  plano: 3,
  licenca: 1
}

class AlterarPlanoV2 extends Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      statuscode: 0,
      checkPermissionButtons: false,
      timelinePosition: 0,
      accepted: true,
      isSubmit: false,
      formHeaders: {
        formName: '',
        tenant: '',
        formTitle: ''
      },
      loadingForm: false,
      loadingUserInfo: false,
			loadingAutorizacao: false,
			autorizado: true,
      planVisualizationLoading: true,
      userId: '',
      searchBundleLimit: false,
      name: "",
      renderizarPlanodeEmail: false,
      listaProdutoComplementoExibicao: [],
      LicensePlanExibicao: "",
      priceLevelCurrencySymbol: "",
      priceLevelAmount: 0,
      priceLevelCurrencyName: "",
      addonCurrencySymbol: "",
      addonAmount: 0,
      addonCurrencyName: "",
      currentPlan: {},
      currentOptionalLicenses: [],
      formContent: {
        _axt_servicebundle_value: {
          nome: "Novo plano",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
          lista: [],
        },
        emailaddress1: {
          nome: "E-mail",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
				emailVisualization: {
					atributo: "emailVisualization",
					formName: null,
					id: 'emailVisualization',
					lista: [],
					nome: "E-mail",
					obrigatorio: false,
					somenteLeitura: true,
					tamanho: 150,
					valores: [],
					visible: true
				},
				planVisualization: {
					atributo: "planVisualization",
					formName: null,
					id: 'planVisualization',
					lista: [],
					nome: "Plano atual",
					obrigatorio: false,
					somenteLeitura: true,
					tamanho: 150,
					valores: [],
					visible: true
				}
      },
      form: {
        _axt_servicebundle_value: {},
        listaProdutoComplemento: [],
        emailaddress1: "",
				emailVisualization: '',
				planVisualization: ''
      },
      validations: {
        _axt_servicebundle_value: {},
        emailaddress1: { mask: "email" },
				emailVisualization: {},
				planVisualization: {}
      },
      hasError: {
        _axt_servicebundle_value: {},
        emailaddress1: {},
				emailVisualization: {},
        planVisualization: {}
      },
      openModalEditarLicenciamento: false,
      editarLicenciamentoResponse: [],
      editarLicenciamentoErro: false,
      showLoadingShadow: false
    };
  }

  renderNewUserPage = () => {
      this.setState({loadingForm: true});
      return this.props.history.push(`/usuarios/novo`);
  }

  onChangeAddons = (value) => {
    const ammout = value
      .map((o) => o.amount)
      .reduce((a, c) => a + c, 0)
      .toFixed(2);

    const addonAmount = Number(ammout);
    const addonCurrencyName = value.map((o) => o.currencyName)[0];
    const addonCurrencySymbol = value.map((o) => o.currencySymbol)[0];

    this.setState({
      addonAmount,
      addonCurrencyName,
      listaProdutoComplementoExibicao: value,
      addonCurrencySymbol,
      form: {
        ...this.state.form,
        listaProdutoComplemento: value.map((o) => { return {value: o.value, UnidadeId: o.unitId}}),
      },
    });
  }

  async onSelectChange(event) {
    event.persist();
    var dados = event.target.value.split(";");

    const value = dados[0];
    const priceLevelCurrencySymbol = dados[1];
    const priceLevelAmount = event.target.value !== "" ? dados[2] : 0;
    const priceLevelCurrencyName = event.target.value !== "" ? dados[3] : "";
    const unitId = event.target.value !== "" ? dados[4] : '';

    const newValue = value ? { value: value, UnidadePrincipalId: unitId } : {}; 

    this.setState({
      priceLevelCurrencySymbol,
      priceLevelAmount,
      priceLevelCurrencyName,
      LicensePlanExibicao: event.target.value,
      form: {
        ...this.state.form,
        _axt_servicebundle_value: newValue,
      },
      validations: {
        ...this.state.validations,
        _axt_servicebundle_value: {
          ...this.state.validations._axt_servicebundle_value,
          hasError: false,
          showErrorCurrentItem: true
        }
      }
    }, () => { this.validateInput(event) });

    this.setState({ searchBundleLimit: true });

    const bundleLimit = value ? await checkBundleLimit(value) : '';

    this.setState({ searchBundleLimit: false });

    if (!bundleLimit.licencaDisponivel) {
      const errorText = bundleLimit.mensagem ?? 'Erro ao tentar validar licença. Favor entre em contato com nosso atendimento.';

      this.setState({
        validations: {
        ...this.state.validations,
        _axt_servicebundle_value: {
          ...this.state.validations._axt_servicebundle_value,
          hasError: true,
          errorMensage: errorText
        }
        }
      }, () => { this.validateInput(event) })
    } else if(!this.state.form?._axt_servicebundle_value?.value){
      this.setState({
        validations: {
        ...this.state.validations,
        _axt_servicebundle_value: {
          ...this.state.validations._axt_servicebundle_value,
          hasError: true,
          errorMensage: 'Campo obrigatório'
        }
        }
      }, () => { this.validateInput(event) })
    } else {
      this.setState({
        validations: {
        ...this.state.validations,
        _axt_servicebundle_value: {
          ...this.state.validations._axt_servicebundle_value,
          hasError: false,
          errorMensage: ''
        }
        }
      }, () => { this.validateInput(event) })
    }
  }

  handleSubmit = async (e) => {
		this.setState({ loadingForm: true });
    
		const {timelinePosition, isSubmit} = this.state;

		if(isSubmit){
			return;
		}

		const currentTimeLinePosition = timelinePosition;
    this.setState({ isSubmit: currentTimeLinePosition >= 1 ? true : false });

		if(currentTimeLinePosition < 1){
			let currentTarget = [];
			[...e.currentTarget].forEach(element => {
				currentTarget.push(element.name);
			});
			const valid = await this.validForm(currentTarget);
			if(valid){
				this.setState({timelinePosition: this.state.timelinePosition + 1});
			}
		} else {
			this.onSubmit();
		}
		this.setState({ loadingForm: false });
	}

  validForm = async currentTarget => {
		this.setState({ showFormErrors: true });
		return await this.formValido(currentTarget);
	};

  formValido = async (currentTarget) => {
		var valid = true;
		for (var name in this.state.form) {
		  	let current = currentTarget.includes && currentTarget.includes(name) ? await this.validateInput(name) : true;
		  	valid = valid && current;
		}
	
		return valid;
	};

  componentWillMount() {
    this.setState({ name: this.props.user.displayName });
  }

  componentDidMount = async () => {
    this.setState({
      loadingAutorizacao: true,
    });

    await this.permitirGestaoUsuario();

    const checkPermissionButtons = TemAcessoAosProfiles([profilesV2.admGlobal, profilesV2.GestorM365Gerenciado, profilesV2.GestorM365Basico]);
    this.setState({ checkPermissionButtons });

    const userId = this.props.user.contact && this.props.user.contact.id;
    if (!userId) return this.props.history.push(`/usuarios`);
    this.setState({ userId: userId });

    await this.props.rdxClearUsers(true);

    /* Busca dados do usuário */
    this.setState({ loadingForm: true, loadingUserInfo: true });
    await this.props.rdxGetUser(userId);
    await this.getProduct(this.props.userInfo.assignedLicenses);
    await this.getPlanVisualization();
    await this.getStatusCode(userId);
    this.props.rdxGetUserFields();
    this.props.rdxGetPlansRightsByEntitlementId();
    this.props.rdxGetAdditionalProductsRightByEntitlementId();
  };
  
	async permitirGestaoUsuario () {
		await GetDireitoPorId()
		  .then(response => {
			if(response?.permitirGestaoUsuario === false)
			  this.setState({autorizado: false});
		  })
		  .finally(() => {
			  this.setState({loadingAutorizacao: false});
		  })
	  }	

  getProduct = async (assignedLicenses) => {
    const userProduct = await postUsuarioObterProduto(assignedLicenses);
    const emailPlan = userProduct.find(prod => prod.estruturaProduto === estruturaProduto.plano);
    const optionalLicenses = userProduct.filter(prod => prod.estruturaProduto === estruturaProduto.licenca);
    const currentPlan = emailPlan?.produtoId ? { ...emailPlan, value: emailPlan?.produtoId, unitId: emailPlan?.unidadeId } : {};
    const currentOptionalLicenses = optionalLicenses.length ? optionalLicenses.map(addon => { return { value: addon?.produtoId, UnidadeId: addon?.unidadeId }}) : [];
    let newValues = { listaProdutoComplemento : currentOptionalLicenses };
    let newForm = { ...this.state.form, ...newValues };
    this.setState({ 
      form: newForm,
      currentPlan,
      currentOptionalLicenses
    });
  }

  hadChangedFields = () => {
    const { currentPlan, currentOptionalLicenses, form } = this.state;
    const initialState = { Plan: { value: currentPlan?.value, UnidadePrincipalId: currentPlan?.unitId }, OptionalLicenses: currentOptionalLicenses };
    const finalState = { Plan: form._axt_servicebundle_value, OptionalLicenses: form.listaProdutoComplemento };
    const isEqual = _.isEqual(initialState, finalState);

    if(!isEqual){
      return true;
    }
    return false;
  }

  getPlanVisualization = async() => {
    const { currentPlan } = this.state;
    
    const labelPlanVisualization = currentPlan?.produto 
      ? `${currentPlan?.produto} - ${currentPlan?.simboloMoeda} ${currentPlan?.valorVendaBhs}${currentPlan?.unidade ? ' | ' + currentPlan?.unidade : ''}` 
      : '';
    const newPlanVisualization = labelPlanVisualization;

    this.setState({ 
      planVisualizationLoading: false,
      form: { ...this.state.form, planVisualization: newPlanVisualization ?? '' }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.form.emailaddress1 !== this.state.form.emailVisualization) {
      let newEmailVisualization = this.state.form.emailaddress1 ?? '';
      this.setState({
        form: { ...this.state.form, emailVisualization: newEmailVisualization }
      });
    }
    
    if (prevProps !== this.props) {
      let newForm = { ...this.state.form };

      let licensePlanOptional = this.state.form.listaProdutoComplemento.length ? this.state.form.listaProdutoComplemento : [];

      const {
        emailaddress1,
      } = this.props.userInfo;

      newForm = {
        ...newForm,
        emailaddress1: emailaddress1 || ''
      };

      const { tenantName } = JSON.parse(sessionStorage.getItem('signature'));

      this.setState({
        loadingForm: (this.props.listaFormFields[0] && this.props.listaFormFields[0].formName) || this.props.listaFormFields.length === 0 ? false : true,
        loadingUserInfo: !emailaddress1,
        form: newForm,
        formHeaders: {
          formName: this.props.listaFormFields[0] && this.props.listaFormFields[0].formName,
          formTitle: this.state.name || 'Alterar Plano',
          tenant: tenantName
        }
      });

      /* Trata dados de licença e licenças opcionais no carregamento do formulário*/
      if (this.state.currentPlan.value) {
        this.setState({ renderizarPlanodeEmail: true });

        if (licensePlanOptional.length) {
          var array = [];
          this.props.listaProdutoComplemento.map((item) => {
            licensePlanOptional.map((valor) => {
              if (item.value === valor.value) {
                array.push(item);
              }
            });
          });

          if (array.length > 0) {
            const ammout = array
              .map((o) => o.amount)
              .reduce((a, c) => a + c, 0)
              .toFixed(2);

            const addonAmount = Number(ammout);
            const addonCurrencyName = array.map((o) => o.currencyName)[0];
            const addonCurrencySymbol = array.map((o) => o.currencySymbol)[0];

            this.setState({
              addonAmount,
              addonCurrencyName,
              listaProdutoComplementoExibicao: array,
              addonCurrencySymbol,
              form: {
                ...newForm,
                listaProdutoComplemento: array.map((o) => {return {value: o.value, UnidadeId: o.unitId } }),
              },
            });
          }
        }
      }
    }
  }

  getStatusCode = async (id) => {
		const userStatusCode = await getUsuarioStatusCode(id);
    this.setState({ statuscode: userStatusCode.statuscode });
	}

  renderField(obj, id, cols, customProps) {
    if (obj.visible) {
      let configInput = [id];

      let props = {
        id: id,
        label: obj.nome + (obj.obrigatorio ? "*" : ""),
        readOnly: obj.somenteLeitura,
        configureInput: this.configureInput(...configInput),
        items: [],
        ...customProps,
      };

      if (obj.tamanho) {
        props.maxLength = obj.tamanho;
      }

      return (
        <div className={cols}>
          <Input {...props} />
        </div>
      );
    }

    return "";
  }

  renderFilterSelect(nameField, list, loading) {
    const items = list.length
      ? [
          { value: "", label: "Selecione" },
          ...list.map((el) => {
            return {
              value: el.value + ";" + el.currencySymbol + ";" + el.amount + ";" + el.currencyName + ';' + el.unitId,
              label: el.label
            };
          }),
        ]
      : [{ value: "", label: "Selecione" }];

    return (
      <React.Fragment>
        <Select
          label="Novo plano*"
          items={items}
          configureInput={this.configureInputCustom(
            "LicensePlanExibicao",
            nameField,
            (event) => this.onSelectChange(event)
          )}
          loading={loading}
        />
        <div style={{ marginTop: -15 }}>
          {this.state.priceLevelAmount > 0 && (
            <span
              style={{
                fontSize: 12,
                marginLeft: 5,
                letterSpacing: "0.33px",
              }}
            >
              Valor da licença em{" "}
              {this.state.priceLevelCurrencyName.toLowerCase()}:{" "}
              {this.state.priceLevelCurrencySymbol}{" "}
              {this.state.priceLevelAmount}
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderValorTotalDoPlano() {
    const totalAmount =
      Number(this.state.addonAmount) + Number(this.state.priceLevelAmount);
    return (
      <div
        className="col-12 col-sm-12 col-lg-12"
        style={{
          justifyContent: "flex-end",
          display: "flex",
          padding: 10,
        }}
      >
        {this.state.priceLevelAmount > 0 && (
          <span>
            Valor total do plano em{" "}
            {this.state.priceLevelCurrencyName.toLowerCase()}:{" "}
            {this.state.priceLevelCurrencySymbol} {totalAmount.toFixed(2)}
          </span>
        )}
      </div>
    );
  }

  renderListaPacoteCspElicencasOpcionais() {
    return (
      <React.Fragment>
        <div className="col-12 col-sm-6 col-lg-6">
          {this.renderFilterSelect(
            "_axt_servicebundle_value",
            this.props.listaDePacotesCSP,
            this.props.listaDePacotesCSPLoading || this.state.searchBundleLimit
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-6">
          <label>{"Licenças Opcionais"}</label>
          <div className="input-group-container">
            <CustomSelect
              className="input-group filter-select"
              value={this.state.listaProdutoComplementoExibicao}
              data={this.props.listaProdutoComplemento}
              multiple={true}
              disabled={false}
              onChange={this.onChangeAddons}
            />
            {this.state.addonAmount > 0 && (
              <span
                style={{
                  fontSize: 12,
                  marginLeft: 5,
                  letterSpacing: "0.33px",
                }}
              >
                Valor total das licenças opcionais em{" "}
                {this.state.addonCurrencyName.toLowerCase()}:{" "}
                {this.state.addonCurrencySymbol} {this.state.addonAmount}
              </span>
            )}
            <div
              className={`simple-loading ${
                !this.props.listaProdutoComplementoLoading ? "d-none" : ""
              }`}
            ></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  backPage = () => {
		this.setState({ loadingForm: true });
		this.setState({ timelinePosition: this.state.timelinePosition - 1 });
		this.setState({ loadingForm: false });
	}

  nextPage = () => {
	}

  onSubmit = () => {
    if (!this.state.form._axt_servicebundle_value.value) {
      this.setState({ isSubmit: false });
      return NotificationManager.error(
          "Você deve selecionar um plano válido.",
          "Não foi possivel alterar o plano!",
          7000
      );
    } 

    if (!this.state.creating) {
      this.setState({ creating: true, loadingForm: true });
      this.setState({showLoadingShadow: true});
      const signature = JSON.parse(sessionStorage.getItem("signature"));

      const userData = {
        UsuarioId: this.state.userId,
        TenantId: signature.axtTenantId,
        DireitoId: signature.entitlementId,
        ProdutoPrincipal: {
          ProdutoPrincipalId: this.state.form._axt_servicebundle_value.value,
          UnidadePrincipalId: this.state.form._axt_servicebundle_value.UnidadePrincipalId
        },
        ProdutosOpcionais: this.state.form.listaProdutoComplemento.map(addon => { return { ProdutoId: addon.value, UnidadeId: addon.UnidadeId }})
      }

      UsuariosService.EditarLicenciamento(userData)
                .then((response) => {
                  this.setState({editarLicenciamentoErro: false});
                  this.setState({editarLicenciamentoResponse: [response]});
                  this.setState({timelinePosition: this.state.timelinePosition + 1});
                })
                .catch((error) => {
                  this.setState({editarLicenciamentoErro: true});
                  this.setState({editarLicenciamentoResponse: error.response?.data?.erros ?? ['Erro ao tentar editar o licenciamento do usuário.']});
                })
                .finally(() => {
                  this.setState({ creating: false, loadingForm: false });
                  this.setState({ isSubmit: false });
                  this.setState({showLoadingShadow: false});
                  this.setState({openModalEditarLicenciamento: true});
                });
    }
  };

  renderModal() {
    const {
      openModalEditarLicenciamento,
      editarLicenciamentoResponse,
      editarLicenciamentoErro
    } = this.state;

    return (
      <ModalGestaoUsuarioResponse
          openModal = {openModalEditarLicenciamento}
          onCloseModal = {this.onCloseModal.bind(this)}
          responseError = {editarLicenciamentoErro}
          response = {editarLicenciamentoResponse}
        />
    )
  }

  renderLoadingShadow() {
    return (
      <LoadingShadow 
      sizeClassName={''}
      open={true} 
      close={() => {}}
      />
    );
  }

  onCloseModal = () => {
    this.setState({openModalEditarLicenciamento: false});
	}

  render() {
    const {
      formHeaders,
      loadingForm,
      loadingUserInfo,
			loadingAutorizacao,
			autorizado,
      timelinePosition,
      checkPermissionButtons,
      accepted,
      planVisualizationLoading,
      showLoadingShadow,
      openModalEditarLicenciamento
    } = this.state;

    if (loadingForm || loadingUserInfo || loadingAutorizacao) {
      return (
        <MasterPage>
          <div className="small-loading">
            <div className="simple-loading"></div>
          </div>
        </MasterPage>
      );
    } 
		else if(!autorizado) {
		  return <ErroGestaoUsuario/>;
		}
    else if (this.props.listaFormFields.length === 0) {
      return (
        <MasterPage>
          <h1>Alterar plano</h1>
          <h2>Não há nenhum formulário no cadastro do tenant</h2>
          <h3>Não foi possível encontrar um formulário no cadastro do tenant, portanto, não é possível realizar a alteração do plano. Entre em contato com nossa equipe de atendimento.</h3>
        </MasterPage>
      )
    } else {
      return (
        <MasterPage>
            <FormHeader formHeaders={formHeaders} />
            <Timeline 
                timelinePosition= {timelinePosition}
                accepted={accepted}
                timelineLabelItems = {['Licenciamento e planos', 'Confirmação e finalização']}
            />
            <ContentWrapperStyle>
                <form onSubmit={e => { e.preventDefault(); this.handleSubmit(e); }}>
                  {timelinePosition === 0 && (
                    <div>
                      <h2>Dados de acesso</h2>
                      <h3>Defina o licenciamento e acesso do usuário</h3>
                      <ContentBox className="form-container">
                        <div className="row">
                          {this.renderField(this.state.formContent.emailVisualization, 'emailVisualization', 'col-12 col-lg-6')}
                          {this.renderField(this.state.formContent.planVisualization, 'planVisualization', 'col-12 col-lg-6', { loading: planVisualizationLoading })}
                        </div>
                        {this.state.renderizarPlanodeEmail && (
                          <div className="row">
                            {this.renderListaPacoteCspElicencasOpcionais()}
                          </div>
                        )}
                        <div className="row">{this.renderValorTotalDoPlano()}</div>
                      </ContentBox>
                    </div>
                  )}

                  {timelinePosition === 1 && (
                    <ConfirmationAlterPlan />
                  )}

                  {timelinePosition > 1 && (
                    <Concluded
                      renderNewUserPage={this.renderNewUserPage}
                    />
                  )}

                  {timelinePosition <=1 && (
                    <NavigationButtons 
                      labelBackButton='Voltar'
                      onClickBackButton={this.backPage}
                      hideBackButton={timelinePosition == 0}
                      labelNextButton={timelinePosition < 1 ? 'Próximo' : 'Confirmar'}
                      onClickNextButton={this.nextPage}
                      disabledNextButton={
                          ( checkPermissionButtons ?
                          ( this.state.searchBundleLimit ||
                          (Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO) ) :
                          ( (Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO) ) ) ||
                          ( !this.hadChangedFields() || !this.state.form._axt_servicebundle_value.value || this.state.validations._axt_servicebundle_value.hasError )
                      }
                    />	
                  )}
                </form>
            </ContentWrapperStyle>
            {showLoadingShadow && (this.renderLoadingShadow())}
            {openModalEditarLicenciamento && (this.renderModal())}
        </MasterPage>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.users.userInfo,
  listaFormFields: state.users.listaFormFields,
  listaProdutoComplemento: state.users.listaProdutoComplemento,
  listaProdutoComplementoLoading: state.users.listaProdutoComplementoLoading,
  listaDePacotesCSP: state.users.listaDePacotesCSP,
  listaDePacotesCSPLoading: state.users.listaDePacotesCSPLoading,
  user: state.users.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rdxClearUsers,
      rdxGetUserFields,
      rdxGetUser,
      rdxGetPlansRightsByEntitlementId, 
      rdxGetAdditionalProductsRightByEntitlementId
    },
    dispatch
  );

const component = connect(mapStateToProps, mapDispatchToProps)(AlterarPlanoV2);

export default withRouter(component);
