import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import React from 'react'

export function getLabelCheckboxAssessor() {
    switch (getFormId()) {
        case CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP:
            return 'Usuário Consultor';
        case CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.XP:
        case CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.BLUE3:	
            return 'Usuário Assessor';
        default:
            return 'Usuário Assessor';
    }
}

export function getFormId () {
    const tenant = JSON.parse(sessionStorage.getItem('tenant'));
    return tenant?.formId;
}
