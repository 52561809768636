const _defaultState = {
    isEditable: true,
    otherMail: '',
    businessPhone: '',
	 mobilePhone: '',
	 displayName: '',
    userInfoLoading: true
}

export default (state = _defaultState, action) => {
    if(action.type==='ALTERAR_DADOS_CLEAR' || state.isEditable){
        switch (action.type){
            case 'ALTERAR_DADOS_GET_USER_INFO':
                return { 
                    ...state, 
                    ...action.payload,
                    userInfoLoading: false
                }
            case 'ALTERAR_DADOS_CLEAR':
                return { ..._defaultState, isEditable: action.payload }
            default:
                return state
        }
    }

    return state;
}