import { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Upload, message } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";
import uuidv1 from 'uuid';
import urlBase from "EstruturaAntiga/assets/urlsBase";

import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import UploadArquivosService from "services/UploadArquivosService";
import { App } from "antd"
import { NotificationManager } from "react-notifications";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";

function PortalInputUpload({input, divider, form}){
    const fn = App.useApp()
    const [hidden, setHidden] = useState(true);
    const [uploadProps, setUploadProps] = useState({})
    const [fileList, setFileList] = useState([]);
    const errorMessage = "Erro ao deletar";
    const defaultAccept = "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-outlook";

    useEffect(()=>{
        PreencherHeader();
    }, [])

    const PreencherHeader = async () =>{
        let newPathName = "rootpath" + uuidv1();
        const url = `${urlBase.urlBase}/storageaccount/blob/blob/UploadByStream?pathName=${newPathName}`
        let {Authorization} = await HttpRequest.axiosApiTokenHeader();
        let header = {Authorization: Authorization}
        setUploadProps({
            headers: header,
            pathName: newPathName,
            url: url
        })
    }

    return(
        <FormInputDivider key={input.name} {...divider}>
            <Upload
                action={uploadProps.url}
                headers={uploadProps.headers}
                accept={input.accept || defaultAccept}
                onChange={(info) =>{
                    let newFileList = [...info.fileList];
                    if(info.file.status == "done" || info.file.status == "removed"){
                        if(info.fileList.length > 0){
                            form.setFieldsValue({[input.name]: uploadProps.pathName})
                        }
                        else{
                            form.setFieldsValue({[input.name]: null})
                        }
                    }
                    setFileList(newFileList)
                }}
                onRemove = {async (file)=>{
                        let newFile = {
                            file: file.originFileObj
                        }
                        let newListFile =  fileList.filter( fileDelete => fileDelete.name !== file.name );
                        let success = false;
                        await UploadArquivosService.DeletarArquivo(uploadProps.pathName, newFile)
                            .then(x=>
                                {
                                    success = true;
                                    setFileList([...newListFile])
                                }
                                )
                            .catch(x=>{
                                success = false;
                            });
                        if(success){
                            NotificationManager.success('Arquivo anexado com sucesso.', 'Sucesso!', MessagesDefaultTimeout.Success); 
                            return true;
                        }else{
                            NotificationManager.error('Ocorreu um erro ao tentar remover o anexo.', 'Error!', MessagesDefaultTimeout.Error); 
                            return false;
                        }   
                    }}
                    beforeUpload={(file)=>{
                        return new Promise((resolve, reject) => {
                            if((file.size / 1024 / 1024) > 1){
                                message.error("Tamanho excedido. Limite de 1mb")
                                return Promise.reject("Tamanho excedido")
                            }
                            if(file.name.includes(".jpeg") || file.name.includes(".jpg") || file.name.includes(".doc") ||
                                file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".gif") || file.name.includes(".pdf") ||
                                file.name.includes(".xlsx") || file.name.includes(".jfif") || file.name.includes(".docx") ||
                                file.name.includes(".msg")){
                                    return resolve("Sucesso")
                            }else{
                                message.error("Formato inválido")
                                return Promise.reject("Formato inválido");
                            }
                        })
                    }}
                >
                <Button icon={<UploadOutlined />}>Clique para anexar arquivos</Button>
            </Upload>
            <StyledForm.Item name={input.name} noStyle>
                <Input hidden={true}  />
            </StyledForm.Item>
        </FormInputDivider>
    )
}

export default PortalInputUpload