const _defaultState = {
    isEditable: true,
    quantidadeChamadosEmAberto: '',
    quantidadeChamadosEmAbertoLoading: true,
    quantidadeChamadosFechados: '',
    quantidadeChamadosFechadosLoading: true,
    listaDeChamados: [],
    listaDeChamadosLoading: true,
    listaDeVideosYoutube: [],
    services: [],
    isOccurrenceActive: true,
}

export default (state = _defaultState, action) => {
    if(action.type==='DASHBOARD_CLEAR' || state.isEditable){
        switch (action.type){
            case 'DASHBOARD_SERVICES':
                return { ...state, services: action.payload }
            case 'DASHBOARD_BUSCA_QUANTIDADE_CHAMADOS_ABERTOS':
                return { ...state, quantidadeChamadosEmAberto: action.payload, quantidadeChamadosEmAbertoLoading: false }
            case 'DASHBOARD_BUSCA_QUANTIDADE_CHAMADOS_FECHADOS':
                return { ...state, quantidadeChamadosFechados: action.payload, quantidadeChamadosFechadosLoading: false }
            case 'DASHBOARD_LISTA_CHAMADOS':
                return { ...state, listaDeChamados: action.payload, listaDeChamadosLoading: false }
            case 'DASHBOARD_CLEAR':
                return { ..._defaultState, isEditable: action.payload }
            case 'DASHBOARD_BUSCA_VIDEOS_YOUTUBE':
                return { ...state, listaDeVideosYoutube: action.payload }
            case 'INDICADOR_CLICKED':
                return { ...state, isOccurrenceActive: action.payload }

            default:
                return state
        }
    }

    return state;
}
