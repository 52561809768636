import styled from 'styled-components';

export const FinishWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 32px;

  h2 {
    color: #343c42;
    font-size: 28px;
    font-weight: lighter;

    small {
      font-size: 20px;
    }
  }

  .finished-text {
    padding: 0 32px;
  }
`;

export const Reasons = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;

  label, textarea {
    width: 100%;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 16px;

    background-color: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0 9px 24px rgba(25, 58, 112, .15);
    cursor: pointer;

    span {
      color: #5e84c1;
    }
  }

  div {
    display: grid;
    gap: 16px;

    span {
      display: block;
      margin-top: 16px;

      color: #5e84c1;
    }

    textarea {
      cursor: auto;

      &::placeholder {
        color: #b9c3d4;
      }
    }
  }
`;
