import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest'
import urlBase from '../../assets/urlsBase'

export default async function generateEmail(data) {
	let url = `${urlBase.urlBase}/logicApps/Start/GerarEmailPrincipal`;
	let _header = await HttpRequest.apiTokenHeader();
	let params = { method: 'POST', headers: _header, body: JSON.stringify(data) };

	return fetch(url, params)
		.then(response => response.json())
    .then(response => response);
}

