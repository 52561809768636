const _defaultState = {
  isEditable: true,
  listaUsers: [],
  listaUsersLoading: true,
  listaUsersEmpty: false,
  listaSkipToken: '',
  listaFormFields: ['a'],
  userInfo: {},
  loadingForcarAtualizar: false,
  listaPerfis: [],
  listaPerfisLoading: true,
  listaLinhaServico: [],
  listaLinhaServicoLoading: true,
  listaPerfisAtribuidos: [],
  listaPerfisAtribuidosLoading: true,
  listaDePacotesCSP: [],
  listaDePacotesCSPLoading: true,
  listaProdutoComplemento: [],
  listaProdutoComplementoLoading: true,
  listaProdutoAssociado: [],
  listaProdutoAssociadoLoading: false,
  account: {},
  user: {}
};

export default (state = _defaultState, action) => {
  if (action.type === "USUARIOS_CLEAR" || state.isEditable) {
    switch (action.type) {
      case "USUARIOS_BUSCAR_POR_UPN":
        return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
      case "GET_USER":
        return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
      case "GET_ACCOUNT_BY_ID":
        return { ...state, account: action.payload };  
      case "USUARIO_FORM_FIELDS":
        return { ...state, listaFormFields: action.payload };
      case "USUARIO_LISTA_PERFIS":
        return {
          ...state,
          listaPerfis: action.payload,
          listaPerfisLoading: false
        };
      case "USUARIOS_LISTA_PLANOS_ADICIONAIS_POR_DIREITO":
        return {
          ...state,
          listaProdutoComplemento: action.payload,
          listaProdutoComplementoLoading: false
        };
      case "USUARIOS_LISTA_PLANOS_POR_DIREITO":
        return {
          ...state,
          listaDePacotesCSP: action.payload,
          listaDePacotesCSPLoading: false
        };
      case "USUARIO_LINHA_SERVICO":
        return {
          ...state,
          listaLinhaServico: action.payload,
          listaLinhaServicoLoading: false
        };
      case "USUARIO_LISTA_PERFIS_ASSOCIADOS":
        return {
          ...state,
          listaPerfisAtribuidos: action.payload,
          listaPerfisAtribuidosLoading: false
        };
      case "USUARIO_INCLUIR_PERFIL_ASSOCIADO":
        return {
          ...state,
          listaPerfisAtribuidos: [
            ...state.listaPerfisAtribuidos,
            action.payload
          ]
        };
      case "USUARIO_EDITANDO":
        return { ...state, user: action.payload };
      case "USUARIOS_LISTAGEM_FORCAR_ATUALIZAR":
        return { ...state, loadingForcarAtualizar: false };
      case "USUARIO_REMOVER_PERFIL_ASSOCIADO":
        return {
          ...state,
          listaPerfisAtribuidos: state.listaPerfisAtribuidos.filter(
            e => JSON.stringify(e) !== JSON.stringify(action.payload)
          )
        };
      case "GET_USER_SIGNATURE":
        return {
          ...state,
          userSignature: [...state.userSignature, action.payload]
        };
      case "USUARIOS_CLEAR":
        return { ..._defaultState, isEditable: action.payload };
      default:
        return state;
    }
  }
  return state;
};
