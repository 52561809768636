const _defaultState = {
  color: '',
}

export default (state = _defaultState, action) => {
  switch (action.type) {
    case 'GLOBAL_COLOR':
      return { color: action.payload }

    default:
      return state
  }
}
