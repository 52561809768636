import { useEffect, useState, useRef } from 'react';
import SelectMainFilter from './Components/SelectBox';
import ContentBox from "../../../components/ContentBox";
import Mask from "../../../utils/masks";
import ListBaseEmptyAndLoading from "components/ListBaseEmptyAndLoading";
import ChamadosService from 'services/ChamadosService';
import ListItemsChamado from '../../../EstruturaAntiga/pages/chamados/componentes/ListItemsChamado';
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import { NotificationManager } from 'react-notifications';
import PrecisoSuporteButton from 'components/PrecisoSuporte/PrecisoSuporteButton';

function ListagemAtendimentos(){
    const [filterTimeout, setFilterTimeout] = useState(null);
    const [userSession, setUserSession] = useState({})
    
    const [loadingStatus, _setLoadingStatus] = useState({isLoading: true, pageNumber: 1, mayHaveMorePages: true})
    const loadingStatusRef = useRef(loadingStatus);
    const setLoadingStatus = data => {
        loadingStatusRef.current = data;
        _setLoadingStatus(data);
    };

    const [filter, _setFilter] = useState({
        text:"", 
        filterActive: "status", 
        statusLoading: false,
        statusSelected: 0,
        sortDir: "desc",
        optionsFilterSelected: 1,
        pageSize: 10,
        needContact: true,
        optionManagerSelected: false,
        nomeSolicitante: "",
        stateCodeFiltroExibicao: "",
        orderBy: "dataAbertura",
        dateTo: "",
        dateFrom: "",
        department: ""
    });
    const loadingFilterRef = useRef(filter);
    const setFilter = data => {
        loadingFilterRef.current = data;
        _setFilter(data);
    };
    
    const [chamados, _setChamados] = useState({ chamadosList: []})
    const chamadosRef = useRef(chamados);
    const setChamados = data => {
        chamadosRef.current = data;
        _setChamados(data);
    };

    useEffect(()=>{
        const userSessionValue = JSON.parse(sessionStorage.getItem('contact'));
        setUserSession(userSessionValue);

        const intersectionObserver = new IntersectionObserver((entries) => {
            if(entries.some((entry) => entry.isIntersecting)){
                if(loadingStatusRef.current.mayHaveMorePages && !loadingStatusRef.current.isLoading){
                    loadChamados(true)
                }
            }
        }, { threshold: 1.0});

        intersectionObserver.observe(document.querySelector('#loadContainerTerm'));
        return () => intersectionObserver.disconnect();
    },[]);

    useEffect(()=>{
        loadChamados();
    },[filter]);

    function HandleFilterOptionChange(e){
        const { name, value } = e.target;
        setFilter({
            ...filter,
            [name]: value
        })
    }

    function HandleChangeDate(e){
        const {name, value} = e.target;
        let maskedValue = Mask(value, 'date')
        setFilter({
            ...filter,
            [name]: maskedValue
        })
    }

    function onChangeMainFilter(e){
        const {name, value} = e.target;
        let newData = {
            needContact: true,
            optionManagerSelected: false,
            stateCodeFiltroExibicao: "",
            department:""
        }
        switch (value) {
            case '1':
                newData.stateCodeFiltroExibicao = 0;
                break;
            case '2':
                break;
            case '3':
                newData.needContact = false;
                newData.department = userSession.contactDepartment;
                break;
            case '4':
                newData.needContact = false;
                newData.department = userSession.contactDepartment;
                break;
            case '5':
                newData.needContact = false;
                newData.optionManagerSelected = true;
                break;
            case '6':
                newData.needContact = false;
                newData.optionManagerSelected = true;
                break;
            default:
                break;
        }
        setFilter(({
            ...filter,
            optionsFilterSelected: value,
            needContact: newData.needContact,
            optionManagerSelected: newData.optionManagerSelected,
            stateCodeFiltroExibicao: newData.stateCodeFiltroExibicao,
            department: newData.department,
            text: ''
        }))
        
    }

    async function loadChamados(loadingMore = false){


        let data = {
            PageNumber: loadingMore? loadingStatusRef.current.pageNumber + 1 : 1,
            PageSize: loadingFilterRef.current.pageSize,
            OrderBy: loadingFilterRef.current.orderBy,
            SortDir: loadingFilterRef.current.sortDir,
            StateCodeFiltroStatus: loadingFilterRef.current.statusSelected,
            Assunto: loadingFilterRef.current.filterActive == 'titulo' ? loadingFilterRef.current.text : "",
            NomeSolicitante: loadingFilterRef.current.filterActive == 'solicitante' ? loadingFilterRef.current.text : "",
            DataAbertura: loadingFilterRef.current.filterActive == "data" ? loadingFilterRef.current.dateFrom : "",
            DataFechamento: loadingFilterRef.current.filterActive == "data" ? loadingFilterRef.current.dateTo : "",
            Department: loadingFilterRef.current.department,
            NeedContact: loadingFilterRef.current.needContact,
            StateCodeFiltroExibicao: loadingFilterRef.current.stateCodeFiltroExibicao,
        }
        setLoadingStatus({isLoading: true, pageNumber: data.PageNumber, mayHaveMorePages: true})

        if((loadingFilterRef.current.dateTo != "" || loadingFilterRef.current.dateFrom != "") && (loadingFilterRef.current.dateTo.length != 10 || loadingFilterRef.current.dateFrom.length != 10)){ 
                return;
        }
        if(filterTimeout){
            clearTimeout(filterTimeout);
            setFilterTimeout(null)
        }
        let timeout = setTimeout( async () => {
            if(!loadingMore){
                setChamados({
                  chamadosList: [],
                })
            }
            await ChamadosService.ListarChamados(data)
                .then(response => {
                    if(response.length > 0){
                        let newChamados = loadingMore ? [...chamadosRef.current.chamadosList, ...response]: [...response]
                        setChamados({
                            chamadosList: newChamados,
                        })
                        setLoadingStatus({ isLoading: false, pageNumber: data.PageNumber, mayHaveMorePages: (newChamados.length) == (loadingFilterRef.current.pageSize * data.PageNumber)})
                    }else{
                        setLoadingStatus({ isLoading: false, pageNumber: data.PageNumber, mayHaveMorePages: false})
                    }
                }).catch((error)=>{
                    NotificationManager.error('Não foi possível listar os chamados. Por favor tente novamente ou entre em contato com o suporte.', 'Ocorreu um erro!', 4000);
                })
            setFilterTimeout(null);
        }, 1000);
        setFilterTimeout(timeout)
    }
    
    return(
        <MasterPage>
            <div className="title-with-action form-group container-help">
                <SelectMainFilter onSelectChange={onChangeMainFilter} isActive={false === false ? "2" : ""} />
                <PrecisoSuporteButton mostrarIcone={false} tipo="button"/>
            </div>
            
            <ContentBox className="list-container">
                <div className="list-header">
                    <div className="filter-box">
                        <div className={`filter-option ${filter.filterActive === "titulo" || filter.filterActive === "solicitante"  ? '' : 'd-none'}`}>
                            <input name="text" type="text" className="filter" placeholder="Filtrar" value={filter.text} onChange={HandleFilterOptionChange}/>
                            <i className="icon icon-search"></i>
                        </div>
                        <div className={`filter-option ${filter.filterActive === "data" ? '' : 'd-none'}`}>
                            <div className="date-filter-container">
                                <div className="date-filter">
                                    <label from="dateFrom">De</label>
                                    <input type="text" id="dateFrom" name="dateFrom" className="date" value={filter.dateFrom} onChange={HandleChangeDate}/>
                                </div>
                                <div className="date-filter">
                                    <label from="dateTo">Até</label>
                                    <input type="text" id="dateTo" name="dateTo" className="date" value={filter.dateTo} onChange={HandleChangeDate}/>
                                </div>
                                <i className="icon icon-calendar"></i>
                            </div>
                        </div>
                        <div className={`filter-option ${filter.filterActive === "status" ? '' : 'd-none'}`}>
                            <div className="filter-select-box">
                                <select id="status" name='statusSelected' value={filter.statusSelected} className="filter" onChange={HandleFilterOptionChange}>
                                    <option value="">Selecionar status</option>
                                    <option value={0}>Ativo</option>
                                    <option value={1}>Resolvido</option>
                                    <option value={2}>Cancelado</option>
                                </select>
                                <i className="icon icon-arrow-dow"></i>
                                <div className={`simple-loading ${(!filter.statusLoading) ? 'd-none' : ''}`}></div>
                            </div>
                            <i className="icon icon-search"></i>
                        </div>
                        <div className="filter-options-container">
                            <select className="filter-select-option" value={filter.filterActive} name='filterActive' onChange={HandleFilterOptionChange}>
                                <option value="titulo">Por título</option>
                                <option value="solicitante" className={`${filter.optionManagerSelected  ? '' : 'd-none'}`}>Por Solicitante</option>
                                <option value="data">Por data</option>
                                <option value="status">Por status</option>
                            </select>
                            <i className="icon icon-arrow-dow"></i>
                        </div>
                        <div className="order-box">
                            <span className="order-label">Ordenar por</span>
                            <div className="select-order">
                                <select value={filter.sortDir} name="sortDir" onChange={HandleFilterOptionChange}>
                                    <option value="desc">Adicionados recentemente</option>
                                    <option value="asc">Adicionados por último</option>
                                </select>
                                <i className="icon icon-arrow-dow"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-items list-chamados">
                    <ListItemsChamado optionSelected={filter.optionsFilterSelected}  listData={chamados.chamadosList}/>
                    <ListBaseEmptyAndLoading emptyText="Nenhum chamado encontrado" isEmpty={chamados.chamadosList.length == 0} isLoading={loadingStatus.isLoading || loadingStatus.mayHaveMorePages}/>
                </div>
            </ContentBox>
            
        </MasterPage>
    )
}

export default ListagemAtendimentos;
