import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"

export default async function deleteTermoAceite(id){
    let url = `${urlBase.urlBase}/portaldb/TermoAceite/${id}`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'DELETE', headers: _header };

    return fetch(url, params)
}
