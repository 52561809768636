import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { rdxGetColor } from '../../../global/globalActions';

class TenantWarning extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
		const signatureServiceLine = sessionStorage.getItem('signature') ? JSON.parse(sessionStorage.getItem('signature')).serviceLineId.toLowerCase() : '';
    
    this.props.rdxGetColor(signatureServiceLine);
  }

  render() {
    const { color } = this.props;

    return (
      <div className="tenant-warning" style={{ background: color || '#F23838' }}>
        <span>Não foi possível localizar seu tenant Microsoft para serviços CSP. Favor entrar em contato com nosso suporte.</span>
      </div>
    )
  }
}

export default connect(
  state => ({
    color: state.global.color,
  }),
  (dispatch) => bindActionCreators({ rdxGetColor }, dispatch)
)(TenantWarning);