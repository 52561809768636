import styled from 'styled-components';

export const PoliceFormWrapper = styled.div`
  display: grid;
  gap: 16px;

  header {
    width: 100%;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      color: #193a70;
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
    }
  }

  > div {
    width: 100%;
    padding: 32px;
    display: grid;
    gap: 32px;

    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 9px 24px rgba(25, 58, 112, .15);

    label {
      position: relative;

      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      input {
        border-color: #193a70;
      }

      span {
        color: #193a70;
        font-size: 12px;
      }

      .policesError {
        position: absolute;
        top: calc(100% + 4px);

        color: #be3434;
      }
    }
  }
`;


