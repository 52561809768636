const _defaultLoadingState = {
    loadingList: [],
    loadingStatus: false
}

export default (state = _defaultLoadingState, action) => {
    switch (action.type){
        case 'LO_SET_LOADING_ITEM':
            return { loadingList: [...state.loadingList, action.payload], loadingStatus: true  }
        case 'LO_REMOVE_LOADING_ITEM':
            state.loadingList.splice(state.loadingList.indexOf(action.payload), 1);
            return { loadingList: state.loadingList, loadingStatus: (state.loadingList.length > 0) }
        default:
            return state
    }
}