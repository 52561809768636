import { Input } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";

function PortalInputText({input, divider, ...props}){
    return(
        <FormInputDivider key={input.name} {...divider}>
            <StyledForm.Item {...input} extra={props.helpMessage}>
                {
                    input.type == "text" ?
                        <Input disabled={input?.disabled} />    
                    :
                    input.type == "textarea" ?
                        <Input.TextArea disabled={input?.disabled} rows={input.rows || 4} />
                    : 
                        null
                }
            </StyledForm.Item>
        </FormInputDivider>
    )
}

export default PortalInputText;
