import React, { Component } from 'react'
import MasterPage from "../../masterpages/errorPage"
import { logOut } from "../../actions/logOut";

export default class Error401 extends Component {
    render() {
        return (
            <MasterPage classContainer="error-401" error="401" title="Acesso negado" text="O seu usuário não possui perfil de acesso definido. Entre em contato com o gestor da sua empresa.">
                <button className="btn-error" onClick={ () => logOut() }><i className="icon-arrow-left"></i>Sair e se conectar com outro login</button>
            </MasterPage>
        );
    };
}
