import React from 'react';

import Logo from '../../../assets/images/logo.png';
import Instagram from '../../../assets/icons/instagram.svg';
import Linkedin from '../../../assets/icons/linkedin.svg';
import Facebook from '../../../assets/icons/facebook.svg';
import Youtube from '../../../assets/icons/youtube.svg';
import Www from '../../../assets/icons/www.svg';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img src={Logo} alt="Página inicial - BHS Axter Portal do cliente" />
      </div>
      
      <div className="footer-midias">
        <div>
          <a href="https://www.instagram.com/somosbhs/" target="_blank">
            <img src={Instagram} alt="Instagram BHS" />
          </a>
          
          <a href="https://www.facebook.com/BhsOficial/" target="_blank">
            <img src={Facebook} alt="Facebook BHS" />
          </a>
          
          <a href="https://www.linkedin.com/company/bhsoficial" target="_blank">
            <img src={Linkedin} alt="Linkedin BHS" />
          </a>
          
          <a href="https://youtube.com/BHSoficial" target="_blank">
            <img src={Youtube} alt="Youtube BHS" />
          </a>
          
          <a href="https://www.bhs.com.br/" target="_blank">
            <img src={Www} alt="Site BHS" />
          </a>
        </div>
        <span>Acompanhe nossos conteúdos</span>
      </div>
    </footer>
  )
}
