import React, { useState, useEffect } from 'react';
import { Rate, Input, Form, Row, Col, Button } from 'antd';
import { SyledModal } from './styles';

const { TextArea } = Input;

const PesquisaFeedbackModal = ({ titulo, rotuloMenorResposta, rotuloMaiorResposta, abrirModal, handleEnviar, handleCancelar, handleNaoQueroResponder, resetModal, onResetCompleto }) => {
  const [nota, setNota] = useState(0);
  const [comentario, setComentario] = useState('');

  useEffect(() => {
    if (resetModal) {
      setNota(0);
      setComentario('');
      onResetCompleto();
    }
  }, [resetModal, onResetCompleto]);

  return (
    <SyledModal
      title={titulo}
      open={abrirModal}
      onCancel={handleCancelar}
      footer={[
        <Button key="back" onClick={handleCancelar}>
          Exibir mais tarde
        </Button>,
        <Button key="naoQueroResponder" type="primary" onClick={handleNaoQueroResponder}>
          Não responder
        </Button>,
        <Button key="submit" type="primary" onClick={() => handleEnviar(nota, comentario)}>
          Enviar
        </Button>,
      ]}
    >
      <Form>
        <Form.Item>
          <Row align="middle">
            <Col>
              <span><b>{rotuloMenorResposta}</b></span>
            </Col>
            <Col flex="auto" style={{ textAlign: 'center' }}>
              <Rate onChange={value => setNota(value)} value={nota} />
            </Col>
            <Col>
              <span><b>{rotuloMaiorResposta}</b></span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Comentário">
          <TextArea rows={3} onChange={e => setComentario(e.target.value)} value={comentario} />
        </Form.Item>
      </Form>
    </SyledModal>
  );
};

export default PesquisaFeedbackModal;