import React, { Component } from 'react';
import { ModalContainer } from './styles';

export default class Modal extends Component {
    render() {
        return (
            <div id={this.props.id} className={`modal ${this.props.open ? 'open' : ''} ${this.props.specialClass && this.props.specialClass}`}>
                <div className="shadow-box" onClick={this.props.close}></div>
                <ModalContainer className={`modal-content ${this.props.sizeClassName ? `${this.props.sizeClassName}` : ''}`}>
                    <div className="header">
                        <i className="icon icon-close" onClick={this.props.close}></i>
                    </div>

                    {this.props.children}
                </ModalContainer>
            </div>
        );
    };
}
