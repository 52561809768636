import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import instrumentationKey from '../../assets/instrumentationKey';

export function initialize(reactPluginConfig) {
    const reactPlugin = new ReactPlugin();

       var appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey.instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: reactPluginConfig
                }
            }
        });
        appInsights.loadAppInsights();
}
