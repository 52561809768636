import React from 'react'
import MasterPage from '../../../masterpages/authenticated'
import ContentBox from "../../../../components/ContentBox";
import Input from "../../../components/form/InputGroup";
import RichText from "../../../components/form/richTextHtmlGroup";
import Select from "../../../components/form/SelectGroup";
import { setLoadingItem, removeLoadingItem } from '../../../components/loading/loadingActions'
import { getTermo, getClients, getServices, getProfiles, removerTermo } from '../termoDeAceiteAdmActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";
import uuid from "EstruturaAntiga/assets/uuid";
import Form from "EstruturaAntiga/assets/formValidate"
import RichTextEditor from 'react-rte'
import Button from "../../../components/form/Button";
import deleteTermoAceite from "../../../actions/termos/deleteTermoAceite";
import putTermoAceite from "../../../actions/termos/putTermoAceite";
import { NotificationManager } from 'react-notifications';

class EditarTermos extends Form {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            publicoAlvo: [],
            erroPublicoAlvo: '',
            form: {
                termTitle: '',
                termDescription: RichTextEditor.createEmptyValue(),
                cliente: '',
                servico: '',
                perfil: ''
            },
            validations: {
                termTitle: { required: true },
                termDescription: { required: true },
                cliente: {},
                servico: {},
                perfil: {}
            },
            hasError: {
                termTitle: {},
                termDescription: {},
                cliente: {},
                servico: {},
                perfil: {}
            },
            clientesLoading: true,
            servicosLoading: true,
            perfisLoading: true,
            loadingRemoveAction: false,
            showConfirmExclude: false,
            termoLoading: false
        }
    }

    componentWillMount() {
        for (var key in this.state.hasError) {
            this.validateInput(key)
        }
    }

    componentDidMount() {
        if (this.props.selectedId === null) {
            this.props.history.push('/termos');

            return false;
        }

        // Busca dados do termo a ser editado
        let loadingTermoName = 'Editar termo';
        this.props.setLoadingItem(loadingTermoName);
        this.props.getTermo({ id: this.props.selectedId }).then(() => {
            var newForm = this.state.form;
            newForm.termTitle = this.props.selectedTerm.titulo;
            newForm.termDescription = RichTextEditor.createValueFromString(this.props.selectedTerm.descricao, 'html')

            var newPublicoAlvo = this.props.selectedTerm.termoAceiteClienteLinhaServicoPerfil
                .filter(el => { return (el.idAccount != null || el.idAxtRole !== null || el.idServiceLine !== null) })
                .map(el => {
                    delete el.idTermoAceite;
                    el.idAxtRole = el.idAxtRole && el.idAxtRole.toString();
                    return el
                })

            this.setState({ form: newForm, publicoAlvo: newPublicoAlvo });

            this.props.removeLoadingItem(loadingTermoName);
        })

        if (!this.props.clientes.length) {
            this.props.getClients().then(() => {
                this.setState({ clientesLoading: false })
            })
        }
        else { this.setState({ clientesLoading: false }) }

        if (!this.props.servicos.length) {
            this.props.getServices().then(() => {
                this.setState({ servicosLoading: false })
            })
        }
        else { this.setState({ servicosLoading: false }) }

        if (!this.props.perfis.length) {
            this.props.getProfiles().then(() => {
                this.setState({ perfisLoading: false })
            })
        }
        else { this.setState({ perfisLoading: false }) }
    }

    onClickAddPublicoAlvo() {
        if (this.state.form.cliente || this.state.form.servico || this.state.form.perfil) {
            let newValue = { idAccount: this.state.form.cliente || null, idServiceLine: this.state.form.servico || null, idAxtRole: this.state.form.perfil || null };
            let isDuplicated = this.state.publicoAlvo.filter(el => JSON.stringify(el) === JSON.stringify(newValue)).length > 0;

            if (!isDuplicated) {
                this.setState(prevState => ({
                    publicoAlvo: [...prevState.publicoAlvo, newValue],
                    erroPublicoAlvo: ''
                }));
            }
            else {
                this.setState({ erroPublicoAlvo: 'Público alvo selecionado já adicionado.' });
            }

            this.setState({ form: {...this.state.form, cliente: '', servico: '', perfil: ''} })
        }
    }

    onClickExcluirPublicoAlvo(obj) {
        this.setState(prevState => ({
            publicoAlvo: prevState.publicoAlvo.filter(el => JSON.stringify(el) !== JSON.stringify(obj)),
            erroPublicoAlvo: ''
        }));
    }

    onClickToggleExcluir() {
        this.setState({ showConfirmExclude: !this.state.showConfirmExclude });
    }

    onClickExcluir() {
        this.setState({ loadingRemoveAction: true });

        deleteTermoAceite(this.props.selectedId).then(() => {
            NotificationManager.error(null, 'Termo excluido!', 3000);
            this.props.removerTermo(this.props.selectedId);
            this.props.history.push('/termos');
        });
    }

    onSubmit = (publicado) => {

        this.setState({ termoLoading: true });
        let data = {
            "titulo": this.state.form.termTitle,
            "descricao": this.state.form.termDescription.toString('html'),
            "termoAceiteClienteLinhaServicoPerfil": this.state.publicoAlvo.length ? this.state.publicoAlvo : [{ idAccount: null, idAxtRole: null, idServiceLine: null }],
            "publicado": publicado,
            "ativo": true
        }

        putTermoAceite(this.props.selectedId, data).then(() => {
            this.setState({ termoLoading: false });
            NotificationManager.success('Alteração realizada com sucesso', 'Sucesso!', 3000);
            this.props.history.push('/termos')
        });
    }

    renderPublicoAlvo() {
        if (!this.state.clientesLoading && !this.state.servicosLoading && !this.state.perfisLoading) {
            return (
                <div className="container-result">
                    <div className={`container-publico-alvo ${this.state.publicoAlvo.length === 0 ? 'd-none' : ''}`}>
                        <span className="title">Termo visível para:</span>
                        <div className="row results">
                            {
                                this.state.publicoAlvo.map(obj => (
                                    <div key={uuid()} className="col-12 col-sm-6 col-lg-4 result">
                                        <div className="container-result">
                                            <div className="result-details">
                                                <span className="label">Cliente:</span>
                                                <span className="value">{obj.idAccount ? this.props.clientes.filter(el => el.accountid === obj.idAccount)[0].displayName : 'Todos'}</span>
                                                <span className="label">Serviço:</span>
                                                <span className="value">{obj.idServiceLine ? this.props.servicos.filter(el => el.id === obj.idServiceLine)[0].axt_Displayname : 'Todos'}</span>
                                                <span className="label">Perfil:</span>
                                                <span className="value">{obj.idAxtRole ? this.props.perfis.filter(el => el.option === parseInt(obj.idAxtRole, 10))[0].localizedLabel : 'Todos'}</span>
                                            </div>
                                            <button className="btn btn-danger" onClick={() => this.onClickExcluirPublicoAlvo(obj)}>Excluir</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <span className={`no-results ${this.state.publicoAlvo.length > 0 ? 'd-none' : ''}`}>Termo visível a todos os usuários</span>
                </div>
            )
        }
        else {
            return (
                <div className="block-loading">
                    <div className='simple-loading'></div>
                </div>
            )
        }
    }

    render() {
        return (
            <MasterPage>
                <h1>Editar termo</h1>
                <h2>Informações do termo</h2>
                <h3>O que será exibido ao usuário</h3>

                <ContentBox className="form-container">
                    <Input id="termTitle" label="Título*" configureInput={this.configureInput('termTitle')} />
                    <RichText id="termDescricao" label="Descrição*" configureInput={this.configureRichText('termDescription')} />
                </ContentBox>

                <h2>Público alvo</h2>
                <h3>Quem deve ver o termo</h3>

                <ContentBox className="form-container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <Select
                                label="Cliente"
                                items={this.props.clientes.map(el => { return { value: el.accountid, label: el.displayName } })}
                                configureInput={this.configureInput('cliente')}
                                loading={this.state.clientesLoading}>
                                <option value="">Todos</option>
                            </Select>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <Select
                                label="Linha de serviço"
                                items={this.props.servicos.map(el => { return { value: el.id, label: el.axt_Displayname } })}
                                configureInput={this.configureInput('servico')}
                                loading={this.state.servicosLoading}>
                                <option value="">Todos</option>
                            </Select>
                        </div>
                        <div className="col-12 col-lg-3">
                            <Select
                                label="Perfil"
                                items={this.props.perfis.map(el => { return { value: el.option, label: el.localizedLabel } })}
                                configureInput={this.configureInput('perfil')}
                                loading={this.state.perfisLoading}>
                                <option value="">Todos</option>
                            </Select>
                        </div>
                        <div className="col-12 col-lg-3 add-publico-alvo">
                            <button className="btn btn-default" disabled={!(this.state.form.cliente || this.state.form.servico || this.state.form.perfil)} onClick={() => this.onClickAddPublicoAlvo()}>Adicionar público alvo</button>
                        </div>
                    </div>

                    <span className={`error-duplicated ${this.state.erroPublicoAlvo ? '' : 'd-none'}`}>{this.state.erroPublicoAlvo}</span>

                    {this.renderPublicoAlvo()}
                </ContentBox>

                <div className="action-container edit-page">
                    <div className="remove-action">
                        <button className={`btn btn ${this.state.showConfirmExclude ? 'd-none' : ''}`} onClick={() => this.onClickToggleExcluir()}>Excluir</button>
                        <div className={`actions ${this.state.showConfirmExclude ? '' : 'd-none'}`}>
                            <button className="btn" onClick={() => this.onClickToggleExcluir()}>Cancelar</button>
                            <Button className="btn-danger" onClick={() => this.onClickExcluir()} loading={this.state.loadingRemoveAction}>Excluir</Button>
                        </div>
                    </div>
                    <div className="default-actions term-container d-flex">
                        <div>
                            <button disabled={this.state.termoLoading} className={`btn ${(this.state.termoLoading) ? 'term-loading' : ''}`} onClick={() => this.handleSubmit(false)}>Salvar</button>
                            <i className={`simple-loading ${(this.state.termoLoading) ? '' : 'd-none'}`}></i>
                        </div>
                        <div>
                            <button disabled={this.state.termoLoading} className={`btn ${(this.state.termoLoading) ? 'term-loading' : 'btn-default'}`} onClick={() => this.handleSubmit(true)}>Salvar e Publicar</button>
                            <i className={`simple-loading ${(this.state.termoLoading) ? '' : 'd-none'}`}></i>
                        </div>
                    </div>
                </div>
            </MasterPage>
        );
    };
}

const mapDispatchToProps = dispatch => (bindActionCreators({ setLoadingItem, removeLoadingItem, getTermo, getClients, getServices, getProfiles, removerTermo }, dispatch))
export default withRouter(connect(
    state => ({
        clientes: state.admtermos.listaClientes,
        servicos: state.admtermos.listaServicos,
        perfis: state.admtermos.listaPerfis,
        selectedId: state.admtermos.selectedTermId,
        selectedTerm: state.admtermos.selectedTerm
    }),
    mapDispatchToProps
)(EditarTermos));
