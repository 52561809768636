import React from 'react';
import { useEffect, useState } from 'react';
import getOcurrenceSearchOptions from "../../../../EstruturaAntiga/actions/chamados/getOcurrenceSearchOptions";
import { options } from 'sw-toolbox';

export default function SelectBox(props) {
    const [state, setState] = useState({ optionsValues: [] });
    const [isMounted, setIsMounted] = useState(true);
    useEffect(()=>{
        const searchOptions = async () =>{
            let response = await getOcurrenceSearchOptions();
            if(!isMounted) return;
            setState({...state, optionsValues: response})
        };
        searchOptions();
        return () => {
            setIsMounted(false);
        };
    },[]);

    return(
        <div className="filter-select-box">
            <div className="input-group-container">
            {
                state.optionsValues? (
                    <div>
                        <select className="input-group filter-select" onChange={props.onSelectChange}>
                            {
                                state.optionsValues.map(option => 
                                    <option 
                                        key={option.value} 
                                        defaultValue={props.isActive == option.value ? 'selected' : options.value} 
                                        value={option.value}
                                    >
                                        {option.description}
                                    </option>
                                )
                            }
                        </select> 
                    </div>
                ): null
            }
            </div>
            <div className={`simple-loading ${state.optionsValues.length > 0 ? 'd-none': ''}`}></div>
        </div>
    )
}
