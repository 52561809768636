import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function getPlansRightsByEntitlementId() {
    const userSignature = HttpRequest.userSignature();
    const tenant = JSON.parse(sessionStorage.getItem('tenant'));

    let url = `${urlBase.urlBase}/portaldb/ListasDePrecos/ListarPlanosPorDireito?modeloDeOfertaCodigo=${tenant.modeloOferta}&direitoId=${userSignature.entitlementId}&tenantId=${userSignature.axtTenantId}`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'GET', headers: _header };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        })
}
