import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function getFormFields() {
    // let idAccount = HttpRequest.contact().parentAccountId;
    // let url = `${urlBase.urlBase}/portaldb/Campo/?idAccount=${idAccount}`;

    const { axtTenantId } = JSON.parse(sessionStorage.getItem('signature'));
    let url = `${urlBase.urlBase}/portaldb/Campo/GetByTenantId?tenantId=${axtTenantId || '00000000-0000-0000-0000-000000000000'}`;

    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'GET', headers: _header };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        })
}
