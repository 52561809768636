import styled from 'styled-components';

export const AccessWrapper = styled.form`
  width: 100%;
  min-height: calc(100vh - 77px);
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 510px;
    padding: 32px;
    display: grid;
    gap: 16px;

    background-color: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0 9px 24px rgba(25, 58, 112, .15);

    h4 {
      margin: 0;

      color: #343c42;
      font-size: 28px;
      font-weight: lighter;
      text-align: center;
    }

    p {
      text-align: center;
    }

    span {
      color: #be3434;
      font-size: 14px;
      text-align: center;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  input {
    width: 360px;

    background-color: none;
    border: none;
    border-bottom: 1px solid #5e84c1;

    color: #5e84c1;
    font-size: 40px;
    text-align: center;

    &:disabled {
      color: #637381;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  .block-loading {
    padding: 0;
  }
`;
