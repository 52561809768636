import { useClearCacheCtx } from "react-clear-cache";

const ClearCache = () => {
	
	const verifyLatestVersion = () => {
		const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

		if(!isLatestVersion){
			emptyCacheStorage();
			console.info('O cache do navegador foi limpo.');
		}

		return true;
	}

	return (
		verifyLatestVersion() && null
	);
}

export default ClearCache;
