export const setLoadingItem = (item) => {
    return {
        type: 'LO_SET_LOADING_ITEM',
        payload: item
    }
}

export const removeLoadingItem = (item) => {
    return {
        type: 'LO_REMOVE_LOADING_ITEM',
        payload: item
    }
}