const _instrumentationKey = () => {
    
    if(window.RUNTIME_API_HOST.toString().toLowerCase() === 'development'){
        return {
            instrumentationKey: '067b3f02-0222-4a8c-852a-e4f571da0518'
        }
    }
    else if(window.RUNTIME_API_HOST.toString().toLowerCase() === 'production'){
        return {
            instrumentationKey: 'eb910e6d-1207-47df-b209-eb255dd00596',
        }
    }
};

const instrumentationKey = _instrumentationKey();

export default instrumentationKey;