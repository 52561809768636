import { Collapse, Descriptions, Flex, Modal } from 'antd';
import { StyledCard, StyledP } from './styles';
import { withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DateUtils from "utils/date";
import { LoadingOutlined } from '@ant-design/icons';
import FaturasService from 'services/FaturasService';
import OcorrenciasService from 'services/OcorrenciasPortalService';
import returnIcons from 'EstruturaAntiga/components/navigation/components/getIcons';
import BotaoDownload from 'components/Botoes/BotaoDownload/BotaoDownload';
import Botao from 'components/Botoes/Botao/Botao';

function CardFinanceiro({ fatura, razoesStatus}){
    const idBancoBrasil = "001";
    const RazaoStatusFaturaVencidaCodigo = 321770002;
	const RazaoStatusParcelaVencidaCodigo = 321770001;
	const [loading, setLoading] = useState(false)
	const [loadingAtualizarBoleto, setLoadingAtualizarBoleto] = useState(false)
	const [hasError, setHasError] = useState(false)
	const [isMounted, setIsMounted] = useState(true);
	
	const [painelParcelasAberto, setPainelParcelasAberto] = useState(false);
	const [apiConsultada, setApiConsultada] = useState(false);
	
	const [faturaDescriptionItems, setFaturaDescriptionItems] = useState([])
	const [parcelas, setParcelas] = useState([])

	useEffect(()=>{
		return () => {
            setIsMounted(false);
        };
	},[]) 
	useEffect(()=>{
		let newItems = [
			{ label: 'Fatura', children: fatura.nome },
			{ label: 'Status', children: RazaoStatus() },
			{ children: BotoesDownload()},
			{ label: 'Emissão', children: DateUtils.DateFormat(new Date(fatura.dataEmissaoFaturaProtheus)) },
			{ label: 'Valor', children: `R$${fatura.valorTotalProtheus}`},
			{ label: 'Linha de servico', children: fatura.linhaServico, },
			{ label: 'Nota fiscal', children: (fatura.notaFiscal ?? ""), },
		];
		if(!fatura.temParcelamento){
			newItems.push({ label: 'Vencimento', children: DateUtils.DateFormat(new Date(fatura.dataVencimentoRealProtheus)) })
		}
		setFaturaDescriptionItems (newItems);
	}, [fatura, razoesStatus, loadingAtualizarBoleto])

	const BuscarParcelas = async () =>{
		if(painelParcelasAberto){
			setPainelParcelasAberto(false);
			return;
		}
		setPainelParcelasAberto(true);
		if(apiConsultada && !hasError) return;
		setLoading(true)
		var parcelas = await FaturasService.ListarParcelasPorFatura(fatura.faturaId)
			.catch(error=>{
				if(!isMounted) return;
				setHasError(true)
				setLoading(false);
				return;
			}
		);
		if(parcelas == undefined) return;
		if(!isMounted) return;
		setApiConsultada(true);
		setLoading(false);
		setParcelas(parcelas)
	}
	const AtualizarBoleto = async (parcela, dataVencimento) =>{
		setLoadingAtualizarBoleto(true)
		const contact = JSON.parse(sessionStorage.getItem('contact'));
        const signature = JSON.parse(sessionStorage.getItem('signature'));
		let values = {
			AtributoEstendido1:fatura.nome, 
			AtributoEstendido2:parcela,
			solicitanteId: contact?.idContact,
			clienteId: signature.accountId,
			direitoId: signature.entitlementId,
			dataCustomizada1: dataVencimento,
		}
		await OcorrenciasService.AtualizarBoletoFaturamento(values).then(response=>{
			setLoadingAtualizarBoleto(false);
			Modal.success({
				title: 'Sucesso!',
				content: `A sua solicitação ${response.numeroOcorrencia} foi registrada com sucesso! Acompanhe o andamento da solicitação pelo menu ATENDIMENTOS.`,
				centered: true
			});
		})
		.catch(error =>{
			setLoadingAtualizarBoleto(false);
			Modal.error({
				title: 'Ocorreu um erro!',
				content: 'Não foi possível criar a solicitação. Por favor tente novamente ou entre em contato com o suporte.',
				centered: true
			});
		})
	}
	
	const Parcelas = () =>{
		if(loading){
			return(
				<Flex justify='center' align='center' vertical>
					<LoadingOutlined style={{ fontSize: 20 }}/>
					<p>Buscando dados...</p>
				</Flex>
			)
		}
		else if(hasError){
			return(<p>Não foi possível buscar as parcelas</p>)
		}
		else if(parcelas.length == 0){
			return(<p>Nenhuma parcela encontrada</p>)
		}else{
			return (
				parcelas.map(parcela =>{
					let dataLimiteAtualizarBoleto = DateUtils.GetPastDate(59, 0)
					let dataLimiteDownloadArquivos = DateUtils.GetPastDate(0, 2);
					let hasBoleto = parcela.dataModificacaoBoleto && new Date(parcela.dataModificacaoBoleto) > dataLimiteDownloadArquivos;
					let atualizarBoleto = parcela.razaoStatusCodigo == RazaoStatusParcelaVencidaCodigo && parcela.vencimentoReal && new Date(parcela.vencimentoReal) < dataLimiteAtualizarBoleto;

					return(
						<Flex key={parcela.parcelaDaFaturaId} justify='flex-start' align='center' style={{marginBottom: "10px"}}>
							<span style={{marginRight: "20px"}}>
								Parcela: {parcela.parcela} Vencimento: {DateUtils.DateFormat(new Date(parcela.vencimentoReal))} Valor: R${parcela.valor} Saldo: R${parcela.saldo} - {parcela.razaoStatus} 
							</span>
							{
								hasBoleto ?
									atualizarBoleto ?
										<Botao
											loading={loadingAtualizarBoleto}
											onClick={()=>AtualizarBoleto(parcela.parcela, parcela.vencimentoReal)}
											icon={<img src={returnIcons("icon-atualizar-boleto")} alt={"icone"} />}
											tooltip="Atualizar Boleto"
										/>
									: 
										<BotaoDownload
											apiCallback={()=>FaturasService.BuscarLink(fatura.faturaId, "boleto", parcela.parcela)}
											linkName={"boletoLink"}
											icon={<img src={returnIcons("icon-download-boleto")} alt={"icone"} />}
											tooltip="Boleto Bancário"
										/>
								: null
							}
						</Flex>
					)	
				})
			)
		}
	}
	const RazaoStatus = () =>{
		let a = razoesStatus.filter(x=>x.codigo == fatura.razaoStatusCodigo);
		return (<StyledP style={{backgroundColor: a[0]?.cor?? "#c9c9c9", color: "#ffffff"}}>{fatura.razaoStatus}</StyledP>)
	}
	const BotoesDownload = () =>{
		let dataLimiteAtualizarBoleto = DateUtils.GetPastDate(59, 0)
		let dataLimiteDownloadArquivos = DateUtils.GetPastDate(0, 2);

		let hasBoleto = fatura.dataModificacaoBoleto && new Date(fatura.dataModificacaoBoleto) > dataLimiteDownloadArquivos;
		let atualizarBoleto = fatura.razaoStatusCodigo == RazaoStatusFaturaVencidaCodigo && fatura.dataVencimentoRealProtheus && new Date(fatura.dataVencimentoRealProtheus) < dataLimiteAtualizarBoleto;
		let hasExtrato = fatura.dataModificacaoExtrato && new Date(fatura.dataModificacaoExtrato) > dataLimiteDownloadArquivos;
		let hasRps = fatura.dataModificacaoRPS && new Date(fatura.dataModificacaoRPS) > dataLimiteDownloadArquivos;
		
		return (
			<Flex vertical>
				{
					hasBoleto || hasRps || hasExtrato ?
					<p style={{margin: 0}}>Arquivos para download</p>
					: null
				}
				<Flex>
					{
						hasBoleto ? 
							atualizarBoleto && fatura.bancoProprietarioBoleto != idBancoBrasil
								?
									<Botao
										loading={loadingAtualizarBoleto}
										onClick={()=>AtualizarBoleto("única", fatura.dataVencimentoRealProtheus)}
										icon={<img src={returnIcons("icon-atualizar-boleto")} alt={"icone"} />}
										tooltip="Atualizar Boleto"
									/>
								:
									<BotaoDownload 
										apiCallback={()=>FaturasService.BuscarLink(fatura.faturaId, "boleto")} 
										linkName={"boletoLink"} 
										icon={<img src={returnIcons("icon-download-boleto")} alt={"icone"} />}
										tooltip="Boleto Bancário"
									/> 
						: null}
					{
						hasRps ?
							<BotaoDownload
								apiCallback={()=>FaturasService.BuscarLink(fatura.faturaId, "rps")}
								linkName={"boletoLink"}
								icon={<img src={returnIcons("icon-download-rps")} alt={"icone"} />}
								tooltip="RPS"
							/>
						: null
					}
					{
						hasExtrato ?
						<BotaoDownload
							apiCallback={()=>FaturasService.BuscarLink(fatura.faturaId, "extrato")}
							linkName={"boletoLink"} icon={<img src={returnIcons("icon-download-extrato")} alt={"icone"} />}
							tooltip="Extrato de Consumo"
						/>
						: null
					}
				</Flex>
			</Flex>
		)
	}
	return(
		<StyledCard bordered={true}>
			<Descriptions items={faturaDescriptionItems} bordered={false} size={"small"}/>
			{fatura.temParcelamento && (
				<Collapse 
					items={[{ 
						label: 'Parcelamento', 
						children: (
							Parcelas()
						)
					}]} 
					style={{width: "100%"}} 
					onChange={BuscarParcelas}/>
			)}
		</StyledCard>
	)
}

export default withRouter(CardFinanceiro)