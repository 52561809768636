export const mapUserFieldsV2toV1 = (user) => {
    return {
        accountEnabled: user.accountEnabled,
        telephone1: user.businessPhones[0],
        address1_city: user.city,
        companyName: user.companyName,
        department: user.department,
        axt_displayname365: user.displayName,
        employeeid: user.employeeId,
        firstname: user.givenName,
        jobtitle: user.jobTitle,
        emailaddress1: user.mail,
        nickname: user.mailNickname,
        mobilephone: user.mobilePhone,
        adx_organizationname: user.officeLocation,
        axt_onpremisessyncenabled: user.onPremisesSyncEnabled,
        emailaddress2: user.otherMails[0],
        address1_stateorprovince: user.state,
        address1_line1: user.streetAddress,
        lastname: user.surname,
        usageLocation: user.usageLocation,
        axt_userprincipalname: user.userPrincipalName,
        _axt_servicebundle_value: user._axt_servicebundle_value,
        axt_contact_products: user.axt_contact_products,
        assignedLicenses: user.assignedLicenses,
        assignedPlans: user.assignedPlans,
        axt_extensionattribute1: user?.onPremisesExtensionAttributes?.extensionAttribute1,
        axt_extensionattribute2: user?.onPremisesExtensionAttributes?.extensionAttribute2,
        axt_extensionattribute3: user?.onPremisesExtensionAttributes?.extensionAttribute3,
        axt_extensionattribute4: user?.onPremisesExtensionAttributes?.extensionAttribute4,
        axt_extensionattribute5: user?.onPremisesExtensionAttributes?.extensionAttribute5,
        axt_extensionattribute6: user?.onPremisesExtensionAttributes?.extensionAttribute6,
        axt_extensionattribute7: user?.onPremisesExtensionAttributes?.extensionAttribute7,
        axt_extensionattribute8: user?.onPremisesExtensionAttributes?.extensionAttribute8,
        axt_extensionattribute9: user?.onPremisesExtensionAttributes?.extensionAttribute9,
        axt_extensionattribute10: user?.onPremisesExtensionAttributes?.extensionAttribute10,
        axt_extensionattribute11: user?.onPremisesExtensionAttributes?.extensionAttribute11,
        axt_extensionattribute12: user?.onPremisesExtensionAttributes?.extensionAttribute12,
        axt_extensionattribute13: user?.onPremisesExtensionAttributes?.extensionAttribute13,
        axt_extensionattribute14: user?.onPremisesExtensionAttributes?.extensionAttribute14,
        axt_extensionattribute15: user?.onPremisesExtensionAttributes?.extensionAttribute15,
        id: user.id
    }
}