import PortalClienteDBService from "services/bases/PortalClienteDBService";

class ChamadoService extends PortalClienteDBService{
    async CadastrarChamado(chamado){
        return await this.post("dynamics/Occurrence",chamado)
    }
    async BuscarChamado(id, searchOption){
        return this.get(`ocorrencias?ocorrenciaId=${id}`)
    }
    async ListarChamados(atributosUrl){
        let defaultUrlAtributes = {
            StateCodeFiltroStatus: '',
            Assunto: '',
            NomeSolicitante: '',
            DataAbertura: '',
            DataFechamento: '',
            PageSize: 5,
            PageNumber: 1,
            OrderBy: '',
            SortDir: 'desc',
            Department: '',
            NeedContact: true,
            StateCodeFiltroExibicao: 0
        };
        let urlAtributes = Object.assign(defaultUrlAtributes, atributosUrl);
        let stringParams = Object.keys(urlAtributes).map(el => el + '=' + urlAtributes[el]).join("&");
        return await this.get(`portaldb/ocorrencias?${stringParams}`)
    }
}

export default new ChamadoService();