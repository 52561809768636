import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export async function getTenant() {
    const tenant = JSON.parse(sessionStorage.getItem('signature'));
    const url = `${urlBase.urlBase}/dynamics/Tenant/${tenant.axtTenantId}`;

    const header = await HttpRequest.apiTokenHeader();
    const params = {
        method: 'GET',
        headers: header
    };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
        return result;
        });
}

export function mapSessionStorageTenant(tenant) {
    return {
        tenantType: tenant.axt_tenanttype,
        tenantCode: tenant.axt_tenantcode,
        tenantName: tenant.axt_displayname,
        isShared: tenant.axt_isshared,
        modeloOferta: tenant.axt_modelodeoferta,
        formId: tenant._axt_adx_entityform_value,
        versaoPortal: tenant.axt_versaodoportal
    }
}