import { useEffect, useState, useRef } from 'react';
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import { Alert, Col, Flex, Pagination, Row, Space, Tooltip, Modal } from 'antd';
import LoadingOrErrorPanel from 'components/LoadingOrErrorPanel';
import FinanceiroCard from './Components/CardFinanceiro';
import FaturasService from 'services/FaturasService';
import OcorrenciasService from 'services/OcorrenciasPortalService';
import Formulario from 'components/Formulario/Formulario';
import { StyledForm } from 'components/Formulario/styles';
import { LoadingType } from 'components/Formulario/Components/PortalSelect';
import DireitosPortalService from 'services/DireitosPortalService';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import { NotFoundDiv } from './Styles';
import DateUtils from "utils/date";
import { StyledButton } from 'components/Botoes/styles';
import { useRoteiro } from 'context/RoteiroContext';

function ListagemFinanceiro(){
    const [error, setHasError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [exibirBaixarVariosArquivos, setExibirBaixarArquivos] = useState(false);
    const [loadingBaixarVariosArquivos, setLoadingBaixarVariosArquivos] = useState(false);
    const [faturas, setFaturas] = useState([]);
    const [cachedPages, setCachedPages] = useState({});
    const [razoesStatus, setRazoesStatus] = useState([]);
    const [formFilterReference] = StyledForm.useForm();
    const [paginationDataConfig, setPaginationDataConfig] = useState({
        total: 10,
        tamanhoPagina: 10,
        filtroDelayEmMs: 1000,
        paginaAtual: 1
    });
    const refBotaoBaixarVariosArquivos = useRef(null);
    const refFiltroFaturas = useRef(null);
    const {setReferenciaObjetosVisuais} = useRoteiro();

    const refs = [
        { id: 'refBotaoBaixarVariosArquivos', ref: refBotaoBaixarVariosArquivos },
        { id: 'refFiltroFaturas', ref: refFiltroFaturas }
    ];

    const filterInputs = [
        { 
            input:{ name: "linhaServicoId", type: "select", label:"Linhas de Serviço",
                options: {
                    idField: "linhaDeServicoId", labelField: "nome", loadingType: LoadingType.AoRenderizar,
                    callback: async () => {return await DireitosPortalService.ListarLinhasDeServico([CONSTANTES_AMBIENTE.PERFIL_ACESSO.FINANCEIRO_CLIENTE, CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO])}
                }
            },
        },
        { 
            input: { type:"dateperiod", colSpan:"10" }, 
            dateperiod: { 
                start: { name:"dataInicio", label: "Início"}, 
                end: { name: "dataTermino", label: "Término"}, 
                rules: { minDate: DateUtils.GetPastDate(0,2), allowNullDate: true }
            }
        },
        { input: 
            { 
                name: "razaoStatus", type:"select", label: "Status da Fatura",
                options: { idField: "codigo", labelField:"rotulo", 
                    callback: async ()=> {return await FaturasService.ListarRazoesStatus()}
                }
            }
        }
    ];

    useEffect(()=>{
        BuscarRazoesStatus();
        BuscarFaturas();
        setReferenciaObjetosVisuais(refs);
    },[]);

    const HandleOnPaginationChange = (paginaAtual, tamanhoPagina) =>{
        let tamanhoPaginaAtual = paginationDataConfig.tamanhoPagina;
        setPaginationDataConfig({
            ...paginationDataConfig,
            paginaAtual,
            tamanhoPagina
        })
        if(cachedPages[paginaAtual] && tamanhoPaginaAtual == tamanhoPagina){
            setFaturas(cachedPages[paginaAtual]);
        }else{
            BuscarFaturas(paginaAtual, tamanhoPagina);
        }
    }
    const BuscarFaturas = async (pagina = 1, tamanhoPagina = 10, filtro = {}) =>{
        setLoading(true);
        setExibirBaixarArquivos(false);
        const dados = await FaturasService.ListarPorCliente(pagina, tamanhoPagina,filtro)
            .catch(error =>{
                setLoading(false)
                setHasError(true);
                return;
            });
        if(dados == undefined) return;
        setPaginationDataConfig((prevPaginationDataConfig) =>({
            ...prevPaginationDataConfig,
            total: dados.quantidadeRegistros,
        }))
        setFaturas([...dados.faturas]);
        if (dados.faturas.length > 0)
            setExibirBaixarArquivos(true);

        if(Object.keys(filtro).length > 0){
            setCachedPages({
                [pagina]: [...dados.faturas],
            });
        }else{
            setCachedPages((prevCachedPages) => ({
                ...prevCachedPages,
                [pagina]: [...dados.faturas],
            }));
        }
        setLoading(false);
        return dados
    }
    const BuscarRazoesStatus = async () => {
        let response = await FaturasService.ListarRazoesStatus();
        setRazoesStatus(response)
    }
    const onFilter = async (values) => {
        if(Object.values(values).some(x=>x != undefined)){
            await BuscarFaturas(1,paginationDataConfig.tamanhoPagina, values)
            formFilterReference.setFieldValue(values)
        }
        else{
            BuscarFaturas()
        }
    }
    const onFilterFailed = (error) => {
    }
    const onClear = (values) => {
        if(Object.values(values).some(x=>x != undefined)){
            BuscarFaturas()
        }
    }
    const BaixarVariosArquivos = async () => {
        setLoadingBaixarVariosArquivos(true);
        const contact = JSON.parse(sessionStorage.getItem('contact'));
        const signature = JSON.parse(sessionStorage.getItem('signature'));
        const filtros = formFilterReference.getFieldsValue();

		let values = {
			AtributoEstendido1:filtros.linhaServicoId, 
			AtributoEstendido2:filtros.razaoStatus?.toString(),
            DataCustomizada1: filtros.dataInicio,
            DataCustomizada2: filtros.dataTermino,
			solicitanteId: contact?.idContact,
			clienteId: signature.accountId,
			direitoId: signature.entitlementId
		}
		await OcorrenciasService.BaixarVariosArquivosFaturamento(values).then(response=>{
			setLoadingBaixarVariosArquivos(false);
            Modal.success({
				title: 'Sucesso!',
				content: 
                <div>
                    <p>A sua solicitação {response.numeroOcorrencia} foi registrada com sucesso!</p>
                    <p>Os arquivos serão consolidados e você receberá um link para download dos itens em seu e-mail principal.
                       O processamento pode levar até 30 minutos e o arquivo ficará disponível por 3 dias.</p>
                </div>,
				centered: true
            });
		})
		.catch(error =>{
            setLoadingBaixarVariosArquivos(false);
			Modal.error({
				title: 'Ocorreu um erro!',
				content: 'Não foi possível criar a solicitação. Por favor tente novamente ou entre em contato com o suporte.',
				centered: true
			});
		})
    }
    return(
        <MasterPage>
            <h1>Financeiro</h1>
            <Space direction='vertical'>
                <Flex align='stretch' vertical wrap='nowrap' justify='stretch' ref={refFiltroFaturas}>
                    <Formulario
                        onFinish={onFilter}
                        onFinishFailed={onFilterFailed}
                        inputsData={filterInputs}
                        formReference={formFilterReference}
                        inputColSpan={6}
                        hideSubmitButton={false}
                        submitButtonMessage={"Filtrar"}
                        showClearButton={true}
                        onClear={onClear}
                        onValuesChange={() => setExibirBaixarArquivos(false)}
                    />
                </Flex>
                <StyledButton onClick={BaixarVariosArquivos} disabled={!exibirBaixarVariosArquivos} loading={loadingBaixarVariosArquivos} ref={refBotaoBaixarVariosArquivos} >
                    <Tooltip title={"Fazer o download de todos os arquivos das faturas retornadas na busca"}>
                        Baixar todos os arquivos do filtro
                    </Tooltip>
                </StyledButton>
                <Alert 
                    message="Devido ao tempo de compensação bancária, o status da fatura será atualizado em até 2 dias úteis."
                    type='info' 
                    showIcon/>
                <Flex vertical={true}>
                    <LoadingOrErrorPanel loading={loading} hasError={error}>
                        <Row gutter={[16, 16]}>
                            {
                                faturas.length > 0 && faturas.map(element =>{
                                    return(
                                        <Col key={element.faturaId}>
                                            <FinanceiroCard fatura={element} razoesStatus={razoesStatus}/>
                                        </Col>
                                        )
                                    }
                                )
                            }
                            {
                                faturas.length == 0 && !loading && !error &&(
                                    <NotFoundDiv justify='center' align='center'>
                                        <span>Nenhuma fatura encontrada.</span>
                                    </NotFoundDiv>
                                )
                            }
                        </Row>
                    </LoadingOrErrorPanel>
                    <Flex justify='center' style={{paddingTop: '10px'}}>
                        <Pagination 
                            defaultCurrent={1} 
                            total={paginationDataConfig.total} 
                            pageSize={paginationDataConfig.tamanhoPagina}
                            onChange={HandleOnPaginationChange}
                            disabled={loading}
                            showSizeChanger={paginationDataConfig.total > 10}
                            current={paginationDataConfig.paginaAtual}
                            />
                    </Flex>
                </Flex>
            </Space>
        </MasterPage>
    )
}

export default ListagemFinanceiro;