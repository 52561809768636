import React from 'react'
import { paginaInicial } from 'EstruturaAntiga/assets/urlsBase';
import MasterError from "../../masterpages/errorPage"

const PaginaErroNaoTratado = () => {
    const _title = 'Falha ao buscar as informações';
    const _text = 'Por favor, tente novamente ou contate nossa Central de Atendimento: atendimento@bhs.com.br / 4000-1744.';

    return (
        <MasterError
            title={_title}
            text={_text}
        >
            <button className="btn-error" onClick={() => window.location.replace(`${paginaInicial.urlBase}`)}>Voltar ao início</button>
        </MasterError>
    )
}

export default PaginaErroNaoTratado;