import styled from 'styled-components';
import {Card} from "antd";
export const StyledCard = styled(Card)`
  width: 100%;
  padding-bottom: 2em;
  background-color: aliceblue;
  cursor: pointer;
  height: 150px;
  img{
    height: 50px;
  };
  svg{
    font-size: 50px
  }
  
`;