import React, { useState, useEffect } from 'react';
import { isTenantNCE } from 'EstruturaAntiga/router/routerHelpers';
import ContentBox from '../../../../../components/ContentBox';

import Policy from '../policy';

import { Container, LabelConfirm } from './styles';

export default function Confirmation({ state, props, titlePage = '', policies, checkedPolicy, setCheckedPolicy }) {

    const renderFieldConfirmation = (labelName, obj = '', widthPercentage=0) => {
        if (labelName) {
            if(labelName == 'Plano de E-mail' && obj){
                const productId = obj.value;
                const unitId = obj.UnidadePrincipalId;
                obj = props.listaDePacotesCSP.filter(el => (el.value.toString() === productId.toString() && el.unitId.toString() === unitId.toString()))[0].label;
            }
            return (
                <LabelConfirm widthPercentage={widthPercentage}>
                    <div className='field-confirm-default'>
                        <span className='label-title'>{labelName}:</span><span className='label-desc'>{obj}</span>
                    </div>
                </LabelConfirm>
            )
        }
        return null;
    }

    const renderFieldList = (labelName, arrayDesc = []) => {
        if (labelName) {
            return (
                <LabelConfirm widthPercentage={0}>
                    <div className='field-confirm-array'>
                        <span className='label-title'>{labelName}:</span>
                        {renderArrayDescConfirm(labelName, arrayDesc)}
                    </div>
                </LabelConfirm>
            )
        }
        return null;
    }

    const renderArrayDescConfirm = (labelName, arrayDesc = []) => {
        if(!arrayDesc.length){
            return null;
        }

        return (
            arrayDesc.map((item) => {{
                switch(labelName){
                    case 'Licenças opcionais':
                        const optionalLicense = props.listaProdutoComplemento.filter(el => el.value === item.value)[0].label;
                        return <span key={item} className='label-desc'>{optionalLicense}</span>
                    case 'Perfis de acesso':
                        return <span key={`${item.DireitoId} - ${item.PerfilDeAcessoCodigo}`} className='label-desc'>{`${item?.Rotulo} - ${item?.PerfilDeAcesso}`}</span>
                    default:
                        return null;
                }
            }})
        )
    }

    const renderValorTotalDoPlano = () => {
		const totalAmount = Number(state.addonAmount) + Number(state.priceLevelAmount)
		return (
			<div className="col-12 col-sm-12 col-lg-12" style={{
				justifyContent: 'flex-end',
				display: 'flex',
				padding: '0 10px 10px 10px',
                fontSize: '12px'
			}}>
				{(state.priceLevelAmount > 0) &&
					<span>
						Valor total do plano em {state.priceLevelCurrencyName.toLowerCase()}: {state.priceLevelCurrencySymbol} {totalAmount.toFixed(2)}
					</span>
				}
			</div>
		)
	}

    if(state && state.formContent){
        return (
            <Container>
                    {titlePage && (
                        <div>
                            <h1>Alterações realizadas</h1>
                        </div>
                    )}
                    <div>
                        <h2>Dados do usuário</h2>
                        <ContentBox className="form-container">
                            <div className="user-data">
                                {renderFieldConfirmation(state.formContent.firstname.nome, state.form.firstname, 20)}
                                {renderFieldConfirmation(state.formContent.lastname.nome, state.form.lastname, 30)}
                                {renderFieldConfirmation(state.formContent.axt_displayname365.nome, state.form.axt_displayname365, 40)}
                                {renderFieldConfirmation(state.formContent.mobilephone.nome, state.form.mobilephone, 20)}
                                {renderFieldConfirmation(state.formContent.emailaddress2.nome, state.form.emailaddress2, 50)}
                                {renderFieldConfirmation(state.formContent.telephone1.nome, state.form.telephone1, 20)}
                                {renderFieldConfirmation(state.formContent.department.nome, state.form.department, 20)}
                                {renderFieldConfirmation(state.formContent.jobtitle.nome, state.form.jobtitle, 20)}
                            </div>
                        </ContentBox>
                    </div>
                    <div>
                        <h2>Dados de acesso</h2>
                        <ContentBox className="form-container">
                            <div className="access-data">
                                <div className='access-data-email'>
                                    {renderFieldConfirmation(state.formContent.emailaddress1.nome, state.form.emailaddress1 ? state.form.emailaddress1+state.form.SufixMail : '', 20)}
                                    {renderFieldConfirmation(state.formContent._axt_servicebundle_value.nome, state.form._axt_servicebundle_value, 30)}
                                </div>
                                {renderFieldList('Licenças opcionais', state.form.listaProdutoComplemento)}
                                {renderFieldList('Perfis de acesso', policies ? state.profiles : state.form.listaPerfisAtribuidos)}
                                <div className="row">
                                    {renderValorTotalDoPlano()}
                                </div>
                            </div>
                        </ContentBox>
                    </div>
                    {policies && isTenantNCE() && (
                        <div>
                            <ContentBox className="form-container">
                                <Policy 
                                    policies={policies} 
                                    checkedPolicy={checkedPolicy}
                                    setCheckedPolicy={setCheckedPolicy}
                                />
                            </ContentBox>
                        </div>
                    )}
            </Container>
        )
    }
    return null;
}