import environment from '../EstruturaAntiga/assets/biEnvironment';
import { CONSTANTES_AMBIENTE_DEV } from './constantesAmbienteDev';
import { CONSTANTES_AMBIENTE_PRD } from './constantesAmbientePrd';

const CONSTANTES = () => {
	switch(environment.environment) {
		case 'development':
			return CONSTANTES_AMBIENTE_DEV;
		case 'production':
			return CONSTANTES_AMBIENTE_PRD;
		default:
			return CONSTANTES_AMBIENTE_PRD;
	}
}

const CONSTANTES_AMBIENTE = CONSTANTES();

export default CONSTANTES_AMBIENTE;
