import React from 'react';
import styled from 'styled-components';

export const MessageWrapper = styled.div`
  width: 100%;

  h1 {
    margin: 0;
    padding: 0;

    border: none;

    color: #343C42;
    font-size: 46px;
    font-weight: lighter;
    line-height: 48px;

    strong {
      display: block;
    }
  }

  div {
    padding-left: 32px;
    margin-top: 32px;

    color: #ADB3B7;
  }
`;
