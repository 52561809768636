import styled from 'styled-components';

export const PerfisDeAcesso = styled.div`
  
  ${props => { return props.erroPerfisDeAcesso ? 
    `
    color: #E94A44 !important;

    h2{
      color: #E94A44 !important;
    }

    span{
      color: #E94A44 !important;
      font-weight: bold;
    }

    .content-box{
      background: $white;
      box-shadow: 2px 2px 5px 0px #E94A44 !important;
      padding: 15px;
      margin-bottom: 30px;
      border: solid red 0.1em;

      &.bi-content{
        height: 722px;
        padding: 0;
        overflow: auto hidden;
        -webkit-overflow-scrolling: touch;

        > div {
          height: 722px;
          padding: 10px;
          box-sizing: content-box;
        }
      }
    }
    ` : 
    ''; 
  }}

`;
