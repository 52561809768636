import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"

export default async function postTermoAceite(termoAceite){
    let url = `${urlBase.urlBase}/portaldb/TermoAceite`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'POST', headers: _header, body: JSON.stringify(termoAceite)};

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        })
}
