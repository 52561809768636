import {StyledCard} from "./styles";
import { withRouter } from 'react-router-dom';
import { Tooltip } from "antd";
import { BorderOutlined } from "@ant-design/icons"

function ServicoCard({ history, ...props}){
    const onClick = ()=>{
        history.push(`/servicos/cadastro?templateid=${props.modeloOcorrenciaId}`)
    }
    return(
            <Tooltip title={props.descricaoServico? props.descricaoServico : ""}>
                <StyledCard bordered={false} onClick={onClick}>
                    <div>
                        {props.imagemServico != null ? 
                            <img src={`data:image/jpeg;base64,${props.imagemServico}`} />
                            :
                            <BorderOutlined />
                        }
                        <p>{props.nome}</p>
                    </div>
                </StyledCard>
            </Tooltip>
    ) 
}

export default withRouter(ServicoCard)