import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MasterPage from "../../masterpages/errorPage"

export default class Error503 extends Component {

    render() {
        return (
            <MasterPage classContainer="error-503" error="503" title="Serviço indisponível" text="A página está temporariamente indisponível">
                <Link to='/' className="btn-error"><i className="icon-arrow-left"></i>Voltar ao início</Link>
            </MasterPage>
        );
    };
}