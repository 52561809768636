import PortalClienteService from 'services/bases/PortalClienteService';
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';

class EmailsService extends PortalClienteService {
    async ValidarEmailPrincipal(email) {
        const userSignature = HttpRequest.userSignature();
        return await this.get(`Emails/ChecarEmailPrincipal?tenantId=${userSignature.axtTenantId}&direitoId=${userSignature.entitlementId}&email=${email}`)
    }
    async ValidarEmailAlternativo(email) {
        const userSignature = HttpRequest.userSignature();
        return await this.get(`Emails/ChecarEmailAlternativo?tenantCode=${userSignature.tenantCode}&email=${email}`)
    }
}

export default new EmailsService();