import PortalClienteService from "services/bases/PortalClienteService";

class FaturasService extends PortalClienteService{
    async ListarPorCliente(pagina = 1, tamanhoPagina = 10, filtro = {}){
        const {linhaServicoId = "", dataInicio = "", dataTermino = "", razaoStatus = ""} = filtro;
        return await this.get(`Faturas?pagina=${pagina}&tamanhoPagina=${tamanhoPagina??''}&linhaServicoId=${linhaServicoId??''}&dataInicio=${dataInicio??''}&dataTermino=${dataTermino??''}&razaoStatus=${razaoStatus??''}`)
    }
    async ListarParcelasPorFatura(faturaId){
        return await this.get(`Faturas/ListarParcelamentos/${faturaId}`)
    }
    async ListarRazoesStatus(){
        return await this.get(`Faturas/ListarRazoesStatus`)
    }
    async BuscarLink(faturaId, tipo, parcela = ""){
        return await this.get(`Faturas/GerarLinkDownloadArquivoFaturamento?faturaId=${faturaId}&tipo=${tipo}&parcela=${parcela}`)
    }
}

export default new FaturasService();