import styled from 'styled-components';
import { Modal } from 'antd';

export const SyledModal = styled(Modal)`
    @media (max-width: 576px) {
        .ant-modal-footer {
            display: flex;
            justify-content: center;
            button {
                font-size: 12px;
            }
        }
    }
`;