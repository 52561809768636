import React, { Component } from 'react'
import { userSignature } from "EstruturaAntiga/assets/httpRequest"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authContext } from '../../adalConfig';
import { logOut } from "../../actions/logOut"
import getPhoto from '../../actions/usuarios/getPhoto';

class UserNavigationMobile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			photo: '',
			userSignature: {
				entitlementId: '',
				financialContractId: '',
				serviceLineId: '',
				accountId: '',
				tenantCode: '',
				entitlementName: '',
				tenantName: '',
				accountName: '',
				serviceLineName: ''
			}
		}
	}

	componentWillMount() {
		this.setState({ photo: getPhoto(), userSignature: userSignature() });
	}

	render() {
		return (
			<div className="user-nav-mobile">
				<img src={this.state.photo} className="user-photo" alt={authContext._user.profile.given_name + ' ' + authContext._user.profile.family_name} />

				<div className="user-nav-container">
					<span className="user-name">{authContext._user.profile.given_name} {authContext._user.profile.family_name}</span>
					<b> { this.state.userSignature.tenantName || this.state.userSignature.entitlementName }</b>
					<span className="user-logout" onClick={() => logOut()}><i className="icon icon-logout"></i>Logout</span>
				</div>
			</div>
		);
	};
}

export default withRouter(connect(
	state => ({

	 }),
  (dispatch) => (bindActionCreators({

 }, dispatch))
 )(UserNavigationMobile));
