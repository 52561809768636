import React, { Component } from 'react';

import ListItens from './ListItens';
import AddAddon from './AddAddon';

export default class components extends Component {
  constructor(props) {
    super(props)

    this.state = {
      blockButton: true,
      allAddons: [],
      addons: [],
      renderComprar: false,
    }
  }

  componentDidMount() {
    const { rateio, license, initialAddons } = this.props;
    
    this.setState({
      licenseOld: license,
      allAddons: initialAddons,
      addons: initialAddons,
      rateio,
      loading: false,
    })
  }

  componentDidUpdate(prevProps) {
    const { allAddons, addons, licenseOld } = this.state;
    const { initialAddons } = this.props;

    if (this.props.initialAddons !== prevProps.initialAddons) {
      this.setState({
        allAddons: initialAddons,
        addons: initialAddons,
      });
    }
  }

  onChangeQuantity = (value, userLicense, readOnly) => {
    if (readOnly) {
      return;
    }

    const { allAddons } = this.state;
    const { calcularTotal } = this.props;

    const newValues = allAddons.map(old => {
      if (old._axt_productid_value === userLicense._axt_productid_value) {
        old.axt_quantity = value;
        old.edited = true;
      }

      return old;
    });

    const total = allAddons.length > 0 ? allAddons.reduce((acumulado, license) => {
      const SUM = (license.axt_unitpricesale !== undefined) ? (license.axt_unitpricesale * license.axt_quantity) : 0;

      return acumulado + SUM;
    }, 0) : 0;

    this.setState({
      allAddons: newValues,
      totalMensal: total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    });

    calcularTotal();
  }

  onChangeApportionment = (value, userLicense, readOnly, moreValues) => {
    const { allAddons } = this.state;

    const newValue = allAddons.map(old => {
      if (
      (!!old.axt_subscriptioncspid && 
      !!userLicense._axt_subscriptioncspid && 
      old._axt_subscriptioncspid === userLicense._axt_subscriptioncspid) 
      || 
      (!!old._axt_productid_value &&
      !!userLicense._axt_productid_value &&
      old._axt_productid_value === userLicense._axt_productid_value)) {
        const newApportionments = old.subscriptionCspQuotes.map(companie => {
          if (companie._axt_financialcontractid_value === moreValues._axt_financialcontractid_value) {
            companie.axt_amount = value;
          }

          return companie;
        });

        old.subscriptionCspQuotes = newApportionments;
      }

      return old;
    });

    this.setState({
      addons: newValue,
      allAddons: newValue,
    });
  }

  openCloseBuyLicenses = () => {
    this.setState({
      renderComprar: !this.state.renderComprar,
    });
  }

  addNewAddons = (newAddons) => {
    const { addons } = this.state;
    const { license, addAddons } = this.props;

    addAddons(license, [...addons, ...newAddons]);

    this.setState({
      newAddons,
      allAddons: [...addons, ...newAddons], 
      renderComprar: false,
    });
  }
  
  disblockButton = () => {
    this.setState({
      blockButton: false,
    });
  }

  render() {
    const { allAddons, loading, renderComprar, blockButton } = this.state;
    const { rateio, license, showAddons, contractMasterId, handleCurrencyFormat } = this.props;

    if (loading && showAddons) {
      return (
        <div className="loading-profiles">
          <div className="simple-loading"></div>
        </div>
      )
    }

    if (!loading && showAddons && allAddons.length > 0) {
      return (
        <div className="perfil-list addons">
          <ListItens 
            licenses={allAddons}
            onChangeQuantity={this.onChangeQuantity} 
            onChangeApportionment={this.onChangeApportionment}
            rateio={rateio}
            isAddons={true}
            handleCurrencyFormat={handleCurrencyFormat}
          />
        
          <button onClick={() => this.openCloseBuyLicenses()} disabled={blockButton} className="btn btn-default btn-rateio">Adicionar Add-on</button>
  
          <AddAddon 
            renderComprar={renderComprar} 
            openCloseBuyLicenses={this.openCloseBuyLicenses} 
            disableManagement={this.disableManagement}
            addNewAddons={this.addNewAddons}
            license={license}
            rateio={rateio}
            disblockButton={this.disblockButton}
            contractMasterId={contractMasterId}
          />
        </div>
      )
    } else if (!loading && showAddons) {
      return (
        <div className="empty-profiles">
          <span className="label">Nenhum add-on associado a esta licença</span>
          
          <button onClick={() => this.openCloseBuyLicenses()} disabled={blockButton} className="btn btn-default btn-rateio">Adicionar Add-on</button>

          <AddAddon 
            rateio={rateio} 
            renderComprar={renderComprar} 
            openCloseBuyLicenses={this.openCloseBuyLicenses} 
            disableManagement={this.disableManagement}
            addNewAddons={this.addNewAddons}
            license={license} 
            disblockButton={this.disblockButton}
            contractMasterId={contractMasterId}
          />
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }
}
