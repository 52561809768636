import { combineReducers, createStore, applyMiddleware } from 'redux';
import reduxPromise from 'redux-promise';
import reduxThunk from 'redux-thunk';
import global from '../global/globalReducer';
import dashboard from '../pages/dashboard/redux/dashboardReducer';
import bi from '../components/bi/dashboardReducer';
import termos from '../pages/termosDeAceite/termoAceiteReducer';
import loading from '../components/loading/loadingReducer';
import admtermos from '../pages/termos/termoDeAceiteAdmReducer';
import router from '../router/redux/routerReducer';
import chamados from "../pages/chamados/redux/chamadosReducer";
import users from "../pages/usuariosV2/redux/usuariosReducer";
import alterarDados from "../pages/gestaoUsuarios/alterarDados/redux/editarMeusDadosReducer";
import listaDistribuicao from "../pages/microsoft365/listaDeDistribuicao/redux/listaDistribuicaoReducer";
import cotacao from "../pages/cotacao/redux/cotacaoReducer";
import { composeWithDevTools } from 'redux-devtools-extension';

var reducers = combineReducers({
    global,
    bi,
    dashboard,
    termos,
    loading,
    admtermos,
    router,
    chamados,
    users,
    alterarDados,
    listaDistribuicao,
    cotacao
})

export default createStore(reducers, composeWithDevTools(
    applyMiddleware(reduxThunk, reduxPromise)
));
