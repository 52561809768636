import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .content-box.form-container {
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  .user-data{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .access-data{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;

    .access-data-email{
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  h1 {
    font-size: 28px;
    margin: 0;
    font-weight: 300;
    color: #343c42;
    padding: 0;
    border-bottom: none;
    letter-spacing: 0px;
  }

  span {
    &.label-title{
      color: #ADB3B7;
      padding-right: 5px;
    }

    &.label-desc{
      font-weight: bold;
      color: #637381;
    }
  }
`;

export const LabelConfirm = styled.div`
  padding-right: 60px;
  letter-spacing: 0.14px;
  min-width: ${props => { return `${props.widthPercentage}%`; }};

  .field-confirm-default{
    line-height: 2.5em;
  }

  .field-confirm-array{
    display: flex;
    flex-direction: column;
    padding-top: 13px;
  }
  
`;
