import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export default async function checkUpnAvailability(upn){
  const userSignature = HttpRequest.userSignature();
  const _header = await HttpRequest.apiTokenHeader();

  const url = `${urlBase.urlBase}/graph/UserPrincipalName/VerificaExclusividade?tenantid=${userSignature.tenantCode}&userprincipalname=${upn}`;

  const params = { method: 'GET', headers: _header };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => response)
    .catch(error => {
      return {
        resultcode: '-1',
        message: 'Serviço indisponível.'
      }
    });
}
