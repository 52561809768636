import React, { Component } from 'react'

export default class TextAreaGroup extends Component {

    classError = () => {
        if (this.props.configureInput.showerror === "true" && this.props.configureInput.error) {
            return 'form-error';
        }

        return '';
    }

    error = () => {
        if (this.props.configureInput.showerror === "true" && this.props.configureInput.error) {
            return <span className="error">{this.props.configureInput.error}</span>
        }

        return null;
    }

    render() {
        return (
            <div className={`form-group ${this.classError()}`}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <div className="input-group-container">
                    <textarea 
                        readOnly={ this.props.readOnly } 
                        className="input-group"
                        {...this.props.configureInput.props} />
                    <div className={`simple-loading ${(!this.props.loading) ? 'd-none' : ''}`}></div> 
                </div>
                {this.error()}
            </div>
        );
    };
}