import getUsuarioAutenticadoAAD from "EstruturaAntiga/actions/usuarios/getUsuarioAutenticadoAAD";
import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import { mapUserFieldsV2toV1 } from "EstruturaAntiga/assets/userHelpers";

export const rdxGetUserEditarMeusDados = () => {
    const user = HttpRequest.adalUser().profile;
    return dispatch => (getUsuarioAutenticadoAAD(user.upn, user.tid).then(obj => {
        dispatch({ type: 'ALTERAR_DADOS_GET_USER_INFO', payload: mapUserFieldsV2toV1(obj) })
    }))
}

export const rdxClearDados = editable => ({
    type: 'ALTERAR_DADOS_CLEAR',
    payload: editable
})