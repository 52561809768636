import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MasterPage from '../../masterpages/authenticated';
import ContentBox from '../../../components/ContentBox';
import ListBaseEmptyAndLoading from 'components/ListBaseEmptyAndLoading';
import UserLabel from './componentes/UserLabel';

import {
	SentinelStyle,
  FilterBoxStyle
  } from './styles';
import GetDireitoPorId from 'EstruturaAntiga/actions/direitos/getDireitoPorId';
import ErroGestaoUsuario from './ErroGestaoUsuario';
import UsuariosService from 'services/UsuariosService';

let timeout;
const statusCodeErrorUnauthorized = 401, statusCodeErrorForbidden = 403;

const ListUserV2 = () => {
  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageSize] = useState(10);
  const [skipTokenControl, setSkipTokenControl] = useState('');
  const [users, setUsers] = useState([]);
  const [autorizado, setAutorizado] = useState(true);
  const [loadingAutorizacao, setLoadingAutorizacao] = useState(true);
  const [loadingInicial, setLoadingInicial] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorStatusCode, setErrorStatusCode] = useState();

  useEffect(() => {
    permitirGestaoUsuario();
    

    return () => getUnmount();
  }, []);

  useEffect(() => {
    if(!loadingInicial && !loadingAutorizacao){
      if(skipTokenControl){
        const intersectionObserver = new IntersectionObserver((entries) => {
          if(entries.some((entry) => entry.isIntersecting)){
            getMoreUsers(objParams());
          }
        });
        intersectionObserver.observe(document.querySelector('#sentinel'));
        return () => intersectionObserver.disconnect();
      }
    }
  }, [skipTokenControl, loadingInicial, loadingAutorizacao]);

  
  const permitirGestaoUsuario = async () => {
    await GetDireitoPorId()
      .then(response => {
        if(response?.permitirGestaoUsuario === false){
          setAutorizado(false);
        } else {
          setLoadingInicial(true);
          getMoreUsers(objParams());
        }
          
      })      
      .finally(() => {
        setLoadingAutorizacao(false);
      })
  }

  const getMoreUsers = async (objParams) => {
    setSkipTokenControl('');
    setLoading(true);
    
    await UsuariosService.ListarUsuarios(objParams)
      .then(response => {
        setSkipTokenControl(response.skipToken ?? '');
        setUsers(users => [...users, ...response.users]);
      })
      .catch((error) => {
        setErrorStatusCode(error.response?.data?.erros[0]);
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
        setLoadingInicial(false);
      })
  }

  function onChangeFilter(filter) {
    setSkipTokenControl('');
    setUsers([]);
    setFilterText(filter);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      getMoreUsers(objParams(filter, ''));
    }, 1300);
  }

  const getUnmount = async () => {
    setSkipTokenControl('');
    setFilterText('');
    timeout = null;
    setLoading(true);
    setUsers([]);
  }

  const getResetUsers = async () => {
    setUsers([]);
    setSkipTokenControl('');
    setFilterText('');
    timeout = null;
    const initialFilter = '', initialToken = '';
    getMoreUsers(objParams(initialFilter, initialToken));
  }

  const objParams = (filter = filterText, token = skipTokenControl, page = pageSize) => {
    return { 
      filtro: filter,
      skipToken: token,
      tamanhoPagina: page
    }
  }

  if(loadingAutorizacao || loadingInicial){
    return (
      <MasterPage>
        <div className="small-loading">
          <div className="simple-loading"></div>
        </div>
      </MasterPage>
    );
  }

  if(!autorizado){
    return <ErroGestaoUsuario/>;
  }

  if(hasError){
    if(errorStatusCode === statusCodeErrorUnauthorized || errorStatusCode === statusCodeErrorForbidden){
      return (
        <MasterPage>
          <h1>Usuários</h1>
          <h3>O Portal do Cliente não possui o consentimento de seu administrador para listar os usuários.</h3>
          <h3>Favor entrar em contato com o nosso atendimento.</h3>
        </MasterPage>
      );
    }

    return (
      <MasterPage>
        <h1>Usuários</h1>
        <h3>Ocorreu um erro ao tentar carregar a lista de usuários.</h3>
      </MasterPage>
    );
  } 

  return (
      <MasterPage>
        <div className="title-with-action">
          <h1>Usuários</h1>
          <Link to={'/usuarios/novo'} className="btn btn-default">Criar usuário</Link>
        </div>
        <ContentBox className="list-container">
          <div className="list-header">
            <FilterBoxStyle className="filter-box-style">
                <div className="input-group-container">
                  <input
                    type="text"
                    className="filter"
                    placeholder="Filtrar por nome ou e-mail"
                    value={filterText}
                    onChange={e => onChangeFilter(e.target.value)}
                    disabled={loading}
                  />
                  <div className={`simple-loading ${!loading ? 'd-none' : ''}`}></div>
                </div>
            </FilterBoxStyle>
          </div>
          <div className="list-items user-list">
            {
              users.map((user) =>
                <UserLabel
                  contact={user}
                  displayName={user.displayName}
                  mail={user.mail}
                  userPrincipalName={user.userPrincipalName}
                  accountEnabled={user.accountEnabled}
                  isSync={user.axt_onpremisessyncenabled}
                  key={user.userPrincipalName}
                  resetUsers={getResetUsers}
                  usuarioId={user.id}
                />
              )
            }

            <SentinelStyle>
              <li id='sentinel'></li>
            </SentinelStyle>
            
            <ListBaseEmptyAndLoading
              emptyText="Nenhum usuário encontrado"
              isEmpty={users.length === 0}
              isLoading={loading}
            />
          </div>
        </ContentBox>
      </MasterPage>
  );
}

export default ListUserV2;


