import React, { Component } from 'react';

import CustomQuantity from './form/CustomQuantity';

export default class BuyLicenseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderRateio: false,
    }
  }

  renderRateioField = e => {
    this.setState({
      renderRateio: !this.state.renderRateio,
    });
  }

  render() {
    const { renderRateio } = this.state;
    const { userLicense, onChangeQuantity, onChangeApportionment, readOnly, rateio } = this.props;

    return (
      <div>
        <div className="licenca">
          <div className="licenca-name">
            <span className="title">{userLicense.axt_name}</span>
          </div>

          <div className="quantidade">
            <span><strong>{userLicense.axt_unitpricesale.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></span>
            
            {/* <CustomQuantity 
              customClass="filter" 
              onChangeFunction={onChangeQuantity} 
              userLicense={userLicense}
              defaultValue={userLicense.axt_quantity} 
              readOnly={readOnly}
            /> */}
          </div>
        </div>

        {/* {
          rateio && (
            <div className="empresa-rateio">
              <span className="see-rateio" onClick={() => this.renderRateioField()}>
                Rateio
                <i className={`icon icon-arrow-${renderRateio ? 'top' : 'dow'}`}></i>
              </span>

              {
                renderRateio && userLicense.subscriptionCspQuotes.map(companie => (
                  <div className="empresa-rateio-wrapper" key={companie.axt_financialcontractid}>
                    <div className="empresa-rateio-name">
                      <span>{companie.axt_companieName}</span><br />
                      <span>{companie.axt_cnpjview}</span>
                    </div>

                    <div className="rateio">
                      <span>Quantidade</span>
                      
                      <CustomQuantity 
                        customClass="filter" 
                        onChangeFunction={onChangeApportionment} 
                        userLicense={userLicense}
                        moreValues={{ _axt_financialcontractid_value: companie.axt_financialcontractid }}
                        defaultValue={companie.axt_amount}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          )
        } */}
      </div>
    )
  }
}
