import Form from "EstruturaAntiga/assets/formValidate";
import { withRouter } from "react-router-dom";

class AlterarSenha extends Form {
    render() {
        window.open('https://account.activedirectory.windowsazure.com/ChangePassword.aspx', '_blank');
        window.location = '/';
    };
}

export default withRouter(AlterarSenha);