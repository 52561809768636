import urlBase from '../../assets/urlsBase';

export async function validateEmailCode(cotacaoId, codigo) {
  const url = `${urlBase.urlBase}/logicApps/Start/ValidarCodigoEmail`;

  const data = {
    cotacaoId,
    codigo
  }

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

  const params = { method: 'POST', headers, body: JSON.stringify(data) };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => {
      return response
    })
    .catch(error => {
      return {
        resultCode: -1,
        message: 'A validação do e-mail excedeu o tempo limite, tente novamente.'
      }
    });
}
