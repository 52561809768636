import React from 'react';

import { HeaderWrapper } from './style';

import Logo from '../../../../../assets/images/logo.png';

export function Header() {
  return (
    <HeaderWrapper>
      <img src={Logo} alt="BHS" />

      <h2>Provisionamento de licenças</h2>
    </HeaderWrapper>
  )
}
