const _environment = () => {
  if(window.RUNTIME_API_HOST.toString().toLowerCase() === 'development'){
      return {
          environment: 'development',
      };
  }
  else if(window.RUNTIME_API_HOST.toString().toLowerCase() === 'production'){
      return {
          environment: 'production',
      };
  }
};

const environment = _environment();

export default environment;
