import React from 'react';

import { MessageWrapper } from './style';

export function LeftMessage({ name }) {
  return (
    <MessageWrapper>
      <h1>Olá, <strong>{name}!</strong></h1>

      <div>
        <p>Precisamos da sua confirmação para disponibilizarmos a assinatura Microsoft!</p>
        <p>Se estiver de acordo com os dados ao lado, clique em aprovar e em até 4 horas a assinatura estará disponível em seu ambiente.</p>
        <p>Se os dados não conferem com sua solicitação, clique em reprovar e informe o motivo, por favor. Nosso time logo providenciará os ajustes necessários!</p>
      </div>
    </MessageWrapper>
  )
}
