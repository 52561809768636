import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";
import { useState } from "react";
import { NotificationManager } from 'react-notifications';
import { StyledButton } from "../styles";
import { Tooltip } from "antd";

function BotaoDownload({apiCallback, message, icon, tooltip, customStyle = {}}){
    const [loading, setLoading] = useState(false)

    const BuscarLink = async () =>{
        setLoading(true)
        var response = await apiCallback().catch(error =>{
            NotificationManager.error(error.response?.data?.erros[0], 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
            setLoading(false)
            return;
        })
        setLoading(false)
        if(response){
            window.open(response, '_blank');
        }
    }
    return (
        <StyledButton onClick={BuscarLink} loading={loading} style={customStyle}>
            <Tooltip title={tooltip}>
                { icon ?? null}
                { message ?? null} 
            </Tooltip>
        </StyledButton>
    )
}

export default BotaoDownload;