const _urlBase = () => {
  if(window.RUNTIME_API_HOST.toString().toLowerCase() === 'development'){
    return {
      urlBase: 'https://dev-portal.bhs.com.br/api'
    };
  }
  else if(window.RUNTIME_API_HOST.toString().toLowerCase() === 'production'){
    return {
      urlBase: 'https://portal.bhs.com.br/api',
    };
  } else {
    return {
      urlBase: 'https://dev-portal.bhs.com.br/api'
    };
  }
};

const _paginaInicial = () => {
  switch(window.RUNTIME_API_HOST.toString().toLowerCase()){
    case 'production':
      return {
        urlBase: 'https://portal.bhs.com.br/',
      };
    case 'development':
    default:
      return {
        urlBase: 'https://dev-portal.bhs.com.br/'
      };
  }
};

const urlBase = _urlBase();

export const paginaInicial = _paginaInicial();

export default urlBase;
