import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"

export default async function postLead(data) {
    let url = `${urlBase.urlBase}/dynamics/Lead`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'POST', headers: _header, body: JSON.stringify(data) };

    return fetch(url, params)
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        })
}
