import { createContext, useContext, useEffect, useState } from "react";


const MenuContext = createContext()

export const useMenu = () =>{
    return useContext(MenuContext);
}

export const MenuProvider = ({children}) =>{
    const [menuVisivel, setMenuVisivel] = useState(false);
    
    const handleResize = () => {
        if (window.innerWidth >= 768) {
          setMenuVisivel(true);
        } else {
          setMenuVisivel(false);
        }
      };
    
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <MenuContext.Provider value={{menuVisivel, setMenuVisivel}}>
            {children}
        </MenuContext.Provider>
    )
}