import React from 'react';

import { Container } from './styles';

export default function NavigationButtons({
    labelBackButton,
    onClickBackButton,
    hideBackButton,
    labelNextButton,
    onClickNextButton,
    disabledNextButton,
    onClickChangePasswordButton,
    hideChangePasswordButton = true,
    disabledChangePasswordButton = true
}) {
    
    return (
        <Container hideBackButton={hideBackButton} hideChangePasswordButton={hideChangePasswordButton} >
            {!hideChangePasswordButton &&
                <button
                    onClick={() => onClickChangePasswordButton()}
                    type="button"
                    className="btn"
                    disabled={disabledChangePasswordButton}
                >
                    Solicitar alteração de senha
                </button>
            }

        	{!hideBackButton &&
                <button 
                    type="button"
                    className="btn" 
                    onClick={() => onClickBackButton()}
                >
                    {labelBackButton}
                </button>
            }
        	
        	<button
                type="submit"
        		id="nextButton"
        		onClick={() => onClickNextButton()}
                disabled={disabledNextButton}
        		className="btn btn-default"
        	>
        		{labelNextButton}
        	</button>
        </Container>
    )
}