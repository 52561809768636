import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export async function GetNewPassword() {
	let _headerGetPassword = await HttpRequest.apiTokenHeader();
	let paramsGetPassword = { method: 'POST', headers: _headerGetPassword };
	let urlGetPassword = `${urlBase.urlBase}/authentication/bhstoken/GetNewPassword`;

	let response = await fetch(urlGetPassword, paramsGetPassword);
	let data = await response.json();

	return data;
}

export default async function patchAlterarSenha(changeData) {
	let userSignature = HttpRequest.userSignature();
	let data = {};
	let url = '';

	data = {
		"passwordProfile": {
			"forceChangePasswordNextSignIn": changeData.forceChange,
			"password": btoa(changeData.password)
		}
	};

	url = `${urlBase.urlBase}/graph/User/UpdatePassword/${userSignature.tenantCode}/${changeData.upn}`;
	

	let _header = await HttpRequest.apiTokenHeader();
	let params = { method: 'PATCH', headers: _header, body: JSON.stringify(data) };

	return fetch(url, params)
		.then(response => {
			if (!response.ok) {
				throw Error(response.statusText);
			}

			return response;
		});
}
