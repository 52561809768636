import NavBase from 'EstruturaAntiga/components/navigation/NavBase';
import React from 'react';
import styled from 'styled-components';
import Uuid from 'EstruturaAntiga/assets/uuid';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .nav-item{
        display: flex;
        align-items:center; 
    }
`;

const MenuComponent = () => {
  return (
    <MenuWrapper>
        <NavBase/>
    </MenuWrapper>
  );
};

export default MenuComponent;
