import React from 'react';
import { Link  } from 'react-router-dom';

import Lottie from 'react-lottie';
import AcceptedLottie from '../../../../../assets/images/lottie/load-and-check.json';

import { Container } from './styles';

export default function Concluded({ loading = false, success = true, renderNewUserPage }){
    
    const options = {
        loop: false,
        autoplay: true,
        animationData: AcceptedLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <Container>
            <div className='confirm-icon'>
                <Lottie
                    options={options}
                    width={156}
                    height={156}
                />
            </div>
            
            {!loading && success && (
                <div className='buttom-concluded'>
                    <div>
                        <Link to="/usuarios" className="btn returnButton" >Retornar</Link>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="btn btn-default newUserButton"
                            onClick={() => renderNewUserPage()}
                        >
                            Criar novo usuário
                        </button>
                    </div>
                </div>
            )}
            
        </Container>
    );
} 