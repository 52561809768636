import PortalClienteDBService from "services/bases/PortalClienteDBService";

class UploadArquivosService extends PortalClienteDBService{
    async DeletarArquivo(pathName, file){
        const data = new FormData();
        data.append("file",file.file);
        await this.delete(`storageaccount/blob/blob/RemoveFilesFromPath?pathName=${pathName}`,data)
    }
}

export default new UploadArquivosService();