import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;

    .confirm-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 30px;
    }

    .buttom-concluded{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .returnButton{
        margin: 15px;
    }

    .newUserButton{
        margin: 15px;
    }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
`;
