import React, { Component } from 'react';
import Modal from '../../components/modal/modal';
import Policy from '../usuariosV2/componentes/policy';
import Input from 'EstruturaAntiga/components/form/InputGroup';
import Form from "EstruturaAntiga/assets/formValidate";
import Mask from "utils/masks";

import './ModalTermoCancelamento.scss';
import LoadingShadow from '../../components/loadingShadow';

export default class ModalTermoCancelamento extends Form {
    constructor(props) {
        super(props);
    
        this.state = {
            ...this.state,
            form: {
                email: '',
                celular: ''
            },
            hasError: {
                email: {},
                celular: {}
            },
            validations: {
                email: { mask: 'email', required: true },
                celular: { mask: 'phone', required: true }
            },
			searchAlternateEmail: false,
            loading: true
        }
      }

    onChangeAlternativePhone(event) {
        event.target.value = Mask(event.target.value, 'phone');
    }

    onSubmit = async() => {
        const signature = JSON.parse(sessionStorage.getItem("signature"));
        const contact = JSON.parse(sessionStorage.getItem("contact"));
    
        const cancelarData = {
            "Email": this.state.form.email,
            "Celular": this.state.form.celular,
            "LinhaDeServicoId": signature.serviceLineId,
            "TenantDominio": signature.tenantName,
            "ClienteNome": contact.accountName,
            "TenantId": signature.axtTenantId,
            "SolicitanteId": contact.idContact,
            "DireitoId": signature.entitlementId,
            "ClienteId": contact.accountId
        }
        
        this.props.onConfirmModal(cancelarData);
    }

    render() {
        return (
            <Modal open={this.props.openModal} close={this.props.onCloseModal} sizeClassName={'modal-content-xl'}>
                {this.props.loadingModal && (
                        <LoadingShadow
                            sizeClassName={''}
                            open={true} 
                            close={() => {}}
                        />
                    )
                }
                <div className="cancelarcontrato-modal">
                    <div className="cancelarcontrato-modal-policy">
                        <div className="cancelarcontrato-modal-wrapper">
                            <Policy 
                                policies={this.props.policies} 
                                implementCheck={false}
                            />
                        </div>
                    </div>
                    <div className="cancelarcontrato-modal-footer">
                        <div className='cancelarcontrato-modal-footer-check'>
                            <label htmlFor="acceptPolices">
                                <input className='checked-Policy' id="acceptPolices" type="checkbox" checked={this.props.checkedPolicy} onChange={() => this.props.setCheckedPolicy(!this.props.checkedPolicy)} />
                                <span>Eu li e concordo com os termos da <strong>{this.props.policies.title}</strong></span>
                            </label>
                        </div>
                        <form onSubmit={e => { e.preventDefault(); this.handleSubmit() }}>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <Input
                                        label="Celular"
                                        configureInput={this.configureInput('celular', (e) => this.onChangeAlternativePhone(e))}
                                        required={true}
                                    />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <Input
                                        label="E-mail alternativo"
                                        configureInput={this.configureInput('email')}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="action-container">
                                <div>
                                    <button
                                        className="btn btn-danger"
                                        type="button" 
                                        onClick={() => this.props.onCloseModal()} 
                                    >
                                        Voltar
                                    </button>
                                    <button 
                                        type="submit"
                                        disabled={!this.props.checkedPolicy || !this.state.form.email || !this.state.form.celular} 
                                        className="btn btn-default"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    };
}