import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function validateManagerProfileUnlink(userPrincipalName,entitlementId) {
	let url = `${urlBase.urlBase}/dynamics/Entitlement/ValidateManagerProfileUnlink/${userPrincipalName}/${entitlementId}`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'GET', headers: _header };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        });
}
