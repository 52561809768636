import { Divider } from "antd";
/* 
Tipagem para adicionar o TS

props:{
    before: Divider,
    after: Divider,
}

divider:{
    show: Boolean,
    text: string
}
*/

function FormInputDivider({children, ...props}){
    return(
        <>
            { props.before && props.before?.show ? <Divider orientation="left">{props.before.text}</Divider> : "" }
                {children}
            { props.after && props.after?.show ? <Divider orientation="left">{props.after.text}</Divider> : "" }
        </>
    );
}

export default FormInputDivider;