import React from 'react';
import MasterPage from '../../../masterpages/authenticated';

export default function ErroGestaoUsuario() {
  return (
    <MasterPage>
      <h3><p>Prezado cliente,</p>
        <p>O menu de gestão de usuários está temporariamente indisponível.</p> 
        <p>Para prosseguir com seu atendimento, nos contate através dos canais:</p>
        <p><a href = "mailto: atendimento@bhs.com.br">atendimento@bhs.com.br</a> | 31 4000-1744</p>
      </h3>
    </MasterPage>
  )
}
