import React, { Component } from 'react'
import MasterPage from '../../masterpages/authenticated'
import Termo from '../../components/admTermoDeAceite/AdmTermoDeAceite'
import ContentBox from "../../../components/ContentBox"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTermos, getMoreTermos } from './termoDeAceiteAdmActions'

class ListaTermos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterText: '',
            orderSelect: 1
        }
        this.loadingTerms = true;
        this.filterTerms = {
            PageSize: 15,
            PageNumber: 1,
            Titulo: ''
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.getInitialTerms();

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    onChangeFilter(el) {
        this.filterTerms.PageNumber = 1;
        this.filterTerms.Titulo = el.target.value;

        this.setState({ filterText: el.target.value })

        this.getInitialTerms();
    }

    onChangeOrder(el) {
        this.setState({ orderSelect: el.target.value });

        switch (el.target.value) {
            case "recentes":
                this.filterTerms.OrderBy = 'dataalteracao';
                this.filterTerms.SortDir = 'desc';
                break;
            case "ultimos":
                this.filterTerms.OrderBy = 'dataalteracao';
                this.filterTerms.SortDir = 'asc';
                break;
            case "publicados":
                this.filterTerms.OrderBy = 'publicado';
                this.filterTerms.SortDir = 'desc';
                break;
            case "rascunhos":
                this.filterTerms.OrderBy = 'publicado';
                this.filterTerms.SortDir = 'asc';
                break;
            default:
                break;
        }

        this.getInitialTerms();
    }

    handleScroll() {
        if (!this.loadingTerms) {
            this.showMoreTerms();
        }
    };

    getInitialTerms() {
        this.loadingTerms = true;
        this.filterTerms.PageNumber = 1;

        this.props.getTermos(this.filterTerms).then(() => {
            this.loadingTerms = false;
            this.showMoreTerms();
        })
    }

    showMoreTerms() {
        if (this.props.hasMoreTerms && !this.loadingTerms) {
            var windowPosition = window.scrollY + window.innerHeight;
            var elOffset = document.getElementById('loadContainerTerm');
            elOffset = elOffset && elOffset.offsetTop;

            if (windowPosition >= elOffset) {
                this.loadingTerms = true;
                this.filterTerms.PageNumber++;

                this.props.getMoreTermos(this.filterTerms).then(() => {
                    this.loadingTerms = false;
                    this.showMoreTerms();
                })
            }
        }
    }

    render() {
        return (
            <MasterPage>
                <div className="title-with-action">
                    <h1>Lista de Termos</h1>
                    <Link to="/termos/novo" className="btn btn-default">Criar termo</Link>
                </div>

                <ContentBox className="list-container term-list-container">
                    <div className="list-header">
                        <div className="filter-box">
                            <input type="text" className="filter" placeholder="Filtrar por nome" value={this.state.filterText} onChange={(el) => this.onChangeFilter(el)} />
                            <i className="icon icon-search"></i>
                        </div>
                        <div className="order-box">
                            <span className="order-label">Ordenar por</span>

                            <div className="select-order">
                                <select value={this.state.orderSelect} onChange={el => this.onChangeOrder(el)}>
                                    <option value="recentes">Adicionados recentemente</option>
                                    <option value="ultimos">Adicionados por último</option>
                                    <option value="publicados">Publicados primeiro</option>
                                    <option value="rascunhos">Rascunhos primeiro</option>
                                </select>
                                <i className="icon icon-arrow-dow"></i>
                            </div>
                        </div>
                    </div>

                    <div className="list-items">
                        {
                            this.props.termos.map((el) =>
                                <Termo key={el.guid} id={el.id} title={el.titulo} status={el.publicado ? 'Publicado' : 'Rascunho'} version={'v' + el.versao} />
                            )
                        }

                        <span className={`list-box empty-result ${this.props.termos.length ? 'd-none' : ''}`}>Nenhum termo encontrado</span>

                        <div className={`list-box list-loading ${!this.props.hasMoreTerms ? 'd-none' : ''}`} id="loadContainerTerm">
                            <div className="box-text">
                                <div className="title"></div>
                                <div className="subtitle"></div>
                            </div>
                            <div className="actions">
                                <div className="simple-loading"></div>
                            </div>
                        </div>
                    </div>
                </ContentBox>
            </MasterPage>
        );
    };
}

const mapDispatchToProps = dispatch => (bindActionCreators({ getTermos, getMoreTermos }, dispatch))
export default connect(
    state => ({
        termos: state.admtermos.listaTermos,
        hasMoreTerms: state.admtermos.hasMoreTerms
    }),
    mapDispatchToProps
)(ListaTermos);