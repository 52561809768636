import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

import { FinishWrapper, Reasons } from './style';

import AcceptedLottie from '../../../../../assets/images/lottie/load-and-check.json';
import RejectedLottie from '../../../../../assets/images/lottie/error.json';

export function Finish({
  acceptedLicensesAndPolices,
  timelinePosition,
  reasons,
  setCheckedReason,
  setAdditionalComment
}) {
  const [commentRequired, setCommentRequired] = useState(false);
  const [selectedReason, setSelectedReason] = useState([]);

  useEffect(() => {
    setCheckedReason(selectedReason);
  }, [selectedReason]);

  const options = {
    loop: false,
    autoplay: true,
    animationData: acceptedLicensesAndPolices ? AcceptedLottie : RejectedLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  if (timelinePosition === 2) {
    return (
      <FinishWrapper>
        {
          !!acceptedLicensesAndPolices ? (
            <React.Fragment>
              <h2><strong>Atenção!</strong></h2>

              <div className="finished-text">
                <p>Após a confirmação, o cancelamento da assinatura pode ser solicitado em até 4 (quatro) horas úteis antes de completar 7 (sete) dias corridos deste provisionamento, havendo cobrança de valor proporcional (pro rata), calculado diariamente. Para cancelamentos após este período, haverá a cobrança normal das assinaturas adquiridas, calculada considerando a vigência dessas assinaturas.</p>
                <p>As horas úteis na BHS compreendem o horário das 09h às 18h, de segunda à sexta, exceto feriados em Belo Horizonte – MG.</p>
                <p>O cancelamento deve ser feito pelos seguintes canais:<br />
                  <ul>
                    <li>Portal do cliente: <a href="https://portal.bhs.com.br/" target="_blank" rel="noopener noreferrer">https://portal.bhs.com.br/</a></li>
                    <li>E-mail: <a href="mailto:atendimento@bhs.com.br" target="_blank" rel="noopener noreferrer">atendimento@bhs.com.br</a></li>
                    <li>Telefone: <a href="tel:4000-1429" target="_blank" rel="noopener noreferrer">4000-1744 - opção 1</a></li>
                  </ul>
                </p>
                <p>O pedido de cancelamento deve conter o CNPJ, o tenant, o número da cotação caracterizado por OPP-XXXX-XXX, a assinatura que se quer cancelar e a quantidade.</p>
                <p>Qualquer dúvida, nos contate através dos canais abaixo.<br />
                  <a href="mailto:comercial@bhs.com.br" target="_blank" rel="noopener noreferrer">comercial@bhs.com.br</a><br />
                  4000-1429
                </p>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2>Motivo da reprovação</h2>
              <Reasons>
                {
                  reasons.map(reason => (
                    <label key={reason.id} htmlFor={`reason-${reason.id}`}>
                      <input
                        id={`reason-${reason.id}`}
                        checked={selectedReason === reason.id}
                        type="checkbox"
                        onChange={() => setSelectedReason(reason.id)}
                      />
                      <span>{reason.reason}</span>
                    </label>
                  ))
                }
                {
                  selectedReason === 321770005 && (
                    <div>
                      <span>Comentário adicional:</span>
                      <textarea required={!!commentRequired} onBlur={(event) => setAdditionalComment(event.target.value)} placeholder="Digite seu comentário adicional aqui" />
                    </div>
                  )
                }
              </Reasons>
            </React.Fragment>
          )
        }
      </FinishWrapper>
    )
  } else {
    return (
      <FinishWrapper>
        {
          !!acceptedLicensesAndPolices ? (
            <React.Fragment>
              <h2>Aprovação enviada com sucesso!</h2>

              <div className="finished-text">
                <p>Recebemos o seu de acordo para a política de licenciamento Microsoft da BHS.</p>
                <p>A autorização para contratação das assinaturas foi enviada com sucesso, esse processo pode levar até 4 horas para ser concluído.</p>
                <p>
                  Qualquer dúvida, nos contate através dos canais abaixo.<br />
                  <a href="mailto:comercial@bhs.com.br" target="_blank" rel="noopener noreferrer">comercial@bhs.com.br</a> <br />
                  <a href="tel:4000-1429" target="_blank" rel="noopener noreferrer">4000-1429</a>
                </p>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
                <div className="finished-text">
                  <p>
                    Agradecemos o feedback!<br />
                    Nosso time logo entrará em contato.
                  </p>
                  <p>
                    Qualquer dúvida, nos contate através dos canais:<br />
                    <a href="mailto:comercial@bhs.com.br" target="_blank" rel="noopener noreferrer">comercial@bhs.com.br</a><br />
                    <a href="tel:4000-1429" target="_blank" rel="noopener noreferrer">4000-1429</a><br />
                    <strong>Equipe BHS</strong>
                </p>
              </div>
            </React.Fragment>
          )
        }

        <Lottie
          options={options}
          width={156}
          height={156}
        />
      </FinishWrapper>
    )
  }
}
