import urlBase from 'EstruturaAntiga/assets/urlsBase';
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import axios from 'axios';
class BaseService{
    constructor(baseURL){
        this.baseURL = baseURL || urlBase.urlBase;
    }
    async request(endpoint, method = "GET", data = null, customHeader = null, authenticated = true){
        let url = `${this.baseURL}/${endpoint}`;
        let options = {
            headers:{
                'Content-Type': "application/json"
            },
            method
        };
        if (authenticated){
            const {Accept, Authorization} = await HttpRequest.axiosApiTokenHeader();
            options.headers.Accept = Accept;
            options.headers.Authorization = Authorization;

            if(customHeader){
                options.headers = {
                    Authorization: Authorization,
                    ...customHeader
                }
            }
        }

        if(data){
            options.data = data;
        }
        return axios(url, options).then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.data;
            }
            return Promise.reject(response);
        }).catch(error =>{
            console.error("Houve um problema com a solicitação:", error)
            throw error;
        });
    }
    async get(endpoint){
        const method = "GET"
        return await this.request(endpoint,method)
    }
    post(endpoint, data){
        const method = "POST"
        return this.request(endpoint, method, data)
    }
    delete(endpoint,data){
        const method = "DELETE"
        return this.request(endpoint, method, data, {})
    }
    put(endpoint, data, authenticated = true){
        const method = "PUT"
        return this.request(endpoint, method, data, null, authenticated)
    }
    patch(endpoint, data){
        const method = "PATCH"
        return this.request(endpoint, method, data)
    }
}
export default BaseService;