import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid #DFE3E8;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  h1 {
    font-size: 28px;
    margin: 0;
    font-weight: 400;
    color: #212B35;
    padding: 0;
    border-bottom: none;
  }

  span {
    color: #637381;
    font-size: 12px;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-self: flex-end;
  }

  strong {
    color: #212B35;
  }
`;
