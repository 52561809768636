import React, { Component } from 'react'
import MasterPage from '../../masterpages/authenticated'

export default class Cobranca extends Component {


    constructor(props) {
        super(props);

        this.state = {
        }
    }
    
    render() {
        return (

            <MasterPage>
                <div className="title-with-action">
                    <h1>Cobrança</h1>
                </div>
            </MasterPage>
        );
    };
}