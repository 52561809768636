const _defaultState = {
    listaTermos: [],
    selectedTerm: {},
    selectedTermId: null,
    listaClientes: [],
    listaServicos: [],
    listaPerfis: [],
    hasMoreTerms: true
}

export default (state = _defaultState, action) => {
    switch (action.type){
        case 'ADM_TA_LISTAR_TERMOS':
            return { ...state, listaTermos: action.payload, hasMoreTerms: action.payload.length === 15, PageNumber: 1 }
        case 'ADM_TA_INCREMENTAR_TERMOS':
            return { ...state, listaTermos: [...state.listaTermos, ...action.payload], hasMoreTerms: action.payload.length === 15, PageNumber: state.PageNumber + 1 }
        case 'ADM_TA_TERMO_SELECIONADO':
            return { ...state, selectedTerm: action.payload }
        case 'ADM_TA_LISTAR_CLIENTES':
            return { ...state, listaClientes: action.payload }
        case 'ADM_TA_LISTAR_SERVICOS':
            return { ...state, listaServicos: action.payload }
        case 'ADM_TA_LISTAR_PERFIS':
            return { ...state, listaPerfis: action.payload }
        case 'ADM_TA_REMOVER_TERMO':
            return { ...state, listaTermos: state.listaTermos.filter( (el) => { return el.id !== action.payload } ) }
        case 'ADM_TA_ID_TERMO_SELECIONADO':
            return { ...state, selectedTermId: action.payload }
        default:
            return state
    }
}