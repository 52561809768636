import { StyledButton } from "../styles";
import { Tooltip } from "antd";

function Botao({onClick, loading, message, icon, tooltip, customStyle = {}}){

    return (
        <StyledButton onClick={onClick} loading={loading} style={customStyle}>
            <Tooltip title={tooltip}>
                { icon ?? null}
                { message ?? null}
            </Tooltip>
        </StyledButton>
    )
}

export default Botao;