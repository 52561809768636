import React from 'react';
import MenuComponent from './MenuComponent';
import Header from 'EstruturaAntiga/components/header/Header';
import Footer from 'EstruturaAntiga/components/Footer/Footer';
import { Aside, Container, Content, ContentSection, Main, Row } from './styles';
import { withRouter } from 'react-router-dom';
import Breadcrumb from 'EstruturaAntiga/components/breadcrumb/Breadcrumb';
import { useMenu } from 'context/MenuContext';

const MasterPage = ({ props, children, match}) => {
  const {menuVisivel} = useMenu()

  return (
    <Main>
      <div className="authenticated">
        <Header />
        <ContentSection className="content">
          <Container className="container">
            <Row className="row content-container">
              <Aside isOpen={menuVisivel}>
                <MenuComponent/>
              </Aside>
              <Content className={`col-12 col-md-8 col-lg-9 ${props?.useFullWidth ? "content-holder-full-width" : "content-holder"}`}>
				        <div className={`content-main ${props?.className || ''}`}>
                  {!props?.useFullWidth && match.path !== '/dashboard' && <Breadcrumb />}
                  {children}
                </div>
              </Content>
            </Row>
          </Container>
        </ContentSection>
        <Footer />
      </div>
    </Main>
  );
};

export default withRouter(MasterPage);
