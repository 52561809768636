import React, { Component } from "react";
import Modal from "../../../../components/modal/modal";
import patchAlterarSenha from "EstruturaAntiga/actions/senha/patchAlterarSenha";
import { GetNewPassword } from "EstruturaAntiga/actions/senha/patchAlterarSenha";
import CheckBox from "EstruturaAntiga/components/form/CheckBox";
import Button from "EstruturaAntiga/components/form/Button";
import { NotificationManager } from "react-notifications";

export default class AlterarSenha extends Component {
  state = {
    modalPassword: 1,
    loadingChangePassword: false,
    user: {
      upn: "",
      forceChange: false,
      password: ""
    },
    selectAlteracao: false
  };

  componentDidMount() {
    this.setState({
      user: {
        ...this.state.user,
        upn: this.props.upn,
        //password: this.novaSenha()
      }
    });
  }

  novaSenha2() {
    // let length = 8;
    let charsetLower = "abcdefghijklmnopqrstuvwxyz";
    let charsetNumbers = "0123456789";
    let charsetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let charsetSpecial = "@";
    let novaSenha = "";
    let retVal = charsetSpecial;

    for (let i = 0, n = charsetLower.length; i < 5; ++i) {
      retVal += charsetLower.charAt(Math.floor(Math.random() * n));
    }

    for (let i = 0, n = charsetNumbers.length; i < 2; ++i) {
      retVal += charsetNumbers.charAt(Math.floor(Math.random() * n));
    }

    for (let i = 0, n = charsetUpper.length; i < 3; ++i) {
      retVal += charsetUpper.charAt(Math.floor(Math.random() * n));
    }

    for (let i = 0, n = retVal.length; i < 10; ++i) {
      novaSenha += retVal.charAt(Math.floor(Math.random() * n));
    }

    return novaSenha;
  }

  novaSenha() {
    let caracteres =
      "abcdefghijklmnopqrstuvwxyz!@#$%&ABCDEFGHIJKLMNOP1234567890";
    let senha = "";
    let minChar = 8;
    let maxChar = 9;
    let tamanho = Math.round(Math.random() * (maxChar - minChar)) + minChar;

    let regex = str => {
      var lowerReg = new RegExp(/[a-z]/);
      var lower = lowerReg.test(str);

      var upperReg = new RegExp(/[A-Z]/);
      var upper = upperReg.test(str);

      var specialReg = new RegExp(/\W/);
      var special = specialReg.test(str);

      return lower && upper && special;
    };

    while (!regex(senha)) {
      senha = "";
      for (var i = 0; i < tamanho; i++) {
        senha += caracteres.charAt(
          Math.round(Math.random() * caracteres.length)
        );
      }
    }
    return senha;
  }

  onClickAlterarSenha() {
    this.setState({ loadingChangePassword: true });

    GetNewPassword().then(result => {
      this.setState({
        user: {
          ...this.state.user,
          password: result.password
        }
      }, () => {

        patchAlterarSenha(this.state.user)
          .then(() => {
            this.setState({ loadingChangePassword: false, modalPassword: 3 });
          })
          .catch((error) => {
            this.setState({ loadingChangePassword: false });

            this.onCloseModal();

            NotificationManager.error(
              `${error.error ? error.error : 'Entre em contato com nossa equipe de atendimento.'}`,
              'Não foi possível alterar sua senha.',
              9000
            );
          });

      })
    }).catch((result) => {
      this.setState({ loadingChangePassword: false });

      this.onCloseModal();

      NotificationManager.error(
        `${result.error ? result.error : 'Entre em contato com nossa equipe de atendimento.'}`,
        'Não foi possível alterar sua senha.',
        9000
      );
    });
  }

  renderModalAlterarSenha() {
    switch (this.state.modalPassword) {
      case 1:
        return this.confirmacao();
      case 2:
        return this.alterarNoPrimeiroLogin();
      case 3:
        return this.senhaAlterada(this.props.sync);
      default:
        break;
    }
  }

  onCloseModal() {
    this.props.onCloseModal();
    this.setState({ modalPassword: 1 });
  }

  onSelectAlteracao = event => {
    this.setState({
      user: {
        ...this.state.user,
        forceChange: event.target.checked
      }
    });
  };

  confirmacao() {
    return (
      <div>
        <div className="text-modal">
          <span>Deseja realmente solicitar a alteração de senha?</span>
        </div>
        <div className="btn-modal">
          <button
            type="button"
            onClick={() => this.onCloseModal()}
            className="btn"
          >
            Cancelar
          </button>
          <Button
            type="button"
            onClick={() =>
              this.props.sync
                ? this.onClickAlterarSenha()
                : this.setState({ modalPassword: 2 })
            }
            loading={this.state.loadingChangePassword}
            className="btn btn-default"
          >
            Solicitar
          </Button>
        </div>
      </div>
    );
  }

  alterarNoPrimeiroLogin() {
    return (
      <div>
        <div className="text-modal">
          <CheckBox
            id="AlterarPrimeiroLogin"
            value={this.state.user.forceChange}
            onChange={this.onSelectAlteracao}
            label="Alterar senha no primeiro login"
          />
        </div>

        <div className="btn-modal">
          <button
            type="button"
            onClick={this.onCloseModal.bind(this)}
            className="btn"
          >
            Cancelar
          </button>
          <Button
            type="buttom"
            onClick={this.onClickAlterarSenha.bind(this)}
            className="btn btn-default"
            loading={this.state.loadingChangePassword}
          >
            Solicitar
          </Button>
        </div>
      </div>
    );
  }

  senhaAlterada(sync) {
    return (
      <div>
        <div className="text-modal">
          <div className="animate-success-icon">
            <div className="success-circular-line-left"></div>
            <span className="success-line-tip"></span>
            <span className="success-line-long"></span>
            <div className="success-ring"></div>
            <div className="success-fix"></div>
            <div className="success-circular-line-right"></div>
          </div>
          <span>
            Senha alterada com sucesso! <br /> Nova senha:
          </span>
          <div className="new-password">{this.state.user.password}</div>

          <br></br>

          {!!sync ? (
            <i>
              <span>
                Após solicitar a alteração da senha deve-se aguardar cerca de 10
                a 15 minutos.
                <br></br>
                Tempo necessário para sincronização dos acessos entre os
                serviços da BHS e Microsoft.
              </span>
            </i>
          ) : null}
        </div>
        <div className="btn-modal">
          <button
            onClick={() => this.onCloseModal()}
            className="btn btn-default"
          >
            Ok
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal open={true} close={this.props.onCloseModal}>
        {this.renderModalAlterarSenha()}
      </Modal>
    );
  }
}
