import CONSTANTES_AMBIENTE from "constants/constantesAmbiente";

export const profilesV2 = {
	admGlobal: {
		idEntitlement: CONSTANTES_AMBIENTE.CONFIGURACOES.DIREITO_ACESSO_ADM_GLOBAL,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.BHS_ADMINISTRADORES_GLOBAIS,
		idServiceLine: null,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	gestorContrato: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: null,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	GestorNuvemOnPremise: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.BHS_NUVEM_ON_PREMISE,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	GestorDispositivos: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.BHS_DISPOSITIVOS,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	GestorM365Gerenciado: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_GERENCIADO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	GestorM365Basico: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_BASICO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	GestorM365GerenciadoDaAssinaturaSelecionada: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_GERENCIADO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: true
	},
	ResponsavelTecnicoM365GerenciadoDaAssinaturaSelecionada: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_GERENCIADO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: true
	},
	GestorM365BasicoDaAssinaturaSelecionada: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_BASICO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: true
	},
	ResponsavelTecnicoM365BasicoDaAssinaturaSelecionada: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_BASICO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: true
	},
	gestorContratoAzure: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.AZURE,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	GestorControleHoras: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.GESTOR_CONTRATO,
		idServiceLine: null,
		serviceLineTypeCode: CONSTANTES_AMBIENTE.TIPO_LINHA_SERVICO.CONTROLE_HORAS,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnico: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: null,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnicoNuvemOnPremise: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.BHS_NUVEM_ON_PREMISE,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnicoDispositivos: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.BHS_DISPOSITIVOS,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnicoM365Gerenciado: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_GERENCIADO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnicoM365Basico: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.M365_BASICO,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnicoAzure: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: CONSTANTES_AMBIENTE.LINHA_SERVICO.AZURE,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	ResponsavelTecnicoControleHoras: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.RESPONSAVEL_TECNICO,
		idServiceLine: null,
		serviceLineTypeCode: CONSTANTES_AMBIENTE.TIPO_LINHA_SERVICO.CONTROLE_HORAS,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	Tecnico: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.TECNICO,
		idServiceLine: null,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	UsuarioFinal: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.USUARIO_FINAL,
		idServiceLine: null,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	},
	FinanceiroCliente: {
		idEntitlement: null,
		idAxtRole: CONSTANTES_AMBIENTE.PERFIL_ACESSO.FINANCEIRO_CLIENTE,
		idServiceLine: null,
		serviceLineTypeCode: null,
		TerAcessoAoDireitoAssinaturaSelecionada: false
	}
}

export const profileNavGerenciamentoUsuarios = [
	profilesV2.admGlobal,
	profilesV2.GestorM365GerenciadoDaAssinaturaSelecionada,
	profilesV2.ResponsavelTecnicoM365GerenciadoDaAssinaturaSelecionada
];

export const profileNavRelatoriosM365Gerenciado = [
	profilesV2.admGlobal,
	profilesV2.GestorM365Gerenciado,
	profilesV2.ResponsavelTecnicoM365Gerenciado
];

export const profileNavRelatoriosMicrosoft365 = [
	profilesV2.admGlobal,
	profilesV2.GestorM365Gerenciado,
	profilesV2.ResponsavelTecnicoM365Gerenciado,
	profilesV2.GestorM365Basico,
	profilesV2.ResponsavelTecnicoM365Basico
];

export const profileNavRelatoriosM365Basico = [
	profilesV2.admGlobal,
	profilesV2.GestorM365Basico,
	profilesV2.ResponsavelTecnicoM365Basico
];

export const profileNavGerenciamentoAssinaturas = [
	profilesV2.admGlobal,
	profilesV2.GestorM365BasicoDaAssinaturaSelecionada,
	profilesV2.ResponsavelTecnicoM365BasicoDaAssinaturaSelecionada,
];

export const profilesGestor = [
	profilesV2.GestorNuvemOnPremise,
	profilesV2.GestorDispositivos,
	profilesV2.GestorM365Gerenciado,
	profilesV2.GestorM365Basico,
  	profilesV2.gestorContratoAzure,
  	profilesV2.GestorControleHoras,
];

export const profileNavRelatoriosNuvemOnPremise = [
	profilesV2.admGlobal,
	profilesV2.GestorNuvemOnPremise,
	profilesV2.ResponsavelTecnicoNuvemOnPremise
];

export const profileNavRelatoriosAzure = [
	profilesV2.admGlobal,
	profilesV2.gestorContratoAzure,
	profilesV2.ResponsavelTecnicoAzure,
];

export const profileNavRelatoriosAdmGlobal = [
	profilesV2.admGlobal,
];

export const profileNavRelatoriosDispositivos = [
	profilesV2.admGlobal,
	profilesV2.GestorDispositivos,
	profilesV2.ResponsavelTecnicoDispositivos,
];

export const profileNavRelatoriosControleHoras = [
	profilesV2.admGlobal,
	profilesV2.GestorControleHoras,
	profilesV2.ResponsavelTecnicoControleHoras,
];

export const profileGestorOuResponsavelTecnico = [
	profilesV2.admGlobal,
	profilesV2.gestorContrato,
	profilesV2.ResponsavelTecnico
];

export const profileGestorOuFinanceiroCliente = [
	profilesV2.admGlobal,
	profilesV2.gestorContrato,
	profilesV2.FinanceiroCliente
]