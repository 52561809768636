import React from 'react';

import { Container, Infos } from './styles';

export function FormHeader({ formHeaders }) {
  return (
    <Container>
      <div>
        <h1>{formHeaders.formTitle}</h1>
        <span>Usuário • {formHeaders.formName}</span>
      </div>

      <Infos>
        <strong>{formHeaders.tenant}</strong>
        <span>Tenant</span>
      </Infos>
    </Container>
  )
}
