const _defaultState = {
    isEditable: true,
    businessPhone: '',
    mobilePhone: '',
    userInfoLoading: true,
    currentProduct: ''
}

export default (state = _defaultState, action) => {
    if (action.type === 'COTACAO_CLEAR' || state.isEditable) {
        switch (action.type) {
            case 'COTACAO_GET_USER_INFO':
                return {
                    ...state,
                    businessPhone: action.payload.telephone1,
                    mobilePhone: action.payload.mobilephone,
                    userInfoLoading: false
                }
            case 'COTACAO_SET_CURRENT_PRODUCT':
                return { ...state, currentProduct: false }
            case 'COTACAO_CLEAR':
                return { ..._defaultState, isEditable: action.payload }
            default:
                return state
        }
    }

    return state;
}