import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setSelectedTerm, markAsRead } from '../../../EstruturaAntiga/pages/termosDeAceite/termoAceiteActions';
import { bindActionCreators } from 'redux'
import Button from "../form/Button";

class CTermosDeAceite extends Component {
    constructor(props){
        super(props);

        this.state = {
            loadingReadTerm: false
        }
    }

    onClickReadTerms(){
        this.setState({ loadingReadTerm: true })
        this.props.markAsRead(this.props.id);
    }

    render() {
        return (
            <div className="termos-de-aceite">
                <div className="box-text">
                    <span className="title">{ this.props.title }</span>
                    <Link to="/TermosDeAceite/termo" className="termo" onClick={ () => this.props.setSelectedTerm(this.props.id) }>
                        <i className="icon icon-eye"></i>Visualizar termo completo
                    </Link>
                </div>
                <Button className="btn-default" onClick={ () => this.onClickReadTerms() } loading={ this.state.loadingReadTerm }>Declaro ter lido e aceito os termos</Button>
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => (bindActionCreators({ setSelectedTerm, markAsRead }, dispatch))
export default connect(
    state => ({ 
        listaTermos: state.termos.listaTermos,
        selectedTerm: state.termos.selectedTerm
    }), 
    mapDispatchToProps
)(CTermosDeAceite);