import React, { Component } from 'react'
import MasterPage from '../../masterpages/simpleAuthenticated'
import Termo from '../../components/termosDeAceite/TermosDeAceite'
import { connect } from 'react-redux'

class TermosDeAceite extends Component {
    render() {
        return (
            <MasterPage>
                <h1>Termos de aceite</h1>
                    
                <div className="container-termos">
                    { this.props.listaTermos.map( (el) => <Termo key={ el.id } title={ el.titulo } id={ el.id } /> ) }
                </div>
            </MasterPage>
        );
    };
}

export default connect(state => ({ listaTermos: state.termos.listaTermos }))(TermosDeAceite);