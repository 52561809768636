import React, { useState, useEffect } from 'react';
import { adalGetToken } from 'react-adal';

import { authContext, adalConfig } from '../../adalConfig';

export function Login({ history }) {
  const [logged, setLogged] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    async function getAdalToken() {
      await adalGetToken(authContext, adalConfig.endpoints.api)
      .then(() => history.push('/'))
      .catch((error) => {
        const { msg } = error;

        const loginError = authContext.getLoginError();
        const loginWasTriedButFailed = loginError !== undefined && loginError !== ""
        if (loginWasTriedButFailed) {
          setError({error: loginError})
        }
        else if (msg === 'login_required' || msg === 'login required') {
          authContext.login();
        } else {
          setError({ error })
        }
      });
    }

    getAdalToken();
  }, [])

  return (
    <div></div>
  )
}
