import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function postObterEstatisticasOcorrencias(data) {
	let url = `${urlBase.urlBase}/portaldb/ocorrencias/ObterEstatisticas`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'POST', headers: _header, body: JSON.stringify(data) };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result.quantidade;
        });
}
