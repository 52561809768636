import styled from 'styled-components';

export const SentinelStyle = styled.div`
    li{
        opacity : 0;
        height: 0;
    }
`;

export const FilterBoxStyle = styled.div`
    &.filter-box-style{
        position: relative;
        color: #637381;
        order: 2;
        width: 100%;
        display: grid;
        
        .filter{
            border: 1px solid #DFE3E8;
            border-radius: 4px;
            line-height: 16px;
            width:100%;
            padding: 10px 10px 10px 40px;
            background: 0 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
        }
    }
`;


