import urlBase from "../assets/urlsBase";
import { adalGetToken } from 'react-adal';
import { authContext, adalConfig } from '../adalConfig';

async function getAdalToken() {
    await adalGetToken(authContext, adalConfig.endpoints.api);    
}

export default async function authenticationBHSToken(token) {
    await getAdalToken();
    let url = `${urlBase.urlBase}/authentication/bhstoken`;
    let _token = token || localStorage.getItem('adal.idtoken');
    let _header = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + _token
    });
    let params = { method: 'GET', headers: _header };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        });
}
