import { authContext } from '../adalConfig';
import authenticationBHSToken from '../actions/authenticationBHSToken.js';

export const contact = () => JSON.parse(sessionStorage.getItem('contact'));
export const adalUser = () => authContext.getCachedUser(authContext.config.clientId);
export const userSignature = () => JSON.parse(sessionStorage.getItem('signature'));

export const apiTokenHeader = () => refreshApiToken(token => new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
}));

export const axiosApiTokenHeader = () => refreshApiToken(token => { 
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export const refreshApiToken = async callback => {
    let token = contact().tokenData;
    if (token && new Date(token.expiration) < new Date()) {
        return await authenticationBHSToken(token.refreshToken)
        .then(e => {
            let _contact = contact();
            _contact.tokenData = e.tokenData;
                sessionStorage.setItem('contact', JSON.stringify(_contact));

                return callback(e.tokenData.accessToken);
            });
    }
    else {
        return callback(token.accessToken);
    }
};
