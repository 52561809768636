export const TiposSolicitacao = {
    DUVIDA: 1,
    INCIDENTE: 2,
    SOLICITACAO: 3,
    MUDANCA: 282390000,
    DEMANDA: 321770001,
  };
export const TarefaServicoRazaoStatus = {
    MODELO: "1",
    NAOINICIADA: "282 390 000",
    EMANDAMENTO: "282 390 001",
    SUSPENSA: "282 390 002",
};