const ObjectUtils = {
  /**
   * Recebe 2 objetos como parametro e compara se os valores e chaves são iguais. Aceita objetos com valores primitivos, object e function
   * @param {*} objeto1 
   * @param {*} objeto2 
   * @returns 
   */
  objetosSaoIguais(objeto1, objeto2) {
    if (!objeto1 || !objeto2) {
      return false;
    }
    // Verifica se o número de chaves é o mesmo
    const keys1 = Object.keys(objeto1);
    const keys2 = Object.keys(objeto2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Verifica se as chaves e os valores são iguais
    for (let key of keys1) {
      const value1 = objeto1[key];
      const value2 = objeto2[key];

      // Se ambos os valores forem objetos, compara recursivamente
      if (typeof value1 === 'object' && typeof value2 === 'object') {
        if (!this.objetosSaoIguais(value1, value2)) {
          return false;
        }
      } else if (typeof value1 === 'function' && typeof value2 === 'function') {
        if (value1.toString() !== value2.toString()) {
          return false;
        }
      } else {
        // Caso contrário, compara os valores diretamente
        if (value1 !== value2) {
          return false;
        }
      }
    }

    return true;
  },
}

export default ObjectUtils
