import React from 'react';

import Download from '../../../../assets/icons/baixar.svg';
import BhsEbook from '../../../../assets/icons/bhs_ebook.svg';
import EbookImg from '../../../../assets/icons/ebook.jpg';

const Ebook = () => {
  return (
    <div className="ebook">
      <div className="ebook-header">
        <img src={Download} alt="E-book"/>

        <img src={BhsEbook} alt="BHS E-book"/>
      </div>

      <div className="ebook-body">
        <div>
          <img src={EbookImg} alt="E-book Lei Geral da proteção de dados: tudo o que você precisa saber"/>
        </div>

        <div className="ebook-content">
          <h3>Lei Geral da proteção de dados: tudo o que você precisa saber</h3>
          <p>Cerca de 85% das empresas brasileiras afirmaram que não estão de acordo com as novas obrigatoriedades. Pensando nisso, especialistas da bhs prepararam um material exclusivo para você entender as modificações trazidas pela lei e como se adequar a elas.</p>
          <a href="https://materiais.bhs.com.br/e-book-lgpd?utm_source=portal-cliente&utm_medium=cliente-bhs&utm_campaign=ebook-lgpd">Baixar</a>
        </div>
      </div>
    </div>
  )
}

export default Ebook;
