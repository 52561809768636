import PortalClienteService from "services/bases/PortalClienteService";
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';

class DireitosPortalService extends PortalClienteService{
    async ListarDireitosServicos(modeloOcorrenciaId){
        return await this.get(`Direitos/ListarDireitosServicos?modeloOcorrenciaId=${modeloOcorrenciaId}`)
    }

    async ListarDireitosGestaoUsuario(){
        const userSignature = HttpRequest.userSignature(); 
        return await this.get(`Direitos/ListarDireitosGestao?clienteId=${userSignature.accountId}`);
    }

    async ListarLinhasDeServico(perfisDeAcesso = []){
        let perfisAcessoString = perfisDeAcesso.join(",")
        return await this.get(`Direitos/ListarLinhasDeServico?perfisDeAcesso=${perfisAcessoString}`);
    }
}

export default new DireitosPortalService();