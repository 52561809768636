import React, { useEffect } from 'react';
import { Tour } from 'antd';
import { useRoteiro } from 'context/RoteiroContext';
import './styles.css';

const TourRoteiro = ({}) => {
  const { tourVisivel, handleFecharTour, tourSteps, indiceStepAtualDoTour, setIndiceStepAtualDoTour } = useRoteiro();

  useEffect(()=>{
    setIndiceStepAtualDoTour(0);
  },[tourSteps])

  return (
    <Tour 
        open={tourVisivel} 
        onClose={() => handleFecharTour()}
        steps={tourSteps}
        disabledInteraction={true}
        current={indiceStepAtualDoTour}
        />
  );
};

export default TourRoteiro;