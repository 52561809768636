import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';

import { acceptQuote } from 'EstruturaAntiga/actions/provisioning/acceptQuote';
import { rejectQuote } from 'EstruturaAntiga/actions/provisioning/rejectQuote';

import { LicensesContent } from '../LicensesContent';
import { PolicesForm } from '../PolicesForm';
import { Finish } from '../Finish';
import {
  AcceptanceWrapper,
  TimelineItem,
  ButtonsWrapper,
  Button,
  ContentWrapper
} from './style';

import AcceptedIcon from '../../../../../assets/icons/accepted.svg';
import RefusedIcon from '../../../../../assets/icons/refused.svg';

export function Acceptance({
  queryParams,
  licenses,
  polices,
  reasons
}) {
  const [timelinePosition, setTimelinePosition] = useState(0);
  const [acceptedLicensesAndPolices, setAcceptedLicensesAndPolices] = useState(true);
  const [triedToAcceptWithoutCheck, setTriedToAcceptWithoutCheck] = useState(false);
  const [needToAcceptPolices, setNeedToAcceptPolices] = useState(false);
  const [checkedReason, setCheckedReason] = useState(0);
  const [additionalComment, setAdditionalComment] = useState('');
  const [licensesAndPolicesHTMLContent, setLicensesAndPolicesHTMLContent] = useState('');

  const { linhaServico: serviceLine, email, cotacao } = queryParams;

  function handleRefuseSomething() {
    setAcceptedLicensesAndPolices(false);

    if (timelinePosition < 2)
      setTimelinePosition(2);
    else {
      if (!checkedReason) {
        return NotificationManager.error('É obrigatório a seleção de pelo menos um motivo.', 'Motivo obrigatório!', 5000);
      }

      setTimelinePosition(3);
      handleSubmitReject();
    }
  }

  function handleAcceptSomething() {
    if (!!needToAcceptPolices) {
      return setTriedToAcceptWithoutCheck(true);
    }

    setAcceptedLicensesAndPolices(true);

    if (timelinePosition === 2) {
      handleSubmitAccept();
    }

    setTimelinePosition(timelinePosition + 1);
  }

  function handleSubmitAccept() {
    const submitInfo = {
      CotacaoId: cotacao,
      DadosAprovados: licensesAndPolicesHTMLContent,
      DataHora: new Date(),
      Email: email,
      Navegador: navigator.userAgent,
      OrigemIP: ""
    }

    acceptQuote(submitInfo);
  }

  function handleSubmitReject() {
    const submitInfo = {
      CotacaoId: cotacao,
      DadosReprovados: licensesAndPolicesHTMLContent || "",
      DataHora: new Date(),
      Email: email,
      Navegador: navigator.userAgent,
      MotivoReprovacao: checkedReason,
      MotivoComentario: additionalComment,
      OrigemIP: ""
    }

    rejectQuote(submitInfo);
  }

  useEffect(() => {
    if (timelinePosition === 1) {
      setNeedToAcceptPolices(true);
    } else {
      setNeedToAcceptPolices(false);
      setTriedToAcceptWithoutCheck(false);
    }

    scrollToTop();
  }, [timelinePosition]);

  return (
    <AcceptanceWrapper timelinePosition={timelinePosition} accepted={acceptedLicensesAndPolices} position={timelinePosition - 1}>
      <div className="timelineWrapper">
        <TimelineItem timelinePosition={timelinePosition} accepted={acceptedLicensesAndPolices} position={0}>
          <div>
            {timelinePosition < 1 && '1'}
            {timelinePosition > 0 && !!acceptedLicensesAndPolices && <img src={AcceptedIcon} alt="accepted" />}
            {timelinePosition > 0 && !acceptedLicensesAndPolices && <img src={RefusedIcon} alt="rejected" />}
          </div>
          <p>{serviceLine === 'basico' ? 'Verificar as licenças' : 'Verificar os usuários'}</p>
        </TimelineItem>

        <TimelineItem timelinePosition={timelinePosition} accepted={acceptedLicensesAndPolices} position={1}>
          <div>
            {timelinePosition < 2 && '2'}
            {timelinePosition > 1 && !!acceptedLicensesAndPolices && <img src={AcceptedIcon} alt="accepted" />}
            {timelinePosition > 1 && !acceptedLicensesAndPolices && <img src={RefusedIcon} alt="rejected" />}
          </div>
          <p>Aceitar Termos</p>
        </TimelineItem>

        <TimelineItem timelinePosition={timelinePosition} accepted={acceptedLicensesAndPolices} position={2}>
          <div>
            {timelinePosition < 3 && '3'}
            {timelinePosition > 2 && !!acceptedLicensesAndPolices && <img src={AcceptedIcon} alt="accepted" />}
            {timelinePosition > 2 && !acceptedLicensesAndPolices && <img src={RefusedIcon} alt="rejected" />}
          </div>
          <p>Confirmação e finalização</p>
        </TimelineItem>
      </div>

      <ContentWrapper>
        {timelinePosition === 0 && <LicensesContent licenses={licenses} serviceLine={serviceLine} setLicensesAndPolicesHTMLContent={(value) => setLicensesAndPolicesHTMLContent(value)} />}
        {timelinePosition === 1 && <PolicesForm polices={polices} needToAcceptPolices={needToAcceptPolices} setNeedToAcceptPolices={(value) => setNeedToAcceptPolices(value)} triedToAcceptWithoutCheck={triedToAcceptWithoutCheck}setLicensesAndPolicesHTMLContent={(value) => setLicensesAndPolicesHTMLContent(licensesAndPolicesHTMLContent + value)} />}
        {timelinePosition >= 2 && <Finish acceptedLicensesAndPolices={acceptedLicensesAndPolices} setAdditionalComment={(value) => setAdditionalComment(value)} timelinePosition={timelinePosition} reasons={reasons} setCheckedReason={(value) => setCheckedReason(value)} />}
      </ContentWrapper>

      {
        timelinePosition <= 2 && (
          <ButtonsWrapper>
            { timelinePosition > 0 && <Button onClick={() => setTimelinePosition(0)} buttonType="back">Voltar</Button> }

            <div>
              {
                (timelinePosition < 2 || !acceptedLicensesAndPolices) && (
                  <Button onClick={() => handleRefuseSomething()} buttonType="refuse">
                    {timelinePosition === 0 && 'Reprovar'}
                    {timelinePosition === 1 && 'Recusar'}
                    {timelinePosition === 2 && 'Finalizar'}
                  </Button>
                )
              }
              {
                (timelinePosition < 2 || !!acceptedLicensesAndPolices) && (
                  <Button onClick={() => handleAcceptSomething()} buttonType="accept">
                    {timelinePosition === 0 && 'Aprovar'}
                    {timelinePosition === 1 && 'Aceitar'}
                    {timelinePosition === 2 && 'Finalizar'}
                  </Button>
                )
              }
            </div>
          </ButtonsWrapper>
        )
      }
    </AcceptanceWrapper>
  )
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
