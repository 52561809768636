import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { rdxGetServices } from '../redux/dashboardActions';

class Services extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.rdxGetServices();
	}

	render() {
		const { services } = this.props;

		return (
			<div className="services">
				{
					services.length > 0 && (
						<Carousel 
							showArrows={false} 
							autoPlay={true}
							infiniteLoop 
							showStatus={false}
							showThumbs={false}>
							{
								services.map(service => (
									<div className="services-item" key={service.title}>
										<div>
											<div className="services-header">
												<img src={service.imageUrl} alt="Serviço" />
												<h2>{service.title}</h2>
											</div>

											<p>{service.content}</p>
											<p className="chamada">{service.chamada}</p>
										</div>

										<a href={service.link} target="_blank" rel="noopener noreferrer" className="btn btn-default">Saiba mais</a>
									</div>
								))
							}
						</Carousel>
					)
				}
			</div>
		)
	};
}

export default connect(
	state => ({ services: state.dashboard.services, }),
	(dispatch) => (bindActionCreators({ rdxGetServices }, dispatch))
)(Services);
