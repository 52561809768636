import React, { useState } from 'react';


import Logo from 'assets/images/logo.png';

import './style.scss';
import UsuariosService from 'services/UsuariosService';

export function EsqueciMinhaSenha() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [emailPrincipal, setEmailPrincipal] = useState('');
  const [emailAlternativo, setEmailAlternativo] = useState('');

  async function handleGetNewPassword(e) {
    e.preventDefault();

    if (!emailPrincipal || !emailAlternativo) {
      return setResponse({
        resultcode: -1,
        message: 'Todos os campos são obrigatórios.'
      });
    }

    setLoading(true);
    setResponse({});

    const data = {
      EmailPrincipal: emailPrincipal,
      EmailAlternativo: emailAlternativo
    }
  await UsuariosService.RedefinirMinhaSenha(data)
    .then((response) => {
      setResponse({
        resultcode: 1,
        message: [response]
      });
    })
    .catch((error) => {
      setResponse({
        resultcode: -1,
        message: error.response?.data?.erros ?? ['Estamos passando por instabilidade eventual, tente novamente daqui alguns minutos. Caso o erro persista, entre em contato com nossa central de atendimento pelo telefone 4000-1744 - opção 1 ou e-mail atendimento@bhs.com.br']
      });
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className="forget-password">
      <form onSubmit={(e) => handleGetNewPassword(e)}>
        <img src={Logo} alt="BHS" />

        <div className="forget-password-titles">
          <h2>Portal do Cliente</h2>
          <h3>Esqueci minha senha</h3>
        </div>

        <div className="forget-password-inputs">
          <input
            type="email"
            name="emailPrincipal"
            placeholder="E-mail Principal*"
            id="emailPrincipal"
            required
            onChange={e => setEmailPrincipal(e.target.value)}
          />

          <input
            type="email"
            name="emailAlternativo"
            placeholder="E-mail Alternativo*"
            id="emailAlternativo"
            required
            onChange={e => setEmailAlternativo(e.target.value)}
          />
          {response.message?.map(x => 
            {return(
              <span className={response.resultcode === -1 ? 'error' : 'success'}>{x}</span>
            )})}
          </div>

        <button
          className="login-button"
          type="submit"
          disabled={loading}
        >
          {
            loading ? (
              <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
            ) : 'Solicitar nova senha'
          }
        </button>
      </form>
    </div>
  )
}
