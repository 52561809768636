import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom';
import { navigation } from '../../router/routerNavigation'

export default class Breadcrumb extends Component {
    render() {
        return (
            <Route path="/:path" component={_breadcrumbsBase} />
        );
    };
}

const _breadcrumbsBase = ({ ...route }) => {
    let current = route.location.pathname;
    let lastRoute = current.replace(/\/((?!\/).)*$/g, '') || '/dashboard';
    return (
        <div className="breadcrumb">
            <Link to={ lastRoute }>
                <i className="icon icon-arrow-left"></i>
                { _linkTitle( lastRoute ) }
            </Link>
        </div>
    )

};

const _linkTitle = (route) => {

    let text = '';

    const search = (arr) => {
        arr.forEach(function(el){
            if(el.path === route){
                text = el.title;

                return false;
            }

            if(Array.isArray(el.child)){
                search(el.child);
            }
        });
    }

    search(navigation)

    return text;
};
