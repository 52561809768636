import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export async function getSufixList(tenantCode, accountId) {
  const url = `${urlBase.urlBase}/portaldb/${tenantCode}/Dominios/Sufixos?accountId=${accountId}`;

  const header = await HttpRequest.apiTokenHeader();
  const params = {
    method: 'GET',
    headers: header
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => {
      return result;
    });
}
