import React, { Component } from 'react';
import Modal from '../../components/modal/modal';

export default class ModalCancelarContratoConfirmacao extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.openModal} close={this.props.onCloseModal} sizeClassName={'modal-content-lg'}>
                    <div>
                        <div className="text-modal-justify">
                            <span>Sua solicitação foi enviada com sucesso!</span>
                            <br />
                            <span>Nosso time logo entrará em contato para alinhar os detalhes do cancelamento.</span>
                            <br />
                            <br />
                            <span>Qualquer dúvida nos contate através dos canais:</span>
                            <br />
                            <span><a href="mailto:atendimento@bhs.com.br">atendimento@bhs.com.br</a> ou 4000-1744.</span>
                        </div>

                        <div className="btn-modal">
                            <button type="button" onClick={() => this.props.onCloseModal()} className="btn btn-default">Ok</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };
}