import getFormFields from 'EstruturaAntiga/actions/usuarios/getFormFields';
import getPlansRightsByEntitlementId from 'EstruturaAntiga/actions/usuarios/getPlansRightsByEntitlementId';
import getAdditionalProductsRightByEntitlementId from 'EstruturaAntiga/actions/usuarios/getAdditionalProductsRightByEntitlementId'; 
import getAccountById from 'EstruturaAntiga/actions/account/getAccountById';
import getPerfil from 'EstruturaAntiga/actions/usuarios/getPerfi';
import getUsuario from 'EstruturaAntiga/actions/usuarios/getUsuario';
import { mapUserFieldsV2toV1 } from 'EstruturaAntiga/assets/userHelpers';
import DireitosPortalService from 'services/DireitosPortalService';
import UsuariosService from 'services/UsuariosService';

export const rdxGetPerfis = () => {
  return dispatch =>
    getPerfil().then(obj => {
      dispatch({
        type: 'USUARIO_LISTA_PERFIS',
        payload: obj.map(el => {
          return { value: el.codigoTipoPerfilAcessoDireito, label: el.tipoPerfilAcessoDireito };
        })
      });
    });
};

export const rdxGetLinhaServico = () => {
  return dispatch =>
  DireitosPortalService.ListarDireitosGestaoUsuario().then(obj => {
      dispatch({
        type: 'USUARIO_LINHA_SERVICO',
        payload: obj.map(el => {
          return { 
            value: el.direitoId, 
            label: `${el.linhaDeServico ?? ''} - ${el.nome ?? ''}`,
            LinhaDeServico: el.linhaDeServico,
            Direito: el.nome
          };
        })
      });
    });
};

export const rdxGetPerfisUsuarioSelecionado = usuarioId => {
  return dispatch =>
    UsuariosService.ListarPerfisDeAcesso(usuarioId).then(obj => {
      dispatch({
        type: 'USUARIO_LISTA_PERFIS_ASSOCIADOS',
        payload: obj.map(e => {
          return {
            PerfilDeAcesso: e.perfilDeAcesso,
            PerfilDeAcessoCodigo: e.perfilDeAcessoCodigo,
            DireitoId: e.direitoId,
            LinhaDeServico: e.linhaDeServico,
            Direito: e.direito,
            Rotulo: `${e.linhaDeServico ?? ''} - ${e.direito ?? ''}`
          };
        })
      });
    });
};

export const rdxIncluirPerfilAssociado = perfil => ({
  type: 'USUARIO_INCLUIR_PERFIL_ASSOCIADO',
  payload: perfil
});

export const rdxSetEditUser = user => ({
  type: 'USUARIO_EDITANDO',
  payload: user
});

export const rdxRemoverPerfilAssociado = perfil => ({
  type: 'USUARIO_REMOVER_PERFIL_ASSOCIADO',
  payload: perfil
});

export const rdxClearUsers = editable => ({
  type: 'USUARIOS_CLEAR',
  payload: editable
});

export const rdxGetUserFields = params => {
  return dispatch =>
    getFormFields(params).then(obj => {
      dispatch({ type: 'USUARIO_FORM_FIELDS', payload: obj });
    });
};

export const rdxGetUser = params => {
  return dispatch =>
    getUsuario(params).then(obj => {
      dispatch({ type: 'GET_USER', payload: mapUserFieldsV2toV1(obj) });
    });
};

export const rdxGetAccountById = () => {
  return dispatch =>
    getAccountById().then(obj => {
      dispatch({ 
        type: 'GET_ACCOUNT_BY_ID', 
        payload: obj 
      });
    });
};

export const rdxGetPlansRightsByEntitlementId = () => {
  return dispatch =>
    getPlansRightsByEntitlementId().then(obj => {
      dispatch({
        type: 'USUARIOS_LISTA_PLANOS_POR_DIREITO',
        payload: obj.map(el => {
          return {
            value: el.produtoId,
            label: `${el.produto} - ${el.simboloMoeda} ${el.valorVendaBhs}${el.unidade ? ' | ' + el.unidade : ''}`,
            currencyName: el.moeda,
            currencySymbol: el.simboloMoeda,
            amount: el.valorVendaBhs,
            unitId: el.unidadeId,
            unit: el.unidade
          };
        })
      });
    });
};

export const rdxGetAdditionalProductsRightByEntitlementId = () => {
  return dispatch =>
    getAdditionalProductsRightByEntitlementId().then(obj => {
      dispatch({
        type: 'USUARIOS_LISTA_PLANOS_ADICIONAIS_POR_DIREITO',
        payload: obj.map(el => {
          return {
            value: el.produtoId,
            label: `${el.produto} - ${el.simboloMoeda} ${el.valorVendaBhs}${el.unidade ? ' | ' + el.unidade : ''}`,
            currencyName: el.moeda,
            currencySymbol: el.simboloMoeda,
            amount: el.valorVendaBhs,
            unitId: el.unidadeId,
            unit: el.unidade
          };
        })
      });
    });
};
