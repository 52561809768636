import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
    padding: 0 4px;
    text-align:center;
    text-justify:center;
    display: flex;
    align-items:center;
    span{
        display: inherit;
        align-items: inherit;
        img{
            height: 30px;
        }
    }
`;