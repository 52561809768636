import React, { useState } from 'react';
import { Container, Header, ContentModal, Action, Confirmation, ImageIcon, ImageContainer } from './styles';
import Modal from '../../../../components/modal/modal';
import { NotificationManager } from 'react-notifications';
import { getFormId } from '../Uteis';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import { isTenantNCE } from 'EstruturaAntiga/router/routerHelpers';
import { isTenantLegacy } from './../../../../router/routerHelpers';
import LoadingShadow from 'EstruturaAntiga/components/loadingShadow';
import RefusedIcon from '../../../../../assets/icons/refused.svg';
import UsuariosService from 'services/UsuariosService';

export default function DeleteUser( {userId, onCloseModal} ){
    const [modalControl, setModalControl] = useState(1);
    const [response, setResponse] = useState([]);
    const [error, setError] = useState(false);

    const onSubmitDeleteUser = async() => {
        if(userId){
            setModalControl(3);

            const signature = JSON.parse(sessionStorage.getItem("signature"));
            const userData = {
                UsuarioId: userId,
                TenantId: signature.axtTenantId,
                DireitoId: signature.entitlementId
            }

            await UsuariosService.ExcluirUsuario(userData)
                .then((response) => {
                    setError(false);
                    setResponse([response]);
                })
                .catch((error) => {
                    setError(true);
                    setResponse(error.response?.data?.erros ?? ['Erro ao tentar excluir o usuário.']);
                })
                .finally(() => {
                    setModalControl(2);
                });
        } else {
            onCloseModal();
            NotificationManager.error('Erro ao tentar excluir o usuário. Favor entrar em contato com o nosso atendimento.', 'Excluir Usuário.', 5000);
        }

    }

    const renderModal = () => {
        switch(modalControl){
            case 1:
                return renderModalDelete();
            case 2:
                return renderModalConfirmation();
            case 3:
                return renderModalWaiting();
        }
    }

    const renderModalDelete = () => {
        return (
            <Container>
                {isTenantNCE() && 
                    <Header>
                        <div>
                            <h1>Atenção!</h1>
                        </div>
                    </Header>
                }
                <ContentModal>
                    <div>
                        <br />
                        <br />
                        { getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP &&
                            <p>
                                Você está excluindo a conta de e-mail, o acesso à plataforma "Taylor" será bloqueado.
                                <br />
                            </p>
                        }
                        {isTenantLegacy() && 
                            <div>
                                <div className="text-modal"><span>Deseja realmente excluir o usuário?</span></div>
                            </div>                        
                        }
                        {isTenantNCE() && 
                            <>
                                <p>
                                    A exclusão do usuário será processada, entretanto, a assinatura vinculada ao usuário continuará disponível no ambiente para uso.<br />
                                <br />
                                </p>
                                <p>
                                    Qualquer dúvida, nos contate através dos canais abaixo.<br />
                                <a href="mailto:atendimento@bhs.com.br" target="_blank" rel="noopener noreferrer">atendimento@bhs.com.br</a> <br />
                                <a href="tel:4000-1744" target="_blank" rel="noopener noreferrer">4000-1744 – opção 1</a>
                                </p>
                            </>
                        }
                    </div>
                </ContentModal>
                <Action className='action-delete'>
                    <button 
                        className='btn'
                        onClick={() => onCloseModal()}
                        >
                            Cancelar
                    </button>
                    
                    <button
                        onClick={() => onSubmitDeleteUser()}
                        type="button"
                        className='btn btn-danger'
                    >
                        Excluir usuário
                    </button>
                </Action>
                
            </Container>
        );
    }

    const renderModalConfirmation = () => {
        return (
            <Confirmation>
                <ContentModal>
                <div className="content-modal-excluir">
                            {!error ? 
                            <div className="animate-success-icon">
                                <div className="success-circular-line-left"></div>
                                <span className="success-line-tip"></span>
                                <span className="success-line-long"></span>
                                <div className="success-ring"></div>
                                <div className="success-fix"></div>
                                <div className="success-circular-line-right"></div>
                            </div> 
                            : 
                            <ImageContainer> <ImageIcon src={RefusedIcon} /></ImageContainer>
                            }
                            <div className="text-modal">
                                {response.map(x => 
                                    {return(
                                        <p>{x}</p>
                                    )})
                                }
                                <br/>
                                <p>
                                Qualquer dúvida, nos contate através dos canais abaixo.<br />
                                </p>
                                <p>
                                    <a href="mailto:atendimento@bhs.com.br" target="_blank" rel="noopener noreferrer">atendimento@bhs.com.br</a> <br /> 
                                </p>
                                <p>
                                    <a href="tel:4000-1744" target="_blank" rel="noopener noreferrer">4000-1744 – opção 1</a>
                                </p>
                            </div>
                        </div>
                </ContentModal>
                <Action className='action-confirmation'>
                    <button
                        onClick={() => onCloseModal(true)}
                        type="button"
                        className='btn btn-default'
                    >
                        Fechar
                    </button>
                </Action>
                
            </Confirmation>
        );
    }

    const renderModalWaiting = () => {
        return (
            <LoadingShadow 
                sizeClassName={''}
                open={true} 
                close={() => {}}
            />
        );
    }

    return (
        <Modal 
            sizeClassName={'modal-content-lg'}
            open={true} 
            close={modalControl == 1 ? ()=>onCloseModal() : ()=>onCloseModal(true)}
        >
            {renderModal()}
        </Modal>
    )
} 