import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"

export default async function putTermoAceite(id, termoAceite){
    let url = `${urlBase.urlBase}/portaldb/TermoAceite/${id}`;

    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'PUT', headers: _header, body: JSON.stringify(termoAceite)};

    return fetch(url, params);
}
