import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    padding-top: 20px;

    display: flex;
    flex-direction: row;
    justify-content: ${props => { return props.hideBackButton && props.hideChangePasswordButton ? 'flex-end' : 'space-between'; }};

    .backButton{
        
    }

    .nextButton{

    }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
`;
