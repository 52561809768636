import React, { Component } from 'react'
import MasterPage from "../../masterpages/errorPage"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { errorPage } from '../../router/redux/routerActions'

class Error401 extends Component {
    componentWillUnmount(){
        this.props.errorPage(null)
    }

    render() {
        return (
            <MasterPage classContainer="error-403" error="403" title="Acesso negado" text="Você não possui permissão para acessar esta página">
                <Link to='/' className="btn-error"><i className="icon-arrow-left"></i>Voltar ao início</Link>
            </MasterPage>
        );
    };
}

const mapDispatchToProps = dispatch => (bindActionCreators({ errorPage }, dispatch))
export default connect(null, mapDispatchToProps)(Error401);