import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

class ListItemsChamado extends Component {

    getStatusClass(status){
        switch (status) {
            case "Aguardando Atendimento":
                return 'aguardando-atendimento'
            case "Em Atendimento":
                return 'em-atendimento'
            case "Aguardando Cliente":
                return 'aguardando-cliente'
            case "Cancelado":
                return 'cancelado'
            case "Mesclado":
                return 'mesclado'
            case "Suspenso":
                return 'suspenso'
            case "Agendado":
                return 'agendado'
            case "Resolvido":
                return 'resolvido'
            case "Informacoes Fornecidas":
                return 'informacoes-fornecidas'
            case "Aceito Pelo Cliente":
                return 'aceito-pelo-cliente'
            default:
                return '';
        }
    }

    convertDate(date){
        return new Date(date).toLocaleString().replace(/:\d{2}$/, '')
    }

    onClickChamado(id){
        this.props.history.push(`/chamados/editar?id=${id}`);
    }

    render() {
        let renderAtendente = name => (name ? <span className="atendente"><i className="icon icon-headset"></i>{ name }</span> : '')
        let renderUser = name => (name ? <span className="user-owner"><i className="icon icon-user"></i>{ name }</span> : '')
        let renderTermino = date => (date ? <span className="date-container">Término <span className="date">{ this.convertDate(date) }</span></span> : '');

        return this.props.listData.map((el) => {
            return(
                <div key={ el.occurrenceId } onClick={ () => this.onClickChamado(el.occurrenceId) } className={`list-box ${ this.getStatusClass(el.status) }`}>
                    <div className="box-text">
                        <div className="header">
                            <span className="id-type">{ el.numeroOcorrencia } - { el.nomeLinhaServico } / { el.nomeCliente }</span>
                            <span className="status">{ el.status }</span>
                            { renderAtendente(el.nomeAbreviadoAtendente) }
                        </div>
                        <span className="title">{ el.assunto }</span>
                        <div className="info">
                            { renderUser(el.nomeSolicitante) }
                            <span className="date-container">Início <span className="date">{ this.convertDate(el.dataAbertura) }</span></span>
                            { renderTermino(el.dataFechamento) }
                        </div>
                    </div>
                </div>
            )
        });
    };
}

export default withRouter(ListItemsChamado);