import React, { Component } from 'react';
import getTiposProduto from 'EstruturaAntiga/actions/microsoftCSP/getTiposProduto';

import Items from './Items';

export default class components extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiposProduto: {},
      renderComprar: false,
      renderRateio: false,
      filteredLicenses: [],
      filtering: false,
      filterText: ''
    }
  }

  componentDidMount() {
    const { licenses, filter } = this.props;

    getTiposProduto().then(response => {
      this.setState({ tiposProduto: response});
    });

    this.setState({
      filteredLicenses: licenses,
      filterText: filter,
      filtering: filter !== '' ? true : false
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      const { licenses, filter } = this.props;
      
      const filterLicenses = licenses.length > 0 ? licenses.filter(license => license.axt_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) : [];
        
      this.setState({
        filteredLicenses: filterLicenses,
        filterText: filter,
        filtering: filter !== '' ? true : false
      });
    }
  }

  render() {
    const { tiposProduto, filteredLicenses, filtering } = this.state;
    const { 
      licenses,
      savedLicenses, 
      loading, 
      onChangeQuantity, 
      rateio,
      isAddons,
      addAddons,
      calcularTotal,
      companies,
      isCheckout,
      contractMasterId,
      handleCurrencyFormat,
      renderModalAlert,
      renderAlert,
      onChangeApportionment
    } = this.props;

    if (loading) {
      return (
        <div className="small-loading">
          <div className="simple-loading"></div>
        </div>
      );
    } else if (filtering) {
      return (
        <div>
        {
          filteredLicenses.length > 0 ? filteredLicenses.map(license => (
            <Items 
              tiposProduto={tiposProduto}
              key={license.axt_subscriptioncspid ? license.axt_subscriptioncspid : license._axt_productid_value} 
              license={license}
              savedLicense={savedLicenses?.find(savedLicense => savedLicense?.axt_subscriptioncspid === license?.axt_subscriptioncspid)}
              onChangeQuantity={onChangeQuantity}
              isAddons={isAddons}
              readOnly={license.statuscode === 282390001 || license.axt_unitpricesale === undefined || license.axt_unitpricesale === 0 || !!isCheckout ? true : false}
              rateio={rateio}
              companies={companies}
              calcularTotal={calcularTotal}
              addAddons={addAddons}
              contractMasterId={contractMasterId}
              handleCurrencyFormat={handleCurrencyFormat}
              renderModalAlert={renderModalAlert}
              renderAlert={renderAlert}
              onChangeApportionment={onChangeApportionment}
            />
          )) : (
            <span className="sem-assinatura">Nenhuma assinatura encontrada.</span>
          )
        }
        </div>
      );
    } else {
      return (
        <div>
          {
            licenses.length > 0 ? licenses.map(license => (
              <Items 
                tiposProduto={tiposProduto}
                key={license.axt_subscriptioncspid ? license.axt_subscriptioncspid : license._axt_productid_value} 
                license={license}
                savedLicense={savedLicenses?.find(savedLicense => savedLicense?.axt_subscriptioncspid === license?.axt_subscriptioncspid)}
                onChangeQuantity={onChangeQuantity}
                isAddons={isAddons}
                addAddons={addAddons}
                readOnly={license.statuscode === 282390001 || license.axt_unitpricesale === undefined || license.axt_unitpricesale === 0 || !!isCheckout ? true : false}
                rateio={rateio}
                companies={companies}
                calcularTotal={calcularTotal}
                isCheckout={isCheckout}
                contractMasterId={contractMasterId}
                handleCurrencyFormat={handleCurrencyFormat}
                renderModalAlert={renderModalAlert}
                renderAlert={renderAlert}
                onChangeApportionment={onChangeApportionment}
              />
            )) : (
              <span className="sem-assinatura">Você não possui nenhuma assinatura.</span>
            )
          }
        </div>
      );
    }    
  }
}
