import React, { Component } from 'react';
import Modal from '../../components/modal/modal';

export default class ModalCancelarContrato extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.openModal} close={this.props.onCloseModal} sizeClassName={'modal-content-lg'}>
                    <div>
                        <div className="text-modal-justify">
                            <span>O cancelamento do contrato desabilita todas as assinaturas do contrato e os usuários não poderão acessar os serviços e arquivos.</span>
                            <br />
                            <span>O cancelamento das assinaturas não poderá ser revertido.</span>
                            <br />
                            <br />
                            <span>Deseja continuar?</span>
                        </div>

                        <div className="btn-modal">
                            <button type="button" onClick={() => this.props.onCloseModal()} className="btn btn-danger">Não</button>
                            <button type="button" onClick={() => this.props.onConfirmCancelarContrato()} className="btn btn-default">Sim</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };
}