import React, { Component } from 'react';

export default class CheckBox extends Component {

    isDisabled(bool) {
        return bool ? 'disabled' : '';
    }

    render() {
        let _props = this.props.configureInput ? this.props.configureInput.props : {};

        return (
            <label htmlFor={this.props.id} className="checkbox">
                <input
                    className="check"
                    disabled={this.props.disabled || this.props.readOnly}
                    type="checkbox"
                    id={this.props.id}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    checked={this.props.checked}
                    {..._props} />
                <div className="custom-check"></div>
                {this.props.label}
            </label>
        );
    };
}