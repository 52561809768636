import ContatosService from "services/ContatosService";
import ContratoFinanceiroService from "services/ContratoFinanceiroService";
import RecursosReservaveisService from "services/RecursosReservaveisService";
import TenantsService from "services/TenantsService";

const prefixosCamposAceitosPeloPortal = [
    "dataCustomizada",
    "atributoEstendido",
    "atributoEstendidoContato",
    "atributoEstendidoRecurso",
    "recursoReservavel",
    "contratoFinanceiro",
    "tenantId",
]


export const GerarInputsPreRequisitos = (data,optionsConfig = null) => {
    const pattern = /([a-zA-Z_]+)(\d*)(id)*/;
    let retorno = [];
    data.forEach(item=>{
        const nomeContrato = item[0];
        const match = pattern.exec(nomeContrato);
        const identifier = match ? match[1] : null;
        const number = match && match[2] ? match[2] : null;
        let type = "";
        let customRules = "";
        let inputOptions = null;
        switch (identifier) {
            case "dataCustomizada":
                type = "date"
                break;
            case "atributoEstendido":
                type = "text"
                customRules = number == 1 ? [{max: 200, message:"Campo deve conter no máximo 200 caracteres"}] : [{max: 100, message:"Campo deve conter no máximo 100 caracteres"}]
                break;
            case "atributoEstendidoContato":
                type = "selectSearch";
                inputOptions = (optionsConfig || null)?.contato || null
                break;
            case "atributoEstendidoRecurso":
            case "recursoReservavelId":
                type = "selectSearch";
                inputOptions = (optionsConfig || null)?.recursosReservaveis || null
                break;
            case "contratoFinanceiroId":
                type = "selectSearch";
                inputOptions = (optionsConfig || null)?.contratosFinanceiros || null
                break;
            case "tenantId":
                type = "selectSearch";
                inputOptions = (optionsConfig || null)?.tenants || null
                break;
            default:
                break;
        }
        if(type != ""){
            let newInput = {
                input:{
                    name: nomeContrato,
                    label: item[1].rotulo,
                    rules: [...customRules, {required: item[1].obrigatorio, message:"Campo obrigatorio"}],
                    type: type,
                    options: inputOptions
                }
            };
            retorno.push(newInput)
        }
    })
    return retorno;
}
export const CampoEhAceitoPeloPortal = (campoNome) =>{
    return prefixosCamposAceitosPeloPortal.some(x=>campoNome.includes(x))
}

export const ExtrairPreRequisitosVisiveis = (preRequisitos) => {
    var preRequisitosVisiveis = Object.entries(preRequisitos).filter(x=>
        x[1] != null && x[1].visivel == true && CampoEhAceitoPeloPortal(x[0])
    )
    return preRequisitosVisiveis;
}
export const criarContatosCallback = (direitoid, preRequisitosVisiveis) => {
    const incluiContato = preRequisitosVisiveis.some(x => x[0].includes("atributoEstendidoContato"));
    return incluiContato ? ContatosService.ListarPorDireito(direitoid) : null;
};
  
export const criarRecursosCallback = (direitoid, preRequisitosVisiveis) => {
    const incluiRecursos = preRequisitosVisiveis.some(x => x[0].includes("atributoEstendidoRecurso")) || preRequisitosVisiveis.some(x => x[0].includes("recursoReservavel"));
    return incluiRecursos ? RecursosReservaveisService.ListarPorDireito(direitoid) : null;
};

export const criarContratosFinanceirosCallback = (direitoid, preRequisitosVisiveis) => {
    const incluiContratosFinanceiros = preRequisitosVisiveis.some(x => x[0].includes("contratoFinanceiro"));
    return incluiContratosFinanceiros ? ContratoFinanceiroService.ListarPorDireito(direitoid) : null;
};

export const criarTenantsCallback = (direitoid, preRequisitosVisiveis) => {
    const incluiTenants = preRequisitosVisiveis.some(x => x[0].includes("tenantId"));
    return incluiTenants ? TenantsService.ListarPorDireito(direitoid) : null;
};