import { Col, DatePicker, Flex, Row } from "antd";
import { StyledForm } from "../styles";
import FormInputDivider from "./FormInputDivider";
import DateUtils from "utils/date";

function PortalDatePeriodPicker({input, divider, form, dateperiod,...props}){
    const dateFormat = 'DD/MM/YYYY';
    return(
        <FormInputDivider key={dateperiod.start.name} {...divider}>
            <Row>
                <Col span={10}>
                    <StyledForm.Item 
                        {...dateperiod.start} 
                        extra={props.helpMessage} 
                        dependencies={[dateperiod.end.name]} 
                        rules={[
                            ...(dateperiod?.start?.rules || []),
                            ({ getFieldValue,isFieldTouched })=> ({
                                validator(_, startDateValue) {
                                    let endDateValue = getFieldValue(dateperiod.end.name) ?? null;
                                    // let endDateIsTouched = isFieldTouched(dateperiod.end.name); // TODO: usar esse campo para retornar um erro apenas depois que o usuario já tocou no input mas nao preencheu
                                    if(startDateValue == null){
                                        return Promise.resolve();
                                    }
                                    let startDate = new Date(startDateValue);
                                    
                                    if(!dateperiod.rules.allowNullDate){
                                        if((endDateValue != null) !== (startDateValue != null)){ //XOR
                                            return Promise.reject(`Preencha ${dateperiod.start.label} e ${dateperiod.end.label}!`);
                                        }
                                    }

                                    if(endDateValue != null){
                                        let endDate = new Date(endDateValue);
                                        if(startDate > endDate){
                                            return Promise.reject(`Data ${dateperiod.start.label} deve ser menor que a ${dateperiod.end.label}!`);
                                        }
                                    }
                                    
                                    if(dateperiod.rules.minDate){
                                        let minDate = new Date(dateperiod.rules.minDate)
                                        if(startDate < minDate){
                                            return Promise.reject(`Data deve ser maior que ${DateUtils.DateFormat(minDate)}`);
                                        }
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}>
                        <DatePicker 
                            disabled={dateperiod.start?.disabled} 
                            format={dateFormat} 
                            placeholder={dateperiod.start?.disabled ? "" : "Selecione" } />
                    </StyledForm.Item>
                </Col>
                <Col span={4}>
                    <Flex align="center">
                        <span>Até</span>
                    </Flex>
                </Col>
                <Col span={10}>
                    <StyledForm.Item 
                        {...dateperiod.end} 
                        extra={props.helpMessage} 
                        dependencies={[dateperiod.start.name]} 
                        rules={[ 
                            ...(dateperiod?.end?.rules || []),
                            ({ getFieldValue, isFieldTouched })=> ({
                                validator(_, endDateValue) {
                                    let startDateValue = getFieldValue(dateperiod.start.name) ?? null;
                                    // let dataInicioTouched = isFieldTouched(dateperiod.start.name);
                                    if(endDateValue == null){
                                        return Promise.resolve();
                                    }
                                    let endDate = new Date(endDateValue);
                                    if(!dateperiod.rules.allowNullDate){
                                        if((startDateValue != null) !== (endDateValue != null)){ //XOR
                                            return Promise.reject('Preencha inicio e fim!');
                                        }
                                    }

                                    if(startDateValue != null){
                                        let startDate = new Date(startDateValue);
                                        if(startDate > endDate){
                                            return Promise.reject(`Data ${dateperiod.end.label} deve ser maior que a ${dateperiod.start.label}!`);
                                        }
                                    }

                                    if(dateperiod.rules.maxDate){
                                        let maxDate = new Date(dateperiod.rules.maxDate)
                                        if(endDate > maxDate){
                                            return Promise.reject(`Data deve ser menor que ${DateUtils.DateFormat(maxDate)}`);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })
                    ]}>
                    <DatePicker 
                        disabled={dateperiod.start?.disabled} 
                        format={dateFormat} 
                        placeholder={dateperiod.start?.disabled ? "": "Selecione" } />
                    </StyledForm.Item>
                </Col>

            </Row>
        </FormInputDivider>
    )
}

export default PortalDatePeriodPicker;
