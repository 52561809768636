const _defaultState = {
    isEditable: true,
    listaChamados: [],
    listaChamadoLoading: true,
    listaChamadoEmpty: false,
    listaStatus: [],
    listaStatusLoading: true,
    listaServicoCliente: [],
    listaServicoClienteLoading: true,
    listaTipoSolicitacao: [],
    listaTipoSolicitacaoLoading: true,
    listaUrgencia: [],
    listaUrgenciaLoading: true,
    listaImpacto: [],
    listaImpactoLoading: true,
    detalhesChamado: {},
    loadingDetalhesChamado: true,
    hasMorePages: true,
    pageSize: 15,
    pageNumber: 1,
    orderBy: 'dataabertura',
    sortDir: 'desc',
    StateCodeFiltroStatus: '',
    assunto: '',
    solicitante: '',
    dataAbertura: '',
    dataFechamento: '',
    Department: '',
    NeedContact: true,
    StateCodeFiltroExibicao: 0,
    FilesUploaded: [],
    PathName: '',
    FileInStorage: {},
    FileDeleted: '',
    AnnotationsList: [],
    LoadingAnnotations: true,
}

export default (state = _defaultState, action) => {
    if(action.type==='CHAMADOS_CLEAR' || state.isEditable){
        switch (action.type){
            case 'CHAMADOS_LISTAGEM':
                return { 
                    ...state, 
                    listaChamados: [...state.listaChamados, ...action.payload], 
                    listaChamadoLoading: false, 
                    listaChamadoEmpty: [...state.listaChamados, ...action.payload].length === 0, 
                    hasMorePages: action.payload.length === state.pageSize, 
                    pageNumber: state.pageNumber + 1
                }
            case 'CHAMADOS_START_REQUEST':
                return { ...state, listaChamadoLoading: true }
            case 'CHAMDOS_CLEAR_LIST':
                return { ...state, listaChamados: [], listaChamadoEmpty: false, hasMorePages: true, pageNumber: 1 }
            case 'CHAMADOS_CHANGE_ORDER':
                return { ...state, orderBy: action.payload.orderBy, sortDir: action.payload.sortDir }
            case 'CHAMADOS_CHANGE_FILTER':
                return { ...state, 
                            StateCodeFiltroStatus: action.payload.StateCodeFiltroStatus, 
                            assunto: action.payload.Assunto, 
                            solicitante: action.payload.Solicitante, 
                            dataAbertura: action.payload.DataAbertura, 
                            dataFechamento: action.payload.DataFechamento,
                            StateCodeFiltroExibicao: action.payload.StateCodeFiltroExibicao,
                            NeedContact: action.payload.NeedContact }
            case 'CHAMADOS_CHANGE_MAIN_FILTER':
                    return {...state,  Department: action.payload.Department, NeedContact: action.payload.NeedContact, StateCodeFiltroExibicao: action.payload.StateCodeFiltroExibicao }
            case 'CHAMADOS_STATUS':
                return { ...state, listaStatus: action.payload, listaStatusLoading: false }
            case 'CHAMADOS_SERVICO_CLIENTE':
                return { ...state, listaServicoCliente: action.payload, listaServicoClienteLoading: false }
            case 'CHAMADOS_TIPO_SOLICITACAO':
                return { ...state, listaTipoSolicitacao: action.payload, listaTipoSolicitacaoLoading: false }
            case 'CHAMADOS_URGENCIA':
                return { ...state, listaUrgencia: action.payload, listaUrgenciaLoading: false }
            case 'CHAMADOS_IMPACTO':
                return { ...state, listaImpacto: action.payload, listaImpactoLoading: false }
            case 'CHAMADOS_DETALHES':
                return { ...state, detalhesChamado: action.payload, loadingDetalhesChamado: false }
            case 'CHAMADOS_ANNOTATIONS':
                return { ...state, AnnotationsList: action.payload, LoadingAnnotations: false }
            case 'CHAMADOS_CLEAR':
                return { ..._defaultState, isEditable: action.payload }
            case 'FILE_UPLOAD':
                return { ...state, FilesUploaded: [...state.FilesUploaded, ...action.payload], }
            case 'FILES_REMOVING':
                return { ...state, FilesUploaded: action.payload }
            case 'DELETE_FILE':
                return { ...state, FileDeleted: action.payload }
            case 'POST_FILES':
                return {...state,  FilesUploaded: state.FilesUploaded.map(file => {
                    return action.payload.id === file.id ? {...file, ...action.payload } : file;
                })}
            case 'GENERATE_PATH_NAME':
                return { ...state, PathName: action.payload }
            default:
                return state
        }
    }

    return state;
}