import React, { Component } from 'react'
import uuid from "EstruturaAntiga/assets/uuid";

export default class SelectGroup extends Component {

    classError = () => {
        if ((this.props.configureInput.showerror === "true" || this.props.configureInput.showCurrentError === "true") &&
            this.props.configureInput.error) {
            return 'form-error';
        }

        return '';
    }

    error = () => {
        if ((this.props.configureInput.showerror === "true" || this.props.configureInput.showCurrentError === "true") &&
            this.props.configureInput.error) {
            return <span className="error">{this.props.configureInput.error}</span>
        }

        return null;
    }

    getContent(){
        if(this.props.items){
            return this.props.items.map((el) => (
                <option key={uuid()} value={!!el.value || el.value === 0 ? el.value : ''}>{el.label}</option>
            ));
        }
    }

    componentWillMount(){
        this.setState({ configureInput: this.props.configureInput ? {...this.props.configureInput.props} : {} })
    }

    render() {
        return (
            <div className={`form-group ${this.classError()}`}>
                <label htmlFor={ this.props.id }>{ this.props.label }</label>
                <div className="input-group-container">
                    <select className="input-group" disabled={ this.props.disabled || this.props.readOnly } {...this.props.configureInput.props} onLoad={this.props.onLoad}>
                        { this.props.children }
                        { this.getContent() }
                    </select>
                    <div className={`simple-loading ${(!this.props.loading) ? 'd-none' : ''}`}></div>
                </div>
                {this.error()}
            </div>
        );
    };
}
