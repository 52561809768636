import React, { Component } from 'react';

export default class CustomQuantity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    this.setState({
      value: defaultValue,
    });
  }

  render() {
    const { customClass, defaultValue, onChangeFunction, userLicense, moreValues, readOnly, onBlur } = this.props;
    
    return (
      <input 
        type="text" 
        className={customClass} 
        value={defaultValue}
        min="0"
        onChange={e => onChangeFunction(e.target.value, userLicense, readOnly, moreValues)}
        readOnly={readOnly}
        onBlur={onBlur}
      />
    );
  }
}