import React, { Component } from 'react';
import Modal from '../../../../components/modal/modal';

export default class ModalAdvisorXpBlue3CannotBeDisabled extends Component {
    render() {
        return (
            <Modal open={true} close={this.props.onCloseModal} sizeClassName={'modal-content-lg'}>
                <div>
                    <div className="text-modal-justify">
                        <span>Prezado(a),</span>
                        <br />
                        <br />
                        <span>Para que sua solicitação possa ser atendida, o gestor do escritório deve abrir um chamado no <b>Taylor</b> para bloquear os acessos do assessor.</span>
                        <br />
                        <br />
                        <span>Segue o link para a abertura do chamado: </span>
                        <span><a href="https://xpinvestimentos.service-now.com/sp_assessor?id=sc_cat_item&sys_id=0ccd36d3dbb74c94ff1a9d40ba9619a1" target="_blank">Taylor</a></span>
                        <br />
                        <br />
                        <span>Obs.: Na abertura, selecione as duas opções "Bloqueio de acesso ou perfil" e “Bloqueio acesso ao pedido do gestor”.</span>
                        <br />
                        <br />
                        <span>Após o encerramento do chamado  no Taylor o gestor deve aguardar 2 horas, então acessar o Portal da BHS e excluir a conta de e-mail.</span>
                    </div>

                    <div className="btn-modal">
                        <button type="button" onClick={() => this.props.onCloseModal()} className="btn btn-default">Ok, entendi.</button>
                    </div>
                </div>
            </Modal>
        );
    };
}