import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function getUsuario(id) {
  const signature = HttpRequest.userSignature();

  let url = `${urlBase.urlBase}/graph/User/${id}/beta?tenantId=${signature.tenantCode}`;
  let _header = await HttpRequest.apiTokenHeader();
  let params = { method: "GET", headers: _header };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => {
      return result;
    });
}