import React from 'react';

import AcceptedIcon from '../../../assets/icons/accepted.svg';
import RefusedIcon from '../../../assets/icons/refused.svg';

import {
    TimelineStyle,
    TimelineItemStyle,
  } from './styles';

export default function Timeline({timelinePosition = 0, accepted = true, timelineLabelItems = ['']})
{
    return (
            <TimelineStyle positionsQuantity={timelineLabelItems.length} timelinePosition={timelinePosition} accepted={accepted} position={timelinePosition - 1}>
                <div className="timelineWrapper">
                    {timelineLabelItems && timelineLabelItems.map((timelineLabelItem, index) => {
                        return (
                            <TimelineItemStyle key={index} timelinePosition={timelinePosition} accepted={accepted} position={index}>
                                <div>
                                    {timelinePosition < index+1 && index+1}
                                    {timelinePosition > index && !!accepted && <img src={AcceptedIcon} alt="accepted" />}
                                    {timelinePosition > index && !accepted && <img src={RefusedIcon} alt="rejected" />}
                                </div>
                                <p>{timelineLabelItem || ''}</p>
                            </TimelineItemStyle>
                        )
                    })}
                </div>
            </TimelineStyle>
    );
}
