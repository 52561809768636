import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: 'common',
    clientId: '43fe22cc-b869-4e9f-bf97-eb019915a85c',
    endpoints: {
        api: '43fe22cc-b869-4e9f-bf97-eb019915a85c',
    },
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin + '/dashboard'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
