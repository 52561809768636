import React from 'react'
import MasterPage from '../../../masterpages/authenticated'
import ContentBox from "../../../../components/ContentBox";
import UsuariosService from 'services/UsuariosService';
import EmailsService from 'services/EmailsService';
import Form from "../../../assets/formValidate"
import Input from "../../../components/form/InputGroup";
import Button from "../../../components/form/Button";
import Mask from "utils/masks"
import { NotificationManager } from 'react-notifications';
import { withRouter } from "react-router-dom";
import { authContext } from '../../../adalConfig';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { rdxGetUserEditarMeusDados, rdxClearDados } from "./redux/editarMeusDadosActions";
import getUsuarioStatusCode from 'EstruturaAntiga/actions/usuarios/getUsuarioStatusCode';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';

import {
	FormEditarMeusDados as FormEditarMeusDados,
	StatusUsuario
} from './styles';

class EditarMeusDados extends Form {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			response: {},
			usuarioStatusCode: {},
			sendingForm: false,
			loadingForm: true,
			searchAlternateEmail: false,
			formSomenteLeitura: true,
			statusCorrente: {},
			contemErroNaPagina: false,
			formContent: {
				axt_displayname365: {
					nome: "Nome de exibição",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: 120,
				},
				emailaddress2: {
					nome: "E-mail alternativo",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: null,
				},
				telephone1: {
					nome: "Telefone Comercial",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: 50,
				},
				mobilephone: {
					nome: "Celular",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: 50,
				}
			},
			form: {
				axt_displayname365: '',
				emailaddress2: '',
				telephone1: '',
				mobilephone: ''
			},
			hasError: {
				axt_displayname365: {},
				emailaddress2: {},
				telephone1: {},
				mobilephone: {}
			},
			validations: {
				axt_displayname365: { maxLength: 120, required: true },
				emailaddress2: { mask: 'email', required: true },
				telephone1: { mask: 'phone', required: true },
				mobilephone: { mask: 'phone', required: true }
			}
		};

		this.onBlurInput = this.onBlurInput.bind(this);
	}

	componentDidMount() {
		this.props.rdxClearDados(true);
		this.props.rdxGetUserEditarMeusDados();
	}

	componentWillUnmount() {
		this.props.rdxClearDados(false);
	}

	componentWillReceiveProps(nextProps) {
		let newObj = {
			axt_displayname365: nextProps.axt_displayname365,
			emailaddress2: nextProps.emailaddress2 || '',
			telephone1: nextProps.telephone1 || '',
			mobilephone: nextProps.mobilephone || ''
		};

		this.setState({ form: newObj })
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.id !== prevProps.id) {
			this.setState({ loadingForm: true });
			this.buscarStatusCodeUsuario();
		}
	}

	async buscarStatusCodeUsuario() {
		const usuarioStatusCode = await getUsuarioStatusCode(this.props.id);
		const statusCorrente = usuarioStatusCode.statuscode ? CONSTANTES_AMBIENTE.ARRAY_STATUS_USUARIOS.filter(theStatus => theStatus.code == usuarioStatusCode.statuscode)[0] : {};
		const formSomenteLeitura = Number(usuarioStatusCode?.statuscode) == CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO || Number(usuarioStatusCode?.statuscode == CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO) ? false : true;
		this.setState({ usuarioStatusCode, statusCorrente, formSomenteLeitura, loadingForm: false });
	}

	async onChangeAlternativePhone(event) {
		event.target.value = Mask(event.target.value, 'phone');
	}

	async limparValidacoesInput(inputName) {
		await this.setState({
			validations: {
				...this.state.validations,
				[inputName]: {
					...this.state.validations[inputName],
					hasError: false,
					showErrorCurrentItem: true,
					errorMensage: null
				}
			}
		})
	}

	async onBlurInput(event) {
		const { formSomenteLeitura } = this.state;

		if (formSomenteLeitura) {
			return;
		}

		event.persist();
		await this.limparValidacoesInput(event.target.name);

		switch (event.target.name) {
			case "emailaddress2":
				if (this.validateInput(event)) {
					this.setState({
						searchAlternateEmail: true
					});

					await EmailsService.ValidarEmailAlternativo(`${event.target.value}`)
						.then((response) => {
							this.setState({
								searchAlternateEmail: false,
								response: {
									resultcode: 1,
									message: response
								}
							});
						})
						.catch((error) => {
							this.setState({
								response: { response: error.response?.data?.erros[0] ?? 'Estamos passando por instabilidade eventual, tente novamente daqui alguns minutos. Caso o erro persista, entre em contato com nossa central de atendimento pelo telefone 4000-1744 - opção 1 ou e-mail atendimento@bhs.com.br' },
								searchAlternateEmail: false,
								contemErroNaPagina: true,
								validations: {
									...this.state.validations,
									emailaddress2: {
										...this.state.validations.emailaddress2,
										hasError: true,
										errorMensage: error.response?.data?.erros[0]
									}
								}
							}, () => { this.validateInput(event) });
						})
				}
				break;

			case "axt_displayname365":
			case "telephone1":
			case "mobilephone":
				if (this.validateInput(event)) {
					this.setState({ showFormErrors: false });
				} else {
					this.setState({
						validations: {
							...this.state.validations,
							[event.target.name]: {
								...this.state.validations[event.target.name],
								hasError: true
							}
						}
					});
				}
				break;

			default:
				break;
		}

		if (this.state.validations.axt_displayname365.hasError ||
			this.state.validations.emailaddress2.hasError ||
			this.state.validations.telephone1.hasError ||
			this.state.validations.mobilephone.hasError) {
			this.setState({ contemErroNaPagina: true });
		}
		else
			this.setState({ contemErroNaPagina: false });
	}

	renderField(obj, id, cols, customProps) {
		if (obj.visible || this.state.teste) {
			let configInput = [id];

			if (obj.mask) {
				configInput = [
					...configInput,
					(el) => {
						el.target.value = Mask(el.target.value, obj.mask);
					},
				];
			}

			let props = {
				id: id,
				label: obj.nome + (obj.obrigatorio ? "*" : ""),
				readOnly: obj.somenteLeitura,
				configureInput: this.configureInput(...configInput),
				items: obj?.lista?.length
					? [
						{ valor: "", label: "Selecione" },
						...obj.lista.map((el) => {
							return { value: el.valor, label: el.textoExibicao };
						}),
					]
					: [],
				...customProps,
			};

			if (obj.tamanho) {
				props.maxLength = obj.tamanho;
			}

			if (obj?.lista?.length) {
				return (
					<div className={cols}>
						<Select {...props} />
					</div>
				);
			} else {
				return (
					<div className={cols}>
						<Input {...props} />
					</div>
				);
			}
		}

		return "";
	}

	desativarBotaoEditarMeusDados() {
		const {
			form,
			sendingForm,
			searchAlternateEmail,
			formSomenteLeitura,
			contemErroNaPagina
		} = this.state;

		const {
			userInfoLoading
		} = this.props;

		return this.state.showFormErrors || contemErroNaPagina || !form.axt_displayname365 ||
			!form.emailaddress2 || !form.telephone1 || !form.mobilephone || userInfoLoading ||
			sendingForm || searchAlternateEmail || formSomenteLeitura;
	}

	handleSubmit = async e => {
		this.setState({ showFormErrors: true });
		let isValid = await this.formValido();
		if (isValid) {
			this.setState({ showFormErrors: false });
			this.onSubmit(e);
		}
	};

	onSubmit = () => {
		if (this.state.showFormErrors) return;

		if (!this.state.sendingForm) {
			let sync = JSON.parse(sessionStorage.getItem('contact')).onPremisesSyncEnabled;
			let data = {
				"NomeParaExibicao": this.state.form.axt_displayname365,
				"EmailAlternativo": this.state.form.emailaddress2,
				"TelefoneComercial": this.state.form.telephone1,
				"TelefoneCelular": this.state.form.mobilephone,
				"SincOnPremisesHabilitado": sync
			}

			this.setState({ sendingForm: true });

			UsuariosService.AtualizarMeusDados(JSON.stringify(data)).then(() => {
				NotificationManager.success('Em breve seus dados serão atualizados.', 'Sucesso', 6000);
				return this.props.history.push('/')
			})
				.catch((error) => {
					NotificationManager.error(error.response?.data?.erros[0], 'Não foi possivel alterar seus dados!', 7000);
					this.setState({ sendingForm: false });
				});
		}
	}

	render() {
		const {
			loadingForm,
			statusCorrente,
			searchAlternateEmail,
			formContent,
			formSomenteLeitura
		} = this.state;

		const {
			userInfoLoading
		} = this.props;

		if (loadingForm) {
			return (
				<MasterPage>
					<div className="small-loading">
						<div className="simple-loading"></div>
					</div>
				</MasterPage>
			);
		}
		else if (JSON.parse(sessionStorage.getItem('contact')).onPremisesSyncEnabled) {
			return (
				<MasterPage>
					<h1>Atualizar meus dados</h1>
					<div>
						<h2>Atualizar meus dados não foi configurado para funcionar com usuários sincronizados localmente.</h2>
					</div>
				</MasterPage>
			);
		} else {
			return (
				<MasterPage>
					<form onSubmit={e => { e.preventDefault(); this.handleSubmit() }}>
						<div className="title-with-action">
							<h1>Atualizar meus dados</h1>
						</div>

						<ContentBox className="form-container">
							<div className="row">
								<div className="col-12 col-lg-12">
									<FormEditarMeusDados>
										<StatusUsuario>
											<strong className={`contact-status ${statusCorrente.class}`}>
												{statusCorrente.status}
											</strong>
										</StatusUsuario>
										{this.renderField(formContent.axt_displayname365, "axt_displayname365", null, { readOnly: formSomenteLeitura, onBlur: this.onBlurInput, loading: userInfoLoading })}
										{this.renderField(formContent.emailaddress2, "emailaddress2", null, { readOnly: formSomenteLeitura, onBlur: this.onBlurInput, loading: userInfoLoading || searchAlternateEmail })}
										{this.renderField(formContent.telephone1, "telephone1", null, { readOnly: formSomenteLeitura, onBlur: this.onBlurInput, loading: userInfoLoading, configureInput: this.configureInput('telephone1', (e) => this.onChangeAlternativePhone(e)) })}
										{this.renderField(formContent.mobilephone, "mobilephone", null, { readOnly: formSomenteLeitura, onBlur: this.onBlurInput, loading: userInfoLoading, configureInput: this.configureInput('mobilephone', (e) => this.onChangeAlternativePhone(e)) })}
									</FormEditarMeusDados>
								</div>
							</div>
						</ContentBox>

						<div className="action-container">
							<div className="default-actions">
								<Button className="btn btn-default" disabled={this.desativarBotaoEditarMeusDados()}>Atualizar meus dados</Button>
							</div>
						</div>
					</form>
				</MasterPage>
			);
		}
	};
}

export default withRouter(connect(
	state => ({
		id: state.alterarDados.id,
		axt_displayname365: state.alterarDados.axt_displayname365,
		emailaddress2: state.alterarDados.emailaddress2,
		telephone1: state.alterarDados.telephone1,
		mobilephone: state.alterarDados.mobilephone,
		userInfoLoading: state.alterarDados.userInfoLoading
	}),
	dispatch => (bindActionCreators({
		rdxGetUserEditarMeusDados, rdxClearDados
	}, dispatch))
)(EditarMeusDados));
