import EnviarDadosNavegacao from "EstruturaAntiga/actions/Navegacao/EnviarDadosNavegacao";
import { Button, Drawer, Flex, Space } from "antd";
import React, { createContext, useContext, useState } from "react";
import uuidv1 from 'uuid';

const DrawerContext = createContext();

const footerStyle = {
  width: '100%',
  height: 40
};
export const ConfirmFunctionStatus = {
  Ok: "ok",
  Carregando: "carregando",
  Invalido: "invalido"
}
export const useDrawer = () => {
  return useContext(DrawerContext);
};

export const DrawerProvider = ({children}) => {
  const childRef = React.useRef(null);

  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(false);
  const [childComponent, setChildComponent] = useState(null)
  const [drawerParams, setDrawerParams] = useState({
    title: "Titulo"
  })
  const [formParams, setFormParams] = useState({
    hideSubmitButton: true,
    onFinish: ()=> console.error("Função OnFinish não definida"),
    onFinishFailed: ()=> console.error("Função onFinishFailed não definida"),
  })
  const [statusBotaoConfirmar, setStatusBotaoConfirmar] = useState(ConfirmFunctionStatus.Ok);

  const callConfirmFunction = async () => {
    if (childRef.current) {
      childRef.current.confirmFunction();
    }else{
      console.error("Função 'Confirmar' nao foi sobrescrita")
    }
  };
  const callCancelFunction = () => {
    if (childRef.current) {
      childRef.current.cancelFunction();
    }else{
      console.error("Função 'Cancelar' nao foi sobrescrita")
    }
    closeDrawer();
  };
  const closeDrawer = () =>{
    setOpen(false);
    childRef.current = null;
  }
  function setup(component, newDrawerParams, newFormParams = {}){
    setKey(uuidv1())
    setDrawerParams({...drawerParams, ...newDrawerParams})
    setFormParams({...formParams, ...newFormParams})
    setChildComponent(component);
    setOpen(true);
    if(newDrawerParams.navigationPath){
      EnviarDadosNavegacao(newDrawerParams.navigationPath, true)
    }else{
      console.error("Parametro 'navigationPath' nao foi especificado")
    }
  }
  return (
    <DrawerContext.Provider value={{setup}}>
      {children}
      <Drawer
        width={720} 
        onClose={callCancelFunction} 
        open={open} 
        maskClosable={false} keyboard={false} autoFocus={false} 
        footer={ 
          <Flex justify="flex-end" style={footerStyle}>
            <Space>
              <Button onClick={callCancelFunction}>Cancelar</Button>
              <Button 
                onClick={callConfirmFunction} type="primary" 
                disabled={statusBotaoConfirmar == ConfirmFunctionStatus.Invalido}
                loading={statusBotaoConfirmar == ConfirmFunctionStatus.Carregando}
                >Confirmar</Button>
            </Space>
          </Flex>
        }
        styles={{
          body: {
              paddingBottom: 80,
          },
        }}
        {...drawerParams}
        >
          {
            childComponent != null?
              React.cloneElement(childComponent, {ref: childRef, key: key, setStatusBotaoConfirmar: setStatusBotaoConfirmar, closeDrawer: closeDrawer, ...formParams})
            : null
          }
        </Drawer>
    </DrawerContext.Provider>
  )
}
