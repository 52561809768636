import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import { authContext } from '../../adalConfig';
import urlBase from "../../assets/urlsBase"

export default async function postAceitarTermoAceite(idTermo){
    let url = `${urlBase.urlBase}/portaldb/TermoAceite/Aceitar`;

    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'POST', headers: _header, body: JSON.stringify({
        "idContato": JSON.parse(window.sessionStorage.getItem('contact')).idContact,
        "idTermoAceite": idTermo,
        "device": navigator.userAgent,
        "ipAddress": authContext.getCachedUser(authContext.config.clientId).profile.ipaddr,
        "timeApproval": new Date()
    })};

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        })
}
