import { useState, useEffect } from 'react';

export function useQuery() {
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const params = window.location.search.slice(1).split('&');

    const queryParamsObject = params.reduce((acc, param) => {
      const paramSplited = param.split('=');

      const newObjectAccumulated = { ...acc, [paramSplited[0]]: paramSplited[1] }

      return newObjectAccumulated;
    }, {});

    setQueryParams(queryParamsObject);
  }, []);

  return { queryParams };
}
