import { Spin } from "antd";
import { SpinContainer } from "./Formulario/styles";
import PrecisoSuporteButton from "./PrecisoSuporte/PrecisoSuporteButton";


// props:{
//     hasError: Boolean,
//     loading: Boolean
// }

function LoadingOrErrorPanel({children, ...props}){
    return (
        <>
            {
                props.hasError &&(
                    <div key="has-error-key">
                        <span>Não foi possível buscar os dados necessários. Por favor tente novamente ou entre em contato com o <PrecisoSuporteButton texto="suporte" mostrarIcone={false} tipo="link"/>.</span>
                    </div>
                )
            }
            {props.loading && 
                <SpinContainer key="spin-container-key">
                    <Spin/>
                </SpinContainer> 
            }
            {
                !props.hasError && !props.loading && (
                    <>
                        {children}
                    </>
                )
            }
        </>
    )

}
export default LoadingOrErrorPanel;