import React from 'react';
import { Button } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { useRoteiro } from 'context/RoteiroContext';

const DicaFeedbackDiv = ({}) => {
  const { handleJoinha, dicaFeedbackVisivel } = useRoteiro();
  return (
    <div hidden={!dicaFeedbackVisivel}>
      <p>Essa dica foi útil?</p>
      <Button
        type="primary"
        icon={<DislikeOutlined/>}
        onClick={() => handleJoinha(1)}
        className="custom-dislike-button"
        style={{ marginRight: 8 }}
        danger
      >
      </Button>
      <Button
        type="primary"
        icon={<LikeOutlined />}
        onClick={() => handleJoinha(5)}
      >
      </Button>
    </div>
  );
};

export default DicaFeedbackDiv;