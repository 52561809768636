import React, { useEffect } from 'react';

import { PoliceFormWrapper } from './style';

export function PolicesForm({
  polices,
  needToAcceptPolices,
  setNeedToAcceptPolices,
  triedToAcceptWithoutCheck,
  setLicensesAndPolicesHTMLContent
}) {
  function handleAcceptPolices() {
    setNeedToAcceptPolices(!needToAcceptPolices);
  }

  useEffect(() => {
    setPolices();
  }, []);

  function setPolices() {
    const policesHTML = `
      <header>
        <strong>${polices.title}</strong>
      </header>

      <div>
        <div>${polices.content}</div>

        <label htmlFor="acceptPolices">
          <input id="acceptPolices" type="checkbox" checked={!needToAcceptPolices} onChange={() => handleAcceptPolices()} />
          <span>Eu li e concordo com os termos da <strong>${polices.title}</strong></span>
          <span className="policesError">É obrigatório aceitar os termos.*</span>
        </label>
      </div>
    `

    setLicensesAndPolicesHTMLContent(policesHTML);
  }

  return (
    <PoliceFormWrapper triedToAcceptWithoutCheck={triedToAcceptWithoutCheck}>
      <header>
        <strong>{polices.title}</strong>
      </header>

      <div>
        <div dangerouslySetInnerHTML={{ __html: polices.content }}></div>

        <label htmlFor="acceptPolices">
          <input id="acceptPolices" type="checkbox" checked={!needToAcceptPolices} onChange={() => handleAcceptPolices()} />
          <span>Eu li e concordo com os termos da <strong>{polices.title}</strong></span>
          { !!triedToAcceptWithoutCheck && !!needToAcceptPolices && (
            <span className="policesError">É obrigatório aceitar os termos.*</span>
          ) }
        </label>
      </div>
    </PoliceFormWrapper>
  )
}
