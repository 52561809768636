const _defaultState = {
    listaTermos: [],
    selectedTerm: null
}

export default (state = _defaultState, action) => {
    switch (action.type){
        case 'TA_LISTAR_TERMOS':
            return { ...state, listaTermos: action.payload }
        case 'TA_SELECIONAR_TERMO':
            return { ...state, selectedTerm: action.payload }
        case 'TA_MARCAR_COMO_LIDO':
            return { ...state, listaTermos: state.listaTermos.filter( (el) => el.id !== action.payload ) }
        default:
            return state
    }
}