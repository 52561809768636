import React, { Component } from 'react'
import { connect } from 'react-redux'

class Loading extends Component {
    render() {
        if(this.props.isLoading){
            return (
                <div className="loading">
                    <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            );
        }

        return false;
    };
}
export default connect(state => ({ isLoading: state.loading.loadingStatus }))(Loading);