import PortalClienteService from "services/bases/PortalClienteService";

class ModelosOcorrenciaService extends PortalClienteService{
    async ListarModelosOcorrenciaPorUsuario(pagina = 1, tamanhoPagina = 20,termosFiltro = ""){
        return await this.get(`ModelosOcorrencia/ListarServicosPortal?pagina=${pagina}&tamanhoPagina=${tamanhoPagina}&termosFiltro=${termosFiltro}`)
    }
    async ObterModelosOcorrenciaPorId(templateId){
        return await this.get(`ModelosOcorrencia/${templateId}`)
    }
}

export default new ModelosOcorrenciaService();