import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import deleteTermoAceite from "../../actions/termos/deleteTermoAceite";
import { removerTermo, selectTermo } from '../../pages/termos/termoDeAceiteAdmActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from "../form/Button";

class AdmTermoDeAceite extends Component {
    constructor() {
        super();
        this.state = {
            showConfirmExclude: false,
            windowWidth: window.innerWidth,
            loadingRemoveAction: false,
            termRemoved: false
        }

        this.onResize = this.onResize.bind(this);
    }

    componentWillMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize(){
        this.setState({ windowWidth: window.innerWidth });
    }

    onClickToggleExcluir(){
        this.setState({ showConfirmExclude: !this.state.showConfirmExclude });
    }

    onClickTermoBox(id){
        if(this.state.windowWidth < 576){
            this.goToEditPage(id);
        }
    }

    onClickEdit(id){
        this.goToEditPage(id);
    }

    goToEditPage(id){
        this.props.selectTermo(id);
        this.props.history.push('/termos/editar');
    }

    onClickExcluir(){
        this.setState({ loadingRemoveAction: true });
        deleteTermoAceite(this.props.id).then(() => {
            this.setState({ termRemoved: true });

            setTimeout(() => {
                this.props.removerTermo(this.props.id);
            }, 600);
        });
    }

    render() {
        return (
            <div className={`list-box${ this.state.termRemoved ? ' removed' : '' }`} onClick={ () => this.onClickTermoBox(this.props.id) }>
                <div className="box-text">
                    <span className="title">{ this.props.title }</span>
                    <span className={`status ${ this.props.status.toLocaleLowerCase() }`}>{ this.props.status }</span>
                    <span className="subtitle">{ this.props.version }</span>
                </div>

                <div className={`actions ${ this.state.showConfirmExclude ? 'd-none' : '' }`}>
                    <button className="btn" onClick={ () => this.onClickToggleExcluir() }>Excluir</button>
                    <button to="/termos/editar" className="btn btn-default" onClick={ () => this.onClickEdit(this.props.id) }>Editar</button>
                </div>

                <div className={`actions remove-actions ${ this.state.showConfirmExclude ? '' : 'd-none' }`}>
                    <button className="btn" onClick={ () => this.onClickToggleExcluir() }>Cancelar</button>
                    <Button className="btn-danger" onClick={ () => this.onClickExcluir() } loading={ this.state.loadingRemoveAction }>Excluir</Button>
                </div>
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => (bindActionCreators({ removerTermo, selectTermo }, dispatch))
export default withRouter(connect(null, mapDispatchToProps)(AdmTermoDeAceite));
