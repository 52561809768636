import React, { Component } from 'react'

import Header from '../components/simpleHeader/SimpleHeader'

export default class MasterSimpleAuthenticated extends Component {

    render() {
        return (
            <main className="simple-authenticated">
                <Header />
                <section className="content">
                    <div className="container">
                        { this.props.children }
                    </div>
                </section>
            </main>
        );
    };
}