import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const Header = styled.header`
    font-size: 18px;
    letter-spacing: 0px;
    color: #193A70;
`;

export const Content = styled.div`
  letter-spacing: 0px;
  color: #ADB3B7;
  font-size: 14px;    

  strong {
      font-size: 18px;
      letter-spacing: 0px;
      color: #5E84C1;
      font-weight: normal;
  }
`;

export const Footer = styled.div`
    font-size: 18px;
    letter-spacing: 0px;
    color: #193A70;
    padding: 25px 0;

    .checked-Policy{
      margin-right: 10px;
    }
`;
