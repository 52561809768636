import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function getOcurrenceSearchOptions(){
    const user = JSON.parse(sessionStorage.getItem('contact'));
    let url = `${urlBase.urlBase}/dynamics/Occurrence/GetSearchOptions?userDepartment=${user.contactDepartment || '' }`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'GET', headers: _header };

    return fetch(url, params)
        .then(response => { return response.json() })
        .then(result => { return result })
        .catch(() => { return [] })
}
