import styled from 'styled-components';

export const LicensesWrapper = styled.div`
  display: grid;
  gap: 16px;

  header {
    width: 100%;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      color: #193a70;
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
    }
  }

  > div {
    width: 100%;
    display: grid;
    gap: 16px;
  }
`;

export const LicenseItem = styled.div`
  padding: 16px 32px;
  display: grid;
  grid-template-columns: ${props => props.serviceLine === 'basico' ? '3fr 1fr' : '1fr 1.2fr'};

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 9px 24px rgba(25, 58, 112, .15);

  div {
    width: 100%;
    display: grid;
    gap: 6px;
    align-items: flex-start;
    align-self: flex-start;

    &:nth-of-type(2) {
      justify-items: flex-end;

      text-align: right;

      h4 {
        font-weight: lighter;
      }

      p strong:nth-of-type(1) {
        margin: 0;
      }
    }

    h4 {
      margin: 0;

      color: #5e84c1;
      font-size: 18px;
      font-weight: normal;
    }

    h5 {
      margin: 16px 0 0;

      color: #193a70;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
    }

    p {
      margin: 0;

      color: #adb3b7;
      font-size: 12px;
      line-height: 16px;

      strong:nth-of-type(1) {
        margin-right: 32px;
      }
    }
  }
`;
