import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { rdxGetColor } from '../../global/globalActions';

// import MasterPage from '../../masterpages/authenticated';
import TenantWarning from './componentes/TenantWarning';
import Indicadores from './componentes/Indicadores';
import Services from './componentes/Services';
import Ebook from './componentes/Ebook';
import Blog from './componentes/Blog';
import { userSignature } from '../../assets/httpRequest';

import SobreBhs from '../../../assets/icons/Sobre-a-BHS.svg';
import ImgAcessoRemoto from '../../../assets/icons/acesso_remoto.svg';
import MasterPage from 'EstruturaAntiga/masterpages/authenticated';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tenantMissing: false,
			backgroundColor1: '#193A70',
			backgroundColor2: '#193A70',
		}
	}

	componentDidMount() {
		const signatureServiceLine = sessionStorage.getItem('signature') ? JSON.parse(sessionStorage.getItem('signature')).serviceLineId.toLowerCase() : '';

		this.props.rdxGetColor(signatureServiceLine);

		const signature = userSignature();

		if (signature.serviceLineId.toUpperCase() === 'A770E850-C323-E811-A954-000D3AC1BCD5' || signature.serviceLineId.toUpperCase() === '8AEE4699-C323-E811-A954-000D3AC1BCD5') {
			this.setState({
				tenantMissing: signature.tenantCode ? false : true,
			});
		}
	}

	render() {
		const {
			backgroundColor1,
			backgroundColor2,
			tenantMissing
		} = this.state
		const { color } = this.props;

		return (
			<MasterPage className="dashboard">
				{ tenantMissing && <TenantWarning /> }

				<Indicadores />

				<div className="container-dashboard-principal">
					<div className="row">
						<div className="col-12 col-lg-4 col-xl-4 order-1 order-lg-0">
							<Services />
						</div>

						<div className="col-12 col-lg-8 col-xl-8 order-0 order-lg-1">
							<Ebook />
						</div>
					</div>
				</div>


				<div className="row">
					<div className="col-12 col-lg-6 col-xl-6">
						<a
							className="links-home"
							href="https://www.bhs.com.br/"
							target="_blank"
							rel="noopener noreferrer"
							onMouseEnter={() => this.setState({ backgroundColor1: color })}
							onMouseLeave={() => this.setState({ backgroundColor1: '#193A70' })}
							style={{ backgroundColor: backgroundColor1 }}
						>
							<img src={SobreBhs} alt="BHS" />
							<h2>Sobre a BHS</h2>
						</a>
					</div>

					<div className="col-12 col-lg-6 col-xl-6">
						<a
							className="links-home"
							href="https://get.teamviewer.com/axter"
							target="_blank"
							rel="noopener noreferrer"
							onMouseEnter={() => this.setState({ backgroundColor2: color })}
							onMouseLeave={() => this.setState({ backgroundColor2: '#193A70' })}
							style={{ backgroundColor: backgroundColor2 }}
						>
							<img src={ImgAcessoRemoto} alt="Acesso Remoto" />
							<h2>Acesso Remoto</h2>
						</a>
					</div>
				</div>

				<Blog />
			</MasterPage>
		);
	};
}

export default connect(
	state => ({ color: state.global.color, }),
	(dispatch) => (bindActionCreators({ rdxGetColor, }, dispatch))
)(Dashboard);
