import postAceitarTermoAceite from "../../actions/termos/postAceitarTermoAceite";

export const getTermos = (data) => {
    return {
        type: 'TA_LISTAR_TERMOS',
        payload: data
    }
}

export const setSelectedTerm = (idTermo) => {
    return {
        type: 'TA_SELECIONAR_TERMO',
        payload: idTermo
    }
}

export const markAsRead = (idTermo) => {
    return dispatch => (postAceitarTermoAceite(idTermo).then(() => {
        dispatch({
            type: 'TA_MARCAR_COMO_LIDO',
            payload: idTermo
        })
    }))
}
