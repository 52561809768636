const _defaultState = {
    error: null
}

export default (state = _defaultState, action) => {
    switch (action.type){
        case 'RT_ERROR_PAGE':
            return { ...state, error: action.payload }
        default:
            return state
    }
}