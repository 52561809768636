import React, { Component } from 'react';
import Modal from '../../components/modal/modal';

export default class ModalAssinaturaForaDoPrazo extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.openModal} close={this.props.onCloseModal} sizeClassName={'modal-content-lg'}>
                    <div>
                        <div className="text-modal-justify">
                            <h4>Cancelar assinatura</h4>
                            <br />
                            <br />
                            <span>Assinatura fora do prazo para cancelamento.</span>
                        </div>

                        <div className="btn-modal">
                            <button type="button" onClick={() => this.props.onCloseModal()} className="btn btn-danger">Fechar</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };
}