import * as HttpRequest from '../../assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export default async function getAccessToken(name) {
	const contact = HttpRequest.contact();
	const url = `${urlBase.urlBase}/authentication/PowerBi/PowerBiTokenRLS?reportName=${name}&key=${contact.idContact}`;
	const _header = await HttpRequest.apiTokenHeader();
	const params = { method: 'GET', headers: _header };

	return fetch(url, params)
		.then(response => response.json())
		.then(result => {
			return result;
		});
}
