import React, { Component } from 'react'

export default class Button extends Component {

    isDisabled(bool){
        return bool ? 'disabled' : '';
    }

    render() {
        return (
            <button className={`btn ${ this.props.className }  ${(this.props.loading) ? 'btn-loading' : ''}`} type={ this.props.type } onClick={ this.props.onClick } disabled={ Boolean(this.props.disabled) } >
                <span className={`btn-text ${ this.props.textClass ? this.props.textClass : '' } ${(this.props.loading) ? 'd-none' : ''}`}>
                    {this.props.children}
                </span>
                <div className={`simple-loading ${(!this.props.loading) ? 'd-none' : ''}`}></div> 
            </button>
        );
    };
}