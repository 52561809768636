import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

async function fetchGet(url, args) {
  try{
    const response = await fetch(url, args);
    if (response.ok) {
      return Promise.resolve(response.json()); 
    }
    if(!response.status || response.status > 400){
      throw new Error();
    }
    const error = await response.json();
    return Promise.reject(error); 
  } catch(error){
    return Promise.reject({error: 'Ocorreu um erro inesperado. Por favor, contate nossa Central de Atendimento: atendimento@bhs.com.br / 4000-1744.'});
  }
}

export default async function getUserLicenses(filters = ''){
  const axtTenantId = JSON.parse(sessionStorage.getItem("signature")).axtTenantId;
  let url = `${urlBase.urlBase}/dynamics/SubscriptionsCsp/${axtTenantId}?${filters}`;
  let _header = await HttpRequest.apiTokenHeader();
  let params = { method: 'GET', headers: _header };

  try {
    const response = await fetchGet(url, params);
    return response;
  } catch (error) {
    return error;
  }
}
