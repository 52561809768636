import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';

export default async function canApportionment(){
  const axtTenantId = JSON.parse(sessionStorage.getItem("signature")).axtTenantId;
  let url = `${urlBase.urlBase}/dynamics/FinancialContracts/GetMasterFinancialContractPrata?tenantId=${axtTenantId}`;
  let _header = await HttpRequest.apiTokenHeader();
  let params = { method: 'GET', headers: _header };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => response)
}
