import { useEffect, useState } from "react"
import queryString from 'query-string'
import { withRouter  } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import ModelosOcorrenciaService from "services/ModelosOcorrenciaService";
import OcorrenciasService from 'services/OcorrenciasPortalService';
import ProdutosService from "services/ProdutosService";
import Formulario from "components/Formulario/Formulario";
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";
import { LoadingType } from "components/Formulario/Components/PortalSelect";
import { ExtrairPreRequisitosVisiveis, GerarInputsPreRequisitos, criarContatosCallback, criarContratosFinanceirosCallback, criarRecursosCallback, criarTenantsCallback } from "utils/ServicosDataHandler";
import DireitosPortalService from "services/DireitosPortalService";
import { StyledForm } from "components/Formulario/styles";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";

function ServicoCadastro({ history, ...props}){
    const [ids, setIds] = useState({});
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [hasError, setHasError] = useState(false)

    const [modelo, setModelo] = useState({})
    const [contato, setContato] = useState(null)    
    const [preRequisitos, setPreRequisitos] = useState([])
    const [direitos, setDireitos] = useState([])
    const [inputsFormulario, setInputsFormulario] = useState([])
    const [form] = StyledForm.useForm();
    const [isMounted, setIsMounted] = useState(true);
    const [formularioErros, setFormularioErros] = useState([])
    useEffect(()=>{
        const {templateid} = queryString.parse(props.location.search)
        const contact = JSON.parse(sessionStorage.getItem('contact'));
        setContato(contact);
        setIds({ templateid })
        CriarFormulario(templateid);
        return () => {
            setIsMounted(false);
        };
    },[]);

    const CriarFormulario = async (templateid) =>{
        const modeloResponse = await BuscarDadosModelo(templateid);
        const direitosResponse =  await BuscarDadosDireitos(templateid);

        if(modeloResponse == null || direitosResponse == null) {
            setLoading(false);    
            return
        }
        if(!isMounted) return;
        setDireitos(direitosResponse)

        var preRequisitosVisiveis = ExtrairPreRequisitosVisiveis(modeloResponse.preRequisitos)
        setPreRequisitos(preRequisitosVisiveis)

        var inputs = GerarInputsPadrao(direitosResponse)
        var inputsPreRequisitos = GerarInputsPreRequisitos(preRequisitosVisiveis)

        if(inputsPreRequisitos.length > 0){
            inputsPreRequisitos[0].divider = { 
                before:{
                    show: true,
                    text: "Pre-requisitos"
                }
            }
        }
        setInputsFormulario([...inputs,...inputsPreRequisitos])
        setLoading(false);
    }
    const BuscarDadosModelo = async (templateid)=>{
        let modeloResponse;
        await ModelosOcorrenciaService.ObterModelosOcorrenciaPorId(templateid)
            .then(response=>{
                modeloResponse = response
                setModelo(response);
            })
            .catch(error=>{
                NotificationManager.error(error.response?.data?.erros[0] ?? `Não foi possível buscar o serviço. Por favor tente novamente ou entre em contato com o suporte.`, 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
                setHasError(true)
            })
        return modeloResponse;
    }
    const BuscarDadosDireitos = async (templateid) =>{
        let direitosResponse;
        await DireitosPortalService.ListarDireitosServicos(templateid)
            .then(response => {
                direitosResponse = response;
            }).catch(error =>{
                NotificationManager.error(`Não foi possível buscar os Contrato de Serviço. Por favor tente novamente ou entre em contato com o suporte.`, 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
                setHasError(true)
            })
        return direitosResponse;
    }

    const ObterOptionsCallbacks = ( preRequisitosVisiveis,direitoid) => {
        var optionsCallback = {
            contato: {
                callback: async () => {return await criarContatosCallback(direitoid, preRequisitosVisiveis)},
                idField: "contatoId",
                labelField: "nomeCompleto",
                loadingType: LoadingType.AoClicar
            },
            recursosReservaveis: {
                callback: async () => {return await criarRecursosCallback(direitoid, preRequisitosVisiveis)},
                idField: "recursoReservavelId",
                labelField: "nome",
                loadingType: LoadingType.AoClicar
            },
            contratosFinanceiros: {
                callback: async () => {return await criarContratosFinanceirosCallback(direitoid, preRequisitosVisiveis)},
                idField: "contratoFinanceiroId",
                labelField: "nomeExibicao",
                loadingType: LoadingType.AoClicar
            },
            tenants: {
                callback: async () => {return await criarTenantsCallback(direitoid, preRequisitosVisiveis)},
                idField: "tenantId",
                labelField: "nomeExibicao",
                loadingType: LoadingType.AoClicar
            }
        }
        return optionsCallback;
    }
    
    const GerarInputsPadrao = (direitos) => {
        let retorno = [];
        const signature = JSON.parse(sessionStorage.getItem('signature'));
        retorno.push(...[
            { input:{ name: "servico", label: "Serviço", type: "text", disabled: true}},
            {
                input:{ 
                    name: "direitoId", label: "Contrato de Serviço", rules: [{required: true, message:"Campo obrigatorio"}], type: "select", hidden: false,
                    options: {
                        callback: async ()=>{return [...direitos]}, 
                        idField: "direitoId", labelField: "nome", loadingType: LoadingType.AoRenderizar, autoFillWhenSingleValue: true, defaultValue: signature.entitlementId
                    }
                }
            },
            {
                input:{ 
                    name: "produtoId", label: "Tipo de Serviço", rules: [{required: true, message:"Campo obrigatorio"}], type: "select",
                    options: { idField: "produtoId", labelField: "nome", loadingType: LoadingType.AoClicar, autoFillWhenSingleValue: true }
                }
            },
            { input:{ name: "modeloOcorrenciaId", rules: [{required: true, message:"Campo obrigatorio"}], type: "text", hidden: true}},
            { input:{ name: "clienteId", rules: [{required: true, message:"Campo obrigatorio"}], type: "text", hidden: true}},
            { input:{ name: "solicitanteId", rules: [{required: true, message:"Campo obrigatorio"}], type: "text", hidden: true}},
            { input:{ name: "pastaArquivos", rules: [], type: "anexo", hidden: false}},
            { input:{ name: "descricao", label: "Descrição", type: "textarea", rules:[{max: 2000, message:"Campo deve conter no máximo 2000 caracteres"}]}}
        ])
        
        return retorno;
    }
    
    const onFinish = async (values) => {
        setDisabled(true)
        setLoading(true)
        setFormularioErros([])
        await OcorrenciasService.CriarSolicitacao(values)
            .then(response => {
                NotificationManager.success(`A sua solicitação ${response.numeroOcorrencia} foi registrada com sucesso! Acompanhe o andamento da solicitação pelo menu ATENDIMENTOS.`, 'Sucesso!', MessagesDefaultTimeout.Success);                
                history.push(`/servicos`)
            })
            .catch(error => {
                var errors = error.response.data.erros
                errors.forEach((erro, index) => {
                    preRequisitos.forEach(([chave, valor]) => {
                        const rotulo = valor.rotulo;
                        const regex = new RegExp(chave, "i")
                        errors[index] = errors[index].replace(regex, rotulo);
                    });
                });
                setFormularioErros(errors)

                NotificationManager.error('Não foi possível criar a Solicitação.', 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
                setDisabled(false)
                setLoading(false)
            })
    };
    const onFinishFailed = (errorInfo) => {
        NotificationManager.error('Por favor preencha todos os dados corretamente.', 'Formulário incompleto!', MessagesDefaultTimeout.Error);
    };
    const AtualizarInputsPreRequisitos = (newInputs, direitoId)=>{
        var optionsCallback = ObterOptionsCallbacks(preRequisitos, direitoId);
        var inputsPreRequisitos = GerarInputsPreRequisitos(preRequisitos,optionsCallback)
        var inputsAtualizados = newInputs.map(x=>{
            let inputIndex = inputsPreRequisitos.findIndex(y=>y.input.name == x.input.name)
            if(inputIndex != -1){
                let retorno = {...x};
                retorno = {...x, input: inputsPreRequisitos[inputIndex].input }
                return retorno
            }else{
                return x;
            }

        })
        return inputsAtualizados;
    }
    const PortalOnSelectValueChange = (fieldName, newValue) => {
        if(fieldName == "direitoId"){
            OnDireitoIdValueChange(newValue)
        }
    }
    const OnDireitoIdValueChange = (newDireitoId) =>{
        let newInputs = [...inputsFormulario];
        let produtoIndex = newInputs.findIndex(x=>x.input.name == "produtoId")
        newInputs[produtoIndex] = {...newInputs[produtoIndex], input:{
            ...newInputs[produtoIndex].input,
            options:{
                ...newInputs[produtoIndex].input.options,
                callback: async ()=>{return await ProdutosService.ListarTiposServicoParaOcorrencia(ids.templateid, newDireitoId)}
            }}
        }
        let inputsAtualizados = AtualizarInputsPreRequisitos(newInputs,newDireitoId);
        setInputsFormulario(inputsAtualizados)

        let direitoIdIndex = direitos.findIndex(x=>x.direitoId == newDireitoId)
        let clienteId = direitos[direitoIdIndex].clienteId;
        form.setFieldsValue({"clienteId": clienteId})
    }

    return(
        <MasterPage>
            <LoadingOrErrorPanel hasError={hasError} loading={loading}>
                <Formulario 
                    onFinish={onFinish} 
                    onFinishFailed={onFinishFailed}
                    inputsData={inputsFormulario} 
                    title={"Solicitação de Serviços"}
                    portalOnSelectValueChange={PortalOnSelectValueChange}
                    disabled={disabled}
                    formReference={form}
                    initialValues={{modeloOcorrenciaId: ids.templateid, servico: modelo.nome, solicitanteId: contato?.idContact}}
                    formErrors={formularioErros}
                />
            </LoadingOrErrorPanel>
        </MasterPage>
    )
}

export default withRouter(ServicoCadastro)

