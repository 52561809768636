import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function postUsuarioObterProduto(assignedLicense){
    const userSignature = HttpRequest.userSignature();
    const tenant = JSON.parse(sessionStorage.getItem('tenant'));
    
    let url = `${urlBase.urlBase}/portaldb/ListasDePrecos/ListarProdutosPorLicencaAtribuida?modeloDeOfertaCodigo=${tenant.modeloOferta}&direitoId=${userSignature.entitlementId}&tenantId=${userSignature.axtTenantId}`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'POST', headers: _header, body: JSON.stringify(assignedLicense) };

    return fetch(url, params)
    .then(response => response.json())
    .then(response => response)
}