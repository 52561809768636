import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export default async function getUsuarioStatusCode(objetoUsuarioId) {

  let url = `${urlBase.urlBase}/dynamics/Usuario365?objetoUsuarioId=${objetoUsuarioId}`;
  let _header = await HttpRequest.apiTokenHeader();
  let params = { method: "GET", headers: _header };

  return fetch(url, params)
    .then(response => {
        if(response.status === 200){
            return response.json();    
        } else if(response.status === 204){
            return { statuscode: 204 };
        }
    }).then(result => {
        return result;
    });
}
