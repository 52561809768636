import React, { Component } from 'react';
import { ContainerSpinner } from './styles';

export default class LoadingShadow extends Component {
    render() {
        return (
            <div id={this.props.id} className={`modal ${this.props.open ? 'open' : ''} ${this.props.specialClass && this.props.specialClass}`}>
                <div className="shadow-box" onClick={this.props.close}>
                    <ContainerSpinner>
                        <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </ContainerSpinner>
                </div>
            </div>
        );
    };
}
