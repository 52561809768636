import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { navGroups, navigation } from '../../router/routerNavigation';
import { logOut } from "../../actions/logOut";
import Uuid from 'EstruturaAntiga/assets/uuid';
import getIcons from './components/getIcons';

import Dados from '../../../assets/icons/dados.svg';
import Senha from '../../../assets/icons/senha.svg';
import Sair from '../../../assets/icons/sair.svg';
import TemAcessoAosProfiles from 'EstruturaAntiga/permissions/checkPermissionV2';
import PrecisoSuporteButton from 'components/PrecisoSuporte/PrecisoSuporteButton';
import { useRoteiro } from 'context/RoteiroContext';
import UserNavigationMobile from '../userNavigation/UserNavigationMobile';

export default function NavBase(){
	const { setReferenciaObjetosVisuais } = useRoteiro()

	const refMenuAssinaturas365 = useRef(null);
	const refMenuUsuarios = useRef(null);
	const refMenuAtendimentos = useRef(null);
	const refMenuSolicitacaoServicos = useRef(null);
	const refMenuFinanceiro = useRef(null);
	const refMenuPrecisoSuporte = useRef(null);
	const refRelatorioAzure = useRef(null);
	const refRelatorioMaster = useRef(null);
	const refRelatorioFaturamentoMaster = useRef(null);
	const refRelatorioLicenciamentoCsp = useRef(null);
	const refRelatorioExtratoUsuario = useRef(null);
	const refRelatorioMonitoramento = useRef(null);
	const refRelatorioAtendimentos = useRef(null);
	const refRelatorioSuportePremium = useRef(null);
	const refMenuAtualizarMeusDadosLateral = useRef(null);
	const refMenuAlterarSenhaLateral = useRef(null);
	const refSairLateral = useRef(null);
	const arrayRef = [
		{ id: "refMenuAssinaturas365", ref: refMenuAssinaturas365 },
		{ id: "refMenuUsuarios", ref: refMenuUsuarios },
		{ id: "refMenuAtendimentos", ref: refMenuAtendimentos },
		{ id: "refMenuSolicitacaoServicos", ref: refMenuSolicitacaoServicos },
		{ id: "refMenuFinanceiro", ref: refMenuFinanceiro },
		{ id: "refMenuPrecisoSuporte", ref: refMenuPrecisoSuporte },
		{ id: "refRelatorioAzure", ref: refRelatorioAzure },
		{ id: "refRelatorioMaster", ref: refRelatorioMaster },
		{ id: "refRelatorioFaturamentoMaster", ref: refRelatorioFaturamentoMaster },
		{ id: "refRelatorioLicenciamentoCsp", ref: refRelatorioLicenciamentoCsp },
		{ id: "refRelatorioExtratoUsuario", ref: refRelatorioExtratoUsuario },
		{ id: "refRelatorioMonitoramento", ref: refRelatorioMonitoramento },
		{ id: "refRelatorioAtendimentos", ref: refRelatorioAtendimentos },
		{ id: "refRelatorioSuportePremium", ref: refRelatorioSuportePremium },
		{ id: "refMenuAtualizarMeusDadosLateral", ref: refMenuAtualizarMeusDadosLateral },
		{ id: "refMenuAlterarSenhaLateral", ref: refMenuAlterarSenhaLateral },
		{ id: "refSairLateral", ref: refSairLateral }
	]

	useEffect(()=>{
		setReferenciaObjetosVisuais(arrayRef)
	},[])
	
	function obterReferenciaPorNome(refName){
		switch (refName) {
			case "refMenuAssinaturas365":
				return refMenuAssinaturas365;
			case "refMenuUsuarios":
				return refMenuUsuarios;
			case "refMenuAtendimentos":
				return refMenuAtendimentos;
			case "refMenuSolicitacaoServicos":
				return refMenuSolicitacaoServicos;
			case "refMenuFinanceiro":
				return refMenuFinanceiro;
			case "refRelatorioAzure":
				return refRelatorioAzure;
			case "refRelatorioMaster":
				return refRelatorioMaster;
			case "refRelatorioFaturamentoMaster":
				return refRelatorioFaturamentoMaster;
			case "refRelatorioLicenciamentoCsp":
				return refRelatorioLicenciamentoCsp;
			case "refRelatorioExtratoUsuario":
				return refRelatorioExtratoUsuario;
			case "refRelatorioMonitoramento":
				return refRelatorioMonitoramento;
			case "refRelatorioAtendimentos":
				return refRelatorioAtendimentos;
			case "refRelatorioSuportePremium":
				return refRelatorioSuportePremium;
		}
		return null
	}

	function VerificaSeVisivel(data, group){
		return data.shownav && data.group == group && (!data.profile || TemAcessoAosProfiles(data.profile, data.icon === 'icon-grupo-email' || data.icon === 'icon-user'))
	}


	function RenderizaGrupo(groupId){
		let rotasPrincipaisVisiveisFiltradasPorGrupo = navigation.filter(x=>VerificaSeVisivel(x, groupId));
		if(rotasPrincipaisVisiveisFiltradasPorGrupo.length == 0) return;
		return (
			<div className='nav-group'>
				{ groupId != 0 ? 
					<span className="group-title">
						{navGroups[groupId][groupId]}
					</span> 
					: <></>
				}
				{ rotasPrincipaisVisiveisFiltradasPorGrupo.map(function(dadosDaRota){
					let reference = obterReferenciaPorNome(dadosDaRota.refName)
					return(
						<NavLinkWithRef  to={dadosDaRota.path} className="nav-item" exact={true} ref={reference} key={dadosDaRota.refName}>
							{dadosDaRota.icon && <img src={getIcons(dadosDaRota.icon)} alt={dadosDaRota.icon} /> }
							{dadosDaRota.title}
						</NavLinkWithRef>
					)
				})}

				{groupId == 0 ? 
					<PrecisoSuporteButton key={'preciso-suporte-button'} mostrarIcone={true} ref={refMenuPrecisoSuporte}/>
				:<></>}
			</div>
		)
	}
	return (
		<nav className="nav-base">
				<UserNavigationMobile/>
				<div className="nav-links-wrapper">
						{RenderizaGrupo(0)}
						{RenderizaGrupo(1)}
						{RenderizaGrupo(2)}
				</div>
				<div className="nav-base-configs">
					<h5>Configurações</h5>

					<NavLinkWithRef className="nav-item" to="/EditarMeusDados" ref={refMenuAtualizarMeusDadosLateral}>
						<img src={Dados} alt="Atualizar meus dados"/>
						<span>Atualizar meus dados</span>
					</NavLinkWithRef>

					<NavLinkWithRef className="nav-item" to="/AlterarSenha" ref={refMenuAlterarSenhaLateral}>
						<img src={Senha} alt="Alterar senha"/>
						<span>Alterar senha</span>
					</NavLinkWithRef>

					<span className="nav-item" onClick={() => logOut()} ref={refSairLateral}>
						<img src={Sair} alt="Logout"/>
						<span>Sair</span>
					</span>
				</div>
			</nav>
	);
};

const NavLinkWithRef = React.forwardRef((props, ref) => (
	<NavLink innerRef={ref} {...props} />
));