import React from 'react';

function ListBaseEmptyAndLoading(props) {
    return(
        <div className="list-base">
            <span className={`list-box empty-result ${!props.isLoading && props.isEmpty ? '' : 'd-none'}`}>{props.emptyText}</span>
            <div className={`list-box list-loading ${props.isLoading ? '' : 'd-none'}`} id="loadContainerTerm" >
                <div className="box-text">
                    <div className="title"></div>
                    <div className="subtitle"></div>
                </div>
                <div className="actions">
                    <div className="simple-loading"></div>
                </div>
            </div>
        </div>
    );
    
}
export default ListBaseEmptyAndLoading;