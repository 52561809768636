import styled from 'styled-components';

function returnColors(type) {
  switch (type) {
    case 'accept':
      return { rgba: 'rgba(52, 190, 70, .15)', hex: '#34be46' }
    case 'refuse':
      return { rgba: 'rgba(190, 52, 52, .15)', hex: '#be3434' }
    case 'back':
      return { rgba: 'rgba(25, 58, 112, .15)', hex: '#adb3b7' }
    default:
      break;
  }
}

function verifyAcceptedRefused(position, timelinePosition, accepted) {
  const styleScheme = {
    circleBackgroundColor: '#fff',
    circleBorderColor: '#b9c3d4',
    numberColor: '#b9c3d4',
    textColor: '#b9c3d4',
  }

  if (timelinePosition === position) {
    styleScheme.circleBackgroundColor = '#193a70';
    styleScheme.circleBorderColor = '#193a70';
    styleScheme.numberColor = '#ffffff';
    styleScheme.textColor = '#193a70';

    return styleScheme;
  }

  if (timelinePosition > position && !accepted) {
    styleScheme.circleBackgroundColor = '#be3434';
    styleScheme.circleBorderColor = '#be3434';
    styleScheme.textColor = '#193a70';

    return styleScheme;
  }

  if (timelinePosition > position && !!accepted) {
    styleScheme.circleBackgroundColor = '#34be46';
    styleScheme.circleBorderColor = '#34be46';
    styleScheme.textColor = '#193a70';

    return styleScheme;
  }

  return styleScheme;
}

export const TimelineStyle = styled.div`
  width: 100%;
  display: grid;
  gap: 64px;
  padding: 60px 100px 0px 100px;

  .timelineWrapper {
    position: relative;

    display: flex;
    justify-content: space-between;
    z-index: 1;

    &::before,
    &::after {
      ${props => { if(props.positionsQuantity > 1){
        return `
        content: '';
        position: absolute;
        top: 50%;
        left: 0;

        display: block;
        width: 100%;
        height: 2px;

        background-color: #b9c3d4;
        transform: translate3d(0, -50%, 0);
        z-index: -1;
        `
      }}}
    }

    &::after {
      width: ${props => { return props.positionsQuantity < 2 || props.timelinePosition < 1 ? 
                '0px' :  
                (100 / props.positionsQuantity * (props.timelinePosition + 1)) > 100 ? '100%' : `${100 / (props.positionsQuantity-1) * (props.timelinePosition)}%`            
              }};
      height: 3px;

      background-color: ${props => verifyAcceptedRefused(props.position, props.timelinePosition, props.accepted).circleBackgroundColor};
    }
  }
`;

export const TimelineItemStyle = styled.div`
  position: relative;

  div {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => verifyAcceptedRefused(props.position, props.timelinePosition, props.accepted).circleBackgroundColor};
    border: 2px solid ${props => verifyAcceptedRefused(props.position, props.timelinePosition, props.accepted).circleBorderColor};
    border-radius: 50%;
    box-shadow: 0 6px 12px rgba(25, 58, 112, .15);

    font-size: 22px;
    color: ${props => verifyAcceptedRefused(props.position, props.timelinePosition, props.accepted).numberColor};
  }

  p {
    position: absolute;
    top: calc(100% + 16px);
    left: 50%;

    width: 150px;

    transform: translate3d(-50%, 0, 0);

    color: ${props => verifyAcceptedRefused(props.position, props.timelinePosition, props.accepted).textColor};
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
`;
