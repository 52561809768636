import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MasterPage from '../../../masterpages/simpleAuthenticated'
import BoxContent from '../../../../components/ContentBox'
import { setSelectedTerm, markAsRead } from '../termoAceiteActions'
import { setLoadingItem, removeLoadingItem } from '../../../components/loading/loadingActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class TermoDeAceite extends Component {
    componentWillUnmount() {
        this.props.setSelectedTerm(null)
    }

    onClickAceptTerms(){
        let loadingTermName = 'Aceite de termo';
        let _props = this.props;

        _props.setLoadingItem(loadingTermName);

        this.props.markAsRead(this.props.termo.id)
            .then(() => {
                _props.removeLoadingItem(loadingTermName);
                _props.setSelectedTerm(null);
            })
            .catch(() => _props.removeLoadingItem(loadingTermName));
    }

    render() {
        return (
            <MasterPage>
                <h1>{ this.props.termo ? this.props.termo.titulo : '' }</h1>
                <BoxContent className="term-text">
                    <div className="description-term" dangerouslySetInnerHTML={{ __html: this.props.termo ? this.props.termo.descricao : '' }} />
                </BoxContent>

                <div className="term-action">
                    <div className="container">
                        <div className="actions">
                            <Link to="/TermosDeAceite" className="btn">Voltar</Link>
                            <button onClick={ () => this.onClickAceptTerms() } className="btn btn-default">Declaro ter lido e aceito os termos</button>
                        </div>
                    </div>
                </div>
            </MasterPage>
        );
    };
}

const mapDispatchToProps = dispatch => (bindActionCreators({ setSelectedTerm, markAsRead, setLoadingItem, removeLoadingItem }, dispatch))
export default connect(
    state => ({ 
        termo: state.termos.listaTermos.filter((el) => el.id === state.termos.selectedTerm)[0],
        selectedTerm: state.termos.selectedTerm
    }),
    mapDispatchToProps
)(TermoDeAceite);