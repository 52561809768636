import styled from 'styled-components';

export const StatusUsuario = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 23px;
`;

export const FormEditarMeusDados = styled.div`
    padding-left: 100px;
    padding-right: 100px;
`;