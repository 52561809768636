import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import {Row, Col, Input, Flex, Divider, Pagination} from "antd";
import ServicoCard from './Components/ServicoCard';
import {useState,useEffect} from 'react'
import ModelosOcorrenciaService from 'services/ModelosOcorrenciaService';
import LoadingOrErrorPanel from 'components/LoadingOrErrorPanel';
import StringUtils from 'utils/string';
import PrecisoSuporteButton from 'components/PrecisoSuporte/PrecisoSuporteButton';
const inputStyle = {
    maxWidth: '450px', height: '45px', borderRadius: '15px', alignSelf: 'center'
}
function ListagemServico(){
    const [modelos, setModelos] = useState([]);
    const [filtro, setFiltro] = useState("")
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [cachedPages, setCachedPages] = useState({});
    const [isMounted, setIsMounted] = useState(true);
    const [paginationDataConfig, setPaginationDataConfig] = useState({
        total: 20,
        tamanhoPagina: 20,
        filtroDelayEmMs: 1000,
        paginaAtual: 1
    })

    useEffect(()=>{
        BuscarServicos();
        return () => {
            setIsMounted(false);
        };
    },[])

    useEffect(()=>{
        const atraso = setTimeout(()=>{
            filtrar(filtro);
        }, paginationDataConfig.filtroDelayEmMs);
        return ()=>clearTimeout(atraso)
    }, [filtro])

    const BuscarServicos = async (pagina = 1, filtro = "") =>{
        setLoading(true)
        await ModelosOcorrenciaService.ListarModelosOcorrenciaPorUsuario(pagina, paginationDataConfig.tamanhoPagina, filtro).then(response=>{
            if(!isMounted){
                return;
            }
            setPaginationDataConfig((prevPaginationDataConfig) =>({
                ...prevPaginationDataConfig,
                total: response.quantidadeRegistros,
            }))
            setModelos([...response.modelos])

            if(filtro != ""){
                setCachedPages({
                    [pagina]: [...response.modelos],
                });
            }else{
                setCachedPages((prevCachedPages) => ({
                    ...prevCachedPages,
                    [pagina]: [...response.modelos],
                }));
            }
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            setError(true)
        });
    }
    const onFilterChange = (e) => {
        setFiltro(e.target.value);
    }
    const filtrar = (valor) =>{
        let valorTratado = StringUtils.removeArticlesPrepositions(valor);
        if(valorTratado.length == 0){
            BuscarServicos(1);
        }else{
            valorTratado = valorTratado.replace(" ", ";");
            BuscarServicos(1, valorTratado);
        }
    }
    const HandleOnPaginationChange = (paginaAtual, tamanhoPagina) =>{
        setPaginationDataConfig({
            ...paginationDataConfig,
            paginaAtual
        })
        if(cachedPages[paginaAtual]){
            setModelos(cachedPages[paginaAtual]);
        }else{
            BuscarServicos(paginaAtual);
        }
    }
    
    return(
        <MasterPage>
            <Flex align='center' vertical wrap='nowrap' justify='space-between'>
                <Input 
                    placeholder='Em que posso lhe ajudar hoje?'
                    style={inputStyle}
                    value={filtro}
                    onChange={onFilterChange}
                    />
            </Flex>
            <Divider/>    
            <Flex vertical={true}>
                <LoadingOrErrorPanel loading={loading} hasError={error}>
                    <Row gutter={[16, 16]}>
                        {
                            modelos.length > 0 && modelos.map(element =>
                                <Col key={element.nome} 
                                xs={{ span: 24}}
                                sm={{ span: 12 }}
                                lg={{ span: 6}}>
                                    <ServicoCard {...element}/>
                                </Col>
                            )
                        }
                        {
                            modelos.length == 0 && !loading && !error && (
                                <span>
                                    No momento não há nenhum serviço cadastrado para o seu contrato. Utilize o menu <PrecisoSuporteButton mostrarIcone={false} />  
                                </span>
                            )
                        }
                    </Row>
                </LoadingOrErrorPanel>
                <Flex justify='center' style={{paddingTop: '10px'}}>
                    <Pagination 
                        defaultCurrent={1} 
                        total={paginationDataConfig.total} 
                        pageSize={paginationDataConfig.tamanhoPagina}
                        onChange={HandleOnPaginationChange}
                        disabled={loading}
                        current={paginationDataConfig.paginaAtual}
                        />
                </Flex>
            </Flex>
        </MasterPage>
    )

}

export default ListagemServico;