import getTermoAceite from '../../actions/termos/getTermoAceite'
import getAccount from "../../actions/account/getAccount";
import getServiceLine from "../../actions/serviceline/getServiceLine";
import getAxtRole from "../../actions/axtrole/getAxtRole";

export const getTermos = (params) => {
    return dispatch => (getTermoAceite(params).then((obj) => {
        dispatch({
            type: 'ADM_TA_LISTAR_TERMOS',
            payload: obj
        })
    }))
}

export const getMoreTermos = (params) => {
    return dispatch => (getTermoAceite(params).then((obj) => {
        dispatch({
            type: 'ADM_TA_INCREMENTAR_TERMOS',
            payload: obj
        })
    }))
}

export const getTermo = (id) => {
    return dispatch => (getTermoAceite(id).then((obj) => {
        dispatch({
            type: 'ADM_TA_TERMO_SELECIONADO',
            payload: obj
        })
    }))
}

export const getClients = () => {
    return dispatch => (getAccount().then((obj) => {
        dispatch({
            type: 'ADM_TA_LISTAR_CLIENTES',
            payload: obj
        })
    }))
}

export const getServices = () => {
    return dispatch => (getServiceLine().then((obj) => {
        dispatch({
            type: 'ADM_TA_LISTAR_SERVICOS',
            payload: obj
        })
    }))
}

export const getProfiles = () => {
    return dispatch => (getAxtRole().then((obj) => {
        dispatch({
            type: 'ADM_TA_LISTAR_PERFIS',
            payload: obj
        })
    }))
}

export const removerTermo = (idTermo) => {
    return {
        type: 'ADM_TA_REMOVER_TERMO',
        payload: idTermo
    }
}

export const selectTermo = (idTermo) => {
    return {
        type: 'ADM_TA_ID_TERMO_SELECIONADO',
        payload: idTermo
    }
}
