import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"

export default async function getFoto() {
	 let user = HttpRequest.adalUser().profile;
    let url = `${urlBase.urlBase}/graph/User/GetPhoto?tenantId=${user.tid}&userPrincipalName=${user.upn}&size=48x48`;
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'GET', headers: _header };

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        });
}
