import styled from "styled-components";
import { Card, Dropdown } from "antd";

export const StyledCard = styled(Card)`
    
`;


// success, info, warning, error
export const StyledP = styled.p`
    padding: 0px 10px;
    border-radius: 5px;
`;

export const StyledDropdown = styled(Dropdown)`
    margin-left: 5px;
    button{
        text-align: center;
        text-justify: center;
    }
`;