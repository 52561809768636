import styled from 'styled-components';

export const Actions = styled.div`
    align-self: end;

    #btnEditar {
        margin-left: 16px;
        padding-left: 23px;
        padding-right: 23px;
    }

    .second-line-actions{
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        .link-delete-user {
            margin-left: 30px;
            //padding-left: 23px;
            //padding-right: 23px;

            color: #637381;
            font-size: 12px;
            cursor: pointer;
            text-decoration: underline;
        }

        .disable-links {
            margin-left: 30px;
            pointer-events: none;
            display: inline-block;
            color: #e3e3e3f7;
            font-size: 12px;
            text-decoration: underline;
        }
    }
`;