import React, { useState } from 'react';

import { Header } from './components/Header';
import { Main } from './components/Main';
import { AccessControl } from './components/AccessControl';


export function Provisionamento() {
  const [canAccess, setCanAccess] = useState(false);

  return (
    <React.Fragment>
      <Header />
      {
        !canAccess ? <AccessControl setCanAccess={(value) => setCanAccess(value)} /> : <Main />
      }
    </React.Fragment>
  )
}
