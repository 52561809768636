import styled from 'styled-components';

export const MainWrapper = styled.main`
  width: 100%;
  min-height: calc(100vh - 77px);
  padding: 128px 0 64px;

  background-color: #fcfdff;

  .container {
    width: 95%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 128px;
    align-items: flex-start;
  }
`;
