import React, { Component } from 'react';
import { NotificationManager } from "react-notifications";

import MasterPage from '../../masterpages/authenticated';
import ContentBox from "../../../components/ContentBox"
import ListItens from './components/ListItens';
import Checkout from './components/Checkout';
import getUserLicenses from '../../actions/microsoftCSP/getUserLicenses';
import canApportionment from '../../actions/microsoftCSP/canApportionment';
import getCompaniesToApportionment from '../../actions/microsoftCSP/getCompaniesToApportionment';
import checkOrder from '../../actions/microsoftCSP/checkOrder';
import buyOrder from '../../actions/microsoftCSP/buyOrder';
import * as httpRequest from '../../assets/httpRequest';
import Modal from "../../components/modal/modal";
import getTiposProduto from 'EstruturaAntiga/actions/microsoftCSP/getTiposProduto';
import policiesObject from './components/polices.json';
import ModalCancelarContrato from '../ModalCancelarContrato';
import ModalTermoCancelamento from '../ModalTermoCancelamento';
import Form from "EstruturaAntiga/assets/formValidate"
import postCancelarContrato from 'EstruturaAntiga/actions/contratos/postCancelarContrato';
import Button from 'EstruturaAntiga/components/form/Button';
import { isTenantNCE } from 'EstruturaAntiga/router/routerHelpers';
import ModalCancelarContratoConfirmacao from 'EstruturaAntiga/pages/ModalCancelarContratoConfirmacao';

export default class microsoftCsp extends Form {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorInfo: '',
      loadingPage: true,
      closeModalCancelarContrato: true,
      closeModalTermoCancelarContrato: true,
      closeModalCancelarContratoConfirmacao: true,
      checkedPolicy: false,
      tiposProduto: {},
      licenses: [],
      newLicenses: [],
      allLicenses: [],
      savedLicenses: [],
      companies: [],
      loading: true,
      loadingModal: false,
      managementButtonDisabled: true,
      totalMensal: 0,
      tenantName: '',
      filterText: '',
      contractMasterId: '',
      rateio: false,
      receive: [],
      timelinePosition: 0,
      newLicensesValue: 0,
      checkoutOption: 0,
      acceptedTerms: false,
      renderAlert: false,
      msgModalAlert: (()=>{}),
      changedLicenseQuantities: false,
      changedQuote: false,
      formContent: {
				emailaddress2: {
					nome: "E-mail alternativo",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: null,
				},
				mobilephone: {
					nome: "Celular",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: 50,
				}	
			},
      form: {
				emailaddress2: '',
				mobilephone: ''
			},
			hasError: {
				emailaddress2: {},
				mobilephone: {}
			},
			validations: {
				emailaddress2: { mask: 'email', required: true },
				mobilephone: { mask: 'phone', required: true }
			}
    }

    //this.onConfirmCancelarContrato = this.onConfirmCancelarContrato.bind(this);
  }

  async componentDidMount() {
    try {
      getTiposProduto().then(response => {
        this.setState({ tiposProduto: response});
      });

      const tenant = httpRequest.userSignature().tenantName;
      const getLicenses = await getUserLicenses(`&filter=_axt_maincspsubscriptionid_value eq null`);
      if(getLicenses.error){
        return this.setState({
          error: true,
          errorInfo: getLicenses.error
        });
      }

      const getCanApportionment = await canApportionment();
      const companies = getCanApportionment.axt_secondarycontractqty > 0 ? await getCompaniesToApportionment() : [];

      const adjustLicenses = getLicenses.length > 0 ? getLicenses.map(license => ({
        ...license,
        axt_unitpricesale: license.axt_unitpricesale ? license.axt_unitpricesale : undefined,
        addonChilds: [],
      })) : [];

      const userLicenses =  adjustLicenses.length > 0 ? 
                            adjustLicenses.map(license => {
                              if (getCanApportionment.axt_secondarycontractqty > 0) {
                                const newQuotesLicense =  companies?.map(companie => {
                                                            const quoteByCompanie = license.subscriptionCspQuotes.find(quote => quote._axt_financialcontractid_value == companie.axt_financialcontractid);
                                                            return {
                                                              axt_companieName: quoteByCompanie ? quoteByCompanie.empresa.name : companie.axt_accountId.name,
                                                              axt_accountid: quoteByCompanie ? quoteByCompanie.empresa.accountid : companie.axt_accountId.accountid,
                                                              axt_subscriptioncspquoteid: quoteByCompanie ? quoteByCompanie.axt_subscriptioncspquoteid : license.axt_subscriptioncspquoteid,
                                                              _axt_financialcontractid_value: quoteByCompanie ? quoteByCompanie._axt_financialcontractid_value : companie.axt_financialcontractid,
                                                              _axt_productid_value: quoteByCompanie ? license._axt_productid_value : null,
                                                              axt_cnpjview: quoteByCompanie ? quoteByCompanie.empresa.axt_cnpjview : companie.axt_accountId.axt_cnpjview,
                                                              axt_amount: quoteByCompanie ? quoteByCompanie.axt_amount : 0,
                                                              axt_displayname: quoteByCompanie ? quoteByCompanie.axt_nome : companie.axt_displayname,
                                                            }
                                                          });

                                license.subscriptionCspQuotes = newQuotesLicense;
                              } else {
                                license.subscriptionCspQuotes = [];
                              }

                              return license;
                            }) : 
                            [];

      const total = adjustLicenses.length > 0 ? adjustLicenses.reduce((acumulado, license) => {
        const SUM = (license.axt_unitpricesale !== undefined) ? (license.axt_unitpricesale * license.axt_quantity) : 0;

        return acumulado + SUM;
      }, 0) : 0;

      const savedLicenses = [...getLicenses];

      this.setState({
        licenses: userLicenses,
        allLicenses: userLicenses,
        savedLicenses: savedLicenses,
        companies,
        managementButtonDisabled: false,
        totalMensal: this.handleCurrencyFormat(total, userLicenses.length ? userLicenses[0].axt_ProductId.transactioncurrencyid.currencysymbol : ''),
        tenantName: tenant,
        rateio: getCanApportionment.axt_secondarycontractqty > 0 ? true : false,
        contractMasterId: getCanApportionment._axt_pricelevelid_value,
      });
    } catch (error) {
      this.setState({
        error: true,
        errorInfo: error.error
      });
    } finally {
      this.setState({
        loading: false,
        loadingPage: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.allLicenses !== this.state.allLicenses) {
      this.handleChangedLicenseQuantities();
    }
  }

  disableManagement = () => {
    this.setState({
      managementButtonDisabled: true
    });
  }

  onChangeFilter = e => {
    return this.setState({
      filterText: e.target.value,
    });
  }

  addNewLicenses = (newLicenses) => {
    const { licenses } = this.state;

    this.setState({
      newLicenses,
      allLicenses: [...licenses, ...newLicenses]
    });
  }

  addAddons = (license, addons) => {
    const { allLicenses } = this.state;

    if (addons.length === 0) {
      const newLicensesWithAddon = allLicenses.map(theLicense => {
        if (theLicense._axt_productid_value === license._axt_productid_value) {
          theLicense.addonChilds = [];
        }

        return theLicense;
      });

      this.setState({
        allLicenses: newLicensesWithAddon,
      });
    } else {
      const newLicensesWithAddon = allLicenses.map(theLicense => {
        if (theLicense._axt_productid_value === license._axt_productid_value) {
          theLicense.addonChilds = addons;
        }

        return theLicense;
      });

      this.setState({
        allLicenses: newLicensesWithAddon,
      });
    }
  }

  calcularTotal = () => {
    const { allLicenses } = this.state;

    const total = allLicenses.length > 0 ? allLicenses.reduce((acumulado, license) => {
      const sumAddons = (!!license.addonChilds && license.addonChilds.length) > 0 ? license.addonChilds.reduce((acumuladoAddon, addon) => { return acumuladoAddon + (addon.axt_unitpricesale !== undefined) ? (addon.axt_unitpricesale * addon.axt_quantity) : 0 }, 0) : 0;
      const SUM = (license.axt_unitpricesale !== undefined) ? (license.axt_unitpricesale * license.axt_quantity) + sumAddons : 0;

      return acumulado + SUM;
    }, 0) : 0;

    this.setState({
      totalMensal:  this.handleCurrencyFormat(total, allLicenses[0].axt_ProductId.transactioncurrencyid.currencysymbol),
    });
  }

  onChangeQuantity = (value, userLicense, readOnly) => {
    if (readOnly) {
      return;
    }
    
    var regex = /^[0-9]+$/;
    if(value && (!regex.test(value) || (value.toString().substring(0,2) == '00'))){
      return;
    } 
      
    const { allLicenses, savedLicenses } = this.state;

    const newValues = allLicenses.map(old => {
      if (old.axt_subscriptioncspid === userLicense.axt_subscriptioncspid) {
        old.axt_quantity = value;
        old.edited = old.axt_ProductId?.producttypecode == this.state.tiposProduto?.onlineServicesNCE ? 
                      savedLicenses.find(saved => saved.axt_subscriptioncspid === old.axt_subscriptioncspid).axt_quantity != value : 
                      !old.is_new;
      }
      return old;
    });

    this.calcularTotal();

    this.setState({
      allLicenses: newValues,
    });
  }

  onChangeApportionment = (newSubscriptionCspQuotes, userLicense) => {
    const { allLicenses } = this.state;

    const newLicenses = allLicenses.map(old => {
      if ((!!old.axt_subscriptioncspid && !!userLicense._axt_subscriptioncspid && old._axt_subscriptioncspid === userLicense._axt_subscriptioncspid) || 
      (!!old._axt_productid_value && !!userLicense._axt_productid_value && old._axt_productid_value === userLicense._axt_productid_value)) {
        old.edited = true;
        old.subscriptionCspQuotes = newSubscriptionCspQuotes;
      }

      return old;
    });

    this.setState({
      allLicenses: newLicenses,
      changedQuote: true
    });
  }

  back = e => {
    this.setState({
      timelinePosition: this.state.timelinePosition - 1,
    });
  }

  checkoutChange = (value) => {
    this.setState({
      checkoutOption: value,
    });
  }

  handleCurrencyFormat = (value, currencySymbol) => {
    value = value ? value : 0;
    currencySymbol = currencySymbol ? currencySymbol : '$';
    
    return `${currencySymbol}${value.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})} `;
  }

  renderModalAlert = (msg = '') => {
    const { renderAlert } = this.state;

    this.setState({
        renderAlert: !renderAlert,
        msgModalAlert: msg
      });
  }

  handleChangedLicenseQuantities = () => {
    const { savedLicenses, allLicenses } = this.state;

    let changedLicenseQuantities = false;

    savedLicenses && savedLicenses.map(savedLicense => {
      const changedLicense = allLicenses.find(changedLicense => changedLicense.axt_subscriptioncspid === savedLicense.axt_subscriptioncspid);
      if(savedLicense.axt_quantity != changedLicense.axt_quantity && !changedLicenseQuantities){
        changedLicenseQuantities = true;
      }
    });

    this.setState({changedLicenseQuantities})
  }

  onSubmitCheck = async e => {
    const { allLicenses, rateio } = this.state;

    let continueToCheckout = true;

    allLicenses.forEach(license => {
      if (license.axt_ProductId.axt_maximumquantity && license.axt_quantity > license.axt_ProductId.axt_maximumquantity) {
        continueToCheckout = false;

        return NotificationManager.error(
          `A licença ${license.axt_name} excedeu o limite máximo de: ${license.axt_ProductId.axt_maximumquantity} licenças.`,
          "Limite de licenças excedido.",
          9000
        );
      }
    });

    if (rateio) {
      const isApportionmentCorrect = allLicenses.map(license => {
        const apportionmentSUM = license.subscriptionCspQuotes.reduce((acumulador, quote) => {
          return acumulador + +quote.axt_amount;
        }, 0);

        if (license.statuscode !== 282390001 && apportionmentSUM > license.axt_quantity) {
          continueToCheckout = false;

          return NotificationManager.error(
            `A quantidade de rateio é maior do que a quantidade de assinaturas: ${license.axt_name}.`,
            "Quantidade de rateio inválida.",
            9000
          );
        } else if (license.statuscode !== 282390001 && apportionmentSUM < license.axt_quantity) {
          continueToCheckout = false;

          return NotificationManager.error(
            `A quantidade de rateio é menor do que a quantidade de assinaturas: ${license.axt_name}.`,
            "Quantidade de rateio inválida.",
            9000
          );
        }
      });
    }

    if (!continueToCheckout) {
      return this.setState({
        loading: false,
      });
    }

    this.setState({
      loading: true,
    });

    const contact = httpRequest.contact();

    const responseData = {
      CspLicenseOrder: [],
      SubscriptionCspQuote: []
    }

    const orders = allLicenses.map(licenses => (
      {
        axt_contactid: contact.idContact,
        _axt_subscriptioncspid_value: licenses.axt_subscriptioncspid ? licenses.axt_subscriptioncspid : null,
        axt_quantity: licenses.axt_quantity,
        statuscode: licenses.statuscode,
        _axt_productid_value: licenses._axt_productid_value,
      }
    ));

    const addons = allLicenses.map(licenses => {
      return (!!licenses.addonChilds && licenses.addonChilds.length > 0) && licenses.addonChilds.map(addon => {
        orders.push({axt_contactid: contact.idContact,
          _axt_subscriptioncspid_value: addon.axt_subscriptioncspid ? addon.axt_subscriptioncspid : null,
          axt_quantity: addon.axt_quantity,
          statuscode: addon.statuscode,
          _axt_productid_value: addon._axt_productid_value,
          _axt_maincspsubscriptionid_value: addon._axt_maincspsubscriptionid_value,
          axt_isaddon: true,
        });
      });
    });

    if (rateio) {
      const quotes = allLicenses.map(licenses => {
        return licenses.subscriptionCspQuotes.map(quote => {
          responseData.SubscriptionCspQuote.push({
            axt_contactid: contact.idContact,
            axt_subscriptioncspquoteid: quote.axt_subscriptioncspquoteid ? quote.axt_subscriptioncspquoteid : null,
            _axt_subscriptioncspid_value: licenses.axt_subscriptioncspid ? licenses.axt_subscriptioncspid : null,
            _axt_productid_value: licenses._axt_productid_value ? licenses._axt_productid_value : null,
            _axt_financialcontractid_value: quote._axt_financialcontractid_value,
            axt_cnpjview: quote.axt_cnpjview,
            axt_amount: quote.axt_amount,
          });
        });
      });

      const quotesAddons = allLicenses.map(licenses => {
        const quoteAddonsChilds = (!!licenses.addonChilds && licenses.addonChilds.length > 0) ? licenses.addonChilds.map(addon => {
          return addon.subscriptionCspQuotes.map(quote => {
            responseData.SubscriptionCspQuote.push({
              axt_contactid: contact.idContact,
              axt_subscriptioncspquoteid: quote.axt_subscriptioncspquoteid ? quote.axt_subscriptioncspquoteid : null,
              _axt_subscriptioncspid_value: addon.axt_subscriptioncspid ? addon.axt_subscriptioncspid : null,
              _axt_productid_value: addon._axt_productid_value ? addon._axt_productid_value : null,
              _axt_financialcontractid_value: quote._axt_financialcontractid_value,
              axt_cnpjview: quote.axt_cnpjview,
              axt_amount: quote.axt_amount,
            });
          });
        }) : [];
      });
    }

    responseData.CspLicenseOrder = orders;

    const response = await checkOrder(responseData);

    if (response.error) {
      this.setState({
        loading: false,
      });

      return NotificationManager.error(
        response.error,
        "Não foi possível verificar as alterações.",
        9000
      );
    }

    this.setState({
      newLicensesValue: response.cspLicenseOrderTotal,
      loading: false,
      timelinePosition: 1,
      receive: responseData
    });
  }

  onSubmitReceive = async e => {
    this.setState({
      loading: true,
      timelinePosition: 2,
    });

    const { receive, allLicenses } = this.state;

    let licenseData =  {...receive};

    if(allLicenses[0]?.axt_ProductId?.producttypecode == this.state.tiposProduto?.onlineServicesNCE){
      licenseData = {
        ...receive, 
        termosDeAceite: JSON.stringify(policiesObject)
      }
    }

    const response = await buyOrder(licenseData);

    if (response.error) {
      this.setState({
        loading: false,
        timelinePosition: 1,
      });

      return NotificationManager.error(
        "Não foi possível realizar a solicitação.",
        response.error,
        9000
      );
    }

    NotificationManager.success(
      'Sua solicitação já está em andamento. Este processo poderá levar alguns minutos. Você receberá um e-mail de confirmação.',
      'Solicitação enviada com sucesso!',
      9000
    );

    this.setState({
      loading: false,
      timelinePosition: 2,
    });
  }

  onClickCancelarModalContrato = () => {
		this.setState({ closeModalCancelarContrato: true });
		this.setState({ closeModalTermoCancelarContrato: true });
	}

  onConfirmCancelarContrato = () => {
    this.setState({ closeModalCancelarContrato: true });
		this.setState({ closeModalTermoCancelarContrato: false });
	}

  onSuccessCancelarContrato = () => {
    this.setState({ closeModalCancelarContratoConfirmacao: false });
	}

  setCheckedPolicy = (value) => {
		this.setState({ checkedPolicy: value });
	}

  onCloseModal = () => {
		this.setState({ checkedPolicy: false });
    this.onClickCancelarModalContrato();
	}

  onCloseModalCancelarContratoConfirmacao = () => {
    this.setState({ closeModalCancelarContratoConfirmacao: true });
	}

  onSubmitCancelarContrato = async (cancelarContratoData) => {
    this.setState({ loadingModal: true });
    
    await postCancelarContrato(cancelarContratoData)
        .then(() => {
          this.onSuccessCancelarContrato();
        })
        .catch((error) => {
          NotificationManager.error(
            error.response?.data?.mensagem ?? 'Erro inesperado ao tentar cancelar o contrato. Favor entrar em contato com o nosso atendimento.',
            error.response?.data?.titulo ?? 'Cancelar Contrato',
            5000);
        })
        .finally(() => {
          this.onCloseModal();
          this.setState({ loadingModal: false });
        });
  }

  renderTitle = () => {
    return (
      <div className="title-with-action">
        <h1>Assinaturas Microsoft CSP</h1>
        {isTenantNCE() && 
          <Button 
            type='button'
            className="btn btn-danger"
            onClick={() => this.setState({ closeModalCancelarContrato : false })}
            loading={this.state.loadingPage || this.state.loadingModal}
          >
            Cancelar Contrato
          </Button>
        }
      </div>
    )
  }

  renderModal() {
    const {
      closeModalCancelarContrato,
      closeModalTermoCancelarContrato,
      closeModalCancelarContratoConfirmacao,
      checkedPolicy,
      loadingModal
    } = this.state;

    if (closeModalCancelarContrato === false) {
      return (
        <ModalCancelarContrato
            openModal = {!closeModalCancelarContrato}
            onCloseModal = {this.onCloseModal.bind(this)}
            onConfirmCancelarContrato = {this.onConfirmCancelarContrato.bind(this)}
          />
      )
    } else if (closeModalTermoCancelarContrato === false) {
      return (
        <ModalTermoCancelamento
            openModal = {!closeModalTermoCancelarContrato}
            onCloseModal = {this.onCloseModal.bind(this)}
            onConfirmModal = {this.onSubmitCancelarContrato.bind(this)}
            policies={policiesObject}
            checkedPolicy={checkedPolicy}
            setCheckedPolicy={this.setCheckedPolicy.bind(this)}
            loadingModal={loadingModal}
          />
      )
    } else if (closeModalCancelarContratoConfirmacao === false) {
      return (
        <ModalCancelarContratoConfirmacao
            openModal = {!closeModalCancelarContratoConfirmacao}
            onCloseModal = {this.onCloseModalCancelarContratoConfirmacao.bind(this)}
          />
      )
    }
  }

  render() {
    const {
      error,
      errorInfo,
      loadingPage,
      tiposProduto,
      allLicenses,
      savedLicenses,
      companies,
      loading,
      totalMensal,
      tenantName,
      filterText,
      timelinePosition,
      rateio,
      checkoutOption,
      newLicensesValue,
      contractMasterId,
      acceptedTerms,
      renderAlert,
      msgModalAlert,
      changedLicenseQuantities,
      changedQuote
    } = this.state;

    if(error){
      return (
        <MasterPage>
          {this.renderTitle()}
          <div>
            <h2>{errorInfo}</h2>
          </div>
          
        </MasterPage>
      )
    }

    if(loadingPage){
      return (
        <MasterPage>
          {this.renderTitle()}
          <div className="small-loading">
            <div className="simple-loading"></div>
          </div>
        </MasterPage>
      )
    }

    return (
      <MasterPage>
        {this.renderTitle()}

        <div>
          <h2>{tenantName}</h2>

          <div className={`timeline ${timelinePosition === 1 && 'half'} ${timelinePosition === 2 && 'complete'}`}>
            <span className={timelinePosition === 0 ? 'timeline-dot active' : 'timeline-dot'}></span>
            <span className={timelinePosition === 1 ? 'timeline-dot active' : 'timeline-dot'}></span>
            <span className={timelinePosition === 2 ? 'timeline-dot active' : 'timeline-dot'}></span>
          </div>
        </div>

        {
          timelinePosition === 0 && (
            <ContentBox className="list-container">
              <div className="list-items user-list">
                <div className="list-header">
                  <div className="filter-box">
                    <input
                      type="text"
                      className="filter"
                      placeholder="Filtrar por assinatura"
                      value={filterText}
                      onChange={e => this.onChangeFilter(e)}
                    />
                    <i className="icon icon-search"></i>
                  </div>
                </div>

                <ListItens
                  licenses={allLicenses}
                  savedLicenses={savedLicenses}
                  loading={loading}
                  filter={filterText}
                  onChangeQuantity={this.onChangeQuantity}
                  companies={companies}
                  rateio={rateio}
                  contractMasterId={contractMasterId}
                  calcularTotal={this.calcularTotal}
                  addAddons={this.addAddons}
                  handleCurrencyFormat={this.handleCurrencyFormat}
                  renderModalAlert={this.renderModalAlert}
                  renderAlert={renderAlert}
                  onChangeApportionment={this.onChangeApportionment}
                />

                <div className="mensal-price">
                  <p>Valor total mensal: <strong>{totalMensal}</strong></p>
                </div>
              </div>
            </ContentBox>
          )
        }

        {
          timelinePosition === 1 && !!rateio && (
            <div>
              <div className="checkout-options">
                <button className={checkoutOption === 0 && 'active'} onClick={() => this.checkoutChange(0)}>Licenças</button>
                <button className={checkoutOption === 1 && 'active'} onClick={() => this.checkoutChange(1)}>Rateio</button>
              </div>

              <Checkout
                licenses={allLicenses}
                newLicensesValue={newLicensesValue}
                totalMensal={totalMensal}
                rateio={rateio}
                loading={loading}
                companies={companies}
                checkoutOption={checkoutOption}
                acceptedTerms={acceptedTerms}
                acceptTerms={() => this.setState({ ...this.state, acceptedTerms: !acceptedTerms })}
                handleCurrencyFormat={this.handleCurrencyFormat}
                tiposProduto={tiposProduto}
                policy={policiesObject}
              />
            </div>
          )
        }

        {
          timelinePosition === 1 && !rateio && (
            <Checkout
              licenses={allLicenses}
              newLicensesValue={newLicensesValue}
              totalMensal={totalMensal}
              rateio={rateio}
              loading={loading}
              checkoutOption={checkoutOption}
              acceptedTerms={acceptedTerms}
              acceptTerms={() => this.setState({ ...this.state, acceptedTerms: !acceptedTerms })}
              handleCurrencyFormat={this.handleCurrencyFormat}
              tiposProduto={tiposProduto}
              policy={policiesObject}
            />
          )
        }

        {
          (timelinePosition === 2 && loading) && (
            <div className="small-loading">
              <div className="simple-loading"></div>
            </div>
          )
        }

        {
          (timelinePosition === 2 && !loading) && (
            <ContentBox className="list-container">
              <div className="list-items user-list receive-finish">
                <p>Sua solicitação já está em andamento. Este processo poderá levar alguns minutos. Você receberá um e-mail de confirmação.</p>

                <button className="btn btn-default" onClick={() => window.location.reload()}>Retornar ao início</button>
              </div>
            </ContentBox>
          )
        }

        {
          timelinePosition < 2 && (
            <div className="actions csp-actions">
              {
                timelinePosition > 0 && (
                  <button className="btn" onClick={() => this.back()}>Voltar</button>
                )
              }
              <button
                id="btnEditar"
                onClick={() => timelinePosition === 0 ? this.onSubmitCheck() : this.onSubmitReceive()} 
                disabled={
                  loading || 
                  allLicenses.length === 0 || 
                  (timelinePosition === 1 && allLicenses[0]?.axt_ProductId?.producttypecode == this.state.tiposProduto?.onlineServicesNCE && !acceptedTerms) 
                  || (allLicenses[0]?.axt_ProductId?.producttypecode == this.state.tiposProduto?.onlineServicesNCE && (allLicenses.filter(license => license.axt_quantity <= 0).length || !changedLicenseQuantities) && !changedQuote)
                }
                className="btn btn-default"
              >Confirmar</button>
            </div>
          )
        }

        {renderAlert &&
          <Modal open={renderAlert} close={() => this.renderModalAlert()}>
            <div>
              <div className="text-modal">
                <div>
                  {msgModalAlert()}
                </div>
              </div>
              <div className="btn-modal">
                <button
                  onClick={() => this.renderModalAlert()}
                  className="btn btn-default"
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal>
        }
        {this.renderModal()}
      </MasterPage>
    );
  }
}
