import urlBase from '../../assets/urlsBase';

export async function getQuote(cotacaoid) {
  const url = `${urlBase.urlBase}/dynamics/quote/GetByQuoteId/${cotacaoid}`;

  const params = { method: 'GET' };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => {
      return response
    })
    .catch(error => {
      return {
        resultCode: -1,
        message: 'A busca pela cotação excedeu o tempo limite, tente novamente.'
      }
    });
}
