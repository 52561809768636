import React, { Component } from 'react';

import ContentBox from "../../../../components/ContentBox"

export default class Checkout extends Component {
  constructor(props) {
    super(props);
  }

  getSubtotalCompanies = (companie) => {
    const { licenses } = this.props;

    return licenses.reduce((acc, license) => {
      const quoteValue = license.subscriptionCspQuotes.reduce((acc, quote) => {
        const quoteValue = (quote._axt_financialcontractid_value === companie.axt_financialcontractid) ?
          acc + +license.axt_unitpricesale * quote.axt_amount : 0;

        return acc + +quoteValue;
      }, 0)

      return acc + +quoteValue;
    }, 0);
  }

  render() {
    const {
      licenses,
      totalMensal,
      newLicensesValue,
      companies,
      checkoutOption,
      loading,
      acceptedTerms,
      acceptTerms,
      handleCurrencyFormat,
      tiposProduto,
      policy
    } = this.props;

    const currencySymbol = licenses.length ? licenses[0].axt_ProductId.transactioncurrencyid.currencysymbol : '$';

    if (loading) {
      return (
        <div className="small-loading">
          <div className="simple-loading"></div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <ContentBox className="list-container">
            <table className="checkout-list">
              <thead>
                {
                  checkoutOption === 0 && (
                    <tr>
                      <th className="align-left">Assinatura</th>
                      <th>Quantidade</th>
                      <th>Preço Unitário</th>
                      <th>Preço Total</th>
                    </tr>
                  )
                }

                {
                  checkoutOption === 1 && (
                    <tr>
                      <th className="align-left">Empresa/Assinatura</th>
                      <th>Quantidade</th>
                      <th>Preço Unitário</th>
                      <th>Preço Total</th>
                    </tr>
                  )
                }
              </thead>

              <tbody>
                  {
                    checkoutOption === 0 && licenses.map(license => (
                      <React.Fragment>
                        <tr key={license.axt_subscriptioncspid ? license.axt_subscriptioncspid : license._axt_productid_value}>
                          <th className="align-left">{license.axt_name}</th>
                          <th>{license.axt_quantity}</th>
                          <th>
                            {!!license.axt_unitpricesale ?
                            handleCurrencyFormat(license.axt_unitpricesale, currencySymbol) :
                            handleCurrencyFormat(0, currencySymbol) }
                          </th>
                          <th>
                            {!!license.axt_unitpricesale ?
                            handleCurrencyFormat(license.axt_unitpricesale * license.axt_quantity, currencySymbol) :
                            handleCurrencyFormat(0, currencySymbol) }
                          </th>
                        </tr>

                        {
                          (!!license.addonChilds && license.addonChilds.length > 0) && license.addonChilds.map(addon => (
                            <tr key={addon.axt_subscriptioncspid ? addon.axt_subscriptioncspid : addon._axt_productid_value}>
                              <th className="align-left sub-item">{addon.axt_name}</th>
                              <th>{addon.axt_quantity}</th>
                              <th>
                                {!!addon.axt_unitpricesale ?
                                handleCurrencyFormat(addon.axt_unitpricesale, currencySymbol) :
                                handleCurrencyFormat(0, currencySymbol) }
                              </th>
                              <th>
                                {!!addon.axt_unitpricesale ?
                                handleCurrencyFormat(addon.axt_unitpricesale * addon.axt_quantity, currencySymbol) :
                                handleCurrencyFormat(0, currencySymbol) }
                              </th>
                            </tr>
                          ))
                        }
                      </React.Fragment>
                    ))
                  }

                  {
                    checkoutOption === 1 && companies.map(companie => (
                      <React.Fragment>
                        <tr key={companie.axt_financialcontractid}>
                          <th className="align-left"><strong>{companie.axt_accountId.name} - {companie.axt_accountId.axt_cnpjview}</strong></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>

                        {
                          licenses.map(license => (
                            <React.Fragment>
                              {
                                license.subscriptionCspQuotes.map(quote => quote._axt_financialcontractid_value === companie.axt_financialcontractid && (
                                  <React.Fragment>
                                    <tr key={license.axt_subscriptioncspid ? license.axt_subscriptioncspid : license._axt_productid_value}>
                                      <th className="align-left sub-item">{license.axt_name}</th>
                                      <th>{quote.axt_amount}</th>
                                      <th>
                                        {!!license.axt_unitpricesale ?
                                        handleCurrencyFormat(license.axt_unitpricesale, currencySymbol) :
                                        handleCurrencyFormat(0, currencySymbol)}
                                      </th>
                                      <th>
                                        {!!license.axt_unitpricesale ?
                                        handleCurrencyFormat(license.axt_unitpricesale * quote.axt_amount, currencySymbol) :
                                        handleCurrencyFormat(0, currencySymbol)}
                                      </th>
                                    </tr>

                                    {
                                      (!!license.addonChilds && license.addonChilds.length > 0) && license.addonChilds.map(addon => (
                                        <tr key={addon.axt_subscriptioncspid ? addon.axt_subscriptioncspid : addon._axt_productid_value}>
                                          <th className="align-left sub-item">{addon.axt_name}</th>
                                          <th>{quote.axt_amount}</th>
                                          <th>
                                            {!!addon.axt_unitpricesale ?
                                            handleCurrencyFormat(addon.axt_unitpricesale, currencySymbol) :
                                            handleCurrencyFormat(0, currencySymbol)}
                                          </th>
                                          <th>
                                            {!!addon.axt_unitpricesale ?
                                            handleCurrencyFormat(addon.axt_unitpricesale * quote.axt_amount, currencySymbol) :
                                            handleCurrencyFormat(0, currencySymbol)}
                                          </th>
                                        </tr>
                                      ))
                                    }
                                  </React.Fragment>
                                ))
                              }
                            </React.Fragment>
                          ))
                        }

                        <tr>
                          <th></th>
                          <th></th>
                          <th>Subtotal:</th>
                          <th>
                            {
                              handleCurrencyFormat(this.getSubtotalCompanies(companie), currencySymbol)
                            }
                          </th>
                        </tr>
                      </React.Fragment>
                    ))
                  }

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th><strong>{totalMensal}</strong></th>
                </tr>
              </tbody>
            </table>

            <div className="mensal-price">
              <p>Alteração de valor na fatura: <strong>{handleCurrencyFormat(newLicensesValue, currencySymbol)}</strong></p>
            </div>
          </ContentBox>

          {licenses[0]?.axt_ProductId?.producttypecode == tiposProduto?.onlineServicesNCE && (
            <ContentBox>
              <div>{policy.title}</div>
              <div dangerouslySetInnerHTML={{ __html: policy.content }}></div>

              <label htmlFor="acceptPolices" style={{cursor: 'pointer'}}>
                <input id="acceptPolices" checked={acceptedTerms} type="checkbox" onChange={() => acceptTerms()} />
                <span> Eu li e concordo com os termos da <strong>Política de licenciamento Microsoft da BHS</strong></span>
              </label>
            </ContentBox>
          )}
        </React.Fragment>
      )
    }
  }
}
