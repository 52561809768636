export const CONSTANTES_AMBIENTE_PRD = Object.freeze({
    CONFIGURACOES: Object.freeze({
        DIREITO_ACESSO_ADM_GLOBAL: '69656ad6-c7b5-e811-a977-000d3ac1bcd5',
        TENANT_CODE_BHS: '92247c24-8a8c-47f3-a7f1-85df939ad4b6',
        TENANT_MODELO_OFERTA_NCE: '321770000',
        TENANT_MODELO_OFERTA_LEGACY: '321770001'
    }),
    LINHA_SERVICO: Object.freeze({
        CSP_AZURE_PARCEIRO: '7ca3527b-8489-ea11-a811-000d3a887093',
        BHS_PROJECT: '54caa09d-2745-eb11-a812-000d3a887093',
        BHS_POWER_PLATFORM: 'd6cfcfa1-0429-eb11-a813-000d3a887093',
        POWER_PLATFORM_PROJETO_CONSULTORIA: '0549fead-0429-eb11-a813-000d3a887093',
        BHS_DISPOSITIVOS: 'bbcffff6-7a69-e811-a964-000d3ac013b9',
        MONITORAMENTO_SMI: '905ace10-7b69-e811-a964-000d3ac013b9',
        BHS_360: 'a636f8a1-0a0a-ea11-a811-000d3ac08176',
        BI_LICENCIAMENTO_SOFTWARE: 'a836f8a1-0a0a-ea11-a811-000d3ac08176',
        INFRA_VENDA_SOFTWARE: '05c671c5-967d-ea11-a811-000d3ac0ae35',
        SEGURANCA_VENDA_SOFTWARE: 'eb8979d7-967d-ea11-a811-000d3ac0ae35',
        ALUGUEL_HARDWARE: '2b55512c-977d-ea11-a811-000d3ac0ae35',
        INFRA_PROJETO_CONSULTORIA: '18542605-9a7d-ea11-a811-000d3ac0ae35',
        SEGURANCA_PROJETO_CONSULTORIA: 'cc244411-9a7d-ea11-a811-000d3ac0ae35',
        SOFTWARE_SUBSCRIPTION: '5525bb2e-3f2f-eb11-a813-000d3ac0ae35',
        BHS_COMPLIANCE: 'dc9ad98e-482f-eb11-a813-000d3ac0ae35',
        BHS_OFFICE_365: '95c5a7a8-18f0-e911-a812-000d3ac0ae9c',
        BHS_SOLUCOES: 'f6781ad4-15bc-ec11-983f-000d3ac108f8',
        CONSUMO_AWS: 'b18b3347-3aa3-e911-a961-000d3ac1a49a',
        M365_PROJETO_CONSULTORIA: '18225b66-c520-e911-a963-000d3ac1b38b',
        BHS_PPM: 'f1765e6c-c520-e911-a963-000d3ac1b38b',
        BI_OPERACOES: '25775e6c-c520-e911-a963-000d3ac1b38b',
        M365_GERENCIADO: 'a770e850-c323-e811-a954-000d3ac1bcd5',
        BHS_FIREWALL: '05dda758-c323-e811-a954-000d3ac1bcd5',
        SUPORTE_BASICO: 'cad0ee5e-c323-e811-a954-000d3ac1bcd5',
        AZURE: 'ccd0ee5e-c323-e811-a954-000d3ac1bcd5',
        SUPORTE_PREMIUM: 'e3fe7d76-c323-e811-a954-000d3ac1bcd5',
        SUPORTE_DPOR: 'cde10e81-c323-e811-a954-000d3ac1bcd5',
        M365_BASICO: '8aee4699-c323-e811-a954-000d3ac1bcd5',
        VENDA_SOFTWARE: 'f87af7bc-c323-e811-a954-000d3ac1bcd5',
        LOCACAO: 'fa7af7bc-c323-e811-a954-000d3ac1bcd5',
        OUTSOURCING: 'a49ea6d2-c323-e811-a954-000d3ac1bcd5',
        CONSULTORIA: 'a69ea6d2-c323-e811-a954-000d3ac1bcd5',
        VENDA_HARDWARE: '274e04dc-c323-e811-a954-000d3ac1bcd5',
        BHS_NUVEM_ON_PREMISE: '0a9958e2-c323-e811-a954-000d3ac1bcd5',
        BHS_DIGITAL: '06dbc25b-5ca8-eb11-9442-00224835d521',
        SOFTWARE_PERPETUAL: '50cffe39-3880-eb11-a812-002248367ae4',
        SOFTWARE_MICROSOFT: '8c5c19b5-198f-ec11-b400-002248d2e90d',
        OUTROS_SOFTWARES: '0ef04ed4-198f-ec11-b400-002248d2e90d'
    }),
    TIPO_LINHA_SERVICO: Object.freeze({
        SERVICO_GERENCIADO: '282390000',
        PROJETO: '282390001',
        OUTRO: '282390002',
        CONTROLE_HORAS: '321770001'
    }),
    PERFIL_ACESSO: Object.freeze({
        GESTOR_CONTRATO: '282390000',
        RESPONSAVEL_TECNICO: '282390001',
        TECNICO: '282390002',
        AUDITOR: '282390003',
        FINANCEIRO_CLIENTE: '282390004',
        BHS_ADMINISTRADORES_GLOBAIS: '282390005',
        BHS_ATENDENTES: '282390006',
        BHS_FINANCEIRO:	'282390007',
        USUARIO_FINAL: '282390008'
    }),
    FORMULARIO_USUARIOS: Object.freeze({
        XP: '60e8c9c5-638d-eb11-b1ac-0022483727a7',
        BLUE3: '672098ed-4517-ec11-b6e7-000d3a885c91',
        BANCO_MODAL: '8d4c25a3-e096-eb11-b1ac-000d3ac11147',
        CONSULTORES_XP: '5c28d7df-7349-ed11-bba2-000d3a892e71'
    }),
    TIPOS_STATUS_USUARIOS: Object.freeze({
        EM_SINCRONIZACAO: 321770000,
        ATIVO: 1,
        DESATIVADO: 2,
        NAO_ENCONTRADO: 204 
    }),
    ARRAY_STATUS_USUARIOS: [
        { code: 321770000, status: 'Em sincronização', class: 'syncing' },
        { code: 1, status: 'Ativo', class: 'active' },
        { code: 2, status: 'Desativado', class: 'inactive' },
        { code: 204, status: 'Ativo', class: 'active' }
    ],
    CHAMADOS_TIPOS_URGENCIA: Object.freeze({
        CRITICA: 282390000,
        ALTA: 282390001,
        MEDIA: 282390002
    }),
    CHAMADOS_TIPOS_IMPACTOS: Object.freeze({
        CRITICA: 1,
        ALTA: 282390000,
        MEDIA: 282390001
    }),
    CHAMADOS_TIPOS_SOLICITACAO: Object.freeze({
        DUVIDA: 0,
        MUDANCA: 5,
        PROBLEMA_OU_INCIDENTE: 3,
        SOLICITACAO: 2
    })
});
