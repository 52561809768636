import { Alert, DatePicker } from "antd";
import { StyledForm } from "../styles";
import FormInputDivider from "./FormInputDivider";
import DateUtils from "utils/date";

function PortalDatePicker({input, divider, form, date, ...props}){
    const dateFormat = 'DD/MM/YYYY';
    return(
        <FormInputDivider key={input.name} {...divider}>
            <StyledForm.Item {...input} extra={props.helpMessage} rules={[
                ...(input?.rules || []),
                ({}) =>({
                validator(_, value){
                    if(value == null){
                        return Promise.resolve();
                    }
                    let valueDate = new Date(value);

                    if(date?.rules?.minDate){
                        let minDate = new Date(date.rules.minDate)
                        if(valueDate < minDate){
                            return Promise.reject(`Data deve ser maior que ${DateUtils.DateFormat(minDate)}!`)
                        }
                    }
                    if(date?.rules?.maxDate){
                        let maxDate = new Date(date.rules.maxDate)
                        if(valueDate > maxDate){
                            return Promise.reject(`Data deve ser menor que ${DateUtils.DateFormat(maxDate)}!`)
                        }
                    }
                    return Promise.resolve();
                }
            })
            ]}>
                <DatePicker disabled={input?.disabled} format={dateFormat} placeholder={input?.disabled ? "" : "Selecione"} />
            </StyledForm.Item>
        </FormInputDivider>
    )
}

export default PortalDatePicker;