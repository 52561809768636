import React, { Component } from 'react';

import BG from '../../../../assets/images/bg-techdrops.jpg';

export default class Techdrops extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			posts: []
		}
	}

	async componentDidMount() {
		const posts = await fetch(`https://www.bhs.com.br/wp-json/wp/v2/posts?_embed&per_page=3`)
			.then(r => r.json())
			.then(r => r);

		this.setState({
			posts,
		});
	}

	removeHTMLFromText = text => {
		const textWithoutHTML = text.replace(/<[^>]+>/g, '');

		const textRendered = textWithoutHTML.length > 0 ? `${textWithoutHTML.substring(0, 80)}...` : '';

		return textRendered;
	}

	render() {
		const { posts } = this.state;

		return (
			<div className="blog-wrapper">
				<h3>Veja as últimas publicações no blog da bhs!</h3>

				<div className="blog-posts">
					{
						posts.map(post => (
							<a href={post.link} target="_blank" className="blog-item" key={post.link}>
								<div className="blog-img">
									<img src={post["_embedded"]["wp:featuredmedia"] ? post["_embedded"]["wp:featuredmedia"][0]["source_url"] : BG} alt={post.title.rendered} />
								</div>
								
								<h3>{post.title.rendered}</h3>
								<h4>{this.removeHTMLFromText(post.content.rendered)}</h4>
							</a>
						))
					}
				</div>
			</div>
		)
	};
}
