import React, { useState, useEffect } from 'react';

import { getQuote } from 'EstruturaAntiga/actions/provisioning/getQuote';
import { getReasons } from 'EstruturaAntiga/actions/provisioning/getReasons';
import { useQuery } from '../../../../hooks/useQuery';

import { Acceptance } from '../Acceptance';
import { LeftMessage } from '../LeftMessage';

import { MainWrapper } from './style';

import usersArray from '../../users.json';
import policesObject from '../../polices.json';

export function Main() {
  const [licenses, setLicenses] = useState([]);
  const [polices, setPolices] = useState({});
  const [reasons, setReasons] = useState([]);
  const { queryParams } = useQuery();

  useEffect(() => {
    getReasonsDisapproval();
  }, []);

  useEffect(() => {
    if (queryParams.linhaServico === 'basico') {
      getQuoteLicenses();
    } else {
      setLicenses(usersArray);
    }

    setPolices(policesObject);
  }, [queryParams]);

  async function getReasonsDisapproval(){
    const reasons = await getReasons();
    const formattedReasons = reasons.map(reason => {
      return {
        id: reason.attributevalue,
        reason: reason.value
      }
    })
    setReasons(formattedReasons);
  }

  async function getQuoteLicenses() {
    const quote = await getQuote(queryParams.cotacao);

    if (!!quote.axt_provisioninglicensesid) {
      // Do the things
    }

    const licensesArray = quote.quoteDetails.map(license => ({
      id: license.quotedetailid,
      name: license.quotedetailname,
      period: license.uomid?.['axt_mpcdurationname'],
      frequency: license.uomid?.['axt_mpcbillingcyclename'],
      price: license.priceperunit,
      quantity: license.quantity
    }))

    setLicenses(licensesArray);
  }

  return (
    <MainWrapper>
      <div className="container">
        <LeftMessage name={queryParams.name} />
        <Acceptance
          licenses={licenses}
          queryParams={queryParams}
          polices={polices}
          reasons={reasons}
        />
      </div>
    </MainWrapper>
  )
}
