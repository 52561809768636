import urlBase from '../../assets/urlsBase';

export async function acceptQuote(content) {
  const url = `${urlBase.urlBase}/logicApps/Start/AprovarCotacaoProvisionamentoLicenca`;

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

  const params = { method: 'POST', headers, body: JSON.stringify(content) };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => response)
    .catch(error => {
      return {
        resultCode: -1,
        message: 'Não foi possível processar a informação, tente novamente mais tarde.'
      }
    });
}
