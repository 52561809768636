import returnIcons from "EstruturaAntiga/components/navigation/components/getIcons";
import ChamadoCriacaoRapida from "pages/Atendimentos/Cadastro/ChamadoCriacaoRapida";
import { useDrawer } from "context/DrawerContext";
import { Container, StyledA } from "./styles";
import React from "react";

function PrecisoSuporteButton(props, ref){
	const { setup } = useDrawer();
	return(
		<Container className="nav-item-group">
			<StyledA 
				className={props.tipo == "button" ? "btn btn-default" : "nav-item"} 
				onClick={()=>{ setup(<ChamadoCriacaoRapida/>,  { title: "Preciso de Suporte",navigationPath:"chamados/novo" }) }}
				ref={ref}
			>
				{ props.mostrarIcone ? <img src={returnIcons("icon-preciso-suporte")} alt="icon-preciso-suporte"/> : null }
				{ props.texto ? props.texto : "Preciso de Suporte"}
			</StyledA>
		</Container>
	)
}
export default React.forwardRef(PrecisoSuporteButton);