import environment from '../../assets/biEnvironment';

const _biEnvironment = () => {
	switch(environment.environment) {
		case 'development':
			return {
				groupId: '782bee8a-66d7-4a1c-b71f-74940cff13f2',
				azure: '08b4625c-62c4-402b-9af3-54a38f25afc1',
				microsoft: '62c00ea2-994b-45b8-a1b2-76b274f7d9aa',
				desktops: '420e0cc5-64df-4371-b93d-b4a7651d8d2a',
				outsourcing: '673683ee-c827-4528-86c9-4064ac0b3fc0',
				projetos: '0d14c935-7613-4fd2-8490-a5a0695f6ade',
				suporte: '17cef4ce-acc4-4a68-8e16-bbae706a57ce'
			}
		case 'production':
			return {
				groupId: 'bd9f57ef-50d1-455c-bea4-ea980f7db95d',
				azure: '1e9dad33-4433-43c5-8548-327a9541f14c',
				microsoft: '6794c76b-087c-40c0-bcdb-2bb9bbeaee7f',
				desktops: '2b806fb0-9daa-4c6d-9175-c38ae93436ce',
				outsourcing: '4a3ea99b-e41c-4122-94d8-5cb884413c5c',
				projetos: '4a3ea99b-e41c-4122-94d8-5cb884413c5c',
				suporte: 'eff6742e-16ce-446e-9a12-9b1b9f6f7dc6'
			}
		default:
			return {
				groupId: 'bd9f57ef-50d1-455c-bea4-ea980f7db95d',
				azure: '1e9dad33-4433-43c5-8548-327a9541f14c',
				microsoft: '6794c76b-087c-40c0-bcdb-2bb9bbeaee7f',
				desktops: '2b806fb0-9daa-4c6d-9175-c38ae93436ce',
				outsourcing: '4a3ea99b-e41c-4122-94d8-5cb884413c5c',
				projetos: '4a3ea99b-e41c-4122-94d8-5cb884413c5c',
				suporte: 'eff6742e-16ce-446e-9a12-9b1b9f6f7dc6'
			}
	}
}

const biEnvironment = _biEnvironment();

const getAccountID = () => {
    let contact = JSON.parse(sessionStorage.getItem('contact'));
    return (contact && contact.idContact) ? contact.idContact : null;
}

const _defaultState = () => {
    return {
        isEditable: true,
        biConfig: {
            azure: {
                url: `https://app.powerbi.com/reportEmbed?reportId=${biEnvironment.azure}&groupId=${biEnvironment.groupId}&filter=Contato/Id eq '${getAccountID()}'`,
                id: biEnvironment.azure,
                width: '805px',
                height: '722px'
            },
            microsoft: {
                url: `https://app.powerbi.com/reportEmbed?reportId=${biEnvironment.microsoft}&groupId=${biEnvironment.groupId}&filter=Empresas/Id eq '${getAccountID()}'`,
                id: biEnvironment.microsoft,
                width: '805px',
                height: '722px'
            },
            desktops: {
                url: `https://app.powerbi.com/reportEmbed?reportId=${biEnvironment.desktops}&groupId=${biEnvironment.groupId}&filter=Empresas/Id eq '${getAccountID()}'`,
                id: biEnvironment.desktops,
                width: '805px',
                height: '722px'
            },
            outsourcing: {
                url: `https://app.powerbi.com/reportEmbed?reportId=${biEnvironment.outsourcing}&groupId=${biEnvironment.groupId}&filter=Empresas/Id eq '${getAccountID()}'`,
                id: biEnvironment.outsourcing,
                width: '805px',
                height: '722px'
            },
            projetos: {
                url: `https://app.powerbi.com/reportEmbed?reportId=${biEnvironment.projetos}&groupId=${biEnvironment.groupId}&filter=Empresas/Id eq '${getAccountID()}'`,
                id: biEnvironment.projetos,
                width: '805px',
                height: '722px'
            },
            suporte: {
                url: `https://app.powerbi.com/reportEmbed?reportId=${biEnvironment.suporte}&groupId=${biEnvironment.groupId}&filter=Empresas/Id eq '${getAccountID()}'`,
                id: biEnvironment.suporte,
                width: '805px',
                height: '722px'
            }
        },
        accessToken: '',
        empresaPossuiAcesso: ''
    }
}

export default (state = _defaultState(), action) => {
    if (action.type === 'BI_MASTER_CLEAR' || state.isEditable) {
        switch (action.type) {
            case 'BI_MASTER_ACCESS_TOKEN':
                return { ...state, accessToken: action.payload }
            case 'BI_MASTER_EMPRESA_POSSUI_ACESSO':
                return { ...state, empresaPossuiAcesso: action.payload }
            case 'BI_MASTER_CLEAR':
                return { ..._defaultState(), isEditable: action.payload }
            default:
                return state
        }
    }

    return state;
}
