import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"

export default async function getTermoAceite(dataUrlAtributes){
    var defaultUrlAtributes = {
        id: null,
        Ativo: true,
        OrderBy: 'dataalteracao',
        SortDir: 'desc',
        Titulo: '',
        PageSize: 5,
        PageNumber: 1
    };
    var urlAtributes = Object.assign(defaultUrlAtributes, dataUrlAtributes);
    let url = '';
    let _header = await HttpRequest.apiTokenHeader();
    let params = { method: 'GET', headers: _header };


    if(urlAtributes.id){
        url = `${urlBase.urlBase}/portaldb/TermoAceite/${urlAtributes.id}`;
    }
    else{
        delete urlAtributes.id;
        var stringParams = Object.keys(urlAtributes).map( el => el + '=' + urlAtributes[el] ).join("&");

        url = `${urlBase.urlBase}/portaldb/TermoAceite/Pesquisar?${stringParams}`;
    }

    return fetch(url, params)
        .then(response => response.json())
        .then(result => {
            return result;
        })
}
