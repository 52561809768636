import React from 'react'
import MasterPage from '../../masterpages/authenticated'
import ContentBox from "../../../components/ContentBox";
import postLead from "../../actions/cotacao/postLead"
import queryString from 'query-string'
import Form from "../../assets/formValidate"
import Input from "../../components/form/InputGroup";
import Button from "../../components/form/Button";
import Textarea from "../../components/form/textAreaGroup";
import Mask from "utils/masks"
import { NotificationManager } from 'react-notifications';
import { withRouter } from "react-router-dom";
import { authContext } from '../../adalConfig';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { rdxGetUser, rdxClearDados } from "./redux/cotacaoActions";

class Cotacao extends Form {
    state = {
        ...this.state,
        sendingForm: false,
        servico: '',
        form: {
            email: '',
            telefoneComercial: '',
            celular: '',
            descricao: ''
        },
        hasError: {
            email: {},
            telefoneComercial: {},
            celular: {},
            descricao: {}
        },
        validations: {
            email: {},
            telefoneComercial: { required: true, mask: 'phone' },
            celular: { mask: 'phone' },
            descricao: {}
        }
    };

    componentWillMount() {
        let user = authContext.getCachedUser(authContext.config.clientId).profile;
        let servico = queryString.parse(this.props.location.search).servico;

        if(!servico){
            return this.props.history.push('/');
        }

        this.setState({
            servico: servico,
            form: { ...this.state.form, email: user.email || user.upn }
        })
    }

    componentDidMount() {
        this.props.rdxClearDados(true);
        this.props.rdxGetUser();
    }

    componentWillUnmount() {
        this.props.rdxClearDados(false);
    }

    componentWillReceiveProps(nextProps) {
        let newObj = {
            telefoneComercial: nextProps.businessPhone || '',
            celular: nextProps.mobilePhone || ''
        };

        this.setState({ form: { ...this.state.form, ...newObj } })
    }

    onChangeAlternativePhone(event) {
        event.target.value = Mask(event.target.value, 'phone');
    }

    onSubmit = () => {
        if (!this.state.sendingForm) {
				let contact = JSON.parse(sessionStorage.getItem('contact'));
				let userSignature = userSignature();
            let data = {
                "chaveServicoOrigem": `Portal.Menu.CentroResultado.${this.state.servico}`,
                "telefoneComercialContato": this.state.form.telefoneComercial,
                "telefoneCelularContato": this.state.form.celular,
                "descricaoSolicitacao": this.state.form.descricao,
                "contatoId": contact.idContact,
                "clienteId": userSignature.accountId
            }

            this.setState({ sendingForm: true });

            postLead(data)
                .then(() => {
                    NotificationManager.success('Recebemos sua solicitação, retornaremos em breve', 'Sucesso', 6000);
                    return this.props.history.push('/')
                })
                .catch((error) => {
                    NotificationManager.error('Favor tentar novamente ou entre em contato com seu gestor.', 'Não foi possivel alterar seus dados!', 7000);
                    this.setState({ sendingForm: false });
                });
        }
    }

    render() {
        return (
            <MasterPage>
                <form onSubmit={e => { e.preventDefault(); this.handleSubmit() }}>
                    <div className="title-with-action">
                        <h1>Solicitar cotação</h1>
                    </div>

                    <ContentBox className="form-container">
                        <Input id="email" label="E-mail" maxLength="50" type="text" readOnly="true" configureInput={this.configureInput('email')} />
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <Input id="telefoneComercial" label="Telefone Comercial*" maxLength="50" loading={this.props.userInfoLoading} configureInput={this.configureInput('telefoneComercial', (e) => this.onChangeAlternativePhone(e))} />
                            </div>
                            <div className="col-12 col-lg-6">
                                <Input id="celular" label="Celular" maxLength="50" loading={this.props.userInfoLoading} configureInput={this.configureInput('celular', (e) => this.onChangeAlternativePhone(e))} />
                            </div>
                        </div>
                        <Textarea id="descricao" label="Descrição" type="text" configureInput={this.configureInput('descricao')} />
                    </ContentBox>

                    <div className="action-container">
                        <div className="default-actions">
                            <Button className="btn btn-default" disabled={this.props.userInfoLoading} loading={this.state.sendingForm}>Solicitar cotação</Button>
                        </div>
                    </div>
                </form>
            </MasterPage>
        );
    };
}

export default withRouter(connect(
    state => ({
        businessPhone: state.cotacao.businessPhone,
        mobilePhone: state.cotacao.mobilePhone,
        userInfoLoading: state.cotacao.userInfoLoading
    }),
    dispatch => (bindActionCreators({
        rdxGetUser, rdxClearDados
    }, dispatch))
)(Cotacao));
