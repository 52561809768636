import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  background-color: #fcfdff;

  img {
    width: 48px;
  }

  h2 {
    margin: 0;
    align-self: center;

    color: #5e84c1;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;
