export const rdxGetColor = signature => {
	let color = '#4052B5';

	switch (signature) {
		case 'a770e850-c323-e811-a954-000d3ac1bcd5':
			color = '#CF2715';
				break;
		case 'ccd0ee5e-c323-e811-a954-000d3ac1bcd5':
			color = '#0058CE';
				break;
		case '05dda758-c323-e811-a954-000d3ac1bcd5':
			color = '#72CA32';
				break;
		case '8aee4699-c323-e811-a954-000d3ac1bcd5':
			color = '#E34B27';
				break;
		case 'bbcffff6-7a69-e811-a964-000d3ac013b9':
			color = '#6C2CB8';
				break;
		case 'e3fe7d76-c323-e811-a954-000d3ac1bcd5':
			color = '#43577A';
				break;
		default:
			color = '#4052B5';
				break;
	}

	return dispatch => dispatch({ type: 'GLOBAL_COLOR', payload: color });	
}
