import PortalClienteService from "services/bases/PortalClienteService";
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';

class AssessoresService extends PortalClienteService{
    async ChecarCodigoAssessor(codigoAssessor) {
        const userSignature = HttpRequest.userSignature();
        return await this.get(`assessores/(${codigoAssessor})/ChecarCodigoAssessor?tenantCode=${userSignature.tenantCode}`)
    }
    async PodeSerDespadronizado(codigoAssessor){
        return await this.get(`assessores/(${codigoAssessor})/PodeSerDespadronizado`)
    }
}

export default new AssessoresService();