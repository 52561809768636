import React, { Component } from 'react'
import Logo from '../../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import UserNavigation from '../userNavigation/UserNavigation'

export default class SimpleHeader extends Component {
    render() {
        return (
            <header className="simple-header">
                <div className="container">
                    <div className="header-content">
                        <Link to="/" className="logo" >
                            <img src={ Logo } alt="Página inicial - BHS Axter Portal do cliente" />
                        </Link>

                        <UserNavigation />
                    </div>
                </div>
            </header>
        );
    };
}