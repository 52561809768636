import urlBase from '../../assets/urlsBase';

export async function getReasons() {
  const url = `${urlBase.urlBase}/dynamics/quote/MotivosReprovacao`;

  const params = { method: 'GET' };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => {
      return response.value
    })
    .catch(error => {
      return {
        resultCode: -1,
        message: 'Erro ao tentar buscar os motivos de reprovação. Tente novamente.'
      }
    });
}