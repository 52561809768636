import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import getPerfisDeAcesso from '../../../../actions/usuarios/getPerfisDeAcesso';
import uuid from 'EstruturaAntiga/assets/uuid';
import AlterarSenha from '../AlterarSenha/index';
import { rdxSetEditUser } from '../../redux/usuariosActions';
import getUsuarioStatusCode from 'EstruturaAntiga/actions/usuarios/getUsuarioStatusCode';
import getUsuario from 'EstruturaAntiga/actions/usuarios/getUsuario';
import postUsuarioObterProduto from '../../../../actions/usuarios/postUsuarioObterProduto';
import validateManagerProfileUnlink from '../../../../actions/usuarios/validateManagerProfileUnlink';
import { NotificationManager } from 'react-notifications';

import {
	Actions
  } from './styles';
import DeleteUser from '../deleteUser';
import TemAcessoAosProfiles from 'EstruturaAntiga/permissions/checkPermissionV2';
import { profilesV2 } from 'EstruturaAntiga/permissions/permissionsV2';
import LoadingShadow from '../../../../components/loadingShadow';
import { mapUserFieldsV2toV1 } from 'EstruturaAntiga/assets/userHelpers';
import { getFormId } from '../Uteis';
import ModalAdvisorXpBlue3CannotBeDisabled from '../ModalAdvisorXpBlue3CannotBeDisabled/index';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import UsuariosService from 'services/UsuariosService';
import AssessoresService from 'services/AssessoresService';

class UserLabel extends Component {
	constructor() {
		super();
		this.state = {
			perfis: [],
			showPerfis: false,
			loadingProfiles: false,
			loadedProfiles: false,
			windowWidth: window.innerWidth,
			modalOpen: '',
			userStatusCode: {},
			emailPlan: {},
			loadingProduct: true,
			loadingStatus: true,
			validaExclusaoUsuarioLoading: false,
			desativarAcoesUsuario: true,
			usuarioDesativado: false
		}

		this.onResize = this.onResize.bind(this);
	}

	async componentDidMount() {
		this.getStatusCode(this.props.contact.id);
		this.getProduct(this.props.contact.assignedLicenses);
		window.addEventListener("resize", this.onResize);
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props) {
			const usuarioDesativado = !this.props.accountEnabled;

			this.setState({
				usuarioDesativado
			});
		}
	}

	getStatusCode = async (id) => {
		this.setState({ loadingStatus: true });
		const userStatusCode = await getUsuarioStatusCode(id);
		this.setState({ userStatusCode, loadingStatus: false });
	}

	getProduct = async (assignedLicenses) => {
		const userProduct = await postUsuarioObterProduto(assignedLicenses);
		const emailPlan = userProduct.find(prod => prod.estruturaProduto === 3);
		this.setState({ emailPlan, loadingProduct: false });
	}

	onResize() {
		this.setState({ windowWidth: window.innerWidth });
	}

	onClickUserBox(id) {
		if(this.desativarAcoesUsuario()){
			return;
		}
		if (this.state.windowWidth < 576) {
			this.onClickEditUser();
		}
	}

	onClickProfiles(email) {
		this.setState({ showPerfis: !this.state.showPerfis });

		if (!this.state.loadedProfiles) {
			this.setState({ loadingProfiles: true }, () => {
				getPerfisDeAcesso(email).then((data) => {
					this.setState({ perfis: data, loadingProfiles: false, loadedProfiles: true });
				})
			});
		}
	}

	renderSeeProfiles() {
		if (this.state.loadedProfiles === false || this.state.perfis.length)
			return (
				<span className="see-profiles" onClick={() => this.onClickProfiles(this.props.id || this.props.userPrincipalName)}>
					Ver acesso ao Portal do Cliente
					<i className={`icon icon-arrow-${this.state.showPerfis ? 'top' : 'dow'}`}></i>
				</span>
			)

		return '';
	}

	renderLoading() {
		if (this.state.loadingProfiles)
			return (
				<div className="loading-profiles">
					<div className="simple-loading"></div>
				</div>
			)

		return '';
	}

	renderProfiles() {
		if (this.state.showPerfis && this.state.perfis.length) {
			return this.state.perfis.map((el) => {
				return (
					<div className="perfil-list" key={uuid()}>
						<div className="perfil-item">
							<div className="row">
								<div className="col-12 col-sm-4 col-md-5 col-lg-3">
									<span className="label">Perfil</span>
									<span className="value">{el.AxtRoleName}</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7 col-lg-9">
									<span className="label">Linha de serviço</span>
									<span className="value">{el.NomeLinhaServico}</span>
								</div>
							</div>
						</div>
					</div>
				)
			});
		}

		if (this.state.loadedProfiles && !this.state.perfis.length) {
			return (
				<div className="empty-profiles">
					<span className="label">Nenhum perfil associado a este usuário</span>
				</div>
			)
		}

		return '';
	}

	onClickEditUser = async () => {
		await this.props.rdxSetEditUser(this.props);
		this.props.history.push(`/usuarios/editar`);
	}

	onClickEditLicense = async () => {
		await this.props.rdxSetEditUser(this.props);
		this.props.history.push(`/usuarios/alterarPlano`);
	}

	onClickDeleteUser = async () => {
		this.setState({ modalOpen: "shadow", validaExclusaoUsuarioLoading: true });

		if (getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.XP || getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.BLUE3) {
			const user = await getUsuario(this.props.contact.id)
								.then(obj => mapUserFieldsV2toV1(obj));
			let advisorUserCode = user?.axt_extensionattribute5;

			if (advisorUserCode){
				AssessoresService.PodeSerDespadronizado(advisorUserCode)
				.then((response) => {
				if (response && response.canBeDisabled) {
					this.deleteUser();
				} else {
					this.setState({
					modalOpen: "alert-advisor-xp-cannot-be-disabled",
					validaExclusaoUsuarioLoading: false,
					});
				}
				})
				.catch(() => {
					this.setState({ validaExclusaoUsuarioLoading: false });
					this.onCloseModal();
					NotificationManager.error(
						"Favor tentar novamente ou entre em contato com seu gestor.",
						"Não foi possivel excluir o usuário!",
						7000
					);
				});
			} else {
				this.deleteUser();
			}
		} else {
			this.deleteUser();
		}
	}

	async deleteUser() {

		const usuarioId = this.props.usuarioId;
		const userMail = this.props.mail || this.props.userPrincipalName;

		await UsuariosService.ListarPerfisDeAcesso(usuarioId).then((data) => {
			this.setState({ perfis: data });
		});
		let canRemoveUser = true;
		let validateProfiles = [];
	
		 this.state.perfis.forEach((profile) => {
		   let entitlementId = profile.direitoId;
		   let perfilDeAcesso = profile.perfilDeAcessoCodigo;
	
		  if (perfilDeAcesso == profilesV2.gestorContrato.idAxtRole) {
			validateProfiles.push(
			  validateManagerProfileUnlink(userMail, entitlementId)
			);
		  }
		});
	
		Promise.all(validateProfiles).then((obj) => {
		  obj.forEach((result) => {
			if (!result.hasMoreThanOneManagerContracts) {
			  this.setState({ validaExclusaoUsuarioLoading: false });
			  this.onCloseModal();
			  
			  NotificationManager.error(
				`Defina outro usuário como Gestor de Contrato para a linha de serviço ${result.serviceLineName} antes de prosseguir.`,
				"Não foi possível excluir este usuário.",
				9000
			  );
	
			  canRemoveUser = false;
			}
		  });
	
		  if (canRemoveUser) {
			this.setState({
			  modalOpen: "excluir-usuario",
			  validaExclusaoUsuarioLoading: false,
			});
		  }
		});
	}
	
	onCloseModalDelete = async (deletedUser=false) => {
		if(deletedUser){
			this.props.resetUsers();
		}
		this.onCloseModal();
	}

	onCloseModal() {
		this.setState({ modalOpen: '' })
	}

	renderModal() {
		if(this.state.modalOpen === 'shadow') {
			return (
				<LoadingShadow 
					sizeClassName={''}
					open={true} 
					close={() => {}}
				/>
			)
		} else if (this.state.modalOpen === 'alterar-senha') {
			return (
				<AlterarSenha
					onCloseModal={this.onCloseModal.bind(this)}
					upn={this.props.userPrincipalName || this.props.mail}
					sync={this.props.isSync || false}
				/>
			)
		} else if (this.state.modalOpen === "excluir-usuario") {
			return (
			  <DeleteUser
				onCloseModal={this.onCloseModalDelete.bind(this)}
				userId={this.props.contact.id}
			  />
			);
		} else if (this.state.modalOpen === "alert-advisor-xp-cannot-be-disabled") {
			return (
			  <ModalAdvisorXpBlue3CannotBeDisabled
				onCloseModal={this.onCloseModal.bind(this)}
			  />
			);
		}
	}

	renderActions() {
		if (TemAcessoAosProfiles([profilesV2.admGlobal, profilesV2.GestorM365Gerenciado, profilesV2.GestorM365Basico])) {
			return (
				<button
					onClick={() => this.setState({ modalOpen: 'alterar-senha' })}
					type="button"
					className="btn"
					disabled={this.desativarAcoesUsuario()}
				>Alterar senha</button>
			)
		}
	}

	disabledUserLinkButton(){
		return this.desativarAcoesUsuario() ||
		this.props.contact.givenName === '' ||
		this.state.validaExclusaoUsuarioLoading ;
	}

	desativarAcoesUsuario(){
		const {
			userStatusCode,
			usuarioDesativado
		} = this.state;

		return usuarioDesativado || (Number(userStatusCode.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && Number(userStatusCode.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO);
	}

	getStatusCorrente(){
		const {
			userStatusCode,
			usuarioDesativado
		} = this.state;

		if(usuarioDesativado){
			if(userStatusCode.statuscode == CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.EM_SINCRONIZACAO){
				return CONSTANTES_AMBIENTE.ARRAY_STATUS_USUARIOS.filter(theStatus => theStatus.code == userStatusCode.statuscode)[0];
			} else {
				return CONSTANTES_AMBIENTE.ARRAY_STATUS_USUARIOS.filter(theStatus => theStatus.code == CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.DESATIVADO)[0];
			}
		} else {
			return userStatusCode.statuscode ? CONSTANTES_AMBIENTE.ARRAY_STATUS_USUARIOS.filter(theStatus => theStatus.code == userStatusCode.statuscode)[0] : {};
		}
	}

	render() {
		const naoPossui = `< não possui >`;

		const {
			userPrincipalName,
			mail,
			displayName,
		} = this.props;

		const {
			emailPlan,
			loadingProduct,
			loadingStatus
		} = this.state;

		const statusCorrente = this.getStatusCorrente();

		return (
			<div className={`list-box`} onClick={this.onClickUserBox.bind(this)}>
				<div className="box-text">
					<span className="title">
						{displayName}

						<strong className={`contact-status ${statusCorrente.class}`}>
							{statusCorrente.status}
						</strong>
					</span>
					<span className="subtitle"><strong>Login: </strong>{userPrincipalName || naoPossui}</span>
					<span className="subtitle"><strong>E-mail: </strong>{mail || naoPossui}</span>
					{!loadingProduct && 
						<span className="subtitle"><strong>Plano: </strong>{emailPlan?.produto ?? naoPossui}</span>
					}
					{!loadingProduct && emailPlan?.vigencia && emailPlan?.frequencia &&
						<div className="box-validity-frequency">
						<span className="subtitle validity"><strong>Vigência - </strong>{emailPlan.vigencia}</span>
						<span className="subtitle"><strong>Periodicidade de cobrança - </strong>{emailPlan.frequencia}</span>
						</div>
					}
					{this.renderSeeProfiles()}
					{this.renderLoading()}
					{this.renderProfiles()}
				</div>

				{!loadingStatus && 
					<Actions>
						<div className='actions'>
								<div className='first-line-actions'>
									{this.renderActions()}
									<button
										id="btnEditar"
										className="btn btn-default"
										onClick={this.onClickEditUser.bind(this)}
										disabled={this.desativarAcoesUsuario()}
									>
										Editar
									</button>
								</div>
													
						</div>

						<div className='second-line-actions'>
							<span 
								type='button'
								className={this.disabledUserLinkButton() ? 'disable-links' : 'link-delete-user'} 
								onClick={this.disabledUserLinkButton() ? null : () => this.onClickEditLicense()}
							>
								Alterar plano
							</span>	
							<span 
								type='button'
								className={this.disabledUserLinkButton() ? 'disable-links' : 'link-delete-user'} 
								onClick={this.disabledUserLinkButton() ? null : () => this.onClickDeleteUser()}
							>
								Excluir usuário
							</span>				
						</div>
					</Actions>
				}
				{this.renderModal()}
			</div>
		)
	};
}

export default withRouter(connect(
	state => ({ 
		user: state.users.user 
	}),
	(dispatch) => (bindActionCreators({
		rdxSetEditUser,
	}, dispatch))
)(UserLabel));
