import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from '../../assets/urlsBase';


//TODO: Esta funcao foi refeita em DireitosService.BuscarDireitoPorIdUsuario(), Remover os usos e deletar este arquivo. 29/11/2023
export default async function GetDireitoPorId() {
    const userSignature = HttpRequest.userSignature();    
    const url = `${urlBase.urlBase}/portaldb/Direitos/${userSignature.entitlementId}`;
    const _header = await HttpRequest.apiTokenHeader();
    const params = { method: 'GET', headers: _header };
  
    return fetch(url, params)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      .catch(ex =>{
        return ex;
      });
  }