import styled from 'styled-components'

export const Confirmation = styled.div`
    width: 100%;
    display: flex;
    flex-direction:column;
    padding: 10px 25px 25px 25px;

    p {
        text-align: center;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }
`;

export const ContentModal = styled.div`
  p {
    text-align: center;
    color: #343c42;
    letter-spacing: 0px;
    font-size: 16px; 
    font-weight: normal;
    line-height: 1.2;
  }

  a {
    text-decoration: underline;
  }
`;

export const Action = styled.div`
    padding: 30px 0 0 0;
    display: flex;
    flex-direction: row;

    &.action-delete{
        justify-content: flex-end;
    }

    &.action-confirmation{
        justify-content: center;
    }
`;

export const ImageIcon = styled.img`
  height: 70px;
  width: 70px;
  background-color: red;
  border-radius: 50%;
`;

export const ImageContainer = styled.div`
  justify-content: center;
  display: flex;
`;