

export default function mask(val, mask){
    
    let _onlyNumbers = (str) => str.replace(/[^0-9]/g,'');
    
    if(mask === "phone"){
        val = _onlyNumbers(val);
        mask = (val.length <= 10) ? '(99) 9999-9999' : '(99) 99999-9999';
    }
    else if(mask === "date"){
        val = _onlyNumbers(val);
        mask = '39/19/2999';
    }
    
    for(var i = 0; i < mask.length; i++){
        var regex;

        if(val[i] !== undefined){
            if(mask[i] === "a" || (!isNaN(mask[i]) && mask[i] !== ' ')){
                if(mask[i] === "a"){
                    regex = new RegExp(/[a-zA-Z]/);
                }
                else if(!isNaN(mask[i])){
                    regex = new RegExp(`[0-${parseInt(mask[i], 10)}]`);
                }

                if (regex && !regex.test(val[i])){
                    return val.slice(0, i);
                }
            }
            else{
                val = val[i] === mask[i] ? val : val.slice(0, i) + mask[i] + val.slice(i);
            }
        }
        else{
            return val;
        }
    }
    
    return val.slice(0, mask.length);
}