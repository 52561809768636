import styled from "styled-components"

export const Main = styled.main`
  .authenticated {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const Aside = styled.aside`
  background-color: #e2e7ea;
  width: 250px;
  position: fixed;
  left: ${props => (props.isOpen ? '0' : '-250px')};
  top: 92px;
  height: 90.5%;
  transition: left 0.3s ease;
  z-index: 998;
  overflow-y: auto;

  @media (min-width: 768px) {
    position: relative;
    left: 0;
    height: auto;
	  top: 0px;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 1rem;
`;

export const ToggleButton = styled.button`
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  
  color: #fff;
  text-align: start;
  font-size: 1.2rem;
  
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }

  @media (min-width: 768px) {
    display: none;
  }
`;