import { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import uuidv1 from 'uuid';
import { Alert, Col, Form, Input, Row, Select, Upload, message  } from 'antd';
const { Dragger } = Upload;
import urlBase from 'EstruturaAntiga/assets/urlsBase';
import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import { NotificationManager } from 'react-notifications';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import DireitoService from 'services/DireitosService';
import ProdutosService from 'services/ProdutosService';
import ChamadosService from 'services/ChamadosService';
import UploadArquivosService from 'services/UploadArquivosService';
import { ConfirmFunctionStatus } from '../../../context/DrawerContext';
import { MessagesDefaultTimeout } from 'constants/messagesDefaultTimeout';

const anexoDefaultProps = { 
    name: 'file',
    action: "actionPath",
    onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} arquivo adicionado com sucesso.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} falhou ao tentar salvar.`);
        }
    },
    beforeUpload: (file)=>{
        return new Promise((resolve, reject) => {
            if(file.size > 6000000){
                message.error("Tamanho excedido")
                return reject("Tamanho excedido")
            }
            if(file.name.includes(".jpeg") || file.name.includes(".jpg") || file.name.includes(".doc") ||
                file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".gif") || file.name.includes(".pdf") ||
                file.name.includes(".xlsx") || file.name.includes(".jfif") || file.name.includes(".docx") ||
                file.name.includes(".msg")){
                    // message.success("Sucesso")
                    NotificationManager.success('Arquivo anexado com sucesso.', 'Sucesso!', MessagesDefaultTimeout.Success); 
                    return resolve("Sucesso")
            }else{
                message.error("Formato inválido")
                return reject("Formato inválido");
                
            }
        })
    }
}

const ChamadoCriacaoRapida = forwardRef((props, ref) => {
    const NOWCY_FILIAL_ID = "6955baa3-c682-ed11-81ad-00224835d91f";
    const [formRef] = Form.useForm();
    const {direito: direitoDoContexto, produto: produtoDoContexto, setStatusBotaoConfirmar, closeDrawer } = props;

    useImperativeHandle(ref, () => ({
        confirmFunction,
        cancelFunction
    }));

    const [pathName, setPathName] = useState("") 
    const [anexoProps, setAnexoProps] = useState(anexoDefaultProps) 
    const [fileList, setFileList] = useState([]);
    const [direitos, setDireitos] = useState({
        direitoAtual: {},
        direitosPorContato: [],
        isLoading: true,
        isNowCy: false
    })
    const [produtos, setProdutos] = useState({
        produtosPorDireito: [],
        isLoading: false
    })
    
    useEffect(()=>{
        let isMounted = true;
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
        const BuscarDadosDeDireitos = async () => {
            var {direitoAtual, direitosPorContato} = await CarregarDireitos()
            if(!isMounted) return;
            setDireitos({
                ...direitos,
                direitoAtual: direitoAtual,
                direitosPorContato: direitosPorContato,
                isLoading: false
            })
            DefinirDireitoPadrao(direitoAtual, direitosPorContato);
        }
        const BuscarDadosDoHeader = async () => {
            var {newPathName, newAnexoProps} = await PreencherHeaderAnexo();
            if(!isMounted) return;
            setPathName(newPathName)
            setAnexoProps({
                ...anexoProps,
                ...newAnexoProps
            })
        }

        BuscarDadosDeDireitos();
        BuscarDadosDoHeader();
        
        return ()=>{
            isMounted = false;
        }
    }, [])
    useEffect(()=>{
        setStatusBotaoConfirmar(TodosDadoNecessarios()? ConfirmFunctionStatus.Ok: EstaCarregando()? ConfirmFunctionStatus.Carregando : ConfirmFunctionStatus.Invalido);
    }, [produtos])
    async function CarregarDireitos(){
        var direitoAtual = await DireitoService.BuscarDireitoAtual();
        var direitosPorContato = await DireitoService.ListarDireitosPorContato();
        return {direitoAtual, direitosPorContato}
    }
    function DefinirDireitoPadrao(direitoAtual, direitosPorContato){
        let valorInicial = null;
        if(direitosPorContato.length == 1){
            valorInicial = direitosPorContato[0].entitlementId;
        }
        else if(direitosPorContato.findIndex(x=>x.entitlementId == direitoDoContexto) != -1){
            valorInicial = direitoDoContexto;
        }
        else if(direitosPorContato.findIndex(x=>x.entitlementId == direitoAtual.entitlementId) != -1){
            valorInicial = direitoAtual.entitlementId;
        }

        if(valorInicial){
            formRef.setFieldValue("direito", valorInicial)
            CarregarProdutos();
        }
    }
    async function PreencherHeaderAnexo(){
        let newPathName = "rootpath" + uuidv1();

        //TODO: Essa parte aqui pode ser substituida por uma callback no componente de Upload, mas as tentativas que fiz não consegui configurar corretamente.
        const url = `${urlBase.urlBase}/storageaccount/blob/blob/UploadByStream?pathName=${newPathName}`
        let {Authorization} = await HttpRequest.axiosApiTokenHeader();
        let header = {Authorization: Authorization}

        return {
            newPathName: newPathName, 
            newAnexoProps: { 
                ...anexoProps,
                headers: header,
                action: url,
                accept: "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-outlook",
                onChange: (info) =>{
                    let newFileList = [...info.fileList];
                    setFileList(newFileList)
                },
                onRemove: (file)=>{
                    return onDeleteFile(file, newPathName)
                }
            }
        }
    }
    async function onDeleteFile(file, newPath){
        if(!(newPath.length > 0)) {
            return false;
        }
        let newFile = {
            file: file.originFileObj
        }
        let newListFile =  fileList.filter( fileDelete => fileDelete.name !== file.name );
        let success = false;
        var response = await UploadArquivosService.DeletarArquivo(newPath, newFile)
            .then(x=>
                {
                    success = true;
                    setFileList([...newListFile])
                }
            )
            .catch(x=>{
                success = false;
            });
        if(success){
            message.success("Arquivo removido com sucesso!")
            return true;
        }else{
            message.error("Ocorreu um erro ao tentar remover o arquivo.")
            return Promise.reject(response);
        }   
    }
    async function CarregarProdutos(){
        setProdutos({...produtos, produtosPorDireito: [], isLoading: true})
        formRef.setFieldValue("servico",null)
        let direitoSelecionado = formRef.getFieldValue("direito");
        var produtosPorDireito = await ProdutosService.ListarProdutosPorDireito(direitoSelecionado);
        setProdutos({...produtos, produtosPorDireito: produtosPorDireito, isLoading: false})
        if(produtosPorDireito.length == 1){
            formRef.setFieldValue("servico",produtosPorDireito[0].produtoId)
        }
        else if(produtosPorDireito.findIndex(x=>x.entitlementId == produtoDoContexto) != -1){
            formRef.setFieldValue("servico",produtoDoContexto)
        }
        else if(produtosPorDireito.length == 0){
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido);
        }
        
    }
    function DireitoSelecionadoIsNowCy(){
        let direitoSelecionado = formRef.getFieldValue("direito")
        var direito = direitos.direitosPorContato.find(x=>x.entitlementId == direitoSelecionado)
        if(direito){
            return direito.filialId == NOWCY_FILIAL_ID;
        }
        return false;
    }
    function TodosDadoNecessarios(){
        // let formValues = formRef.getFieldsValue(true);
        return produtos.produtosPorDireito.length != 0;
    }
    function EstaCarregando(){
        return produtos.isLoading || direitos.isLoading
    }
    const cancelFunction = () => {}
    const confirmFunction = async () => {
        formRef.submit();
        let formValues = formRef.getFieldsValue(true);
        let validacao = await formRef.validateFields()
            .then(formValues=>{
                return formValues;
            })
            .catch(errors=> {
                console.error(errors)
                return false
            });
        if(!validacao){
            return;
        }
        if(!fileList.every(x=>x.percent == 100 && x.status == "done")){
            NotificationManager.error('Alguns arquivos ainda não terminaram de ser enviados.', 'Por favor aguarde!', MessagesDefaultTimeout.Error);
            return;
        }

        const contact = JSON.parse(sessionStorage.getItem('contact'))

        let direitoSelecionado = direitos.direitosPorContato.find(x=>x.entitlementId == formValues.direito)
        if(!direitoSelecionado){
            NotificationManager.error('Favor selecionar um Contrato de Serviço.', 'Preencha todos os dados!', MessagesDefaultTimeout.Error);
            return;
        }
        let direitoNome = direitoSelecionado.accountName;
        let clienteId = direitoSelecionado.accountId;

        let produtoSelecionado = produtos.produtosPorDireito.find(x=>x.produtoId == formValues.servico)
        if(!produtoSelecionado){
            NotificationManager.error('Favor selecionar um Tipo de Serviço.', 'Preencha todos os dados!', MessagesDefaultTimeout.Error);
            return;
        }
        let produtoNome = produtoSelecionado.nome
        const data = {
            assunto: `Portal Cliente | ${direitoNome} | ${produtoNome}`,
            direitoId: formValues.direito,
            produtoId: formValues.servico,
            tipoSolicitacaoId: CONSTANTES_AMBIENTE.CHAMADOS_TIPOS_SOLICITACAO.PROBLEMA_OU_INCIDENTE,
            urgenciaId: CONSTANTES_AMBIENTE.CHAMADOS_TIPOS_URGENCIA.MEDIA,
            impactoId: CONSTANTES_AMBIENTE.CHAMADOS_TIPOS_IMPACTOS.MEDIA,
            descricao: formValues.descricao,
            contatoId: contact.idContact,
            clienteId: clienteId,
            pathName: fileList.length > 0? pathName : '' 
          }
          setStatusBotaoConfirmar(ConfirmFunctionStatus.Carregando);
          ChamadosService.CadastrarChamado(JSON.stringify(data)).then((chamado) => {
            NotificationManager.success(`Chamado ${chamado.numeroSolicitacao} criado com sucesso`, 'Sucesso!', MessagesDefaultTimeout.Success);
            closeDrawer();
          })
          .catch((error) => {
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok);
            console.error(error)
            NotificationManager.error('Favor tentar novamente ou entre em contato com seu gestor.', 'Não foi possivel criar o chamado!', MessagesDefaultTimeout.Error);
          });
      };
    return(
        <div>
            <Form layout="vertical" requiredMark="required" form={formRef} initialValues={{direito: null, servico: null, descricao: ""}}>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item name="direito"  label="Contrato de Serviço" rules={[{ required: true, message: 'Escolha um contrato de serviço' }]}>
                        <Select onChange={CarregarProdutos} loading={direitos.isLoading} disabled={direitos.isLoading}>
                            {direitos.direitosPorContato.map(direito=>{
                                return(
                                    <Select.Option key={direito.entitlementId} value={direito.entitlementId}>{direito.entitlementName}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item name="servico" label="Tipo de Serviço" rules={[{ required: true, message: 'Escolha um tipo de serviço' }]}>
                    <Select name="servico" loading={produtos.isLoading} disabled={!TodosDadoNecessarios()}>
                        {produtos.produtosPorDireito.map(produto=>{
                            return(
                                <Select.Option key={produto.produtoId} value={produto.produtoId}>{produto.nome}</Select.Option>
                            )
                        })}
                    </Select>
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item name="descricao" 
                        label="Como podemos lhe ajudar?" 
                        rules={[ { required: true, message: "Descreva como podemos lhe ajudar." }, ]}>
                        <Input.TextArea rows={4} placeholder="" disabled={!TodosDadoNecessarios()}/>
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item disabled={!TodosDadoNecessarios()}>
                        <Dragger  {...anexoProps} listType="picture" disabled={!TodosDadoNecessarios()}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Se for necessário algum arquivo para contribuir na resolução do problema, anexe aqui:</p>
                            <p className="ant-upload-hint">Clique aqui ou arraste um arquivo para essa área para fazer o upload.</p>
                        </Dragger>
                    </Form.Item>
                    </Col>
                </Row>
                {
                    !TodosDadoNecessarios() && !EstaCarregando() ?
                        DireitoSelecionadoIsNowCy()? 
                            <Alert 
                                description="No momento não é possível seguir com o atendimento neste canal. 
                                    Por favor, registre uma solicitação pelo telefone (31)3058-6606 ou e-mail atendimento@nowcy.com.br"
                                closable={false}
                                showIcon={true}
                                type="warning"
                            />
                            :
                            <Alert 
                                description="No momento não é possível seguir com o atendimento neste canal. 
                                    Por favor, registre uma solicitação pelo telefone 4000-1744 - opção 1 ou e-mail atendimento@bhs.com.br"
                                closable={false}
                                showIcon={true}
                                type="warning"
                            />
                            : null
                }
            </Form>
        </div>
    )
});

export default ChamadoCriacaoRapida;