import React, { Component } from 'react'

export default class InputGroup extends Component {

    classError = () => {
        if ((this.props.configureInput.showerror === "true" || this.props.configureInput.showCurrentError === "true" || this.props.advisorControl === false ) &&
            (this.props.configureInput.error ||  this.props.alertControlAdvisor)) {
            return 'form-error';
        }

        return '';
    }

    error = () => {
        if ((this.props.configureInput.showerror === "true" || this.props.configureInput.showCurrentError === "true" || this.props.advisorControl === false) &&
            this.props.configureInput.error) {
            return <span className="error">{this.props.configureInput.error}</span>
        }else if (this.props.alertControlAdvisor){
            return <span className="error">{this.props.alertControlAdvisor}</span>
        }

        return null;
    }

    render() {
        return (
            <div className={`form-group ${this.classError()}`}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <div className="input-group-container">
                    <input
                        onBlurCapture={this.props.onFocus}
                        onFocus={this.props.onFocus}
                        className="input-group"
                        disabled={this.props.disabled}
                        readOnly={this.props.readOnly}
                        maxLength={this.props.maxLength}
                        type={this.props.type || "text"}
                        onBlur={this.props.onBlur}
                        {...this.props.configureInput.props} />
                    <div className={`simple-loading ${(!this.props.loading) ? 'd-none' : ''}`}></div>
                </div>
                {this.error()}
            </div>
        );
    };
}