import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0 25px 25px 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const Content = styled.div`
  p {
    text-align: justify;
    color: ##637381;
    letter-spacing: 0px;
    font-size: 16px; 
    font-weight: normal;
    line-height: 1.6;
  }

  a {
    text-decoration: underline;
  }
`;
