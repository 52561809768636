import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { rdxGetColor } from '../../../global/globalActions';
import { 
	rdxPostObterQtdChamadosFechados, 
	rdxPostObterQtdChamadosAbertos,
	rdxClearDashboard, 
	rdxIndicadorClicked 
} from '../redux/dashboardActions';

import AtendimentoIcon from '../../../../assets/icons/atendimentos_concluidos.svg';
import TutoriaisImg from '../../../../assets/images/Imagem-Tutorias-Portal-do-Cliente-2024.png';

class Indicadores extends Component {
	constructor(props) {
		super(props);

		this.state = {
			noticia: {
				link: '',
				img: ''
			}
		}
	}

	async componentDidMount() {
		const signature = sessionStorage.getItem('signature') ? JSON.parse(sessionStorage.getItem('signature')).serviceLineId.toLowerCase() : '';
		
		this.props.rdxGetColor(signature);

		// await this.props.rdxClearDashboard(true);
		this.props.rdxPostQtdChamadosFechados();
		this.props.rdxPostQtdChamadosAbertos();
	}

	render() {
		const { qtdChamadosFechados } = this.state;
		const { 
			color,
			quantidadeChamadosFechadosLoading, 
			quantidadeChamadosEmAbertoLoading, 
			rdxIndicadorClicked,
			quantidadeChamadosFechados
		} = this.props;

		const chamadosFechados = 
			quantidadeChamadosFechadosLoading || quantidadeChamadosEmAbertoLoading ? 
			<i className="simple-loading"></i> : qtdChamadosFechados;

		return (
			<div className="row principal-indicators">
				<div className="col-12 col-sm-6">
					<a href={"https://www.bhs.com.br/manuais-de-utilizacao/"} target="_blank" className="img-indicator">
						<img src={TutoriaisImg} />
					</a>
				</div>

				<div className="col-12 col-sm-6" onClick={() => rdxIndicadorClicked(2)}>
					<Link to={`/chamados`} className="indicator" >
						<div>
							<span className="value" style={{ color }}>{quantidadeChamadosFechados}</span>
							<span className="label">Atendimentos concluídos</span>
						</div>

						<img src={AtendimentoIcon} alt="Atendimentos concluídos"/>
					</Link>
				</div>
			</div>
		)
	};
}

export default connect(
	state => ({
		color: state.global.color,
		quantidadeChamadosEmAberto: state.dashboard.quantidadeChamadosEmAberto,
		quantidadeChamadosEmAbertoLoading: state.dashboard.quantidadeChamadosEmAbertoLoading,
		quantidadeChamadosFechados: state.dashboard.quantidadeChamadosFechados,
		quantidadeChamadosFechadosLoading: state.dashboard.quantidadeChamadosFechadosLoading,
	}),
	(dispatch) => (bindActionCreators({
			rdxGetColor, rdxPostQtdChamadosFechados: rdxPostObterQtdChamadosFechados, rdxPostQtdChamadosAbertos: rdxPostObterQtdChamadosAbertos, rdxClearDashboard, rdxIndicadorClicked
	}, dispatch))
)(Indicadores);