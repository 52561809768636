import React, { Component } from 'react'
import logo from '../../assets/images/logo.svg'

export default class MasterError extends Component {
    render() {
        return (
            <div className={ "error-page " + this.props.classContainer }>
                <div className="error-container">
                    <img src={ logo } alt="BHS Axter" className="logo"/>
                    <div className="error-text-container">
                        <h1>{ this.props.error }</h1>
                        <span className="error-title">{ this.props.title }</span>
                        <span className="error-text">{ this.props.text }</span>
                        <div className="error-actions">
                            { this.props.children }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
