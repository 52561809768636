const DateUtils = {
  /**
   * 
   * @param {Date} date 
   * @param {"dd/MM/YYYY"|"dd/MM"} format O formato desejado para a string (opcional, padrão é "dd/MM/YYYY")
   * @returns {string}
   */
  DateFormat(date, format = "dd/MM/YYYY") {
    switch (format) {
      case 'dd/MM':
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1)
        ].join('/');
      case 'dd/MM/YYYY':
      default:
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
    }
  },
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  },
  /**
   * Retorna um objeto Date subtraindo os valores passados de Ano e Dias.
   * @param {number} days Quantidade de dias antecedentes ao dia atual
   * @param {number} [years=0] Quantidade de anos antecedentes ao dia atual (opcional, padrão é 0)
   * @returns {Date} O Objeto Date com a data obtida
   */
  GetPastDate(days, years = 0) {
    let today = new Date();
    let pastDate = new Date(today);

    if (years > 0) {
      pastDate.setFullYear(today.getFullYear() - years);
    }
    if (days > 0) {
      pastDate.setDate(pastDate.getDate() - days)
    }
    return pastDate;
  }
}
export default DateUtils;
