import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import urlBase from "../../assets/urlsBase";

export async function checkBundleLimit(bundleId){
  const userSignature = HttpRequest.userSignature();
  const _header = await HttpRequest.apiTokenHeader();

  const url = `${urlBase.urlBase}/portaldb/Produtos/ChecarDisponibilidadePorPacote?tenantCode=${userSignature.tenantCode}&pacoteId=${bundleId}`;

  const params = { method: 'GET', headers: _header };

  return fetch(url, params)
    .then(response => response.json())
    .then(response => response)
    .catch(error => {
      return {
        code: "-1",
        message: "Serviço indisponível.",
        messagedetails: "Houver algum erro durante a validação da licença, tente novamente."
      }
    });
}
