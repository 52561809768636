import * as HttpRequest from "EstruturaAntiga/assets/httpRequest"
import urlBase from "../../assets/urlsBase"
import axios from 'axios'

export default async function postCancelarAssinatura(data) {
	try{		
		let url = `${urlBase.urlBase}/portaldb/AssinaturasCsp/Cancelar`;
		axios.defaults.headers = await HttpRequest.axiosApiTokenHeader();

		const result = await axios.post(url, data);
		return Promise.resolve(result.data);
  	} catch (error) {
		return Promise.reject(error);
	}
}
