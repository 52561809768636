import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import * as pbi from 'powerbi-client';

import getAccessToken from '../../actions/bi/getAccessToken';

export function Report({ reportName }) {
  const [loading, setLoading] = useState(true);
  const [biToken, setBiToken] = useState({});

  useEffect(()=>{
    const ObterAccessToken = async()=>{
      const token = await getAccessToken(reportName);
      setBiToken(token)
      setLoading(false)
    }
    ObterAccessToken()
  }, [])

  if(loading){
    return (
      <div className="block-loading">
        <div className="simple-loading"></div>
      </div>
    )
  } else if (!!biToken.token) {
    return (
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: biToken.reportData.id,
          embedUrl: biToken.embedURL,
          accessToken: biToken.token,
          tokenType: pbi.models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              },
              pageNavigation: {
                expanded: false,
                visible: false
              }
            }
          }
        }}
      />
    )
  } else {
    return (
      <div className="mkt-lead azure">
        <div className="mkt-content">
          <h2>Ops...</h2>
          <p>Não conseguimos conectar no serviço Microsoft Power BI. Por favor, entre em contato com nosso atendimento.</p>
        </div>
      </div>
    )
  }
}
